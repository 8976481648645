<template>
  <v-card
    class="fill-height"
    data-testid="assessment-messages"
    elevation="2"
    tile
  >
    <v-card-title class="bb-1 bc-very-light-grey fs-16 fw-600 pa-5 d-flex">
      <span
        v-t="'Messages'"
        tabindex="0"
      />
      <v-spacer />
      <v-btn
        @click="$emit('close')"
        aria-label="Close Messages"
        class="focus-visible"
        icon="close"
        variant="text"
      />
    </v-card-title>
    <v-card-text class="pt-4">
      <p
        v-if="messages.length == 0"
        v-t="'No messages sent yet.'"
        class="c-light-black fs-14"
        tabindex="0"
      />
      <AssessmentMessage
        v-for="message in messages"
        :key="message.id"
        :message="message"
        class="mb-3"
        tabindex="0"
      />

      <v-divider class="my-6" />

      <div
        class="w-100pc br-0 mb-3"
        style="height: 242px"
      >
        <div class="pa-2">
          <v-textarea
            v-model="newMessageText"
            aria-label="Type message"
            class="mb-2"
            data-cy="message-textarea"
            rows="4"
            variant="filled"
            hide-details
          />
          <v-btn
            @click="createMessage"
            :disabled="!newMessageText"
            :loading="processing"
            color="primary"
            block
          >
            Send message
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import AssessmentMessage from './AssessmentMessage.vue';
import { onMounted } from 'vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const store = useStore();

const props = defineProps({
  assessment: {
    type: Object,
    default: null,
  },
  businessId: {
    type: String,
    default: '',
  },
});

defineEmits(['close']);

const messages = ref([]);
const unreadMessages = ref([]);
const newMessageText = ref(null);
const processing = ref(null);

async function createMessage() {
  processing.value = true;

  const resp = await Api.organization.message
    .create({
      business_id: props.businessId,
      assessment_id: props.assessment.id,
      text: newMessageText.value,
    })
    .catch((error) => eventBus.error(error));

  processing.value = false;
  if (!resp?.data) return;

  newMessageText.value = null;
  messages.value.push(resp.data);
}

async function loadMessages() {
  const resp = await Api.organization.message.index({
    days: 10000,
    business_id: props.businessId,
    assessment_id: props.assessment.id,
  });
  messages.value = resp.data.reverse();
  const unreadMessagesFromOthers = messages.value.filter(
    (message) => !!message.read_at && message.member_id !== store.state.profile.id,
  );
  unreadMessages.value = unreadMessagesFromOthers.filter(
    (message) => !message.meta.member_is_specialist,
  );
}

onMounted(loadMessages);
</script>
