<template>
  <v-container
    v-if="siteTemplate && pageTemplate"
    fluid
  >
    <v-row>
      <v-col>
        <div
          @click="$router.push({ name: 'SiteTemplateIndex' })"
          class="fw-600 bc-h5 d-inline-block me-2 bc-link-hover"
        >
          Templates
        </div>
        <div class="d-inline-block me-2">
          <v-icon class="fs-26 c-black va-sub"> chevron_right </v-icon>
        </div>
        <div
          @click="
            $router.push({ name: 'SiteTemplateShow', params: { siteTemplateId: siteTemplate.id } })
          "
          v-text="siteTemplate.name"
          class="fw-600 bc-h5 bc-link-hover d-inline-block me-2"
        />
        <div class="fw-600 bc-h5 d-inline-block me-2">
          <v-icon class="c-black"> chevron_right </v-icon>
        </div>
        <div
          v-text="pageTemplate.name"
          class="fw-600 bc-h5 d-inline-block me-2"
        />
      </v-col>
    </v-row>
    <PageTemplateForm
      @save="refresh()"
      :page-template="pageTemplate"
      :site-template="siteTemplate"
      class="mb-4"
    />
    <PageTemplateConfig
      :page-template="pageTemplate"
      :site-template="siteTemplate"
      class="mb-4"
    />
    <PageTemplateDanger
      @save="refresh()"
      :page-template="pageTemplate"
      :site-template="siteTemplate"
      class="mb-4"
    />
  </v-container>
</template>

<script setup>
import Api from '@/admin/services/bright_finder';
import PageTemplateConfig from '@/admin/components/page-templates/PageTemplateConfig.vue';
import PageTemplateDanger from '@/admin/components/page-templates/PageTemplateDanger.vue';
import PageTemplateForm from '@/admin/components/page-templates/PageTemplateForm.vue';
import { useRoute } from 'vue-router';

defineProps({
  siteTemplate: {
    type: Object,
    default: null,
  },
});

const route = useRoute();
const emit = defineEmits(['refresh']);
const pageTemplate = ref(null);

watch(() => route.params, load);

function load() {
  Api.organization.siteTemplate.pageTemplate.get(
    route.params.siteTemplateId,
    route.params.pageTemplateId,
    (resp) => {
      pageTemplate.value = resp.data;
    },
  );
}

function refresh() {
  load();
  emit('refresh');
}

onMounted(load);
</script>
