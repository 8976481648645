<template>
  <ResourceDialog
    @save="$emit('save', $event)"
    ref="dialog"
    :processing="processing"
    title="Edit team"
  >
    <template #form="{ localValue }">
      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            v-model="localValue.value"
            :disabled="disabled"
            :items="teams"
            item-title="name"
            item-value="id"
            label="Assign to"
            placeholder="Assign to"
            variant="filled"
            hide-details
          />
        </v-col>
      </v-row>
      <v-checkbox
        v-model="disabled"
        @update:model-value="localValue.value = null"
        class="mt-0"
        label="Unassign team"
        hide-details
        inset
        tile
      />
    </template>
  </ResourceDialog>
</template>

<script setup>
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

defineEmits(['save']);

defineProps({
  processing: Boolean,
  teams: {
    type: Array,
    default: () => [],
  },
});

const dialog = ref(null);
const disabled = ref(false);

const close = () => {
  if (disabled.value) {
    disabled.value = false;
  }

  dialog.value.close();
};

const open = () => {
  dialog.value.open({ value: null });
};

defineExpose({ open, close });
</script>
