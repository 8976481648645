<template>
  <v-card class="pa-6">
    <v-row dense>
      <v-col cols="12">
        <div class="fs-28 fw-600 c-black mb-3">Configurations</div>
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
      >
        <div class="fs-18 fw-600 c-black">Edit your advanced configuration file.</div>
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
      >
        <v-card>
          <prism-editor
            v-model="pageTemplate.config"
            :highlight="highlighter"
            class="my-editor"
          />
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-btn
          @click="save()"
          :disabled="!valid"
          color="primary"
          size="x-large"
          rounded
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import Api from '@/admin/services/bright_finder';
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles
import useEventBus from '@/shared/composables/useEventBus';

const props = defineProps({
  siteTemplate: {
    type: Object,
    required: true,
  },
  pageTemplate: {
    type: Object,
    required: true,
  },
});

const eventBus = useEventBus();

const valid = ref(false);
watch(
  () => props.pageTemplate.config,
  (newVal, _oldValue) => {
    try {
      JSON.parse(newVal);
      valid.value = true;
    } catch (_error) {
      valid.value = false;
    }
  },
);
function highlighter(code) {
  return highlight(code, languages.json); // languages.<insert language> to return html with markup
}

function save() {
  Api.organization.siteTemplate.pageTemplate.update(
    props.siteTemplate.id,
    props.pageTemplate.id,
    { config: JSON.parse(props.pageTemplate.config), name: props.pageTemplate.name },
    () => {
      eventBus.chime('Configuration saved.');
    },
    (err) => {
      eventBus.chime(err.response.data.errors[0]);
    },
  );
}
</script>

<style>
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #fff;
  color: #38beb5;

  /* you must provide font-family font-size line-height. Example: */
  font-family:
    Fira code,
    Fira Mono,
    Consolas,
    Menlo,
    Courier,
    monospace;
  font-size: 12px;
  line-height: 1.5;
  padding: 10px;
}

.token.punctuation {
  color: #38beb5;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
