<template>
  <v-container class="mxw-1200">
    <router-view />
  </v-container>
</template>

<script setup>
import childSubsidyProgramLinks from '@/admin/services/child-subsidy-program-links';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const links = ref([...childSubsidyProgramLinks]);

onBeforeUnmount(() => {
  store.commit('setNavigation', null);
});

onMounted(() => {
  load();
});

function load() {
  store.commit('setNavigation', null);

  if (store.state.profile.org_iep_referrals_admin) {
    links.value.push({
      title: 'IEP',
      route: 'ChildSubsidyProgramIEPReferrals',
    });
  }

  store.commit('setNavigation', [
    {
      icon: 'chevron_left',
      type: 'back',
      title: 'Back',
      route: 'ChildSubsidyProgramIndex',
    },
    {
      type: 'section',
      items: links.value.map((link) => ({
        ...link,
        params: { subsidyProgramId: route.params.subsidyProgramId },
      })),
    },
  ]);
}
</script>
