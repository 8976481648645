<template>
  <v-container
    v-if="familySubsidyProgram"
    class="mxw-1200"
  >
    <TitleBar
      :back-name="familySubsidyProgram.name"
      :back-route="{ name: 'FamilySubsidyProgramShow' }"
      name="Stages"
    >
      <template #actions>
        <template v-if="familySubsidyProgram.enable_stages">
          <v-btn
            @click="$refs.newStageDialog.open({})"
            color="primary"
          >
            <span v-t="'New'" />
          </v-btn>
        </template>
      </template>
    </TitleBar>

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <LabeledSwitch
            v-model="familySubsidyProgram.enable_stages"
            @input="changed = true"
            subtitle="You can customize your application review process by creating stages for each step in your workflow."
            title="Enable stages"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <template v-if="familySubsidyProgram.stages.length == 0">
      <NullState
        @new="$refs.newStageDialog.open({})"
        :new-button-disabled="!familySubsidyProgram.enable_stages"
        new-button-title="New stage"
      />
    </template>

    <div
      v-for="(stage, index) in familySubsidyProgram.stages"
      :key="index"
      class="mb-4"
    >
      <StageCard
        @change="changed = true"
        @move="reorder(index, index + $event)"
        @remove="remove(index)"
        :features="features"
        :index="index"
        :length="familySubsidyProgram.stages.length"
        :owners="members"
        :stage="stage"
        :teams="teams"
      />
    </div>

    <ResourceDialog
      @save="addStage"
      ref="newStageDialog"
      :fields="stageValues"
      :processing="processing"
      title="New stage"
    />

    <VerticalSpacer :min-height="24" />

    <SaveBar
      @cancel="load"
      @save="save"
      :model-value="changed"
      :processing="processing"
      flat
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder.js';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import StageCard from '@/specialist/components/StageCard.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import NullState from '@/shared/components/NullState.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute } from 'vue-router';
import { v4 as uuidv4 } from 'uuid';

const changed = ref(false);
const eventBus = useEventBus();
const familySubsidyProgram = ref(null);
const features = ref([]);
const members = ref([]);
const newStageDialog = ref(null);
const processing = ref(false);
const route = useRoute();
const stageValues = ref([{ text: 'Title', value: 'title' }]);
const teams = ref([]);

onMounted(async () => {
  await load();
});

function addStage(stage) {
  familySubsidyProgram.value.stages.push({ ...stage, id: uuidv4() });
  newStageDialog.value.close();
  changed.value = true;
}

function getEnabledFeatures() {
  const feats = [
    { title: 'Enable application', field: 'enable_application' },
    { title: 'Enable reviews', field: 'enable_reviews' },
  ];

  if (familySubsidyProgram.value.enable_claims)
    feats.push({ title: 'Enable claims', field: 'enable_claims' });

  return feats;
}

async function load() {
  await loadMembers();
  await loadTeams();
  await loadFamilySubsidyProgram();
  features.value = getEnabledFeatures();
  changed.value = false;
}

async function loadFamilySubsidyProgram() {
  const resp = await Api.organization.family_subsidy_program.get(route.params.id);
  familySubsidyProgram.value = resp.data;
}

async function loadMembers() {
  const resp = await Api.organization.member.index({ is_specialist: true });
  members.value = resp.data;
}

async function loadTeams() {
  const resp = await Api.organization.team.index();
  teams.value = resp.data;
}

async function remove(index) {
  if (!confirm('Are you sure you want to remove this stage?')) return;

  familySubsidyProgram.value.stages.splice(index, 1);
  changed.value = true;
  await save();
}

function reorder(fromIndex, toIndex) {
  const element = familySubsidyProgram.value.stages[fromIndex];
  familySubsidyProgram.value.stages.splice(fromIndex, 1);
  familySubsidyProgram.value.stages.splice(toIndex, 0, element);
  changed.value = true;
}

async function save() {
  processing.value = true;
  const params = {
    id: familySubsidyProgram.value.id,
    enable_stages: familySubsidyProgram.value.enable_stages,
    stages: familySubsidyProgram.value.stages,
  };
  const resp = await Api.organization.family_subsidy_program
    .update(params)
    .catch((error) => eventBus.error(error));
  processing.value = false;

  if (resp?.data) changed.value = false;
}
</script>
