<template>
  <v-container
    v-if="familySubsidyProgram && groupSchema && familySubsidySchema"
    class="mxw-1200"
  >
    <TitleBar
      :back-name="familySubsidyProgram.name"
      :back-route="{ name: 'FamilySubsidyProgramShow' }"
      name="Eligibility"
    >
      <template #actions>
        <v-btn
          @click="updateQuery({ action: 'new' })"
          color="primary"
        >
          <span v-t="'New'" />
        </v-btn>
      </template>
    </TitleBar>

    <NullState
      v-if="fundingSources.length == 0"
      @new="draftFundingSource"
    />

    <v-row
      v-for="source in fundingSources"
      :key="source.id"
      class="mb-4"
    >
      <v-col>
        <FundingSourceCard
          @destroy="loadFundingSources"
          @move="reorder(source, $event)"
          :display-index="getDisplayPositionIndex(source)"
          :schemas="schemas"
          :source="source"
          :total-count="fundingSources.length"
          ordered
        />
      </v-col>
    </v-row>

    <ResourceDialog
      @save="createFundingSource"
      :fields="fundingSourceFields"
      :processing="processing"
      action="new"
      save-button-text="Create"
      title="New funding source"
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import FundingSourceCard from '@/admin/components/FundingSourceCard.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import TitleBar from '@/shared/components/TitleBar.vue';
import { useRoute } from 'vue-router';

const OWNER_TYPE = 'FamilySubsidyProgram';

const route = useRoute();
const groupSchema = ref(null);
const familySubsidySchema = ref(null);
const familySubsidyProgram = ref(null);
const funds = ref([]);
const fundingSources = ref([]);
const processing = ref(false);
const { updateQuery } = useRouterHelper();

const fundingSourceFields = computed(() => [
  { required: true, text: 'Name', value: 'name' },
  {
    required: true,
    text: 'Fund',
    value: 'fund_id',
    items: funds.value,
    itemText: 'name',
    itemValue: 'id',
  },
]);

const schemas = computed(() => [groupSchema.value, familySubsidySchema.value]);

onMounted(async () => {
  await loadFundingSources();
  await loadFamilySubsidyProgram();
  await loadSchemas();
  await loadFunds();
});

async function createFundingSource(newVal) {
  processing.value = true;
  const resp = await Api.organization.funding_source.create({
    ...newVal,
    owner_id: route.params.id,
    owner_type: OWNER_TYPE,
  });
  processing.value = false;
  await updateQuery({ action: null });
  fundingSources.value.push(resp.data);
}

async function draftFundingSource() {
  await updateQuery({ action: 'new' });
}

function getDisplayPositionIndex(source) {
  if (source.display_order !== null) return source.display_order - 1;

  return fundingSources.value.indexOf(source);
}

async function loadFunds() {
  const resp = await Api.organization.fund.index({});
  funds.value = resp.data;
}

async function loadFundingSources() {
  const resp = await Api.organization.funding_source.index({
    owner_id: route.params.id,
  });
  fundingSources.value = resp.data;
}

async function loadSchemas() {
  let resp;
  resp = await Api.organization.schema.get(familySubsidyProgram.value.group_schema_id);
  groupSchema.value = resp.data;
  resp = await Api.organization.schema.get(familySubsidyProgram.value.family_subsidy_schema_id);
  familySubsidySchema.value = resp.data;
}

async function loadFamilySubsidyProgram() {
  const resp = await Api.organization.family_subsidy_program.get(route.params.id);
  familySubsidyProgram.value = resp.data;
}

async function reorder(source, displayOrderChange) {
  const params = {
    display_order_was: source.display_order,
    display_order: getDisplayPositionIndex(source) + 1 + displayOrderChange,
  };

  await Api.organization.funding_source.reorder(source.id, params);
  await loadFundingSources();
}
</script>
