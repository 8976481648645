<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle :title="$store.state.pages.Assessments.title || 'Assessments'" />

    <v-toolbar
      class="mb-3"
      elevation="5"
      extension-height="60"
      extended
    >
      <v-row class="d-flex align-center px-4">
        <v-col class="mxw-400">
          <v-select
            v-model="filters.schema_id"
            @update:model-value="load()"
            :aria-label="selectedSchema?.name"
            :items="assessmentSchemas"
            density="compact"
            item-title="name"
            item-value="id"
            variant="filled"
            hide-details
          >
            <template #prepend-inner>
              <v-icon
                class="me-1 pt-2px"
                color="primary"
                icon="poll"
              />
            </template>
          </v-select>
        </v-col>
        <v-col class="mxw-340">
          <v-text-field
            v-model="query"
            id="assessments_search_link"
            :aria-label="$t('Search your results')"
            :placeholder="$t('Search your results')"
            color="secondary"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
            tracked
          />
        </v-col>

        <v-col class="ta-right">
          <v-btn
            @click="$refs.newAssessmentDialog.open()"
            color="primary"
            prepend-icon="add"
          >
            {{ $t('New') }}
          </v-btn>

          <ResourceDialog
            @save="create"
            @search="loadNewProviders"
            ref="newAssessmentDialog"
            :fields="newAssessmentFields"
            title="Start new assessment"
          />
        </v-col>
      </v-row>

      <template #extension>
        <v-row class="c-black justify-space-between dense px-4">
          <v-col cols="1">
            <v-btn
              @click.stop.prevent="draftAssignment()"
              :disabled="!selectedAnyItems"
              color="primary"
              variant="outlined"
            >
              {{ $t('Assign') }}
            </v-btn>
          </v-col>
          <v-col
            class="ta-right"
            cols="11"
          >
            <FilterMenu
              :active="!!filters.reviewer_id"
              classes="d-none d-md-inline-flex"
              title="Assigned to"
            >
              <template #card>
                <v-autocomplete
                  v-model="filters.reviewer_id"
                  @update:model-value="load()"
                  :items="assignedFilterItems"
                  :placeholder="$t('Select assignee')"
                  item-title="name"
                  item-value="id"
                  variant="filled"
                  clearable
                />
              </template>
            </FilterMenu>

            <FilterMenu
              :active="!!filters.status"
              classes="d-none d-md-inline-flex"
              title="Status"
              paddingless
            >
              <template #card>
                <v-list
                  v-model:selected="filters.status"
                  @update:selected="load()"
                  :item-value="(v) => v"
                  :items="[$t('Requested'), $t('In progress'), $t('Completed')]"
                >
                  <template #prepend="{ isSelected }">
                    <v-checkbox-btn
                      :model-value="isSelected"
                      false-icon="check_box_outline_blank"
                      tabindex="-1"
                      true-icon="check_box"
                    />
                  </template>
                </v-list>
              </template>
            </FilterMenu>

            <FilterMenu
              :active="!!filters.county"
              classes="d-none d-md-inline-flex"
              title="County"
            >
              <template #card>
                <v-autocomplete
                  v-model="filters.county"
                  @update:model-value="load()"
                  :items="$store.state.pages.Assessments.counties"
                  :placeholder="$t('Select a county')"
                  variant="filled"
                  clearable
                />
              </template>
            </FilterMenu>

            <FilterMenu
              v-if="selectedSchema?.enable_stages"
              :active="!!filters.stage_id"
              classes="d-none d-md-inline-flex"
              title="Stage"
              paddingless
            >
              <template #card>
                <v-list
                  v-model:selected="filters.stage_id"
                  @update:selected="load()"
                  :items="selectedSchema.stages"
                  item-title="title"
                  item-value="id"
                >
                  <template #prepend="{ isSelected }">
                    <v-checkbox-btn
                      :model-value="isSelected"
                      false-icon="check_box_outline_blank"
                      tabindex="-1"
                      true-icon="check_box"
                    />
                  </template>
                </v-list>
              </template>
            </FilterMenu>
          </v-col>
        </v-row>
      </template>
    </v-toolbar>

    <v-container
      class="px-3 py-0"
      fluid
    >
      <v-card
        class="bb-1 bc-extra-light-gray pa-0"
        tile
      >
        <v-card-text class="pa-3">
          <v-card
            class="bb-1 bc-extra-light-gray mb-4"
            flat
            tile
          >
            <v-card-text class="pt-0 px-0 pb-3 fs-15">
              <v-row dense>
                <v-col class="d-flex align-center">
                  <template v-if="filterSummary">
                    <v-icon
                      icon="filter_alt"
                      start
                    />
                    <span class="me-1"> {{ $t('Filtering by') }}: </span>
                    <span class="fw-600">{{ $t(filterSummary) }}</span>
                    <span>.&nbsp;</span>
                  </template>
                  <span
                    >{{ $t('Displaying first') }} <strong>{{ collection.length }}</strong>
                    {{ $t('results') }}, {{ $t('out of') }} <strong>{{ total }}</strong>
                    {{ $t('total results') }}.</span
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-row
            v-if="selectedSchema"
            dense
          >
            <v-col
              class="fw-600 fs-16 c-black"
              cols="3"
            >
              <div class="d-flex align-center fw-600 fs-16 c-black">
                <v-checkbox-btn
                  v-model="selectedAll"
                  :aria-label="$t('Select all assessments')"
                  class="d-inline-flex flex-none pa-0 me-2 va-middle"
                  density="compact"
                  false-icon="check_box_outline_blank"
                  true-icon="check_box"
                />

                <v-btn
                  @click="toggleSort('name')"
                  class="h-inherit pa-0 ma-0"
                  variant="text"
                >
                  <span class="fs-14 fw-600 c-black">
                    {{ $t('Provider name') }}
                  </span>
                  <v-icon class="c-black ms-1">
                    {{ sortDirection['name'] == 'desc' ? 'arrow_drop_down' : 'arrow_drop_up' }}
                  </v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col class="fw-600 fs-16 c-black">
              <v-btn
                @click="toggleSort('county')"
                class="h-inherit pa-0 ma-0"
                variant="text"
              >
                <span class="fs-14 fw-600 c-black">
                  {{ $t('County') }}
                </span>
                <v-icon class="c-black ms-1">
                  {{ sortDirection['county'] == 'desc' ? 'arrow_drop_down' : 'arrow_drop_up' }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col class="fw-600 fs-16 c-black">
              <v-btn
                @click="toggleSort('reviewer_id')"
                class="h-inherit pa-0 ma-0"
                variant="text"
              >
                <span class="fs-14 fw-600 c-black">
                  {{ $t('Assigned to') }}
                </span>
                <v-icon class="c-black ms-1">
                  {{ sortDirection['reviewer_id'] == 'desc' ? 'arrow_drop_down' : 'arrow_drop_up' }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col class="fw-600 fs-16 c-black">
              <v-btn
                @click="toggleSort('created_at')"
                class="h-inherit pa-0 ma-0"
                variant="text"
              >
                <span class="fs-14 fw-600 c-black">
                  {{ $t('Opened') }}
                </span>
                <v-icon class="c-black ms-1">
                  {{ sortDirection['created_at'] == 'desc' ? 'arrow_drop_down' : 'arrow_drop_up' }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col v-show="selectedSchema.enable_stages">
              <v-btn
                @click="toggleSort('stage_id')"
                class="h-inherit pa-0 ma-0"
                variant="text"
              >
                <span class="fs-14 fw-600 c-black">
                  {{ $t('Stage') }}
                </span>
                <v-icon class="c-black">
                  {{ sortDirection['stage_id'] == 'desc' ? 'arrow_drop_down' : 'arrow_drop_up' }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                @click="toggleSort('status')"
                class="h-inherit pa-0 ma-0"
                variant="text"
              >
                <span class="fs-14 fw-600 c-black">
                  {{ $t('Status') }}
                </span>
                <v-icon class="c-black ms-1">
                  {{ sortDirection['status'] == 'desc' ? 'arrow_drop_down' : 'arrow_drop_up' }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="selectedSchema.meta.enable_priority_scoring"
              class="ta-center"
            >
              <v-btn
                @click="toggleSort('score')"
                class="h-inherit pa-0 ma-0"
                variant="text"
              >
                <span class="fs-14 fw-600 c-black">
                  {{ $t('Store') }}
                </span>
                <v-icon class="c-black ms-1">
                  {{ sortDirection['score'] == 'desc' ? 'arrow_drop_down' : 'arrow_drop_up' }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card
        v-for="(assessment, index) in collection"
        :key="index"
        class="pointer bb-1 bc-extra-light-gray pa-0"
        tile
      >
        <v-card-text class="pa-3">
          <v-row class="d-flex align-center">
            <v-col
              class="c-black fs-14 fw-500"
              cols="3"
            >
              <div class="d-flex align-center fw-500 fs-14 c-black">
                <v-checkbox-btn
                  v-model="selectedItems[assessment.id]"
                  @click.stop
                  :aria-label="$t('Select') + ' ' + assessment.provider_name"
                  class="d-inline-flex me-2 flex-0-0"
                  density="compact"
                  false-icon="check_box_outline_blank"
                  true-icon="check_box"
                />
                <router-link
                  :to="{ name: 'AssessmentShow', params: { assessmentId: assessment.id } }"
                  data-cy="assessment-name"
                >
                  <div v-text="assessment.provider_name" />
                </router-link>
              </div>
            </v-col>
            <v-col class="c-black fs-14 fw-500">
              <span
                v-text="assessment.provider_county"
                data-cy="assessment-county"
              />
            </v-col>
            <v-col class="c-black fs-14 fw-500">
              <span
                v-if="assessment.reviewer_name"
                v-text="assessment.reviewer_name"
                data-cy="assessment-reviewer"
              />
            </v-col>
            <v-col class="c-black fs-14 fw-500">
              <LongDate
                :date="assessment.created_at"
                data-cy="assessment-reviewer"
              />
            </v-col>
            <v-col
              v-show="selectedSchema.enable_stages"
              class="c-black fs-14 fw-500"
            >
              <template v-if="assessment.stage_id && stages[assessment.stage_id]">
                <span data-cy="assessment-reviewer">
                  {{ $t(stages[assessment.stage_id].title) }}
                </span>
              </template>
            </v-col>
            <v-col class="c-black fs-14 fw-500">
              <span data-cy="assessment-reviewer">{{ $t(assessment.status) }}</span>
            </v-col>
            <v-col
              v-if="selectedSchema.meta.enable_priority_scoring"
              class="c-black fs-14 fw-500 ta-center"
            >
              <v-chip
                :color="getAssessmentScoreColor(assessment.score)"
                class="w-48 justify-center"
              >
                {{ assessment.score || '0' }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <div
        v-show="processing"
        class="mxw-800 mx-auto py-12 ta-center"
      >
        <v-progress-circular
          color="primary"
          size="75"
          indeterminate
        />
      </div>

      <v-row
        v-show="!processing && collection.length == 0"
        class="justify-center px-2"
      >
        <v-col cols="3">
          <NullState hide-new-button />
        </v-col>
      </v-row>

      <v-pagination
        v-show="collection.length > 0"
        v-model="page"
        :length="pages"
        :total-visible="8"
        class="mt-8"
      />

      <VerticalSpacer />
    </v-container>

    <v-dialog
      v-model="assignDialogIsVisible"
      max-width="768"
    >
      <v-card class="pt-3">
        <v-card-title>{{ $t('Assign assessments') }}</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-select
                v-model="assignTo"
                :items="assignedFilterItems"
                :label="$t('Assign to')"
                :placeholder="$t('Assign to')"
                item-title="name"
                item-value="id"
                variant="outlined"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="assignDialogIsVisible = false"
            variant="text"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="assign()"
            :disabled="!assignTo"
            color="primary"
          >
            {{ $t('Assign') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/specialist/services/bright_finder';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import LongDate from '@/shared/components/LongDate.vue';
import NullState from '@/shared/components/NullState.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';

const eventBus = useEventBus();
const router = useRouter();
const store = useStore();

const assignDialogIsVisible = ref(false);
const assignedFilterItems = ref([]);
const assignTo = ref(null);
const filters = reactive(getDefaultFilters());
const newProviderFilterItems = ref([]);
const selectedSchema = ref(null);
const selectedAll = ref(false);
const selectedItems = ref({});
const sort = ref(null);
const sortDirection = reactive({
  created_at: 'desc',
  score: 'desc',
});
const collection = ref([]);
const page = ref(1);
const pages = ref(1);
const processing = ref(false);
const query = ref('');
const queryCount = ref(0);
const stages = ref([]);
const total = ref(0);

const assessmentSchemas = computed(() => {
  const unorderedSchemas = Object.values(store.state.schemas).filter(
    (schema) => schema.data_type === 'Assessment',
  );

  return _.orderBy(unorderedSchemas, [(schema) => schema.name.toLowerCase()], ['asc']);
});

const filterSummary = computed(() => {
  const appliedFilters = [];
  if (filters.status) appliedFilters.push(filters.status);
  if (filters.stage_id) {
    const stages = getStages(selectedSchema.value);
    const stage = stages[filters.stage_id];
    if (stage) appliedFilters.push(stage.title);
  }
  if (filters.county) appliedFilters.push(filters.county);
  if (filters.reviewer_id) {
    appliedFilters.push(
      assignedFilterItems.value.find(
        (assignedFilterItem) => assignedFilterItem.id === filters.reviewer_id,
      )?.name,
    );
  }

  return appliedFilters.length > 0 ? appliedFilters.join(', ') : 'All';
});

const newAssessmentFields = computed(() => {
  return [
    {
      text: 'Type of assessment',
      value: 'schema_id',
      items: assessmentSchemas.value,
      itemText: 'name',
      itemValue: 'id',
      required: true,
    },
    {
      text: 'Provider',
      value: 'provider_id',
      itemText: 'name',
      itemValue: 'id',
      items: newProviderFilterItems.value,
      required: true,
      search: true,
    },
  ];
});

const selectedAnyItems = computed(() => {
  return Object.values(selectedItems.value).some((item) => item === true);
});

watch(
  () => page.value,
  () => load(page.value),
);

watch(
  () => query.value,
  () => load(),
);

watch(selectedAll, (newVal) => selectAll(newVal));

onMounted(() => {
  load();
  loadSpecialists();
});

function assign() {
  collection.value.forEach((assessment, index) => {
    if (selectedItems.value[assessment.id]) {
      Api.organization.assessment.update(assessment.id, { reviewer_id: assignTo.value }, (resp) => {
        Object.assign(collection.value[index], resp.data);
      });
      assignDialogIsVisible.value = false;
    }
    selectedItems.value[assessment.id] = false;
  });
  selectedAll.value = false;
}

function create(newVal) {
  processing.value = true;
  Api.organization.assessment.create(
    newVal,
    (resp) => {
      router.push({ name: 'AssessmentShow', params: { assessmentId: resp.data.id } });
    },
    (err) => {
      eventBus.error(err);
    },
  );
}

function getDefaultFilters() {
  if (store.state.filters.assessment) return store.state.filters.assessment;

  return {
    county: null,
    label: null,
    status: null,
    schema_id: null,
    page: 1,
    query: null,
    stage_id: null,
    reviewer_id: null,
    team_id: null,
  };
}

function draftAssignment() {
  assignDialogIsVisible.value = true;
}

function getAssessmentScoreColor(score) {
  const defaultColor = 'grey-lighten-2';

  if (!score) return defaultColor;
  if (score > 80) return 'blue-lighten-2';
  if (score > 50) return 'green-lighten-2';
  if (score > 30) return 'yellow-lighten-2';
  return defaultColor;
}

function getStages(schema) {
  const stageHash = {};
  schema.stages.forEach((stage) => (stageHash[stage.id] = stage));
  return stageHash;
}

function load(selectedPage) {
  if (!filters.schema_id) {
    selectedSchema.value = assessmentSchemas.value[0];
    filters.schema_id = assessmentSchemas.value[0].id;
  } else {
    selectedSchema.value = store.state.schemas[filters.schema_id];
  }
  stages.value = getStages(selectedSchema.value);

  page.value = selectedPage || 1;
  filters.page = page.value;
  filters.sort = sort.value;
  filters.sort_dir = sortDirection[sort.value];
  filters.query = query.value;

  store.commit('setFilters', ['assessment', filters]);
  const currentQueryCount = queryCount.value;

  Api.organization.assessment.index(filters, (resp) => {
    if (queryCount.value === currentQueryCount) {
      pages.value = parseInt(resp.headers['x-page-count'] || 0, 10);
      total.value = parseInt(resp.headers['x-total-count'] || 0, 10);
      collection.value = resp.data;
      selectedItems.value = {};
      selectedAll.value = false;
      processing.value = false;
    }
  });
}

function loadSpecialists() {
  Api.organization.member.index({ is_specialist: true }, (resp) => {
    assignedFilterItems.value = resp.data;
  });
}

function loadNewProviders(query) {
  if (query[0] === 'provider_id') {
    queryCount.value += 1;
    const currentQueryCount = queryCount.value;
    Api.organization.provider.index({ query: query[1], page_size: 200 }, (resp) => {
      if (currentQueryCount === queryCount.value) {
        newProviderFilterItems.value = resp.data.filter((provider) => !!provider.business_id);
      }
    });
  }
}

function selectAll(val) {
  collection.value.forEach((item) => {
    selectedItems.value[item.id] = val;
  });
}

function toggleSort(field) {
  sort.value = field;
  Object.keys(sortDirection).forEach((key) => {
    if (field !== key) sortDirection[key] = 'desc';
  });
  if (sortDirection[field] === 'desc') sortDirection[field] = 'asc';
  else sortDirection[field] = 'desc';

  load();
}
</script>
