<template>
  <v-expansion-panel
    :ripple="false"
    class="bg-transparent"
  >
    <v-expansion-panel-title disable-icon-rotate>
      <h2 class="text-h6 d-flex align-center">
        <v-avatar
          :color="questions.length > 0 ? 'primary' : 'grey'"
          class="me-3"
          size="30"
        >
          <span class="c-white fs-14">{{ questions.length }}</span>
        </v-avatar>
        <span v-t="title" />
      </h2>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <v-divider class="mb-6" />
      <v-row
        class="mb-2"
        dense
      >
        <v-col
          v-for="(question, index) in questions"
          :key="question.id"
          cols="12"
        >
          <QuestionForm
            @cancel="$emit('cancel')"
            @change="$emit('change')"
            @edit="$emit('edit', question)"
            @move="move(question, index, $event)"
            @settings="$emit('settings', question)"
            :answerable="answerable"
            :eligibility="eligibility"
            :index="index"
            :liquid-rendering-enabled="
              false /* assumes this component renders in a form admin where forms are authored */
            "
            :list-length="questions.length"
            :processing="processing || moving"
            :question="question"
            :schemas="schemas"
            :selected="selectedQuestion == question"
            :verification="verification"
          />

          <div
            v-if="!(index + 1 == questions.length)"
            class="align-center d-flex justify-center mt-2"
          >
            <v-btn
              @click="$emit('new', question)"
              class="me-3"
              color="primary"
              variant="text"
              icon
            >
              <v-icon>add</v-icon>
            </v-btn>
          </div>
        </v-col>

        <div class="align-center d-flex mt-4">
          <v-btn
            @click="$emit('new')"
            class="me-3"
            color="primary"
          >
            <span v-t="'Add question'" />
          </v-btn>

          <v-btn
            v-if="sections"
            @click="$emit('new-section')"
            color="primary"
          >
            <span v-t="'Add section'" />
          </v-btn>
        </div>
      </v-row>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script setup>
import Api from '@/admin/services/bright_finder';
import QuestionForm from '@/admin/components/QuestionForm.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const props = defineProps({
  answerable: Boolean,
  eligibility: Boolean,
  processing: Boolean,
  questions: {
    type: Array,
    required: true,
  },
  schemas: {
    type: Array,
    default: null,
  },
  sections: {
    default: true,
    type: Boolean,
  },
  selectedQuestion: {
    type: Object,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
  verification: {
    default: true,
    type: Boolean,
  },
});

const emit = defineEmits(['cancel', 'change', 'edit', 'new', 'new-section', 'settings']);

const moving = ref(false);

function move(question, index, amount) {
  const newIndex = index + amount;

  let newLeadingQuestionId;
  if (newIndex > index) {
    newLeadingQuestionId = props.questions[newIndex].id;
  } else {
    newLeadingQuestionId = props.questions[newIndex].leading_question_id;
  }

  if (question.id === newLeadingQuestionId) {
    eventBus.error(`Question is assigned to itself: ${question.id}`);
    return;
  }

  moving.value = true;
  Api.organization.question.update(
    question.id,
    { leading_question_id: newLeadingQuestionId, index: newIndex },
    () => {
      emit('change');
      moving.value = false;
    },
    (err) => {
      eventBus.chime(err.response.data.errors[0]);
      moving.value = false;
    },
  );
}
</script>

<style>
.v-expansion-panels--accordion .v-expansion-panel-header,
.v-expansion-panels--accordion .v-expansion-panel-content__wrap {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
