<template>
  <v-card
    id="licensing"
    class="mb-4"
    border
    flat
    tile
  >
    <v-card-title class="tab-title">
      <span v-t="'Change history'" />
    </v-card-title>

    <v-divider />

    <v-card-text class="my-4">
      <v-expansion-panels
        v-model="panel"
        flat
      >
        <v-expansion-panel
          v-for="revision in revisions"
          :key="revision.id"
          :value="revision.id"
          class="b-1 b-radius-0 bc-very-light-gray"
        >
          <v-expansion-panel-title>
            <div>
              <div class="mb-2 fs-16">
                <LongDateTime :date="revision.created_at" />
              </div>
              <p class="mb-2 fs-16">
                {{ revision.author_role ? revision.author_role + ': ' : ''
                }}{{ revision.author_email }}
              </p>
              <v-chip
                v-for="key in Object.keys(revision.data)"
                :key="key"
                class="me-1 my-1"
                variant="outlined"
              >
                {{ key }}
              </v-chip>
            </div>
          </v-expansion-panel-title>

          <v-expansion-panel-text>
            <div
              v-for="field in Object.keys(revision.data)"
              :key="field"
              class="b-1 bc-extra-light-gray pa-3"
            >
              <v-row class="bb-1 bc-extra-light-gray">
                <v-col class="fs-16 fw-600">
                  {{ $t('Field') }}
                </v-col>
                <v-col class="fs-16 fw-500">
                  {{ $t(field) }}
                </v-col>
              </v-row>
              <v-row class="bb-1 bc-extra-light-gray">
                <v-col class="fs-16 fw-600">
                  {{ $t('Original') }}
                </v-col>
                <v-col class="fs-16 fw-500">
                  {{ revision.data[field][0] || '-' }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="fs-16 fw-600">
                  {{ $t('Updated to') }}
                </v-col>
                <v-col class="fs-16 fw-500">
                  {{ revision.data[field][1] }}
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>

    <v-pagination
      v-show="pages > 1"
      v-model="page"
      :length="pages"
      :total-visible="8"
      class="my-4"
    />
  </v-card>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import LongDateTime from '@/shared/components/LongDateTime.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    LongDateTime,
  },

  props: {
    ownerType: {
      type: String,
      default: null,
    },
    ownerId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      panel: null,
      page: 1,
      pages: 1,
      revisions: [],
    };
  },

  watch: {
    page() {
      this.load();
    },

    '$route.params.providerId': {
      immediate: true,
      handler() {
        this.load();
      },
    },
  },

  methods: {
    load() {
      Api.organization.revision.index(
        { page: this.page, owner_type: this.ownerType, owner_id: this.ownerId },
        (resp) => {
          this.pages = parseInt(resp.headers['x-page-count'] || '0', 10);
          this.revisions = resp.data;
        },
      );
    },
  },
};
</script>
