<template>
  <div class="fill-height">
    <template v-if="!loaded">
      <v-progress-linear indeterminate />
    </template>
    <template v-else>
      <v-toolbar
        class="bc-outlined-gray bb-1"
        elevation="0"
        height="70"
        style="z-index: 1; left: var(--v-layout-left); right: 0; width: auto"
        absolute
      >
        <div class="d-flex flex-1-0 align-center px-4">
          <div class="d-flex align-center flex-1-0">
            <v-btn
              :to="{ name: 'GrantIndex' }"
              class="me-3"
              variant="text"
              icon
            >
              <v-icon
                class="c-black"
                icon="west"
                size="28"
              />
            </v-btn>

            <div
              class="d-inline-block fs-22 fw-600 me-3"
              data-cy="page-title"
            >
              {{ $t(grant.name) }}
            </div>

            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-btn
                  v-if="grantProgram.enable_priority"
                  @click="priorityDialogIsVisible = true"
                  v-bind="props"
                  :aria-label="$t('Priority')"
                  class="ms-0"
                  color="primary"
                  size="small"
                  variant="text"
                  icon
                  rounded
                >
                  <v-icon>{{ grant.priority ? 'star' : 'star_border' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t(grant.priority || 'Grant priority') }}</span>
            </v-tooltip>

            <div
              v-if="grantProgramLabels.length > 0"
              class="ms-0"
            >
              <LabelValue
                @edit="$refs.grantLabelDialog.open(grant)"
                :applied-labels="grant.relationships.labels.data"
                :disabled="processing"
              />
            </div>

            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-chip
                  @click="$refs.updateAwardAmountDialog.open(grant)"
                  v-bind="props"
                  :aria-label="$t('Award amount')"
                  :color="grant.award_amount ? 'green' : null"
                  :disabled="readonly"
                  class="ms-3"
                  size="small"
                >
                  <span v-if="grant.award_amount">{{ currency(grant.award_amount) }}</span>
                  <span v-else>$</span>
                </v-chip>
              </template>
              <span>{{ $t('Grant award amount') }}</span>
            </v-tooltip>

            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-btn
                  @click="toggleLock"
                  v-bind="props"
                  :disabled="readonly"
                  :icon="grant.locked ? 'lock' : 'lock_open'"
                  class="ms-0"
                  color="primary"
                  data-cy="lock-button"
                  size="small"
                  variant="text"
                />
              </template>
              <span v-if="grant.locked">
                {{ $t('The grant is locked. The applicant cannot make changes.') }}
              </span>
              <span v-else>{{ $t('The grant is unlocked. The applicant can make changes.') }}</span>
            </v-tooltip>
          </div>

          <div class="d-flex flex-wrap justify-end flex-0-1">
            <v-btn-toggle
              v-model="rightBarTab"
              class="me-3"
              selected-class="bg-primary c-white"
              variant="outlined"
              divided
              tile
            >
              <v-btn
                :aria-label="$t('Reviews')"
                size="large"
                value="reviews"
                variant="outlined"
                icon
              >
                <v-icon
                  :color="rightBarTab == 'reviews' ? 'white' : 'black'"
                  class="material-icons-outlined"
                  icon="reviews"
                />
              </v-btn>
              <v-btn
                :aria-label="$t('Messaging')"
                size="large"
                value="messaging"
                variant="outlined"
                icon
              >
                <v-icon
                  :color="rightBarTab == 'messaging' ? 'white' : 'black'"
                  class="material-icons-outlined"
                  icon="forum"
                />
              </v-btn>
              <v-btn
                :aria-label="$t('Notes')"
                size="large"
                value="notes"
                variant="outlined"
                icon
              >
                <v-icon
                  :color="rightBarTab == 'notes' ? 'white' : 'black'"
                  class="material-icons-outlined"
                  icon="assignment"
                />
              </v-btn>
            </v-btn-toggle>

            <v-menu
              v-if="grantProgram.enable_stages"
              v-model="openStageDialog"
              :min-width="350"
              max-width="500"
              offset="5"
            >
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  :aria-label="$t('Stages')"
                  append-icon="keyboard_arrow_down"
                  color="primary"
                  size="large"
                  variant="outlined"
                  tile
                >
                  {{ $t(currentStage.title) }}
                </v-btn>
              </template>

              <v-list
                v-model:selected="currentStageId"
                class="bc-light-gray b-1 py-0"
                tile
              >
                <template
                  v-for="(stage, index) in grantProgram.stages"
                  :key="stage.id"
                >
                  <v-list-item
                    :class="getStageItemClasses(stage, index)"
                    :title="$t(stage.title)"
                    :value="stage.id"
                    lines="two"
                  >
                    <template
                      v-if="index < grantProgram.stages.length - 1"
                      #append
                    >
                      <template v-if="index < currentStageIndex">
                        <v-hover v-slot="{ isHovering, props }">
                          <v-btn
                            @click="setStage(index)"
                            v-bind="props"
                            :loading="processing"
                            :variant="isHovering ? 'outlined' : undefined"
                            class="ms-3"
                            color="primary"
                            icon="check"
                          />
                        </v-hover>
                      </template>
                      <template v-else>
                        <v-btn
                          v-if="grant.stage_id == stage.id || currentStageIndex == index"
                          @click.stop="completeStage()"
                          :loading="processing"
                          class="ms-3"
                          color="primary"
                        >
                          {{ $t('Complete') }}
                        </v-btn>
                      </template>
                    </template>
                  </v-list-item>
                  <v-divider
                    v-if="index < grantProgram.stages.length - 1"
                    :key="index"
                  />
                </template>
              </v-list>
            </v-menu>

            <ActionMenu
              v-if="$store.state.profile.org_grants_delete"
              @click:action:delete="destroy"
              :items="[{ event: 'delete', avatar: 'delete', title: 'Delete application' }]"
              button-class="ms-3"
              button-icon="more_vert"
              x-small
            />
          </div>
        </div>

        <template #extension>
          <v-tabs v-model="tab">
            <v-tab
              :aria-lable="$t('Grant application')"
              class="c-black fs-16 ls-normal tt-none"
              value="application"
              exact
            >
              {{ $t('Application') }}
            </v-tab>
            <v-tab
              v-if="showFunding"
              :aria-lable="$t('Grant funding')"
              class="c-black fs-16 ls-normal tt-none"
              value="funding"
              exact
            >
              {{ $t('Funding') }}
            </v-tab>
            <v-tab
              v-if="showClaims"
              :aria-lable="$t('Grant claims')"
              class="c-black fs-16 ls-normal tt-none"
              value="claims"
              exact
            >
              {{ $t('Claims') }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <div class="bg-super-light-blue fill-height d-flex">
        <div
          :style="rightBarTab ? 'width: 65%;' : 'width: 100%'"
          class="fill-height px-3"
          style="overflow-y: auto; padding-top: 136px"
        >
          <v-window v-model="tab">
            <v-window-item
              v-if="showApplication || showEligibility"
              value="application"
            >
              <GrantEligibility
                v-if="showEligibility"
                @edit-score="scoreDialogIsVisible = true"
                :elevation="0"
                :grant="grant"
                :grant-program="grantProgram"
                :name="grant.name"
                explanation
                outlined
              />

              <GrantApplication
                v-if="showApplication"
                @change="load"
                @reset="load"
                ref="application"
                :grant="grant"
                :grant-program="grantProgram"
                :initial-grant="grant"
              />
            </v-window-item>

            <v-window-item
              v-if="showFunding"
              value="funding"
            >
              <GrantFunding
                :grant="grant"
                :grant-program="grantProgram"
              />
            </v-window-item>

            <v-window-item
              v-if="showClaims"
              value="claims"
            >
              <ClaimsTable
                :grant="grant"
                :readonly="readonly"
              />
            </v-window-item>
          </v-window>

          <VerticalSpacer :min-height="60" />
        </div>

        <v-card
          :style="rightBarTab ? 'width: 35%;' : 'display: none;'"
          class="fill-height"
          style="overflow-y: auto; padding-top: 136px"
          border
        >
          <v-window
            v-model="rightBarTab"
            eager-load
          >
            <v-window-item
              key="messaging"
              value="messaging"
            >
              <GrantMessages
                @close="rightBarTab = null"
                :grant="grant"
              />
            </v-window-item>

            <v-window-item
              key="notes"
              value="notes"
            >
              <GrantNotes
                @close="rightBarTab = null"
                :grant="grant"
              />
            </v-window-item>

            <v-window-item
              key="reviews"
              value="reviews"
            >
              <GrantReviews
                @close="rightBarTab = null"
                @published="load()"
                :grant="grant"
                :grant-program="grantProgram"
              />
            </v-window-item>
          </v-window>
        </v-card>
      </div>

      <v-dialog
        v-model="scoreDialogIsVisible"
        max-width="500"
        scrim="transparent"
      >
        <v-card border>
          <v-card-title>{{ $t('Edit score') }}</v-card-title>
          <v-card-text>
            <v-row>
              <LabeledTextfield v-model.number="grant.score" />
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="scoreDialogIsVisible = false"
              size="x-large"
              variant="text"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-spacer />
            <v-btn
              @click="save"
              :loading="processing"
              color="primary"
              size="x-large"
            >
              {{ $t('Save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="priorityDialogIsVisible"
        max-width="400"
        scrim="transparent"
      >
        <v-card border>
          <v-card-title>{{ $t('Prioritize grant') }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div
                  class="fw-500 fs-16 mb-2"
                  cols="12"
                >
                  {{ $t('What level of priority?') }}
                </div>
                <v-select
                  v-model="grant.priority"
                  :items="[$t('High'), $t('Medium'), $t('Low')]"
                  :placeholder="$t('Select a priority')"
                  variant="filled"
                  hide-details
                />
              </v-col>
              <LabeledTextarea
                v-model="grant.priority_reason"
                message="Priority reason"
              />
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="priorityDialogIsVisible = false"
              variant="text"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-spacer />
            <v-btn
              @click="save()"
              :loading="processing"
              color="primary"
            >
              {{ $t('Save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ResourceDialog
        @save="setGrantAwardAmount"
        ref="updateAwardAmountDialog"
        :fields="[
          { text: 'Award amount', value: 'award_amount', type: 'currency', required: true },
        ]"
        :processing="processing"
      >
        <template #append>
          <v-alert
            v-if="invalidAwardAmount"
            type="error"
          >
            <div>
              {{ $t('Award amount must be greater than zero.') }}
            </div>
          </v-alert>
        </template>
      </ResourceDialog>
    </template>

    <LabelDialog
      @change="updateGrantLabels($event)"
      ref="grantLabelDialog"
      :program-labels="grantProgramLabels"
      label-owner-type="Grant"
    />
  </div>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import ClaimsTable from '@/specialist/components/claims/ClaimsTable.vue';
import GrantApplication from '@/specialist/components/grant/GrantApplication.vue';
import GrantEligibility from '@/shared/components/grant/GrantEligibility.vue';
import GrantFunding from '@/specialist/components/grant/GrantFunding.vue';
import GrantMessages from '@/specialist/components/grant/GrantMessages.vue';
import GrantNotes from '@/specialist/components/grant/GrantNotes.vue';
import GrantReviews from '@/specialist/components/grant/GrantReviews.vue';
import LabelDialog from '@/specialist/components/LabelDialog.vue';
import LabelValue from '@/specialist/components/LabelValue.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { currency } from '@/plugins/filters';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();
const TABS = {
  APPLICATION: 'application',
  FUNDING: 'funding',
  CLAIMS: 'claims',
};

const currentStageId = ref(null);
const invalidAwardAmount = ref(false);
const openStageDialog = ref(false);
const priorityDialogIsVisible = ref(false);
const processing = ref(false);
const rightBarTab = ref(null);
const rightBarVisible = ref(false);
const grant = ref(null);
const grantProgram = ref(null);
const grantProgramLabels = ref([]);
const scoreDialogIsVisible = ref(false);
const tab = ref(null);
const updateAwardAmountDialog = ref(null);

const currentStage = computed(() => {
  if (!grantProgram.value) return {};
  return grantProgram.value.stages[currentStageIndex.value] || {};
});

const currentStageIndex = computed(() => {
  if (!loaded.value) return 0;

  const index = grantProgram.value.stages.findIndex((stage) => grant.value.stage_id === stage.id);
  if (index === -1) return 0;
  return index;
});

const loaded = computed(() => grant.value && grantProgram.value);

const readonly = computed(() => {
  return (
    !store.state.profile.org_grants_edit ||
    (!store.state.profile.org_grants_admin && grant.value?.locked)
  );
});

const showApplication = computed(() => {
  return !grantProgram.value.enable_stages || currentStage.value.enable_application;
});

const showEligibility = computed(() => {
  return (
    grantProgram.value?.enable_eligibility &&
    (!grantProgram.value?.enable_stages || currentStage.value.enable_eligibility)
  );
});

const showFunding = computed(() => {
  return (
    (!grantProgram.value?.enable_stages && grantProgram.value?.enable_awards) ||
    currentStage.value.enable_funding
  );
});

const showClaims = computed(() => grantProgram.value.claim_schema_id);

watch(
  () => currentStageIndex.value || loaded.value,
  () => {
    setDefaultTab();
    setDefaultRightBarTab();
  },
  { immediate: true },
);

watch(
  () => rightBarTab.value,
  (newVal) => {
    if (newVal != null) rightBarVisible.value = true;
    else rightBarVisible.value = false;
  },
  { immediate: true },
);

watch(
  () => route.params.id,
  () => {
    processing.value = true;
    load();
    tab.value = route.query?.tab || TABS.APPLICATION;
  },
  { immediate: true },
);

watch(
  () => tab.value,
  () => {
    router.push({ query: { tab: tab.value } });
  },
  { immediate: true },
);

function completeStage() {
  grant.value.stage_id = grantProgram.value.stages[currentStageIndex.value + 1].id;
  void save();
}

function destroy() {
  // eslint-disable-next-line no-alert
  if (confirm('Are you sure you want to delete this application? This cannot be undone.')) {
    api.organization.grant.destroy(route.params.id, () => {
      eventBus.chime('Deleted successfully');
      router.replace({ name: 'GrantIndex' });
    });
  }
}

function getStageItemClasses(stage, index) {
  const activeStage = grant.value.stage_id == stage.id || currentStageIndex.value == index;
  return activeStage ? 'bg-primary-extra-light' : null;
}

function load() {
  api.organization.grant.get(route.params.id, (resp) => {
    grant.value = resp.data;
    currentStageId.value = grant.value.stage_id;
    loadGrantProgram();
    processing.value = false;
  });
}

function loadGrantProgram() {
  api.organization.grant_program.get(grant.value.grant_program_id, (resp) => {
    grantProgram.value = resp.data;
    void loadGrantProgramLabels();
  });
}

async function loadGrantProgramLabels() {
  const { data } = await api.organization.label.index({
    grant_program_id: grantProgram.value.id,
  });
  grantProgramLabels.value = data;
}

async function save() {
  processing.value = true;
  api.organization.grant.update(
    grant.value.id,
    grant.value,
    (resp) => {
      processing.value = false;
      grant.value = null;
      setTimeout(() => {
        grant.value = resp.data;
      }, 100);
      priorityDialogIsVisible.value = false;
      scoreDialogIsVisible.value = false;
      eventBus.chime('Application saved');
    },
    (err) => {
      processing.value = false;
      eventBus.chime(err.response.data.errors[0]);
    },
  );
}

function setDefaultRightBarTab() {
  if (grantProgram.value) {
    if (currentStage.value.enable_reviews) {
      rightBarTab.value = 'reviews';
    } else {
      rightBarTab.value = null;
    }
  }
}

function setDefaultTab() {
  if (!grantProgram.value) return;

  if (showApplication.value || showEligibility) tab.value = TABS.APPLICATION;
  else if (showFunding.value) tab.value = TABS.FUNDING;
  else if (showClaims.value) tab.value = TABS.CLAIMS;
}

async function setGrantAwardAmount(params) {
  if (params.award_amount <= 0) {
    invalidAwardAmount.value = true;
  } else {
    processing.value = true;
    invalidAwardAmount.value = false;

    await update(params);

    updateAwardAmountDialog.value?.close();
  }
}

function setStage(index) {
  if (index < currentStageIndex.value) {
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure you want to move back to a previous stage?')) {
      grant.value.stage_id = grantProgram.value.stages[index].id;
      void save();
    }
  }
}

function toggleLock() {
  void update({ locked: !grant.value.locked }, grant.value.locked ? 'Unlocked' : 'Locked');
}

async function update(newVal, msg = 'Saved') {
  const resp = await api.organization.grant
    .update(grant.value.id, newVal)
    .catch((err) => eventBus.chime(err.response.data.errors[0]));

  if (resp.status === 200) {
    grant.value = resp.data;
    eventBus.chime(msg);
  }
}

function updateGrantLabels(updatedGrant) {
  grant.value = updatedGrant;
}
</script>
