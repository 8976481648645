<template>
  <div
    v-if="loaded"
    fluid
  >
    <PageTitle
      :title="title"
      flat
      outlined
    />

    <v-container fluid>
      <v-card
        border
        flat
        tile
      >
        <v-card-text v-if="contentTemplate?.attributes?.communication_type === 'email'">
          <v-row>
            <LabeledTextfield
              v-model="localizedContentTemplate.subject"
              message="Subject"
              filled
              mandatory
            />
            <LabeledTextfield
              v-model="localizedContentTemplate.reply_to"
              message="Reply to"
              filled
              mandatory
            />
            <LabeledSimpleSelect
              v-model="localizedContentTemplate.locale"
              @change="handleLanguageSelection"
              :items="organization?.languages"
              class="mt-2"
              message="Language"
              chips
              deletable-chips
              mandatory
            />

            <LabeledControl message="Intro Text">
              <ToastEditor
                ref="introEditor"
                :initial-value="localizedContentTemplate.intro_text"
              />
            </LabeledControl>

            <LabeledControl message="Closing Text">
              <ToastEditor
                ref="closingEditor"
                :initial-value="localizedContentTemplate.closing_text"
              />
            </LabeledControl>
          </v-row>
        </v-card-text>

        <v-card-text v-if="contentTemplate?.attributes?.communication_type === 'text'">
          <v-row>
            <LabeledTextfield
              v-model="localizedContentTemplate.subject"
              message="Title"
              filled
              mandatory
            />
            <LabeledSimpleSelect
              v-model="localizedContentTemplate.locale"
              @change="handleLanguageSelection"
              :items="organization?.languages"
              class="mt-2"
              message="Language"
              chips
              deletable-chips
              mandatory
            />

            <LabeledTextarea
              v-model="localizedContentTemplate.text_message_body"
              message="Text Message"
              mandatory
            />
            <span class="ml-5">{{ textBodyLength }} / 160 characters used</span>
          </v-row>
        </v-card-text>

        <v-divider class="mb-4" />

        <v-card-actions>
          <v-btn
            @click="update"
            color="primary"
            size="x-large"
          >
            {{ $t('Save') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="destroy"
            color="red"
            variant="text"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import useEventBus from '@/shared/composables/useEventBus';
import Api from '@/specialist/services/bright_finder';
import ToastEditor from '@/admin/components/ToastEditor.vue';

const eventBus = useEventBus();
const router = useRouter();
const route = useRoute();

const organization = ref(null);
const contentTemplate = ref(null);
const localizedContentTemplate = ref({
  subject: null,
  locale: null,
  reply_to: null,
  intro_text: null,
  closing_text: null,
});
const loaded = ref(false);

// refs
const introEditor = ref(null);
const closingEditor = ref(null);

// formerly created
onMounted(async () => {
  loadOrganization();
  await load();
});

// computed
const title = computed(() => {
  return localizedContentTemplate.value.subject || 'Edit localized content template';
});

const textBodyLength = computed(() => {
  return localizedContentTemplate.value.text_message_body?.length || 0;
});

// methods
function handleSetMarkdown() {
  localizedContentTemplate.value.intro_text = introEditor.value.getMarkdown();
  localizedContentTemplate.value.closing_text = closingEditor.value.getMarkdown();
}

function loadOrganization() {
  Api.organization.get((resp) => {
    organization.value = resp.data;
  });
}

function handleLanguageSelection(locale) {
  localizedContentTemplate.value.locale = locale;
}

async function load() {
  await loadLocalizedContentTemplate();
  await loadContentTemplate();
  loaded.value = true;
}

async function loadLocalizedContentTemplate() {
  const { localizedContentTemplateId } = route.params;
  const res = await Api.organization.localized_content_template.get(localizedContentTemplateId);

  const { data } = res.data;
  localizedContentTemplate.value = data.attributes;
}

async function loadContentTemplate() {
  const { contentTemplateId } = route.params;
  const res = await Api.organization.content_template.get(contentTemplateId);
  contentTemplate.value = res.data.data;
}

async function destroy() {
  const { contentTemplateId, localizedContentTemplateId } = route.params;
  const res = await Api.organization.localized_content_template.destroy(localizedContentTemplateId);

  const { status } = res;

  if (status === 200) {
    router.push({ name: 'LocalizedContentTemplateIndex', params: { contentTemplateId } });
    eventBus.chime('Localized content template deleted.');
  }
}

async function update() {
  const { contentTemplateId, localizedContentTemplateId } = route.params;
  if (contentTemplate.value.attributes.communication_type === 'email') {
    handleSetMarkdown();
  }

  const res = await Api.organization.localized_content_template.update(
    localizedContentTemplateId,
    localizedContentTemplate.value,
  );

  const { data } = res.data;

  if (data) {
    router.push({ name: 'LocalizedContentTemplateIndex', params: { contentTemplateId } });
    eventBus.chime('Localized content template updated.');
  }
}
</script>
