<template>
  <div>
    <CustomViewToolbar
      @change:custom-view="handleCustomViewChange"
      @change:filters="handleCustomViewUpdate"
      @change:subsidy-program="handleSubsidyProgramChange"
      @edit:custom-view:columns="handleCustomViewColumnsEdit"
      @edit:custom-view:filters="handleCustomViewFiltersEdit"
      @search="handleSearch"
      @update:page="resetPage"
      :allowed-properties="allowedProperties"
      :custom-view="selectedCustomView"
      :custom-views="customViews"
      :items-selected="itemsSelected"
      :labels="labels"
      :reviewers="validReviewers"
      :subsidy-programs="subsidyPrograms"
      :teams="validTeams"
    />

    <template v-if="selectedCustomView">
      <CustomViewColumnsEditor
        @save="handleCustomViewColumnsUpdate"
        ref="customViewColumnsEditor"
        :allowed-properties="allowedProperties"
        :processing="processing"
      />

      <CustomViewFiltersEditor
        @save="handleCustomViewFiltersUpdate"
        ref="customViewFiltersEditor"
        :columns="selectedCustomView.attributes.columns"
        :processing="processing"
      />

      <ApplicationCreator
        @change="router.push({ name: 'SubsidyShow', params: { id: $event.id } })"
        :selected-subsidy-program="selectedSubsidyProgram"
        :subsidy-programs="subsidyPrograms"
        :teams="validTeams"
      />

      <div class="mt-3 mx-3">
        <v-card
          class="bt-1 br-1 bl-1 bc-outlined-gray"
          flat
          tile
        >
          <v-card-text class="fs-15 py-3">
            <v-row dense>
              <v-col class="d-flex align-center">
                <template v-if="filterSummary?.length <= 5">
                  <v-icon start> filter_alt </v-icon>
                  <span
                    v-t="'Filtering by:'"
                    class="me-1"
                  />
                  <span class="fw-600">{{ $t(filterSummary?.join(', ')) }}</span>
                  <span>.&nbsp;</span>
                </template>
                <template v-else-if="filterSummary?.length > 5">
                  <v-icon start> filter_alt </v-icon>
                  <span
                    v-t="'Filters applied.'"
                    class="me-1"
                  />
                  <span>&nbsp;</span>
                </template>
                <p class="mb-0 py-1">
                  Displaying
                  <strong>{{ items.length }}</strong> out of
                  <strong>{{ totalItemCount }}</strong> applications.
                </p>
              </v-col>
              <v-col
                v-if="displayActionSheet"
                class="d-flex justify-end"
              >
                <v-btn
                  @click="handleSelectedItemDownload"
                  class="ms-1"
                  color="primary"
                  size="small"
                  variant="flat"
                >
                  Download
                </v-btn>
                <v-btn
                  v-if="(selectedSubsidyProgram.stages?.length ?? 0) > 0"
                  @click="handleSelectedItemStageEdit"
                  class="ms-1"
                  color="primary"
                  size="small"
                  variant="flat"
                >
                  Stage
                </v-btn>
                <v-btn
                  v-if="
                    selectedSubsidyProgram.enable_individual_review && validReviewers.length > 0
                  "
                  @click="handleSelectedItemReviewerEdit"
                  class="ms-1"
                  color="primary"
                  size="small"
                  variant="flat"
                >
                  Assignment
                </v-btn>
                <v-btn
                  v-if="selectedSubsidyProgram.enable_team_review & (validTeams.length > 0)"
                  @click="handleSelectedItemTeamEdit"
                  class="ms-1"
                  color="primary"
                  size="small"
                  variant="flat"
                >
                  Team
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <v-data-table-server
        v-model="selected"
        @update:page="handlePageChange"
        @update:sort-by="handleCustomViewSortsUpdate"
        :headers="headers"
        :hide-default-footer="!totalItemCount"
        :items="items"
        :items-length="totalItemCount"
        :items-per-page="50"
        :loading="processing"
        :page="currentPage"
        :show-select="true"
        class="mx-3 b-radius-0 b-1 bc-outlined-gray fixed-height"
        density="comfortable"
        item-key="id"
        disable-sort
        return-object
      >
        <template #item.duplicate_count="{ item }">
          <v-btn
            v-if="item.duplicate_count > 0 && !item.duplicate_alert_suppressed_at"
            @click="openDuplicateAlert(item)"
            class="mx-0 pa-0"
            min-width="0"
            size="x-small"
            variant="text"
          >
            <v-icon class="c-red"> error </v-icon>
          </v-btn>
        </template>

        <template #item.name="{ item }">
          <router-link
            :to="{ name: 'SubsidyShow', params: { id: item.id }, query: { from: currentPage } }"
          >
            <span v-text="item.name" />
          </router-link>
        </template>

        <template
          v-if="selectedSubsidyProgram.enable_individual_review"
          #item.reviewer_id="{ item }"
        >
          {{ getReviewerName(item.reviewer_id) }}
        </template>

        <template
          v-if="selectedSubsidyProgram.enable_team_review"
          #item.team_id="{ item }"
        >
          {{ getTeamName(item.team_id) }}
        </template>

        <template #item.submitted_at="{ item }">
          <LongDateTime :date="item.submitted_at" />
        </template>

        <template
          v-if="displayStages"
          #item.stage_id="{ item }"
        >
          {{ getStageName(item.stage_id) }}
        </template>

        <template
          v-if="displayLabels"
          #item.label_ids="{ item }"
        >
          <LabelValue
            @edit="handleLabelEdit(item)"
            :applied-labels="getLabelsFromIds(item.label_ids)"
            :disabled="processing"
          />
        </template>
      </v-data-table-server>
    </template>

    <CustomSubsidyDuplicateEditor
      @save="handleSuppressedDuplicateAlert"
      ref="customSubsidyDuplicateEditor"
      :processing="processing"
    />

    <LabelDialog
      v-if="displayLabels"
      @change="handleLabelsUpdate($event)"
      ref="subsidyLabelDialog"
      :program-labels="labels"
      label-owner-type="Subsidy"
    />

    <ReviewerEditor
      @save="handleSelectedItemReviewerUpdate"
      ref="selectedItemReviewerEditor"
      :processing="processing"
      :reviewers="validReviewers"
    />

    <StageEditor
      v-if="displayStages"
      @save="handleSelectedItemStageUpdate"
      ref="selectedItemStageEditor"
      :processing="processing"
      :stages="selectedSubsidyProgram?.stages"
    />

    <TeamEditor
      @save="handleSelectedItemTeamUpdate"
      ref="selectedItemTeamEditor"
      :processing="processing"
      :teams="validTeams"
    />
  </div>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/specialist/services/bright_finder';
import ApplicationCreator from '@/specialist/components/subsidy/ApplicationCreator.vue';
import CustomViewToolbar from '@/specialist/components/custom-views/CustomViewToolbar.vue';
import CustomViewColumnsEditor from '@/specialist/components/custom-views/CustomViewColumnsEditor.vue';
import CustomSubsidyDuplicateEditor from '@/specialist/components/subsidy/CustomSubsidyDuplicateEditor.vue';
import CustomViewFiltersEditor from '@/specialist/components/custom-views/CustomViewFiltersEditor.vue';
import customViewResultApi from '@/specialist/services/api/custom-view-result';
import LabelDialog from '@/specialist/components/LabelDialog.vue';
import LabelValue from '@/specialist/components/LabelValue.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import StageEditor from '@/specialist/components/StageEditor.vue';
import TeamEditor from '@/specialist/components/TeamEditor.vue';
import ReviewerEditor from '@/specialist/components/ReviewerEditor.vue';
import useEnrollmentStatuses from '@/shared/composables/useEnrollmentStatuses';
import useEventBus from '@/shared/composables/useEventBus';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';

const { getStatusText } = useEnrollmentStatuses();
const allowedProperties = ref([]);
const allReviewers = ref([]);
const allTeams = ref([]);
const customSubsidyDuplicateEditor = ref(null);
const customViewColumnsEditor = ref(null);
const customViewFiltersEditor = ref(null);
const customViews = ref([]);
const workflowColumns = ref([]);
const eventBus = useEventBus();
const headers = ref([]);
const items = ref([]);
const labels = ref([]);
const processing = ref(false);
const route = useRoute();
const router = useRouter();
const page = ref(route.query.page || 1);
const search = ref('');
const selected = ref([]);
const selectedCustomView = ref(null);
const selectedItemReviewerEditor = ref(null);
const selectedItemStageEditor = ref(null);
const selectedItemTeamEditor = ref(null);
const selectedSubsidyProgram = ref(null);
const totalItemCount = ref(null);
const store = useStore();
const subsidyLabelDialog = ref(null);
const subsidyPrograms = ref([]);
const teamMemberships = ref([]);
const validReviewers = ref([]);
const validTeams = ref([]);
const { t } = useI18n();

const displayActionSheet = computed(() => selected.value.length > 0);
const displayLabels = computed(
  () => store.state.profile.org_subsidies_access && labels.value.length > 0,
);
const displayStages = computed(() => {
  return (
    store.state.profile.org_subsidies_access &&
    selectedSubsidyProgram.value?.enable_stages &&
    selectedSubsidyProgram.value.stages.length > 0
  );
});

const filterSummary = computed(() => {
  const appliedFilters = [];
  const pushFilterSummary = (filter, filterName, operator, transform) => {
    if (filter?.length > 0) {
      appliedFilters.push(t(`${filterName} ${operator} ${transform ? transform(filter) : filter}`));
    }
  };
  // status
  let statusFilter = Array.isArray(selectedCustomView.value.attributes.statuses)
    ? selectedCustomView.value.attributes.statuses.join(', ')
    : selectedCustomView.value.attributes.statuses;
  pushFilterSummary(statusFilter, 'Status', '=', getStatusText);

  // teams
  let teamFilter = selectedCustomView.value.attributes.team_ids?.map(
    (teamId) => allTeams.value.find((team) => team.id === teamId)?.name,
  );
  pushFilterSummary(teamFilter, 'Teams', '=', (teams) => teams.join(', '));

  // reviewers
  let reviewerFilter = selectedCustomView.value.attributes.reviewer_ids?.map(
    (reviewerId) => allReviewers.value.find((reviewer) => reviewer.id === reviewerId)?.name,
  );
  pushFilterSummary(reviewerFilter, 'Reviewers', '=', (reviewers) => reviewers.join(', '));

  // stages
  let stageFilter = selectedCustomView.value.attributes.stage_ids?.map(
    (stageId) => selectedSubsidyProgram.value.stages.find((stage) => stage.id === stageId)?.title,
  );
  pushFilterSummary(stageFilter, 'Stages', '=', (stages) => stages.join(', '));

  // labels
  let labelFilter = selectedCustomView.value.attributes.label_ids?.map(
    (labelId) => labels.value.find((label) => label.id === labelId)?.name,
  );
  pushFilterSummary(labelFilter, 'Labels', '=', (labels) => labels.join(', '));

  // custom columns
  const operators = {
    equal: '=',
    not_equal: '!=',
    less: 'less than',
    earlier_than: 'earlier than',
    later_than: 'later than',
    greater_equal: 'greater than or equal to',
    less_equal: 'less than or equal to',
    not_contains: 'does not contain',
  };
  selectedCustomView.value.attributes.columns?.map((column) => {
    selectedCustomView.value.attributes.filters?.forEach((filter) => {
      if (filter.key === column.key) {
        if (filter.operator === 'blank' || filter.operator === 'present') {
          appliedFilters.push(`${column.data_type} ${column.alias_name} is ${filter.operator}`);
        }
        pushFilterSummary(
          filter.value,
          `${column.data_type} ${column.alias_name}`,
          operators[filter.operator] || filter.operator,
        );
      }
    });
  });
  return appliedFilters.length > 0 ? appliedFilters : null;
});

const itemsSelected = computed(() => false);

const currentPage = computed(() => (route.query.page ? Number(route.query.page) : 1));

onMounted(async () => {
  await load();
});

function isAgent() {
  return store.state.profile.org_subsidies_agent && !store.state.profile.org_subsidies_access;
}

async function openDuplicateAlert(currentSubsidy) {
  let response = await Api.organization.subsidy.index({
    duplicate_key: currentSubsidy.duplicate_key,
  });

  const duplicateSubsidies =
    response.data?.filter((subsidy) => subsidy.id !== currentSubsidy.id) || [];

  const subsidyIds = duplicateSubsidies.map((subsidy) => subsidy.id);

  // Get revisions for all duplicate subsidies
  response = await Api.organization.revision.index({
    owner_id: subsidyIds,
    owner_type: 'Subsidy',
  });

  const revisionsByOwner = _.groupBy(response.data, 'owner_id');

  // Set created_by and submitted_by for each duplicate subsidy
  const updatedDuplicateSubsidies = duplicateSubsidies.map((subsidy) => {
    const revisions = revisionsByOwner[subsidy.id];

    if (!revisions) return subsidy;

    const firstRevision = revisions.reduce((oldest, current) => {
      if (!oldest || new Date(current.created_at) < new Date(oldest.created_at)) return current;

      return oldest;
    }, null);
    const lastRevision =
      revisions.find((revision) => revision.data.submitted_at && !revision.data.submitted_at[0]) ||
      null;

    const createdBy = firstRevision
      ? [firstRevision?.author_name, firstRevision?.author_email].filter(Boolean).join(', ')
      : null;
    const submittedBy = lastRevision
      ? [lastRevision?.author_name, lastRevision?.author_email].filter(Boolean).join(', ')
      : null;

    return {
      created_by: createdBy,
      submitted_by: submittedBy,
      ...subsidy,
    };
  });

  customSubsidyDuplicateEditor.value.open(currentSubsidy, updatedDuplicateSubsidies);
}

function getLabelsFromIds(labelIds) {
  if (!labelIds) return [];
  return labels.value.filter((label) => labelIds.includes(label.id));
}

function getReviewerName(reviewerId) {
  if (!reviewerId) return null;

  return allReviewers.value.find((reviewer) => reviewer.id === reviewerId)?.name || 'Unknown';
}

function getStageName(stageId) {
  if (!stageId) return null;

  return (
    selectedSubsidyProgram.value.stages.find((stage) => stage.id === stageId)?.title || 'Unknown'
  );
}

function getTeamName(teamId) {
  if (!teamId) return null;

  return allTeams.value.find((team) => team.id === teamId)?.name || 'Unknown';
}

async function handleCustomViewChange(newVal) {
  selectedCustomView.value = newVal;
  await loadResults();
}

function handleCustomViewColumnsEdit() {
  customViewColumnsEditor.value.open(selectedCustomView.value.attributes.columns);
}

function handleCustomViewFiltersEdit() {
  customViewFiltersEditor.value.open(selectedCustomView.value.attributes.filters);
}

async function handleCustomViewColumnsUpdate(newVal) {
  await handleCustomViewUpdate({ columns: newVal });
  customViewColumnsEditor.value.close();
}

async function handleCustomViewFiltersUpdate(newVal) {
  await handleCustomViewUpdate({ filters: newVal });
  customViewFiltersEditor.value.close();
}

async function handleCustomViewSortsUpdate(newVal) {
  const processedSorts = processSorts(newVal);
  await handleCustomViewUpdate(processedSorts);
}

async function handleCustomViewUpdate(newVal) {
  processing.value = true;

  const updatedAttributes = { ...selectedCustomView.value.attributes, ...newVal };
  const resp = await Api.organization.custom_view.update(
    selectedCustomView.value.id,
    updatedAttributes,
  );
  processing.value = false;
  if (resp?.status !== 200) return false;

  selectedCustomView.value = resp.data.data;
  await loadResults();
  return true;
}

function processSorts(newVal) {
  // when newVal returns an empty array, it means the user has removed all sorts
  if (newVal.length < 1) return { sorts: [] };

  const incomingSort = newVal[0];
  let existingColumns = selectedCustomView.value.attributes.columns;
  let existingSorts = selectedCustomView.value.attributes.sorts;
  let incomingDirection = `${incomingSort.order}ending`;

  const columnValue =
    existingColumns.find((column) => column.key === incomingSort.key) ||
    workflowColumns.value.find((column) => column.property_name === incomingSort.key);

  const sortValue = existingSorts.find(
    (sort) =>
      sort.data_type === columnValue.data_type && sort.property_name === columnValue.property_name,
  );

  if (sortValue) {
    sortValue.direction = incomingDirection;
    existingSorts = [sortValue];
  } else {
    const key = uuid().replaceAll('-', '');
    const newSort = {
      key,
      direction: incomingDirection,
      data_type: columnValue.data_type,
      property_name: columnValue.property_name,
    };
    existingSorts = [newSort];
  }

  return { sorts: existingSorts };
}

async function handleLabelEdit(item) {
  subsidyLabelDialog.value.open({
    id: item.id,
    name: item.name,
    relationships: {
      labels: {
        data: getLabelsFromIds(item.label_ids),
      },
    },
  });
}

function handleLabelsUpdate(event) {
  const itemIndex = items.value.findIndex((item) => item.id === event.id);
  const newLabelIds = event.relationships.labels.data.map((label) => label.id);
  items.value[itemIndex].label_ids = newLabelIds;
}

function addPageParams(page) {
  const params = { page };

  if (currentPage.value !== page) {
    router.push({ query: params });
  }
}

async function handlePageChange(newVal) {
  page.value = newVal;
  addPageParams(newVal);
  await loadResults();
}

async function resetPage() {
  page.value = 1;
  addPageParams(1);
}

async function handleSearch(query) {
  search.value = query;
  if (selectedCustomView.value) await loadResults();
}

function handleSelectedItemDownload() {
  const downloadURL = Api.organization.subsidy.bulkDownloadUrl(
    selected.value.map((selected) => selected.id),
    'Applications.zip',
  );
  window.open(downloadURL, '_blank');
}

function handleSelectedItemReviewerEdit() {
  selectedItemReviewerEditor.value.open();
}

async function handleSelectedItemReviewerUpdate(newVal) {
  processing.value = true;
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const index in selected.value) {
    // eslint-disable-next-line no-await-in-loop
    await Api.organization.subsidy.update(selected.value[index].id, {
      reviewer_id: newVal.value,
    });
  }
  selectedItemReviewerEditor.value.close();
  selected.value = [];
  processing.value = false;
  await loadResults();
}

function handleSelectedItemStageEdit() {
  selectedItemStageEditor.value.open();
}

async function handleSelectedItemStageUpdate(newVal) {
  processing.value = true;
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const index in selected.value) {
    // eslint-disable-next-line no-await-in-loop
    await Api.organization.subsidy.update(selected.value[index].id, {
      stage_id: newVal.value,
    });
  }
  selectedItemStageEditor.value.close();
  processing.value = false;
  await loadResults();
}

function handleSelectedItemTeamEdit() {
  selectedItemTeamEditor.value.open();
}

async function handleSelectedItemTeamUpdate(newVal) {
  processing.value = true;
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const index in selected.value) {
    // eslint-disable-next-line no-await-in-loop
    await Api.organization.subsidy.update(selected.value[index].id, {
      team_id: newVal.value,
    });
  }
  selectedItemTeamEditor.value.close();
  selected.value = [];
  processing.value = false;
  await loadResults();
}

async function handleSubsidyProgramChange(newVal) {
  labels.value = [];
  customViews.value = [];
  allowedProperties.value = [];
  selectedSubsidyProgram.value = newVal;
  await loadCustomViews();
  await loadLabels();
}

async function handleSuppressedDuplicateAlert(subsidy) {
  // eslint-disable-next-line no-alert
  if (
    confirm(
      t(
        'Are you sure you want to remove this alert icon? You will not be notified again that this is a duplicate application.',
      ),
    )
  ) {
    processing.value = true;

    await Api.organization.subsidy.update(
      subsidy.id,
      { duplicate_alert_suppressed_at: new Date().toISOString() },
      async () => {
        processing.value = false;
        eventBus.chime('Removed alert');
        await loadResults();
      },
      (err) => {
        processing.value = false;
        eventBus.chime(err.response.data.errors[0]);
      },
    );
  }
}

async function load() {
  await loadTeamMemberships();
  await loadReviewers();
  await loadTeams();
  await loadSubsidyPrograms();
}

async function loadCustomViews() {
  const params = {
    'filter[subsidy_program]': selectedSubsidyProgram.value?.id,
  };
  const { data } = await Api.organization.custom_view.index(params);
  customViews.value = data.data;
  allowedProperties.value = data.meta.allowed_values.columns;
  workflowColumns.value = data.meta.allowed_values.workflow_columns;
}

async function loadResults() {
  const { data } = await customViewResultApi.index({
    customViewId: selectedCustomView.value.id,
    page: currentPage.value,
    search: search.value,
  });

  totalItemCount.value = data.meta.total_count;

  const headersToOmit = ['id', 'duplicate_key', 'duplicate_alert_suppressed_at'];

  if (!store.state.profile.org_subsidies_admin) headersToOmit.push('duplicate_count');
  if (!selectedSubsidyProgram.value.enable_team_review) headersToOmit.push('team_id');
  if (!selectedSubsidyProgram.value.enable_individual_review) headersToOmit.push('reviewer_id');
  if (!displayStages.value) headersToOmit.push('stage_id');
  if (!displayLabels.value) headersToOmit.push('label_ids');

  headers.value = _.map(data.meta.all_columns, (column) => ({
    title: column.alias_name === 'Duplicate Count' ? '' : column.alias_name,
    key: column.key,
  })).filter((header) => !_.includes(headersToOmit, header.key));

  items.value = _.map(data.data, (item) => ({
    ...item.attributes,
    label_ids: JSON.parse(item.attributes.label_ids), // this blows up if label_ids is an empty array
    id: item.id,
  }));
}

async function loadReviewers() {
  const resp = await Api.organization.member.index({ is_specialist: true });
  allReviewers.value = resp.data;

  if (isAgent() && teamMemberships.value.length === 0) {
    validReviewers.value = [];
    return;
  }

  if (isAgent()) {
    const teamIds = _.map(teamMemberships.value, 'team_id');
    const teamResp = await Api.organization.member.index({
      team_id: teamIds,
      is_specialist: true,
    });
    validReviewers.value = teamResp.data;
  } else {
    validReviewers.value = allReviewers.value;
  }
}

async function loadSubsidyPrograms() {
  const resp = await Api.organization.subsidy_program.index();

  if (store.state.profile.org_subsidies_admin) {
    subsidyPrograms.value = resp.data;
  } else {
    const programs = resp.data.filter(
      (program) => !!program.published_at && programOpened(program),
    );
    subsidyPrograms.value = programs;
  }
}

async function loadLabels() {
  if (!store.state.profile.org_subsidies_access) return;

  const params = { subsidy_program_id: selectedSubsidyProgram.value?.id };
  labels.value = (await Api.organization.label.index(params)).data;
}

async function loadTeams() {
  const resp = await Api.organization.team.index();
  allTeams.value = resp.data;

  if (isAgent() && teamMemberships.value.length === 0) {
    validTeams.value = [];
    return;
  }

  if (isAgent()) {
    const ids = _.map(teamMemberships.value, 'team_id');
    validTeams.value = allTeams.value.filter((team) => ids.includes(team.id));
  } else {
    validTeams.value = allTeams.value;
  }
}

async function loadTeamMemberships() {
  const response = await Api.organization.teamMembership.index({
    member_id: store.state.profile.id,
  });
  teamMemberships.value = response.data;
}

async function programOpened(program) {
  return !program.opens_at || new Date() >= new Date(program.opens_at);
}
</script>
