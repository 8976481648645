<template>
  <v-row
    class="bg-super-light-blue w-100pc oy-hidden bottom-0 p-absolute"
    style="top: 112px; bottom: 0px"
    no-gutters
  >
    <v-col
      v-show="$vuetify.display.mdAndUp || activeThread == false"
      class="bc-extra-light-gray bt-1 bg-white h-100pc oy-hidden-scroll br-1 pb-16"
      cols="12"
      md="4"
    >
      <div
        class="pa-4 bb-1 bc-extra-light-gray d-flex align-center"
        data-cy="conversation-type-selector"
      >
        <v-select
          v-model="audience"
          :items="['Parents', 'Providers']"
          :prepend-inner-icon="audience == 'Parents' ? 'escalator_warning' : 'storefront'"
          bg-color="primary"
          class="w-50pc"
          density="compact"
          item-color="primary"
          variant="filled"
          hide-details
        />
        <v-text-field
          v-model="query"
          :aria-label="$t('Search')"
          :placeholder="$t('Search')"
          density="compact"
          prepend-inner-icon="search"
          variant="filled"
          hide-details
        />
        <v-btn
          @click="loadMessages"
          :loading="processing"
          aria-label="Refresh"
          class="ms-1 focus-visible"
          variant="text"
          icon
        >
          <v-icon>refresh</v-icon>
        </v-btn>
      </div>
      <div
        v-for="meta in filteredThreads"
        @click="switchThread(meta)"
        :key="[meta.group_id, meta.business_id].join('')"
        class="pa-4 bb-1 bc-extra-light-gray d-flex align-center pointer"
      >
        <div>
          <div
            v-text="meta.name"
            class="fs-18 fw-500"
          />
          <MarkdownContent
            :content="messagesByThread[[meta.group_id, meta.business_id]][0]?.text"
            class="fs-16 c-light-black mb-1 message-container h-20 oy-hidden"
          />
          <LongDateTime
            :date="messagesByThread[[meta.group_id, meta.business_id]][0].created_at"
            class="fs-14 c-light-black"
          />
        </div>
        <v-spacer />
        <v-icon
          v-if="
            [messagesByThread[[meta.group_id, meta.business_id]][0]].some(
              (message) =>
                message.member_id && !message.meta.member_is_specialist && !message.read_at,
            )
          "
          class="mx-1"
          color="green"
        >
          circle
        </v-icon>
      </div>
      <v-btn
        @click="goBack()"
        :loading="processing"
        class="mt-2 focus-visible"
        color="primary"
        variant="text"
      >
        View older messages
      </v-btn>
    </v-col>

    <v-col
      v-show="$vuetify.display.mdAndUp || activeThread != false"
      class="h-100pc oy-hidden-scroll p-relative bg-white"
      cols="12"
      md="8"
    >
      <div class="bc-extra-light-gray bt-1 h-100pc w-100pc">
        <MessageThreadHeader
          v-if="activeThread"
          @back="switchThread(false)"
          :subtitle="'Last message sent ' + lastMessageDate"
          :title="activeThread.name"
        />

        <transition-group name="fade">
          <div
            v-for="meta in filteredThreads"
            id="messages_list"
            :key="[meta.group_id, meta.business_id].join('')"
            :class="messageListClass"
            style="bottom: 242px"
          >
            <MessageItem
              v-for="message in currentMessageThread"
              :key="message.id"
              :message="message"
            />
          </div>
        </transition-group>

        <div
          class="bc-extra-light-gray bg-white w-100pc bottom-0 p-absolute"
          style="height: 242px"
        >
          <div class="pa-2">
            <v-row dense>
              <v-col>
                <v-card
                  class="bb-1 bc-very-light-grey"
                  style="background: rgba(0, 0, 0, 0.04)"
                  flat
                  tile
                >
                  <v-btn
                    @click="$refs.attachmentDialog.open({})"
                    class="focus-visible"
                    color="transparent"
                    data-cy="attach-button"
                    variant="flat"
                  >
                    <v-icon start> attachment </v-icon>
                    <span>Attach document</span>
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
            <AttachmentDialog
              @save="createAttachmentMessages"
              @upload="uploadedAttachments.push($event)"
              ref="attachmentDialog"
              :processing="processing"
            />
            <v-textarea
              v-model="newMessageText"
              aria-label="Type message"
              class="mb-2"
              data-cy="message-textarea"
              rows="4"
              variant="filled"
              hide-details
            />
            <v-btn
              @click="createMessage"
              :disabled="!newMessageText"
              :loading="processing"
              class="focus-visible"
              color="primary"
              data-cy="send-message-button"
              size="x-large"
              block
            >
              Send message
            </v-btn>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import AttachmentDialog from '@/shared/components/attachments/AttachmentDialog.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import MessageItem from '@/shared/components/MessageItem.vue';
import MessageThreadHeader from '@/shared/components/MessageThreadHeader.vue';
import vuetify from '@/plugins/vuetify';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const activeThread = ref(vuetify.display.mdAndUp ? null : false);
const audience = ref('Parents');
const currentMessageThread = ref([]);
const days = ref(30);
const threads = ref([]);
const messageListClass = ref(
  'bg-white w-100pc d-flex flex-column pa-4 top-100 oy-scroll p-absolute',
);
const messages = ref([]);
const messagesByThread = ref({});
const newMessageText = ref(null);
const processing = ref(false);
const query = ref(null);
const uploadedAttachments = ref([]);

// Template refs
const attachmentDialog = ref(null);

const lastMessageDate = computed(() => {
  const referenceArray = [activeThread.value.group_id, activeThread.value.business_id];
  return new Date(
    messagesByThread.value[referenceArray][
      messagesByThread.value[referenceArray].length - 1
    ].created_at,
  ).toLocaleString();
});

const threadsForAudience = computed(() => {
  const filterFn = (thread) =>
    audience.value === 'Parents' ? thread.group_id : thread.business_id;

  return threads.value.filter(filterFn);
});

const filteredThreads = computed(() => {
  // Remove first check on value?
  const queryExists = query.value && query.value !== '';
  const filterFn = queryExists
    ? (thread) => thread.name.toLowerCase().includes(query.value.toLowerCase())
    : () => true;

  return threadsForAudience.value.filter(filterFn).slice();
});

watch(audience, () => sortMessages());

onMounted(loadMessages);

async function createAttachmentMessages() {
  processing.value = true;

  const params = {
    business_id: activeThread.value.business_id,
    group_id: activeThread.value.group_id,
  };

  await Promise.all(
    uploadedAttachments.value.map(async (asset) => {
      const { data } = await Api.organization.attachment.create({
        ...asset,
        ...params,
        // group_id: params.group_id,
        // business_id: params.business_id,
      });
      const resp = await Api.organization.message.create({ ...params, attachment_id: data.id });
      messages.value.push(resp.data);
    }),
  );

  attachmentDialog.value.close();
  uploadedAttachments.value = [];
  processing.value = false;
  newMessageText.value = null;
  await sortMessages();
  scrollToBottom();
}

function createMessage() {
  processing.value = true;

  let params;
  if (activeThread.value) {
    params = {
      text: newMessageText.value,
      business_id: activeThread.value.business_id,
      group_id: activeThread.value.group_id,
    };
  } else {
    params = { text: newMessageText.value };
  }

  Api.organization.message.create(
    params,
    async () => {
      await loadThreadMessages();
      processing.value = false;
      newMessageText.value = null;
    },
    (err) => {
      processing.value = false;
      eventBus.error(err);
    },
  );
}

async function goBack() {
  days.value += 30;
  await loadMessages();
}

async function loadThreadMessages() {
  if (!activeThread.value) {
    return;
  }

  // Move to a computed ref? This array of activeThread group and business id is used quite a bit.
  const lastActiveThread = [activeThread.value.group_id, activeThread.value.business_id].join('');
  await Api.organization.message.index(
    {
      days: 1000,
      group_id: activeThread.value.group_id,
      business_id: activeThread.value.business_id,
    },
    (resp) => {
      if (
        lastActiveThread === [activeThread.value.group_id, activeThread.value.business_id].join('')
      ) {
        messagesByThread.value[[activeThread.value.group_id, activeThread.value.business_id]] =
          resp.data;
        currentMessageThread.value = messagesByThread.value[
          [activeThread.value.group_id, activeThread.value.business_id]
        ]
          .slice()
          .reverse();
        const msg = currentMessageThread.value[currentMessageThread.value.length - 1];
        msg.read_at = true;
        Api.organization.message.get(msg.id); // allow server to potentially mark the message as read
        setTimeout(scrollToBottom, 100);
      }
    },
  );
}

async function loadMessages() {
  processing.value = true;
  await Api.organization.message.index({ days: days.value }, async (resp) => {
    messages.value = resp.data;
    await sortMessages();
    processing.value = false;
  });
}

function scrollToBottom() {
  nextTick(() => {
    const list = document.getElementById('messages_list');
    if (!list) return; // ? race condition with setTimeout

    list.scrollTop = list.scrollHeight;
  });
}

async function sortMessages() {
  messagesByThread.value = {};

  messages.value.forEach((message) => {
    if (messagesByThread.value[[message.group_id, message.business_id]] === undefined) {
      messagesByThread.value[[message.group_id, message.business_id]] = [];
    }
    messagesByThread.value[[message.group_id, message.business_id]].push(message);

    const key = {
      group_id: message.group_id,
      business_id: message.business_id,
      name: message.meta.group_name || message.meta.business_name,
    };

    if (
      !threads.value.find(
        (threadKey) =>
          threadKey.group_id === key.group_id && threadKey.business_id === key.business_id,
      )
    ) {
      threads.value.push(key);
    }
  });

  await switchThread(filteredThreads.value[0]);
}

async function switchThread(thread) {
  activeThread.value = thread;
  await loadThreadMessages();
}
</script>
