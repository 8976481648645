<template>
  <v-card class="px-6 pt-0 pb-6">
    <v-row dense>
      <v-col cols="12">
        <div class="fs-28 fw-600 c-black mb-3" />
      </v-col>
      <v-col cols="12">
        <div class="fs-18 fw-600 c-black">What is the name of this template?</div>
      </v-col>
      <v-col
        class="mb-4"
        cols="12"
      >
        <v-text-field
          v-model="pageTemplate.name"
          placeholder="Input text"
          variant="solo"
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <div class="fs-18 fw-600 c-black">What does this page do?</div>
      </v-col>
      <v-col
        class="mb-4"
        cols="12"
      >
        <v-textarea
          v-model="pageTemplate.description"
          placeholder="Description"
          variant="solo"
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-btn
          @click="submit()"
          :disabled="processing"
          color="primary"
          rounded
        >
          {{ pageTemplate.id ? 'Save' : 'Create' }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script setup>
import Api from '@/admin/services/bright_finder';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';

const props = defineProps({
  siteTemplate: {
    type: Object,
    required: true,
  },
  pageTemplate: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['save']);
const eventBus = useEventBus();
const router = useRouter();

let processing = ref(false);

function submit() {
  processing.value = true;
  Api.organization.siteTemplate.pageTemplate.updateOrCreate(
    props.siteTemplate.id,
    {
      description: props.pageTemplate.description,
      id: props.pageTemplate.id,
      name: props.pageTemplate.name,
    },
    (resp) => {
      emit('save');
      if (props.pageTemplate.id) {
        eventBus.chime('Page saved.');
        processing.value = false;
      } else {
        router.push({
          name: 'PageTemplateShow',
          params: { siteTemplateId: props.siteTemplate.id, pageTemplateId: resp.data.id },
        });
        processing.value = false;
      }
    },
    (err) => {
      eventBus.chime(err.response.data.errors[0]);
      processing.value = false;
    },
  );
}
</script>
