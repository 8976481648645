<template>
  <div v-if="subsidyProgram && seatTypes">
    <BreadcrumbTitle
      :back-name="subsidyProgram.name"
      name="Seat types"
    />

    <v-divider class="my-8" />

    <v-card
      v-for="seatType in seatTypes"
      :key="seatType.id"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row class="d-flex align-center">
          <v-col>
            <v-row dense>
              <v-col>{{ seatType.name }}</v-col>
            </v-row>
            <v-row
              class="fs-12 c-light-black ml-2"
              dense
            >
              ID: {{ seatType.id }}
            </v-row>
            <v-row
              v-for="(attribute, index) in seatTypeMetadata(seatType)"
              :key="index"
              class="fs-12 c-light-black ml-2"
              dense
            >
              {{ attribute }}
            </v-row>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn
              @click="edit(seatType)"
              class="me-3"
              color="primary"
              variant="flat"
            >
              Edit
            </v-btn>
            <v-btn
              @click="remove(seatType)"
              color="red"
              variant="outlined"
            >
              Delete
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <template v-if="seatTypes.length == 0">
      <NullState @new="draft" />
    </template>

    <template v-else>
      <v-btn
        @click="draft"
        class="mt-4"
        color="primary"
      >
        <span v-t="'New'" />
      </v-btn>
    </template>

    <ResourceDialog
      @save="create"
      ref="newSeatTypeDialog"
      :fields="seatFields"
      :processing="processing"
      save-button-text="Create"
      title="New seat type"
    />

    <ResourceDialog
      @save="update"
      ref="editSeatTypeDialog"
      :fields="seatFields"
      :processing="processing"
      save-button-text="Save"
      title="Edit seat type"
    />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder.js';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { useRoute } from 'vue-router';

const editSeatTypeDialog = ref(null);
const processing = ref(false);
const seatFields = ref([
  { text: 'Name', value: 'name' },
  { text: 'Reserved for COC', value: 'reserved_for_continuity_of_care', type: 'boolean' },
  { text: 'Visible to families', value: 'visible_to_families', type: 'boolean' },
  { text: 'Providers can manage', value: 'providers_can_manage', type: 'boolean' },
]);
const seatTypes = ref(null);
const subsidyProgram = ref(null);
const newSeatTypeDialog = ref(null);
const route = useRoute();

onMounted(async () => {
  await loadSubsidyProgram();
  await loadSeatTypes();
});

async function create(newVal) {
  processing.value = true;
  await Api.organization.subsidy_program.seat_type.create(newVal);
  newSeatTypeDialog.value.close();
  await loadSeatTypes();
  processing.value = false;
}

function draft() {
  newSeatTypeDialog.value.open({
    subsidy_program_id: route.params.subsidyProgramId,
  });
}

function edit(seatType) {
  editSeatTypeDialog.value.open(seatType);
}

async function loadSubsidyProgram() {
  const resp = await Api.organization.subsidy_program.get(route.params.subsidyProgramId);
  subsidyProgram.value = resp.data;
}

async function loadSeatTypes() {
  const resp = await Api.organization.subsidy_program.seat_type.index(
    route.params.subsidyProgramId,
  );
  seatTypes.value = resp?.data;
}

async function remove(seatType) {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you sure you want to remove this seat type?')) return;
  processing.value = true;
  await Api.organization.subsidy_program.seat_type.destroy(seatType);
  await loadSeatTypes();
  processing.value = false;
}

function seatTypeMetadata(seatType) {
  const metadata = [];

  if (seatType.reserved_for_continuity_of_care)
    metadata.push(`Reserved for COC: ${seatType.reserved_for_continuity_of_care}`);
  if (seatType.visible_to_families)
    metadata.push(`Visible to families: ${seatType.visible_to_families}`);
  if (seatType.providers_can_manage)
    metadata.push(`Providers can manage: ${seatType.providers_can_manage}`);
  if (seatType.iep_direct_placement)
    metadata.push(`IEP Direct Placement: ${seatType.iep_direct_placement}`);
  if (seatType.dob_start_date) metadata.push(`DOB start date: ${seatType.dob_start_date}`);
  if (seatType.dob_end_date) metadata.push(`DOB end date: ${seatType.dob_end_date}`);
  if (seatType.min_score) metadata.push(`Minimum score: ${seatType.min_score}`);
  if (seatType.max_score) metadata.push(`Maximum score: ${seatType.max_score}`);
  if (seatType.label_operator) metadata.push(`Label operator: ${seatType.label_operator}`);

  return metadata;
}

async function update(newVal) {
  processing.value = true;
  await Api.organization.subsidy_program.seat_type.update(newVal);
  editSeatTypeDialog.value.close();
  await loadSeatTypes();
  processing.value = false;
}
</script>
