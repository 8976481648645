<template>
  <div v-if="subsidiesLoaded">
    <v-toolbar
      class="mb-3"
      elevation="5"
      extension-height="60"
      extended
    >
      <v-row class="d-flex align-center px-4">
        <template v-if="subsidyPrograms.length > 0">
          <v-col class="mxw-400">
            <v-select
              v-model="selectedSubsidyProgram"
              @update:model-value="handleSubsidyProgramChange"
              :aria-label="selectedSubsidyProgram?.name"
              :items="subsidyPrograms"
              density="compact"
              item-title="name"
              item-value="id"
              variant="filled"
              hide-details
              return-object
            />
          </v-col>
        </template>
        <v-col class="mxw-340">
          <v-text-field
            v-model="filters.query"
            @update:model-value="reload(1)"
            id="search_link"
            :aria-label="$t('Filter results by name')"
            :placeholder="$t('Filter results by name')"
            color="primary"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
            tracked
          />
        </v-col>
        <v-col>
          <template v-if="selectedSubsidyProgram">
            <AdvancedFilterManager
              @change="setAdvancedFilters"
              :field-value="filters.advanced_filter"
              :query-value="filters.advanced_filter_query"
              :subsidy-program-id="selectedSubsidyProgram.id"
            />
          </template>
        </v-col>
      </v-row>

      <template
        v-if="selectedSubsidyProgram"
        #extension
      >
        <div class="d-flex flex-grow-1 px-4">
          <FilterMenu
            :active="filters.statuses?.length > 0"
            classes="d-none d-md-inline-flex"
            title="Status"
            paddingless
          >
            <template #card>
              <v-autocomplete
                v-model="filters.statuses"
                @update:model-value="reload(1)"
                :aria-label="$t('Filter by status')"
                :disabled="processing"
                :items="statusOptions"
                :menu="true"
                :placeholder="$t('Filter by status')"
                density="compact"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="displayPriority"
            :active="!!filters.priority"
            classes="d-none d-md-inline-flex"
            title="Priority"
            paddingless
          >
            <template #card>
              <v-list
                v-model:selected="filters.priority"
                @update:selected="reload(1)"
              >
                <v-list-item
                  v-for="priority in ['Low', 'Medium', 'High']"
                  :key="priority"
                  :title="priority"
                  :value="priority"
                >
                  <template #prepend="{ isSelected }">
                    <v-checkbox-btn
                      :model-value="isSelected"
                      false-icon="check_box_outline_blank"
                      tabindex="-1"
                      true-icon="check_box"
                    />
                  </template>
                </v-list-item>
              </v-list>
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="selectedSubsidyProgram.enable_team_review"
            :active="filters.team_ids?.length > 0"
            classes="d-none d-md-inline-flex"
            title="Team"
            paddingless
          >
            <template #card>
              <v-autocomplete
                v-model="filters.team_ids"
                @update:model-value="reload(1)"
                :aria-label="$t('Filter by team name')"
                :disabled="processing"
                :items="teams"
                :menu="true"
                :placeholder="$t('Filter by team name')"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="selectedSubsidyProgram.enable_individual_review"
            :active="filters.reviewer_ids?.length > 0"
            classes="d-none d-md-inline-flex"
            title="Assignment"
            paddingless
          >
            <template #card>
              <v-autocomplete
                v-model="filters.reviewer_ids"
                @update:model-value="reload(1)"
                :aria-label="$t('Filter by reviewer name')"
                :disabled="processing"
                :items="assignedReviewerFilterItems"
                :menu="true"
                :placeholder="$t('Filter by reviewer name')"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="displayStages"
            :active="filters.stage_ids?.length > 0"
            classes="d-none d-md-inline-flex"
            title="Stage"
            paddingless
          >
            <template #card>
              <v-autocomplete
                v-model="filters.stage_ids"
                @update:model-value="reload(1)"
                :aria-label="$t('Filter by stage title')"
                :items="selectedSubsidyProgram.stages"
                :menu="true"
                :placeholder="$t('Filter by stage title')"
                density="compact"
                item-title="title"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="displayLabels"
            :active="filters.label_ids?.length > 0"
            classes="d-none d-md-inline-flex"
            title="Label"
            paddingless
          >
            <template #card>
              <v-autocomplete
                v-model="filters.label_ids"
                @update:model-value="reload(1)"
                :aria-label="$t('Filter by label name')"
                :disabled="processing"
                :items="subsidyProgramLabels"
                :menu="true"
                :placeholder="$t('Filter by label name')"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              >
                <template #chip="{ item, props }">
                  <v-chip v-bind="props">
                    <template #prepend>
                      <v-avatar
                        :color="item.raw.color"
                        start
                      />
                    </template>
                    {{ item.raw.name }}
                  </v-chip>
                </template>
                <template #item="{ item, props }">
                  <v-list-item v-bind="props">
                    <template #prepend="{ isSelected }">
                      <v-checkbox-btn
                        :key="item.value"
                        :model-value="isSelected"
                        :ripple="false"
                        tabindex="-1"
                      />
                    </template>
                    <template #append>
                      <v-avatar
                        :color="item.raw.color"
                        size="20"
                      />
                    </template>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </template>
          </FilterMenu>

          <v-dialog
            v-model="assignTeamDialogIsVisible"
            max-width="400"
            scrim="transparent"
          >
            <v-card
              border
              flat
              tile
            >
              <v-card-title>Assign</v-card-title>
              <v-divider class="mb-2" />
              <v-card-text class="py-3">
                <v-row dense>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="assignTeamTo"
                      :items="teams"
                      item-title="name"
                      item-value="id"
                      label="Assign to"
                      placeholder="Assign to"
                      variant="filled"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="my-2" />
              <v-card-actions>
                <v-btn
                  @click="assignTeamDialogIsVisible = false"
                  variant="text"
                >
                  Cancel
                </v-btn>
                <v-spacer />
                <v-btn
                  @click="assignTeam()"
                  :disabled="!assignTeamTo"
                  color="primary"
                >
                  Assign
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="assignReviewerDialogIsVisible"
            max-width="400"
            scrim="transparent"
          >
            <v-card
              border
              flat
              tile
            >
              <v-card-title>Assign</v-card-title>
              <v-divider class="mb-2" />
              <v-card-text class="py-3">
                <v-row dense>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="assignReviewerTo"
                      :items="assignedReviewerFilterItems"
                      item-title="name"
                      item-value="id"
                      label="Assign to"
                      placeholder="Assign to"
                      variant="filled"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="my-2" />
              <v-card-actions>
                <v-btn
                  @click="assignReviewerDialogIsVisible = false"
                  variant="text"
                >
                  Cancel
                </v-btn>
                <v-spacer />
                <v-btn
                  @click="assignReviewer()"
                  :disabled="!assignReviewerTo"
                  color="primary"
                >
                  Assign
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="updateStageDialogIsVisible"
            max-width="400"
            scrim="transparent"
          >
            <v-card
              v-if="updateStageDialogIsVisible"
              border
              flat
              tile
            >
              <v-card-title>Set stage</v-card-title>
              <v-divider class="my-2" />
              <v-card-text class="pa-0">
                <v-list class="py-0">
                  <v-list-item
                    v-for="stage in selectedSubsidyProgram.stages"
                    :key="stage.id"
                    lines="two"
                  >
                    <v-list-item-title>{{ $t(stage.title) }}</v-list-item-title>

                    <template #append>
                      <v-hover v-slot="{ isHovering, props }">
                        <v-btn
                          @click="selectedStageId = stage.id"
                          v-bind="props"
                          :loading="processing"
                          color="grey-lighten-3"
                          data-cy="set-stage-button"
                          variant="outlined"
                          icon
                        >
                          <v-icon
                            v-if="isHovering || selectedStageId === stage.id"
                            color="primary-lighten-2"
                          >
                            check
                          </v-icon>
                        </v-btn>
                      </v-hover>
                    </template>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-divider class="my-2" />
              <v-card-actions>
                <v-btn
                  @click="updateStageDialogIsVisible = false"
                  variant="text"
                >
                  Cancel
                </v-btn>
                <v-spacer />
                <v-btn
                  @click="setSubsidyStage()"
                  :disabled="!selectedStageId || processing"
                  color="primary"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-spacer />

          <template v-if="selectedSubsidyProgram.enable_team_review && teams.length > 0">
            <v-btn
              v-show="anySelectedItems"
              @click="assignTeamDialogIsVisible = true"
              class="me-3"
              color="primary"
            >
              Assign team
            </v-btn>
          </template>

          <template
            v-if="
              selectedSubsidyProgram.enable_individual_review &&
              assignedReviewerFilterItems.length > 0
            "
          >
            <v-btn
              v-show="anySelectedItems"
              @click="assignReviewerDialogIsVisible = true"
              class="me-3"
              color="primary"
            >
              Assign member
            </v-btn>
          </template>

          <v-btn
            @click="updateQuery({ action: 'create' })"
            color="primary"
            data-cy="create-button"
            elevation="0"
            prepend-icon="add"
            variant="flat"
          >
            <span
              v-if="$vuetify.display.mdAndUp"
              v-t="`New ${terms.subsidy.toLowerCase()}`"
            />
          </v-btn>

          <template v-if="anySelectedItems || selectedAll">
            <ActionMenu
              v-show="anySelectedItems || selectedAll"
              @click:action:download="download"
              @click:action:update:stage="openStageSelectionDialog"
              :items="[
                { title: 'Download', event: 'download' },
                { title: 'Update stage', event: 'update:stage' },
              ]"
              button-class="ms-3"
              button-icon="more_vert"
            />
          </template>
        </div>
      </template>
    </v-toolbar>

    <v-container
      class="px-3 py-0"
      fluid
    >
      <v-card
        class="bb-1 bc-extra-light-gray pa-0"
        tile
      >
        <v-card-text class="pa-3">
          <v-card
            class="bb-1 bc-extra-light-gray mb-4"
            flat
            tile
          >
            <v-card-text class="pt-0 px-0 pb-3 fs-15">
              <v-row dense>
                <v-col class="d-flex align-center">
                  <template v-if="filterSummary">
                    <v-icon start> filter_alt </v-icon>
                    <span
                      v-t="'Filtering by:'"
                      class="me-1"
                    />
                    <span class="fw-600">{{ $t(filterSummary) }}</span>
                    <span>.&nbsp;</span>
                  </template>
                  <span>
                    {{ $t('Displaying') }} <strong>{{ collection.length }}</strong>
                    {{ $t('results') }}, {{ $t('out of') }} <strong>{{ total }}</strong>
                    {{ $t('total results') }}.
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-row
            v-if="selectedSubsidyProgram"
            dense
          >
            <v-col
              class="fw-600 fs-14 c-black"
              cols="1"
            >
              <div class="d-flex align-center fw-600 fs-14 c-black">
                <v-checkbox-btn
                  v-model="selectedAll"
                  aria-label="Select all applications"
                  class="d-inline-flex flex-none pa-0 ma-0 va-middle"
                  false-icon="check_box_outline_blank"
                  true-icon="check_box"
                />
                <div class="d-flex flex-column justify-center">
                  <v-btn
                    @click="toggleSort('children.last_name')"
                    class="h-inherit pa-0 ma-0"
                    variant="text"
                  >
                    <span
                      v-t="'Child'"
                      class="fs-14 fw-600 c-black"
                    />
                    <v-icon class="c-black">
                      {{
                        sortDirection['children.last_name'] === 'desc'
                          ? 'arrow_drop_down'
                          : 'arrow_drop_up'
                      }}
                    </v-icon>
                  </v-btn>
                  <div>Child ID</div>
                </div>
              </div>
            </v-col>
            <v-col
              class="ta-center"
              cols="1"
            >
              <v-btn
                @click="toggleSort('group_application_data.home_county')"
                class="h-inherit pa-0 ma-0"
                variant="text"
              >
                <span
                  v-t="'County'"
                  class="fs-14 fw-600 c-black"
                />
                <v-icon class="c-black">
                  {{
                    sortDirection['group_application_data.home_county'] === 'desc'
                      ? 'arrow_drop_down'
                      : 'arrow_drop_up'
                  }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="selectedSubsidyProgram.enable_team_review"
              class="fw-600 fs-14 c-black ta-center"
              cols="1"
            >
              Team
            </v-col>
            <v-col
              v-if="selectedSubsidyProgram.enable_individual_review"
              class="fw-600 fs-14 c-black ta-center"
              cols="1"
            >
              Assigned
            </v-col>
            <v-col
              class="ta-center"
              cols="1"
            >
              <v-btn
                @click="toggleSort('submitted_at')"
                class="h-inherit pa-0 ma-0"
                variant="text"
              >
                <span
                  v-t="'Date'"
                  class="fs-14 fw-600 c-black"
                />
                <v-icon class="c-black">
                  {{
                    sortDirection['submitted_at'] === 'desc' ? 'arrow_drop_down' : 'arrow_drop_up'
                  }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              class="ta-center"
              cols="2"
            >
              <v-btn
                @click="toggleSort('status')"
                class="h-inherit"
                variant="text"
              >
                <span
                  v-t="'Status'"
                  class="fs-14 fw-600 c-black"
                />
                <v-icon class="c-black">
                  {{ sortDirection['status'] === 'desc' ? 'arrow_drop_down' : 'arrow_drop_up' }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="displayStages"
              class="ta-center"
              cols="2"
            >
              <v-btn
                @click="toggleSort('stage_id')"
                class="h-inherit pa-0 ma-0"
                variant="text"
              >
                <span
                  v-t="'Stage'"
                  class="fs-14 fw-600 c-black"
                />
                <v-icon class="c-black">
                  {{ sortDirection['stage_id'] === 'desc' ? 'arrow_drop_down' : 'arrow_drop_up' }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="displayPriority"
              class="ta-center"
              cols="1"
            >
              <v-btn
                @click="toggleSort('priority')"
                class="h-inherit pa-0 ma-0"
                variant="text"
              >
                <span
                  v-t="'Priority'"
                  class="fs-14 fw-600 c-black"
                />
                <v-icon class="c-black">
                  {{ sortDirection['priority'] === 'desc' ? 'arrow_drop_down' : 'arrow_drop_up' }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="displayLabels"
              class="ta-center"
              cols="1"
            >
              <v-btn
                class="h-inherit pa-0 ma-0"
                variant="text"
              >
                <span
                  v-t="'Labels'"
                  class="fs-14 fw-600 c-black"
                />
              </v-btn>
            </v-col>
            <v-col
              v-if="displayPriorityScore"
              class="ta-center"
              cols="1"
            >
              <v-btn
                @click="toggleSort('score')"
                class="h-inherit pa-0 ma-0"
                variant="text"
              >
                <span
                  v-t="'Score'"
                  class="fs-14 fw-600 c-black"
                />
                <v-icon class="c-black">
                  {{ sortDirection['score'] === 'desc' ? 'arrow_drop_down' : 'arrow_drop_up' }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card
        v-for="(subsidy, index) in collection"
        :key="index"
        class="pointer bb-1 bc-extra-light-gray pa-0"
        tile
      >
        <v-card-text class="pa-3">
          <v-row
            class="d-flex align-center"
            dense
          >
            <v-col
              class="c-black fs-14 fw-500"
              cols="1"
            >
              <div class="d-flex align-center fw-500 fs-14 c-black">
                <v-checkbox-btn
                  v-model="selectedItems[subsidy.id]"
                  @update:model-value="checkSelectedItems()"
                  :aria-label="$t('Select') + ' ' + subsidy.name"
                  class="d-inline-block pa-0 ma-0 va-middle"
                  false-icon="check_box_outline_blank"
                  true-icon="check_box"
                />
                <router-link
                  :to="{ name: 'SubsidyShow', params: { id: subsidy.id } }"
                  data-cy="subsidy-name"
                >
                  <span v-text="subsidy.name" />
                </router-link>
              </div>
            </v-col>
            <v-col
              class="c-black fs-14 fw-500 ta-center"
              cols="1"
            >
              <span v-text="subsidy.home_county" />
            </v-col>
            <v-col
              v-if="selectedSubsidyProgram.enable_team_review"
              class="c-black fs-14 fw-500 ta-center"
              cols="1"
            >
              <v-tooltip
                v-if="subsidy.team_name"
                location="bottom"
                center
              >
                <template #activator="{ props }">
                  <v-chip
                    @click:close="removeReviewer(subsidy, true)"
                    v-bind="props"
                    :closable="$store.state.profile.org_subsidies_admin"
                    :disabled="processing"
                    close-icon="close"
                    size="small"
                  >
                    {{ teamName(subsidy.team_name) }}
                  </v-chip>
                </template>
                <span v-text="subsidy.team_name" />
              </v-tooltip>
            </v-col>
            <v-col
              v-if="selectedSubsidyProgram.enable_individual_review"
              class="c-black fs-14 fw-500 ta-center"
              cols="1"
            >
              <v-tooltip
                v-if="subsidy.reviewer_name"
                location="bottom"
                center
              >
                <template #activator="{ props }">
                  <v-chip
                    @click:close="removeReviewer(subsidy, false)"
                    v-bind="props"
                    :closable="$store.state.profile.org_subsidies_admin"
                    :disabled="processing"
                    close-icon="close"
                    size="small"
                  >
                    {{
                      [
                        subsidy.reviewer_name[0],
                        subsidy.reviewer_name[subsidy.reviewer_name.length - 1],
                      ]
                        .join('')
                        .toUpperCase()
                    }}
                  </v-chip>
                </template>
                <span v-text="subsidy.reviewer_name" />
              </v-tooltip>
            </v-col>
            <v-col
              class="c-black fs-14 fw-500 ta-center"
              cols="1"
            >
              <LongDate
                :date="subsidy.submitted_at"
                short-month
              />
            </v-col>
            <v-col
              class="c-black fs-14 fw-500 ta-center"
              cols="2"
            >
              {{ subsidy.status }}
            </v-col>
            <v-col
              v-if="displayStages"
              class="c-black fs-14 fw-500 ta-center"
              cols="2"
            >
              <template v-if="subsidy.stage_id">
                <span v-text="stages[subsidy.stage_id]?.title" />
              </template>
            </v-col>
            <v-col
              v-if="displayPriority"
              class="c-black fs-14 fw-500 ta-center"
              cols="1"
            >
              {{ subsidy.priority }}
            </v-col>
            <v-col
              v-if="displayLabels"
              class="c-black fs-14 fw-500 ta-center"
              cols="1"
            >
              <LabelValue
                @edit="$refs.subsidyLabelDialog.open(subsidy)"
                :applied-labels="subsidy.relationships.labels.data"
                :disabled="processing"
              />
            </v-col>
            <v-col
              v-if="displayPriorityScore"
              class="c-black fs-14 fw-500 ta-center"
              cols="1"
            >
              <v-chip
                :color="getScoreDisplayColor(subsidy.score)"
                class="w-48 justify-center"
              >
                {{ subsidy.score || '0' }}
              </v-chip>
            </v-col>
            <v-col
              class="c-black fs-14 fw-500 ta-center"
              cols="1"
            >
              {{ subsidy.child_external_id }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <div
        v-if="processing"
        class="mxw-800 mx-auto py-12 ta-center"
      >
        <v-progress-circular
          color="primary"
          size="75"
          indeterminate
        />
      </div>

      <v-row
        v-if="!processing && collection.length === 0"
        class="justify-center px-2"
      >
        <v-col cols="3">
          <v-img :src="$a.url('icons.nullstate')" />
        </v-col>
      </v-row>

      <v-pagination
        v-show="pages > 1"
        v-model="filters.page"
        @update:model-value="reload"
        :length="pages"
        :total-visible="8"
        class="mt-4"
      />

      <VerticalSpacer :min-height="60" />

      <ApplicationCreator
        @change="$router.push({ name: 'SubsidyShow', params: { id: $event.id } })"
        :selected-subsidy-program="selectedSubsidyProgram"
        :subsidy-programs="subsidyPrograms"
        :teams="teams"
      />
    </v-container>

    <LabelDialog
      @change="updateEditedSubsidyLabels"
      @close="loadSubsidies()"
      ref="subsidyLabelDialog"
      :program-labels="subsidyProgramLabels"
      label-owner-type="Subsidy"
    />
  </div>
</template>

<script>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import AdvancedFilterManager from '@/specialist/components/subsidy/AdvancedFilterManager.vue';
import ApplicationCreator from '@/specialist/components/subsidy/ApplicationCreator.vue';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import LabelDialog from '@/specialist/components/LabelDialog.vue';
import LabelValue from '@/specialist/components/LabelValue.vue';
import LongDate from '@/shared/components/LongDate.vue';
import { toAcronym } from '@/shared/services/string_utils';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import _ from 'lodash';

import API from '@/shared/mixins/api';
import RouterHelper from '@/shared/mixins/RouterHelper';
import Terms from '@/shared/mixins/terms';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ActionMenu,
    AdvancedFilterManager,
    ApplicationCreator,
    FilterMenu,
    LabelDialog,
    LabelValue,
    LongDate,
    VerticalSpacer,
  },

  mixins: [API, RouterHelper, Terms],

  props: {
    subsidyTerm: {
      type: String,
      default: 'Subsidy',
    },
  },

  data() {
    return {
      anySelectedItems: false,
      assignTeamDialogIsVisible: false,
      assignTeamTo: null,
      assignReviewerDialogIsVisible: false,
      assignedReviewerFilterItems: [],
      assignReviewerTo: null,
      collection: [],
      filters: this.getDefaultFilters(),
      filterSummary: null,
      pages: 1,
      processing: false,
      searchCounter: 0,
      selectedAll: false,
      selectedItems: {},
      selectedStageId: null,
      selectedSubsidyProgram: null,
      sort: null,
      sortDirection: {
        'children.last_name': 'asc',
        submitted_at: 'desc',
        updated_at: 'desc',
        status: 'desc',
        priority: 'desc',
      },
      status: null,
      subsidiesLoaded: false,
      subsidyPrograms: [],
      subsidyProgramLabels: [],
      teamMemberships: [],
      teams: [],
      total: 0,
      updateStageDialogIsVisible: false,
    };
  },

  computed: {
    displayLabels() {
      return this.$store.state.profile.org_subsidies_access && this.subsidyProgramLabels.length > 0;
    },

    displayPriority() {
      return (
        this.$store.state.profile.org_subsidies_access &&
        this.selectedSubsidyProgram?.enable_priority
      );
    },

    displayPriorityScore() {
      return (
        this.$store.state.profile.org_subsidies_access &&
        this.selectedSubsidyProgram?.enable_scoring &&
        this.selectedSubsidyProgram?.display_priority_score
      );
    },

    displayStages() {
      return (
        this.$store.state.profile.org_subsidies_access &&
        this.selectedSubsidyProgram?.enable_stages &&
        this.selectedSubsidyProgram?.stages.length > 0
      );
    },

    selectedIds() {
      return Object.keys(this.selectedItems).filter((key) => this.selectedItems[key]);
    },

    stages() {
      if (this.selectedSubsidyProgram) {
        const hsh = {};
        this.selectedSubsidyProgram.stages.forEach((stage) => {
          hsh[stage.id] = stage;
        });
        return hsh;
      }

      return {};
    },

    statusOptions() {
      return this.selectedSubsidyProgram.statuses;
    },
  },

  watch: {
    selectedAll(newVal) {
      this.setAll(newVal);
      this.checkSelectedItems();
    },
  },

  created() {
    this.load();
  },

  methods: {
    async assignTeam() {
      this.processing = true;
      let resp;
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const id in this.selectedItems) {
        if (this.selectedItems[id]) {
          // eslint-disable-next-line no-await-in-loop
          resp = await this.api.organization.subsidy.update(id, { team_id: this.assignTeamTo });
          this.$set(
            this.collection,
            this.collection.indexOf(this.collection.find((item) => item.id === id)),
            resp.data,
          );
        }
      }
      this.assignTeamDialogIsVisible = false;
      this.assignTeamTo = null; // TODO: temporary clear until we convert v-dialog into resource dialog
      this.selectedAll = false;
      this.setAll(false);
      this.processing = false;
      this.checkSelectedItems();
    },

    async assignReviewer() {
      this.processing = true;
      let resp;
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const id in this.selectedItems) {
        if (this.selectedItems[id]) {
          // eslint-disable-next-line no-await-in-loop
          resp = await this.api.organization.subsidy.update(id, {
            reviewer_id: this.assignReviewerTo,
          });
          this.$set(
            this.collection,
            this.collection.indexOf(this.collection.find((item) => item.id === id)),
            resp.data,
          );
        }
      }
      this.assignReviewerDialogIsVisible = false;
      this.assignReviewerTo = null; // TODO: temporary clear until we convert v-dialog into resource dialog
      this.selectedAll = false;
      this.setAll(false);
      this.processing = false;
      this.checkSelectedItems();
    },

    download() {
      const downloadURL = this.api.organization.subsidy.bulkDownloadUrl(
        this.selectedIds,
        'Applications.zip',
      );
      window.open(downloadURL, '_blank');
    },

    getDefaultFilters() {
      return {
        advanced_filter_query: null,
        advanced_filter: null,
        label: null,
        statuses: [],
        subsidy_program_id: null,
        filter: null,
        page: 1,
        priority: null,
        query: null,
        label_ids: [],
        stage_ids: [],
        reviewer_ids: [],
        team_ids: [],
      };
    },

    getFilterSummary() {
      const appliedFilters = [];
      if (this.filters.statuses?.length > 0) appliedFilters.push(this.filters.statuses.join(', '));

      if (this.filters.priority) appliedFilters.push(this.filters.priority);
      if (this.filters.team_ids?.length > 0) {
        Object.values(this.filters.team_ids).forEach((id) => {
          appliedFilters.push(this.teams.find((team) => team.id === id).name);
        });
      }
      if (this.filters.reviewer_ids?.length > 0) {
        Object.values(this.filters.reviewer_ids).forEach((id) => {
          appliedFilters.push(
            this.assignedReviewerFilterItems.find(
              (assignedReviewerFilterItem) => assignedReviewerFilterItem.id === id,
            ).name,
          );
        });
      }
      if (this.filters.stage_ids?.length > 0) {
        Object.values(this.filters.stage_ids).forEach((id) => {
          appliedFilters.push(
            this.selectedSubsidyProgram.stages.find((stage) => stage.id === id).title,
          );
        });
      }
      if (this.filters.label_ids?.length > 0) {
        Object.values(this.filters.label_ids).forEach((id) => {
          appliedFilters.push(this.subsidyProgramLabels.find((label) => label.id === id).name);
        });
      }
      return appliedFilters.length > 0 ? appliedFilters.join(', ') : null;
    },

    getScoreDisplayColor(score = 0) {
      const HIGH = { FLOOR: 80, COLOR: 'blue-lighten-2' };
      const MEDIUM = { FLOOR: 50, COLOR: 'green-lighten-2' };
      const LOW = { FLOOR: 30, COLOR: 'yellow-lighten-2' };
      const DEFAULT = { COLOR: 'grey-lighten-2' };

      if (score > HIGH.FLOOR) return HIGH.COLOR;
      if (score > MEDIUM.FLOOR) return MEDIUM.COLOR;
      if (score > LOW.FLOOR) return LOW.COLOR;
      return DEFAULT.COLOR;
    },

    handleSubsidyProgramChange() {
      this.filters = this.getDefaultFilters();
      this.filters.subsidy_program_id = this.selectedSubsidyProgram.id;
      this.loadSubsidyProgramLabels();
      this.reload(1);
    },

    isAgent() {
      return (
        this.$store.state.profile.org_subsidies_agent &&
        !this.$store.state.profile.org_subsidies_access
      );
    },

    async load() {
      this.collection.splice(0);
      this.processing = true;

      await this.loadSubsidyPrograms();

      this.loadFilters();
      await this.updateQuery({ ...this.filters });
      this.$store.commit('setFilters', ['subsidy', this.filters]);

      await this.loadTeamMemberships();
      await this.loadTeams();
      await this.loadMembers();

      this.loadSubsidies();
    },

    async loadSubsidyPrograms() {
      const resp = await this.api.organization.subsidy_program.index();

      if (this.$store.state.profile.org_subsidies_admin) {
        this.subsidyPrograms = resp.data;
      } else {
        this.subsidyPrograms = resp.data.filter(
          (program) => !!program.published_at && this.programOpened(program),
        );
      }
    },

    loadFilters() {
      const queryFilters = { ...this.$route.query };
      const storedFilters = this.$store.state.filters.subsidy || {};

      let filtersHash;
      if (Object.keys(queryFilters).length > 1) {
        filtersHash = queryFilters;
      } else if (Object.keys(storedFilters).length > 1) {
        filtersHash = storedFilters;
      } else {
        filtersHash = {
          subsidy_program_id: this.subsidyPrograms[0].id,
        };
      }

      // TODO: With limiting view of subsidy programs in index views we need to determine whether or not
      // it is possible for other views to set the query params/stored filters w/ an "ineligible" program
      // which would cause errors here when it attempts to load.

      const newFilters = _.merge(this.getDefaultFilters, filtersHash);

      const defaultFilters = this.getDefaultFilters();
      Object.keys(defaultFilters).forEach((key) => {
        if (!_.isString(newFilters[key])) return;

        if (_.isArray(defaultFilters[key])) newFilters[key] = [newFilters[key]];

        if (_.isNumber(defaultFilters[key])) newFilters[key] = parseInt(newFilters[key], 10);
      }, this);

      Object.keys(newFilters).forEach((key) => {
        this.filters[key] = newFilters[key];
      }, this);

      this.selectedSubsidyProgram = this.subsidyPrograms.find(
        (sp) => sp.id === this.filters.subsidy_program_id,
      );

      this.loadSubsidyProgramLabels();
    },

    async loadMembers() {
      if (this.isAgent() && this.teamMemberships.length === 0) {
        this.assignedReviewerFilterItems = [];
        return;
      }

      if (this.isAgent()) {
        const teamIds = _.map(this.teamMemberships, 'team_id');
        const resp = await this.api.organization.member.index({
          team_id: teamIds,
          is_specialist: true,
        });
        this.assignedReviewerFilterItems = resp.data;
      } else {
        const resp = await this.api.organization.member.index({ is_specialist: true });
        this.assignedReviewerFilterItems = resp.data;
      }
    },

    async loadSubsidies() {
      this.processing = true;
      this.filterSummary = this.getFilterSummary();
      this.searchCounter += 1;
      const currentSearchCounter = this.searchCounter;

      const resp = await this.api.organization.subsidy.index(this.filters);
      if (this.searchCounter !== currentSearchCounter) return;

      this.pages = parseInt(resp.headers['x-page-count'] || 0, 10);
      this.total = parseInt(resp.headers['x-total-count'] || 0, 10);
      this.collection = resp.data;
      this.subsidiesLoaded = true;
      this.selectedItems = {};
      this.processing = false;
    },

    async loadSubsidyProgramLabels() {
      if (!this.$store.state.profile.org_subsidies_access) return;

      const resp = await this.api.organization.label.index({
        subsidy_program_id: this.selectedSubsidyProgram?.id,
      });
      this.subsidyProgramLabels = resp.data;
    },

    async loadTeams() {
      if (this.isAgent() && this.teamMemberships.length === 0) {
        this.teams = [];
        return;
      }

      if (this.isAgent()) {
        const ids = _.map(this.teamMemberships, 'team_id');
        const resp = await this.api.organization.team.index({ ids });
        this.teams = resp.data;
      } else {
        const resp = await this.api.organization.team.index();
        this.teams = resp.data;
      }
    },

    async loadTeamMemberships() {
      const response = await this.api.organization.teamMembership.index({
        member_id: this.$store.state.profile.id,
      });
      this.teamMemberships = response.data;
    },

    openStageSelectionDialog() {
      if (!this.displayStages) return;
      this.selectedStageId = null;
      this.updateStageDialogIsVisible = true;
    },

    programOpened(program) {
      return !program.opens_at || new Date() >= new Date(program.opens_at);
    },

    async reload(page) {
      if (page) this.filters.page = page;
      await this.updateQuery({ ...this.filters });
      this.$store.commit('setFilters', ['subsidy', this.filters]);
      this.loadSubsidies();
    },

    async removeReviewer(subsidy, isTeam) {
      this.processing = true;
      const params = {
        team_id: isTeam ? null : subsidy.team_id,
        reviewer_id: isTeam ? subsidy.reviewer_id : null,
      };

      await this.api.organization.subsidy.update(subsidy.id, params);

      this.loadSubsidies();
    },

    async setAdvancedFilters(newVal) {
      [this.filters.advanced_filter, this.filters.advanced_filter_query] = newVal;
      this.filters.page = 1;

      await this.updateQuery({ ...this.filters });
      this.reload(1);
    },

    setAll(val) {
      this.collection.forEach((subsidy) => {
        this.selectedItems[subsidy.id] = val;
      }, this);
    },

    async setSubsidyStage() {
      this.processing = true;
      let resp;
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const id in this.selectedItems) {
        if (this.selectedItems[id]) {
          // eslint-disable-next-line no-await-in-loop
          resp = await this.api.organization.subsidy.update(id, { stage_id: this.selectedStageId });
          this.$set(
            this.collection,
            this.collection.indexOf(this.collection.find((item) => item.id === id)),
            resp.data,
          );
        }
      }
      this.updateStageDialogIsVisible = false;
      this.processing = false;
      this.selectedStageId = null;
      this.selectedAll = false;
      this.setAll(false);
    },

    teamName(name) {
      return toAcronym(name);
    },

    toggleSort(field) {
      this.sort = field;
      Object.keys(this.sortDirection).forEach((key) => {
        if (field !== key) {
          this.sortDirection[key] = 'desc';
        }
      });
      if (this.sortDirection[field] === 'desc') {
        this.sortDirection[field] = 'asc';
      } else {
        this.sortDirection[field] = 'desc';
      }

      this.filters.sort = this.sort;
      this.filters.sort_dir = this.sortDirection[this.sort];

      this.reload(1);
    },

    updateEditedSubsidyLabels(subsidy) {
      this.$set(
        this.collection,
        this.collection.findIndex((item) => item.id === subsidy.id),
        subsidy,
      );
    },

    checkSelectedItems() {
      this.anySelectedItems = Object.values(this.selectedItems).some((item) => item === true);
    },
  },
};
</script>
