<template>
  <ResourceDialog
    @save="save"
    ref="dialog"
    :max-width="600"
    :save-button-disabled="!validCount"
    title="Add count"
  >
    <template #form>
      <v-row
        class="mb-3"
        dense
      >
        <LabeledTextfield
          v-model="draft.alias"
          message="Name"
        />
        <LabeledTextfield
          v-model="draft.count_min"
          message="Minimum"
        />
        <LabeledTextfield
          v-model="draft.count_max"
          message="Maximum"
        />
      </v-row>
      <v-row
        v-show="draft.filters.length == 0"
        dense
      >
        <v-col class="fs-16 c-light-black">
          <span v-t="'Add filters to create a count column'" />
        </v-col>
      </v-row>
      <v-row
        v-for="(filter, index) in draft.filters"
        :key="index"
      >
        <LabeledControl
          @remove="removeFilter(index)"
          :message="`Filter ${index + 1}`"
          removable
        >
          <div>
            <v-row dense>
              <v-col cols="12">
                <v-autocomplete
                  v-model="draft.filters[index].data_type"
                  :items="dataTypes"
                  item-title="data_type"
                  item-value="data_type"
                  prefix="Table:"
                  variant="filled"
                  chips
                  hide-details
                />
              </v-col>

              <template v-if="draft.filters[index].data_type">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="draft.filters[index].field"
                    :items="fields[draft.filters[index].data_type]"
                    prefix="Field:"
                    variant="filled"
                    chips
                    hide-details
                  />
                </v-col>
              </template>

              <template v-if="draft.filters[index].field">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="draft.filters[index].comparator"
                    :items="Object.values(comparators)"
                    prefix="Condition:"
                    variant="filled"
                    chips
                    hide-details
                  />
                </v-col>
              </template>

              <template v-if="comparators[draft.filters[index].comparator]">
                <v-col
                  v-show="comparators[draft.filters[index].comparator].compareable"
                  class="d-flex align-center"
                  cols="12"
                >
                  <template v-if="comparators[draft.filters[index].comparator].type == 'number'">
                    <v-text-field
                      v-model="draft.filters[index].value"
                      prefix="Number:"
                      type="number"
                      variant="filled"
                      hide-details
                    />
                  </template>

                  <template v-if="comparators[draft.filters[index].comparator].type == 'string'">
                    <template v-if="comparators[draft.filters[index].comparator].format == 'date'">
                      <v-row dense>
                        <LabeledDatePicker
                          v-model="draft.filters[index].value"
                          filled
                        />
                      </v-row>
                    </template>

                    <template v-else>
                      <v-text-field
                        v-model="draft.filters[index].value"
                        prefix="Value:"
                        variant="filled"
                        hide-details
                      />
                    </template>
                  </template>
                </v-col>
              </template>
            </v-row>
          </div>
        </LabeledControl>
      </v-row>

      <v-row dense>
        <v-col class="d-flex justify-start">
          <v-btn
            @click="draft.filters.push({})"
            class="mt-4"
            color="primary"
            size="small"
          >
            {{ $t('Add') }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </ResourceDialog>
</template>

<script setup>
import LabeledControl from '@/shared/components/form/LabeledControl.vue';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import reportComparators from '@/specialist/services/report-comparators';

defineProps({
  dataTypes: {
    type: Array,
    default: null,
  },
  fields: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['change']);

defineExpose({ open, close });

const draft = ref(getDefaultColumn());
const comparators = ref(reportComparators);
const dialog = ref(null);

const validCount = computed(() => {
  return (
    !!draft.value.alias &&
    draft.value.filters.length > 0 &&
    !draft.value.filters.some((filter) => !filter.data_type || !filter.field || !filter.comparator)
  );
});

function close() {
  dialog.value.close();
  draft.value = getDefaultColumn();
}

function getDefaultColumn() {
  return {
    alias: null,
    count: true,
    filters: [],
  };
}

function open(column) {
  draft.value = column ? JSON.parse(JSON.stringify(column)) : getDefaultColumn();
  dialog.value.open();
}

function removeFilter(index) {
  draft.value.filters.splice(index, 1);
}

function save() {
  if (!draft.value.key) {
    draft.value.key = Date.now().toString();
  }

  emit('change', draft.value);
}
</script>
