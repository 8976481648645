<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      @create="createNewSpecialistForm"
      :createable="selectedSchemaIsRequestable"
      :title="terms.forms || 'Forms'"
      create-text="New form"
    />

    <v-toolbar
      class="mb-3 px-4 bb-1"
      extension-height="60"
    >
      <v-row class="d-flex align-center">
        <template v-if="schemas.length > 0">
          <v-col class="mxw-400">
            <v-select
              v-model="selectedSchema"
              @update:model-value="handleSchemaChange"
              :aria-label="selectedSchema?.name"
              :items="schemas"
              data-cy="schemas_list"
              density="compact"
              item-title="name"
              item-value="id"
              variant="filled"
              hide-details
              return-object
            />
          </v-col>
        </template>
      </v-row>

      <template
        v-if="selectedSchema"
        #extension
      >
        <div class="d-flex flex-grow-1">
          <FilterMenu
            :active="filters.statusIds?.length > 0"
            classes="d-none d-md-inline-flex"
            title="Status"
          >
            <template #card>
              <v-autocomplete
                v-model="filters.statusIds"
                @update:model-value="handlePageChange(1)"
                :aria-label="$t('Filter by status')"
                :disabled="processing"
                :item-title="(item) => $t(item)"
                :items="statusOptions"
                :menu="true"
                :placeholder="$t('Filter by status')"
                density="compact"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            :active="filters.teamIds?.length > 0"
            classes="d-none d-md-inline-flex"
            title="Team"
            dense
          >
            <template #card>
              <v-autocomplete
                v-model="filters.teamIds"
                @update:model-value="handlePageChange(1)"
                :aria-label="$t('Filter by team name')"
                :disabled="processing"
                :item-title="(item) => $t(item.name)"
                :items="teams"
                :menu="true"
                :placeholder="$t('Filter by team name')"
                density="compact"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            :active="filters.reviewerIds?.length > 0"
            classes="d-none d-md-inline-flex"
            title="Reviewer"
            dense
          >
            <template #card>
              <v-autocomplete
                v-model="filters.reviewerIds"
                @update:model-value="handlePageChange(1)"
                :aria-label="$t('Filter by reviewer name')"
                :disabled="processing"
                :items="reviewers"
                :menu="true"
                :placeholder="$t('Filter by reviewer name')"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="selectedSchema.enable_stages"
            :active="filters.stageIds?.length > 0"
            classes="d-none d-md-inline-flex"
            title="Stage"
            dense
          >
            <template #card>
              <v-autocomplete
                v-model="filters.stageIds"
                @update:model-value="handlePageChange(1)"
                :aria-label="$t('Filter by stage title')"
                :items="selectedSchema.stages"
                :menu="true"
                :placeholder="$t('Filter by stage title')"
                density="compact"
                item-title="title"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="labels.length > 0"
            :active="filters.labelIds?.length > 0"
            classes="d-none d-md-inline-flex"
            title="Label"
            paddingless
          >
            <template #card>
              <v-autocomplete
                v-model="filters.labelIds"
                @update:model-value="handlePageChange(1)"
                :aria-label="$t('Filter by label name')"
                :closable-chips="true"
                :disabled="processing"
                :items="labels"
                :menu="true"
                :placeholder="$t('Filter by label name')"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              >
                <template #chip="{ item, props }">
                  <v-chip v-bind="props">
                    <template #prepend>
                      <v-avatar
                        :color="item.raw.color"
                        size="5"
                        start
                      />
                    </template>
                    {{ item.raw.name }}
                  </v-chip>
                </template>
                <template #item="{ item, props }">
                  <v-list-item
                    v-bind="props"
                    :title="$t(item.raw.name)"
                  >
                    <template #prepend="{ isSelected }">
                      <v-checkbox-btn
                        :key="item.value"
                        :model-value="isSelected"
                        :ripple="false"
                        tabindex="-1"
                      />
                    </template>
                    <template #append>
                      <v-avatar
                        :color="item.raw.color"
                        size="20"
                      />
                    </template>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </template>
          </FilterMenu>

          <!-- TODO: Replace with TeamEditor -->
          <v-dialog
            v-model="assignTeamDialogIsVisible"
            max-width="400"
            scrim="transparent"
          >
            <v-card
              border
              tile
            >
              <v-card-title>{{ $t('Assign team') }}</v-card-title>
              <v-divider class="mb-2" />
              <v-card-text class="py-3">
                <v-row dense>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="assignTeamTo"
                      :items="teams"
                      item-title="name"
                      item-value="id"
                      label="Assign to"
                      placeholder="Assign to"
                      variant="filled"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="my-2" />
              <v-card-actions>
                <v-btn
                  @click="assignTeamDialogIsVisible = false"
                  variant="text"
                >
                  {{ $t('Cancel') }}
                </v-btn>
                <v-spacer />
                <v-btn
                  @click="assignTeam()"
                  :disabled="!assignTeamTo"
                  color="primary"
                >
                  {{ $t('Assign') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- TODO: Replace with ReviewerEditor -->
          <v-dialog
            v-model="assignReviewerDialogIsVisible"
            max-width="400"
            scrim="transparent"
          >
            <v-card
              border
              tile
            >
              <v-card-title>{{ $t('Assign reviewer') }}</v-card-title>
              <v-divider class="mb-2" />
              <v-card-text class="py-3">
                <v-row dense>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="assignReviewerTo"
                      :items="reviewers"
                      :label="$t('Assign to')"
                      :placeholder="$t('Assign to')"
                      item-title="name"
                      item-value="id"
                      variant="filled"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="my-2" />
              <v-card-actions>
                <v-btn
                  @click="assignReviewerDialogIsVisible = false"
                  variant="text"
                >
                  {{ $t('Cancel') }}
                </v-btn>
                <v-spacer />
                <v-btn
                  @click="assignReviewer()"
                  :disabled="!assignReviewerTo"
                  color="primary"
                >
                  {{ $t('Assign') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-spacer />

          <v-btn
            v-show="showAssignButtons"
            @click="assignTeamDialogIsVisible = true"
            class="me-3"
            color="primary"
            variant="outlined"
          >
            {{ $t('Assign team') }}
          </v-btn>

          <v-btn
            v-show="showAssignButtons"
            @click="assignReviewerDialogIsVisible = true"
            color="primary"
            variant="outlined"
          >
            {{ $t('Assign member') }}
          </v-btn>
        </div>
      </template>
    </v-toolbar>

    <v-data-table-server
      @click:row="handleRowClick"
      @update:model-value="handleModelValueUpdate"
      @update:page="handlePageChange"
      @update:sort-by="handleSortChange"
      :headers="tableHeaders"
      :hide-default-footer="!totalItemCount"
      :items="items"
      :items-length="totalItemCount"
      :items-per-page="pageSize"
      :loading="processing"
      :model-value="selectedItems"
      :page="filters?.page"
      class="mx-3 b-radius-0 b-1 bc-outlined-gray"
      no-data-text="No forms found"
      fixed-header
      hover
      show-select
    >
      <template #item.id="{ item }">
        {{ item.id.split('-')[0] }}
      </template>

      <template #item.submitter="{ item }">
        <span>
          {{ getFormMemberName(item) }}
        </span>
      </template>

      <template #item.team="{ item }">
        <v-tooltip
          v-if="getFormTeamName(item)"
          location="bottom"
          center
        >
          <template #activator="{ props }">
            <v-chip
              @click:close="removeReviewer(item, true)"
              v-bind="props"
              :closable="store.state.profile.org_providers_admin"
              :disabled="processing"
              close-icon="close"
              size="small"
            >
              <InitialedText :value="getFormTeamName(item)" />
            </v-chip>
          </template>

          <span>
            {{ getFormTeamName(item) }}
          </span>
        </v-tooltip>
      </template>

      <template #item.assigned="{ item }">
        <v-tooltip
          v-if="getFormReviewerName(item)"
          location="bottom"
          center
        >
          <template #activator="{ props }">
            <v-chip
              @click.stop.prevent
              @click:close="removeReviewer(item, false)"
              v-bind="props"
              :closable="store.state.profile.org_providers_admin"
              :disabled="processing"
              close-icon="close"
              size="small"
            >
              <InitialedText :value="getFormReviewerName(item)" />
            </v-chip>
          </template>

          <span>
            {{ getFormReviewerName(item) }}
          </span>
        </v-tooltip>
      </template>

      <template #item.submitted_at="{ item }">
        <LongDate
          :date="item.attributes?.submitted_at"
          short-month
        />
      </template>

      <template #item.status="{ item }">
        {{ $t(item.attributes?.status) }}
      </template>

      <template #item.enrollment="{ item }">
        <router-link
          v-if="formHasEnrollmentAndSubsidy(item)"
          @click.stop.prevent
          :ref="`subsidyLink-${getFormSubsidyId(item)}`"
          :to="{
            name: 'SubsidyShow',
            params: {
              id: getFormSubsidyId(item),
            },
            query: {
              tab: 'Enrollment',
            },
          }"
          tracked
        >
          {{ $t(getFormEnrollmentName(item)) }}
        </router-link>

        <span v-else>
          {{ $t(getFormEnrollmentName(item)) }}
        </span>
      </template>

      <template #item.provider="{ item }">
        <router-link
          v-if="getFormProviderId(item)"
          @click.stop.prevent
          :ref="`providerLink-${getFormProviderId(item)}`"
          :to="{
            name: 'ProviderShow',
            params: {
              providerId: getFormProviderId(item),
            },
            query: {
              tab: 'forms',
            },
          }"
          tracked
        >
          {{ $t(getFormProviderName(item)) }}
        </router-link>

        <span v-else>
          {{ $t(getFormProviderName(item)) }}
        </span>
      </template>

      <template #item.stage="{ item }">
        <template v-if="item.attributes?.stage_id">
          {{ $t(stages[item.attributes?.stage_id].title) }}
        </template>
      </template>

      <template #item.labels="{ item }">
        <LabelValue
          @edit="labelDialog.open(item)"
          :applied-labels="getFormLabels(item)"
          :disabled="processing"
        />
      </template>
    </v-data-table-server>

    <VerticalSpacer :min-height="14" />

    <LabelDialog
      @change="updateEditedLabels"
      @close="loadForms()"
      ref="labelDialog"
      :program-labels="labels"
      label-owner-type="Form"
    />
  </v-container>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/specialist/services/bright_finder';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import InitialedText from '@/shared/components/InitialedText.vue';
import LabelDialog from '@/specialist/components/LabelDialog.vue';
import LabelValue from '@/specialist/components/LabelValue.vue';
import LongDate from '@/shared/components/LongDate.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import useTerms from '@/shared/composables/useTerms';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import {
  STATIC_STATUSES,
  NEUTRAL_STATUSES,
  POSITIVE_STATUSES,
} from '@/specialist/services/statuses';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const router = useRouter();
const store = useStore();
const { terms } = useTerms();
const { updateQuery } = useRouterHelper();

const assignTeamDialogIsVisible = ref(false);
const assignTeamTo = ref(null);
const assignReviewerDialogIsVisible = ref(false);
const assignReviewerTo = ref(null);
const enrollments = ref([]);
const filters = ref(getDefaultFilters());
const filterSummary = ref(null);
const items = ref([]);
const labelDialog = ref(null);
const members = ref([]);
const pageSize = ref(1);
const processing = ref(false);
const providers = ref([]);
const searchCounter = ref(0);
const selectedItems = ref([]);
const selectedSchema = ref(null);
const schemas = ref([]);
const reviewers = ref([]);
const statuses = ref(STATIC_STATUSES.concat(NEUTRAL_STATUSES, POSITIVE_STATUSES));
const labels = ref([]);
const teams = ref([]);
const totalItemCount = ref(null);

const selectedSchemaIsRequestable = computed(() => {
  return selectedSchema.value?.meta.specialist_requestable;
});

const showAssignButtons = computed(() => {
  const authorizedToAssign =
    store.state.profile.org_forms_admin || store.state.profile.org_forms_edit;

  return authorizedToAssign && selectedItems.value.length > 0;
});

const stages = computed(() => {
  if (!selectedSchema.value) return {};

  const schemaStages = {};

  selectedSchema.value.stages.forEach((stage) => {
    schemaStages[stage.id] = stage;
  });

  return schemaStages;
});

const statusOptions = computed(() => {
  const removedStatuses = ['Waitlist'];
  return statuses.value.filter((status) => !removedStatuses.includes(status));
});

const tableHeaders = computed(() => {
  const headers = [
    {
      title: 'ID',
      value: 'id',
    },
    {
      title: 'Submitter',
      value: 'submitter',
    },
    {
      title: 'Team',
      value: 'team',
      align: 'center',
    },
    {
      title: 'Assigned',
      value: 'assigned',
      align: 'center',
    },
    {
      title: 'Submitted',
      align: 'center',
      sortable: true,
      key: 'submitted_at',
    },
    {
      title: 'Status',
      value: 'status',
      align: 'center',
      sortable: true,
    },
    {
      title: 'Enrollment',
      value: 'enrollment',
      align: 'center',
    },
    {
      title: 'Provider',
      value: 'provider',
      align: 'center',
    },
  ];

  if (selectedSchema.value?.enable_stages) {
    headers.push({
      title: 'Stage',
      value: 'stage',
      align: 'center',
    });
  }

  if (labels.value.length > 0) {
    headers.push({
      title: 'Labels',
      value: 'labels',
      align: 'center',
    });
  }

  return headers;
});

async function assignTeam() {
  processing.value = true;

  await Promise.all(
    selectedItems.value.map(async (selectedItemId) => {
      const response = await Api.organization.form.update(selectedItemId, {
        team_id: assignTeamTo.value,
      });

      const index = items.value.findIndex((item) => item.id === selectedItemId);
      items.value[index] = response.data;
    }),
  );

  assignTeamDialogIsVisible.value = false;
  assignTeamTo.value = null; // TODO: temporary clear until we convert v-dialog into resource dialog
  selectedItems.value = [];

  await handlePageChange(1);
  processing.value = false;
}

async function assignReviewer() {
  processing.value = true;

  await Promise.all(
    selectedItems.value.map(async (selectedItemId) => {
      const response = await Api.organization.form.update(selectedItemId, {
        reviewer_id: assignReviewerTo.value,
      });

      const index = items.value.findIndex((item) => item.id === selectedItemId);
      items.value[index] = response.data;
    }),
  );

  assignReviewerDialogIsVisible.value = false;
  assignReviewerTo.value = null; // TODO: temporary clear until we convert v-dialog into resource dialog
  selectedItems.value = [];

  await handlePageChange(1);
  processing.value = false;
}

async function createNewSpecialistForm() {
  const params = {
    schema_id: selectedSchema.value.id,
  };
  const { data } = await Api.organization.form.create(params);
  const destination = store.state.profile.org_forms_review ? 'FormReview' : 'FormShow';
  router.push({ name: destination, params: { formId: data.id } });
}

// Enrollment column functions
function formHasEnrollmentAndSubsidy(form) {
  const enrollmentId = form?.relationships?.enrollment?.data?.id || null;
  const subsidyId = getFormSubsidyId(form) || null;

  return enrollmentId && subsidyId;
}

function getFormMemberName(form) {
  const memberId = form?.relationships?.member?.data?.id || null;
  if (!memberId) return '';

  const formMember = members.value.find((member) => member.id === memberId);

  return formMember?.attributes?.name || '';
}

function getFormLabels(form) {
  const labelIds = form?.relationships?.labels?.data?.map((label) => label.id) || [];
  if (labelIds.length === 0) return [];

  return labels.value?.filter((label) => labelIds.includes(label.id)) || [];
}

function getFormProvider(form) {
  const providerId = form?.relationships?.provider?.data?.id || null;
  if (!providerId) return '';

  const formProvider = providers.value.find((provider) => provider.id === providerId);

  return formProvider || {};
}

function getFormProviderId(form) {
  return getFormProvider(form)?.id || '';
}

function getFormProviderName(form) {
  return getFormProvider(form)?.attributes?.name || '';
}

// Reviewer column function
function getFormReviewerName(form) {
  const reviewerId = form?.relationships?.reviewer?.data?.id || null;
  if (!reviewerId) return '';

  const formReviewer = reviewers.value.find((reviewer) => reviewer.id === reviewerId);

  return formReviewer?.name || '';
}

// Team column function
function getFormTeamName(form) {
  const teamId = form?.relationships?.team?.data?.id || null;
  if (!teamId) return '';

  const formTeam = teams.value.find((team) => team.id === teamId);

  return formTeam?.name || '';
}

function getFormEnrollment(form) {
  const enrollmentId = form?.relationships?.enrollment?.data?.id || null;
  if (!enrollmentId) return '';

  const formEnrollment = enrollments.value.find((enrollment) => enrollment.id === enrollmentId);

  return formEnrollment || {};
}

function getFormSubsidyId(form) {
  return form?.relationships?.subsidy?.data?.id || '';
}

function getFormEnrollmentName(form) {
  return getFormEnrollment(form)?.attributes?.name || '';
}

function getDefaultFilters() {
  return {
    filter: null,
    label: null,
    labelIds: [],
    page: 1,
    query: null,
    reviewerIds: [],
    schemaId: null,
    stageIds: [],
    statusIds: [],
    teamIds: [],
  };
}

function getFilterSummary() {
  const appliedFilters = [];
  if (filters.value.statusIds?.length > 0) appliedFilters.push(filters.value.statusIds.join(', '));

  if (filters.value.teamIds?.length > 0) {
    filters.value.teamIds.forEach((teamId) => {
      appliedFilters.push(teams.value.find((team) => team.id === teamId).name);
    });
  }

  if (filters.value.reviewerIds?.length > 0) {
    filters.value.reviewerIds.forEach((reviewerId) => {
      appliedFilters.push(reviewers.value?.find((reviewer) => reviewer.id === reviewerId).name);
    });
  }

  if (filters.value.stageIds?.length > 0) {
    filters.value.stageIds.forEach((stageId) => {
      appliedFilters.push(
        selectedSchema.value?.stages?.find((stage) => stage.id === stageId).title,
      );
    });
  }

  if (filters.value.labelIds?.length > 0) {
    filters.value.labelIds.forEach((labelId) => {
      appliedFilters.push(labels.value?.find((label) => label.id === labelId).name);
    });
  }

  return appliedFilters.length > 0 ? appliedFilters.join(', ') : null;
}

function getFormLink(formId) {
  return {
    name: store.state.profile.org_forms_review ? 'FormReview' : 'FormShow',
    params: { formId },
  };
}

async function handlePageChange(page) {
  if (!page) return;
  filters.value.page = page;

  await updateQuery({ ...filters.value });
  store.commit('setFilters', ['form', filters.value]);

  await loadForms();
}

function handleRowClick(_event, { item }) {
  router.push(getFormLink(item.id));
}

async function handleSchemaChange() {
  filters.value = getDefaultFilters();
  filters.value.schemaId = selectedSchema.value.id;
  await loadSchemaLabels();
  await handlePageChange(1);
}

async function handleModelValueUpdate(event) {
  selectedItems.value = event;
}

async function handleSortChange(event) {
  const sortBy = event?.[0] || {};

  filters.value = {
    ...filters.value,
    sort: sortBy.key || null,
    sort_dir: sortBy.order || null,
  };

  await updateQuery({ ...filters.value });
  store.commit('setFilters', ['form', filters.value]);

  await loadForms();
}

async function load() {
  items.value.splice(0);
  processing.value = true;

  let unorderedSchemas = Object.values(store.state.schemas).filter(
    (schema) => schema.data_type === 'Form',
  );

  schemas.value = _.orderBy(unorderedSchemas, [(schema) => schema.name.toLowerCase()], ['asc']);
  loadFilters();
  await updateQuery({ ...filters.value });
  store.commit('setFilters', ['form', filters.value]);

  await loadSchemaLabels();
  await loadTeams();
  await loadReviewers();

  await loadForms();
}

function loadFilters() {
  const queryFilters = { ...route.query };
  const storedFilters = store.state.filters.form || {};

  let filtersHash;
  if (Object.keys(queryFilters).length > 1) {
    filtersHash = queryFilters;
  } else if (Object.keys(storedFilters).length > 1) {
    filtersHash = storedFilters;
  } else {
    filtersHash = {
      schemaId: schemas.value[0].id,
    };
  }

  const newFilters = _.merge(getDefaultFilters.value, filtersHash);
  const defaultFilters = getDefaultFilters();

  Object.keys(defaultFilters).forEach((key) => {
    if (!_.isString(newFilters[key])) return;

    if (_.isArray(defaultFilters[key])) newFilters[key] = [newFilters[key]];

    if (_.isNumber(defaultFilters[key])) newFilters[key] = parseInt(newFilters[key], 10);
  }, this);

  Object.keys(newFilters).forEach((key) => {
    filters.value[key] = newFilters[key];
  });

  selectedSchema.value = schemas.value.find((schema) => schema.id === filters.value.schemaId);
}

async function loadReviewers() {
  const response = await Api.organization.member.index({ is_specialist: true });
  reviewers.value = response?.data || [];
}

async function loadForms() {
  processing.value = true;

  filterSummary.value = getFilterSummary();
  searchCounter.value += 1;
  const currentSearchCounter = searchCounter.value;

  let sort = null;

  if (filters.value.sort_dir && filters.value.sort) {
    sort = (filters.value.sort_dir === 'desc' ? '-' : '') + filters.value.sort;
  }

  const params = {
    filter: {
      reviewer: filters.value.reviewerIds?.toString() || null,
      schema: filters.value.schemaId || null,
      stage_id: filters.value.stageIds?.toString() || null,
      status: filters.value.statusIds?.toString() || null,
      team: filters.value.teamIds?.toString() || null,
    },
    include: 'enrollment,member,provider',
    page: {
      limit: 10,
      offset: ((parseInt(filters.value.page) || 1) - 1) * 10,
    },
    sort,
  };

  if (store.state.profile.org_settings_access) {
    params.filter.labels = filters.value.labelIds?.toString() || null;
    params.include += ',labels';
  }

  const response = await Api.organization.form.indexVersion2(params);

  if (searchCounter.value !== currentSearchCounter) return;

  pageSize.value = response?.data?.meta?.page_limit || 0;
  totalItemCount.value = response?.data?.meta?.total_count || 0;
  items.value = response?.data?.data;

  members.value = response?.data?.included?.filter((item) => item.type === 'member');
  providers.value = response?.data?.included?.filter((item) => item.type === 'provider');
  enrollments.value = response?.data?.included?.filter((item) => item.type === 'enrollment');

  selectedItems.value = [];
  processing.value = false;
}

async function loadSchemaLabels() {
  if (!store.state.profile.org_settings_access) return;

  const response = await Api.organization.label.index({
    schema_id: selectedSchema.value?.id,
  });

  labels.value = response?.data || [];
}

async function loadTeams() {
  const response = await Api.organization.team.index();
  teams.value = response?.data || [];
}

async function removeReviewer(form, isTeam) {
  processing.value = true;
  const params = {
    team_id: isTeam ? null : form.team_id,
    reviewer_id: isTeam ? form.reviewer_id : null,
  };

  await Api.organization.form.update(form.id, params);

  await loadForms();
}

function updateEditedLabels(form) {
  const index = items.value.findIndex((item) => item.id === form.id);
  items.value[index] = form;
}

onMounted(load);
</script>
