<template>
  <v-card
    id="agreement"
    class="mb-4"
    data-cy="agreements-card"
    elevation="0"
    border
    tile
  >
    <v-card-title class="tab-title">
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col
          cols="12"
          sm="8"
        >
          <span v-t="'Agreements'" />
        </v-col>
        <v-col
          class="d-flex align-center justify-end"
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="query"
            class="mxw-250 me-2"
            density="compact"
            prepend-inner-icon="search"
            variant="solo-filled"
            flat
            hide-details
            rounded
          />
          <v-btn
            @click="$refs.dialog.open({})"
            color="primary"
            variant="text"
            icon
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text class="mt-2 mb-4">
      <AgreementCard
        v-for="agreement in queriedCollection"
        @change="load"
        @destroy="load"
        @open="openAgreement(agreement)"
        :key="agreement.id"
        :agreement="agreement"
        :members="members"
      />

      <div
        v-if="collection.length == 0"
        class="fs-16"
      >
        No agreements created yet.
      </div>
    </v-card-text>

    <AgreementDialog
      @change="load"
      :api="api"
      role="organization"
    />

    <ResourceDialog
      @save="create"
      ref="dialog"
      :fields="[
        {
          text: 'Agreement type',
          value: 'schema_id',
          items: agreementSchemas,
          itemText: 'name',
          itemValue: 'id',
        },
        {
          text: 'Recipient',
          value: 'member_id',
          items: members,
          itemText: 'email',
          itemValue: 'id',
        },
      ]"
      :processing="processing"
      title="Add agreement"
    />
  </v-card>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import AgreementCard from '@/specialist/components/AgreementCard.vue';
import AgreementDialog from '@/shared/components/AgreementDialog.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { onMounted } from 'vue';

const props = defineProps({
  api: {
    type: Object,
    default: () => Api,
  },
  members: {
    type: Array,
    default: null,
  },
  providerId: {
    type: String,
    default: null,
  },
});

const eventBus = useEventBus();
const store = useStore();
const route = useRoute();
const router = useRouter();

const agreementSchemas = Object.values(store.state.schemas).filter(
  (schema) => schema.data_type === 'Agreement',
);

const collection = ref([]);
const dialog = ref(null);
const query = ref(null);
const processing = ref(false);

const queriedCollection = computed(() => {
  if (query.value) {
    return collection.value.filter((item) =>
      item.name.toLowerCase().includes(query.value.toLowerCase()),
    );
  }
  return collection.value;
});

onMounted(() => {
  load();
});

watch(
  () => route.params.providerId,
  () => {
    load();
  },
  { immediate: true },
);

function create(newResource) {
  processing.value = true;
  props.api.organization.agreement.create(
    {
      ...newResource,
      owner_id: props.providerId,
      owner_type: 'Provider',
    },
    (resp) => {
      collection.value.push(resp.data);
      processing.value = false;
      dialog.value.close();
    },
    (err) => {
      processing.value = false;
      eventBus.chime(err.response.data.errors[0]);
    },
  );
}

function load() {
  props.api.organization.agreement.index({ owner_id: props.providerId }, (resp) => {
    collection.value = resp.data;
  });
}

function openAgreement(agreement) {
  router.push({
    query: { ...route.query, agreementId: agreement.id },
  });
}
</script>
