<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      :back-name="'Cohorts'"
      :back-route="{
        name: 'LearningIndex',
        query: { tab: 'cohorts' },
      }"
      :title="cohort?.attributes?.name"
      flat
    />

    <v-tabs
      v-model="tab"
      @update:model-value="tabChange"
      bg-color="surface"
      grow
    >
      <v-tab value="learners">
        {{ $t('Learners') }}
      </v-tab>
      <v-tab value="course">
        {{ $t('Course') }}
      </v-tab>
      <v-tab value="settings">
        {{ $t('Settings') }}
      </v-tab>
    </v-tabs>

    <v-window
      v-if="cohort"
      v-model="tab"
      class="border-t"
    >
      <v-window-item value="learners">
        <CohortLearners
          :initial-cohort="cohort"
          :initial-included-data="includedCohortData"
        />
      </v-window-item>

      <v-window-item value="course">
        <CohortCourse :cohort="cohort" />
      </v-window-item>

      <v-window-item value="settings">
        <CohortSettings
          :course="course"
          :initial-cohort="cohort"
        />
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import CohortCourse from '@/specialist/views/learning/cohort/CohortCourse.vue';
import CohortLearners from '@/specialist/views/learning/cohort/CohortLearners.vue';
import CohortSettings from '@/specialist/views/learning/cohort/CohortSettings.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const route = useRoute();
const router = useRouter();

const DEFAULT_PARAMS = { include: 'course,facilitator,members' };

const cohort = ref(null);
const course = ref(null);
const includedCohortData = ref(null);
const tab = ref('learners');

async function load() {
  if (route.query?.tab) {
    tab.value = route.query.tab;
  } else {
    tabChange('learners');
  }

  await loadCohort();
}

async function loadCohort() {
  const { data } = await Api.cohort.get(route.params.cohortId, DEFAULT_PARAMS);

  cohort.value = data.data;

  const courses = data.included.filter((item) => item.type === 'course') || [];
  if (courses.length) course.value = courses[0];

  store.commit('setCohort', cohort.value);
  includedCohortData.value = data.included || [];
}

function tabChange(tab) {
  router.push({
    query: { tab },
  });
}

onMounted(load);
</script>
