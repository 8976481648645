<template>
  <v-container
    v-if="subsidyProgram"
    class="mxw-1200"
  >
    <TitleBar
      :back-route="{ name: 'ChildSubsidyProgramIndex' }"
      :name="subsidyProgram.name"
      back-name="Child subsidy programs"
    />

    <SectionHeader title="Overview" />
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <LabeledTextfield
            v-model="subsidyProgram.name"
            @input="changed = true"
            message="Name"
          />
          <LabeledTextarea
            v-model="subsidyProgram.description"
            @input="changed = true"
            message="Description"
          />
          <LabeledTextfield
            v-model="subsidyProgram.image_url"
            @input="changed = true"
            message="Image URL"
          />
          <LabeledSimpleSelect
            v-model="subsidyProgram.state_options"
            @input="changed = true"
            :items="$a.assets.states"
            message="Allowed states"
            chips
            deletable-chips
            multiple
          />
          <LabeledCombobox
            v-model="subsidyProgram.zip_options"
            @input="changed = true"
            message="Allowed zips"
            chips
            deletable-chips
            multiple
          />
          <LabeledCombobox
            v-model="subsidyProgram.city_options"
            @input="changed = true"
            message="Allowed cities"
            chips
            deletable-chips
            multiple
          />
          <LabeledDate
            v-model="subsidyProgram.eligibility_start_date"
            @input="changed = true"
            :maximum="maximumYearAsString()"
            :minimum="minimumYearAsString()"
            description="Date of birth must be on or after this date to be eligible for the subsidy program"
            message="Eligibility start date"
            divided-bottom
          />
          <LabeledDate
            v-model="subsidyProgram.eligibility_end_date"
            @input="changed = true"
            :maximum="maximumYearAsString()"
            :minimum="minimumYearAsString()"
            description="Date of birth must be on or before this date to be eligible for the subsidy program"
            message="Eligibility end date"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Application" />
    <v-card
      class="mb-8"
      data-cy="subsidy-program-form"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row dense>
          <SectionHeader title="Application > Families" />
          <LabeledSwitch
            v-for="(feature, index) in getApplicationFamiliesSettings()"
            v-model="subsidyProgram[feature.attribute]"
            @input="changed = true"
            :key="index + '-' + feature.attribute"
            :divided-bottom="index !== getApplicationFamiliesSettings().length - 1"
            :hard-lock="feature.locked"
            :readonly="feature.locked"
            :subtitle="feature.subtitle"
            :title="feature.title"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      class="mb-8"
      data-cy="subsidy-program-form"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row dense>
          <SectionHeader title="Application > Specialists" />
          <LabeledSwitch
            v-for="(feature, index) in getApplicationSpecialistsSettings()"
            v-model="subsidyProgram[feature.attribute]"
            @input="changed = true"
            :key="index + '-' + feature.attribute"
            :divided-bottom="index !== getApplicationSpecialistsSettings().length - 1"
            :hard-lock="feature.locked"
            :readonly="feature.locked"
            :subtitle="feature.subtitle"
            :title="feature.title"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      class="mb-8"
      data-cy="subsidy-program-form"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row dense>
          <SectionHeader title="Application > Providers" />
          <LabeledSwitch
            v-for="(feature, index) in getApplicationProvidersSettings()"
            v-model="subsidyProgram[feature.attribute]"
            @input="changed = true"
            :key="index + '-' + feature.attribute"
            :divided-bottom="index !== getApplicationProvidersSettings().length - 1"
            :hard-lock="feature.locked"
            :readonly="feature.locked"
            :subtitle="feature.subtitle"
            :title="feature.title"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Placements" />
    <v-card
      class="mb-8"
      data-cy="subsidy-program-form"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row dense>
          <LabeledSwitch
            v-for="(feature, index) in getPlacementsSettings()"
            v-model="subsidyProgram[feature.attribute]"
            @input="changed = true"
            :key="index + '-' + feature.attribute"
            :divided-bottom="index !== getPlacementsSettings().length - 1"
            :hard-lock="feature.locked"
            :readonly="feature.locked"
            :subtitle="feature.subtitle"
            :title="feature.title"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Enrollment" />
    <v-card
      class="mb-8"
      data-cy="subsidy-program-form"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row dense>
          <SectionHeader title="Enrollments > Families" />
          <LabeledSwitch
            v-model="subsidyProgram.allow_enrolled"
            @input="changed = true"
            subtitle="Families will be asked to state which provider they’re already enrolled in."
            title="Families can apply for subsidy for care they’re already enrolled in"
            divided-bottom
          />
          <LabeledSwitch
            v-model="subsidyProgram.allow_preference"
            @input="changed = true"
            :readonly="subsidyProgram.allow_program_preference"
            subtitle="Families not enrolled in care will be able to include their favorite child care providers as part of the application."
            title="Families can express preference in a provider"
            divided-bottom
          />
          <LabeledSwitch
            v-model="subsidyProgram.allow_program_preference"
            @input="changed = true"
            :readonly="subsidyProgram.allow_preference"
            subtitle="Families will be able to search for and select their preferred programs."
            title="Families can express preference in a program"
            divided-bottom
          />
          <LabeledSwitch
            v-model="selectionMinimum"
            @input="$event == false ? resetSelectionMinimum() : null"
            :divided-bottom="!selectionMinimum"
            subtitle="Require a minimum number of program or provider selections families must make on an application."
            title="Enable selection minimum"
          />
          <template v-if="selectionMinimum">
            <LabeledTextfield
              v-model="subsidyProgram.selection_minimum"
              @input="changed = true"
              class="mt-2"
              message="Selection minimum"
              divided-bottom
            />
          </template>
          <LabeledSwitch
            v-model="selectionLimit"
            @input="$event == false ? resetSelectionLimit() : null"
            :divided-bottom="!selectionLimit"
            subtitle="Limit the number of selections of programs or providers families can make in a single application."
            title="Enable selection maximum"
          />
          <template v-if="selectionLimit">
            <LabeledTextfield
              v-model="subsidyProgram.selection_limit"
              @input="changed = true"
              class="mt-2"
              message="Selection maximum"
              divided-bottom
            />
          </template>

          <LabeledSwitch
            v-if="subsidyProgram.allow_program_preference"
            v-model="subsidyProgram.enable_automatic_preference_unlock"
            @input="changed = true"
            subtitle="Automatically unlocks preferences for families to add preferences if they do not currently have an enrollment status that reserves a seat."
            title="Enable preference unlock for subsidy applications that do not have a placement"
            divided-bottom
          />

          <LabeledSwitch
            v-if="subsidyProgram.allow_preference || subsidyProgram.allow_program_preference"
            v-model="subsidyProgram.enable_advanced_selector"
            @input="changed = true"
            subtitle="Beta: Advanced selector improves the user experience of finding, selecting and ranking providers."
            title="Enable advanced provider selector"
            divided-bottom
          />
          <LabeledSwitch
            v-if="subsidyProgram.allow_preference || subsidyProgram.allow_program_preference"
            v-model="subsidyProgram.enable_staff_provider_selection"
            @input="changed = true"
            subtitle="Families can indicate if they have family members on staff at any of their selected locations."
            title="Enable family member staff provider selection"
            divided-bottom
          />
          <LabeledSwitch
            v-if="subsidyProgram.allow_preference || subsidyProgram.allow_program_preference"
            v-model="subsidyProgram.enable_sibling_provider_selection"
            @input="changed = true"
            subtitle="Families can indicate if the applicant child has a sibling currently enrolled at any of their selected locations."
            title="Enable sibling provider selection"
            divided-bottom
          />
          <LabeledSwitch
            v-if="subsidyProgram.allow_program_preference"
            v-model="subsidyProgram.enable_search_dob_eligibility_filter"
            @input="changed = true"
            subtitle="Display a search filter allowing exclusion of results where the child DOB is outside program dob_start_date and dob_end_date."
            title="Enable search filter for program date of birth eligibility"
            divided-bottom
          />
          <LabeledSwitch
            v-if="subsidyProgram.allow_preference || subsidyProgram.allow_program_preference"
            v-model="subsidyProgram.allow_preference_order"
            @input="changed = true"
            subtitle="Families will be able to rank order their the providers they select in the application."
            title="Families can rank their preferences"
            divided-bottom
          />
          <LabeledSwitch
            v-model="subsidyProgram.enable_show_seat_availability"
            @input="changed = true"
            subtitle="Users will be able to see which programs have seats available when searching for care in the admin and family sites."
            title="Show seat availability to families"
            divided-bottom
          />
          <LabeledSwitch
            v-model="subsidyProgram.enable_additional_parent_enrollment_withdraw"
            @input="changed = true"
            subtitle="Allows parents to withdraw enrollments in Selected, Offered, Accepted, Waitlisted, and Standby if public status and status match."
            title="Allow parents to withdraw enrollments"
            divided-bottom
          />
          <LabeledSwitch
            v-if="subsidyProgram.allow_program_preference"
            v-model="subsidyProgram.enable_withdraw_all_others_on_offer_accept"
            @input="changed = true"
            subtitle="If this is enabled, when a parent accepts an offered placement, other enrollments for the subsidy are withdrawn. Declined, Enrolled, Unenrolled, and Graduated are not changed. Not compatible with the below setting."
            title="Upon offer acceptance by parent, withdraw other enrollments for the subsidy."
            divided-bottom
          />
          <LabeledSwitch
            v-if="subsidyProgram.allow_program_preference"
            v-model="subsidyProgram.enable_withdraw_or_standby_non_upgrades_on_offer_accept"
            @input="changed = true"
            :subtitle="`If this is enabled, when a parent accepts an offered placement, any other enrollments in Offered or Accepted for the subsidy are Withdrawn, and any lower ranked Selected, Waitlisted, Proposed, or \
Placed enrollments change to Standby. Not compatible with the above setting.`"
            title="Upon offer acceptance by parent, allow upgrades and withdraw other enrollments holding a seat."
            divided-bottom
          />
          <LabeledDate
            v-model="subsidyProgram.search_for_care_as_of_date"
            @input="changed = true"
            :maximum="maximumYearAsString()"
            :minimum="minimumYearAsString()"
            description="Search results will be displayed based on the age of the child in months as
          of this date"
            message="Program search results as of date"
            divided-bottom
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      class="mb-8"
      data-cy="subsidy-program-form"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row dense>
          <SectionHeader title="Enrollments > Specialists" />
          <LabeledSwitch
            v-model="subsidyProgram.enable_publish_of_subsidy_enrollment_public_statuses"
            @input="changed = true"
            subtitle="If this is enabled, Enrollment editors will be able to publish public statuses to families."
            title="Allow editors to publish enrollment public statuses"
            divided-bottom
          />
          <LabeledSwitch
            v-model="subsidyProgram.enable_release_seat_on_decline_form_acceptance"
            @input="changed = true"
            subtitle="When a specialist approves the form from a provider declining an enrollment, release the seat."
            title="Enable release of seat on decline form acceptance"
            divided-bottom
          />
          <LabeledSwitch
            v-model="subsidyProgram.enrollment_start_date_required"
            @input="changed = true"
            subtitle="Requires enrollment start date on enrollments that are or have previously been in enrolled status."
            title="Require enrollment start date"
            divided-bottom
          />
          <LabeledSwitch
            v-model="subsidyProgram.enable_enrollment_funding_sources"
            @input="changed = true"
            subtitle="Public funding sources can be associated with enrollments"
            title="Enable enrollment funding sources"
            divided-bottom
          />
          <LabeledSwitch
            v-model="subsidyProgram.enable_enrollment_filtering_by_subsidy_status"
            @input="changed = true"
            title="Enable filtering by subsidy status in the enrollments index page"
            divided-bottom
          />
          <LabeledDate
            v-model="subsidyProgram.enrollment_start_date"
            @input="changed = true"
            :maximum="maximumYearAsString()"
            :minimum="minimumYearAsString()"
            description="First date an enrollment can start or end."
            message="Program enrollment start date"
            divided-bottom
          />
          <LabeledDate
            v-model="subsidyProgram.enrollment_end_date"
            @input="changed = true"
            id="subsidy-program-enrollment-end-date"
            :maximum="maximumYearAsString()"
            :minimum="minimumYearAsString()"
            description="Last date an enrollment can start or end."
            message="Program enrollment end date"
            divided-bottom
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      class="mb-8"
      data-cy="subsidy-program-form"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row dense>
          <SectionHeader title="Enrollments > Providers" />
          <LabeledSwitch
            v-model="subsidyProgram.restrict_provider_decline"
            @input="changed = true"
            subtitle="Providers cannot decline an enrollment when it's in Offered, Accepted, or Unenrolled status."
            title="Restrict provider declines to specific statuses"
            divided-bottom
          />
          <LabeledSwitch
            v-if="subsidyProgram.allow_program_preference"
            v-model="subsidyProgram.enable_provider_program_referral_links"
            @input="changed = true"
            subtitle="Providers can utilize direct links and send to families in order to refer them to a specific program during the application process."
            title="Providers can access direct links per program"
            divided-bottom
          />
          <LabeledSwitch
            v-model="subsidyProgram.enable_decline_enrollment_requests"
            @input="changed = true"
            subtitle="Enrollment requests can be declined by providers. Declined enrollment requests still count against available openings until removed."
            title="Providers can decline enrollment requests"
            divided-bottom
          />
          <LabeledSwitch
            v-model="subsidyProgram.enable_enrollment_start_date_backdating_by_providers"
            @input="changed = true"
            subtitle="Enrollment backdating of start date by providers"
            title="Enable backdating of enrollment start date by providers"
            divided-bottom
          />
          <LabeledSwitch
            v-if="subsidyProgram.enable_deferred_acceptance"
            v-model="subsidyProgram.enable_providers_can_view_seat_types"
            @input="changed = true"
            subtitle="Allows providers to view seat counts by seat type and program in the provider site"
            title="Enable provider seat type viewing"
            divided-bottom
          />
          <LabeledSwitch
            v-if="subsidyProgram.enable_deferred_acceptance"
            v-model="subsidyProgram.enable_provider_seat_creation"
            @input="changed = true"
            subtitle="Allows providers to add seats by program in the provider site"
            title="Enable provider seat creation"
            divided-bottom
          />
          <LabeledSwitch
            v-if="subsidyProgram.enable_deferred_acceptance"
            v-model="subsidyProgram.enable_provider_seat_deletion"
            @input="changed = true"
            subtitle="Allows providers to delete seats by program in the provider site"
            title="Enable provider seat deletion"
            divided-bottom
          />
          <LabeledTextfield
            v-model="subsidyProgram.default_unenrolled_action"
            @input="changed = true"
            description="Name of the action the provider will take when Unenrolling a provider. Defaults to Unenroll."
            message="Default Unenrolled action"
            divided-bottom
          />
          <LabeledTextfield
            v-model="subsidyProgram.default_unenrolled_status"
            @input="changed = true"
            description="Status the Enrollment will take when Unenrolled by a provider. Defaults to Unenrolled."
            message="Default Unenrolled status"
            divided-bottom
          />
          <LabeledNumber
            v-model="subsidyProgram.offered_enrollment_due_date_days"
            @input="changed = true"
            :min="1"
            description="Sets the due date on an enrollment when a provider updates the status to offered."
            message="Provider rolling due date on offered enrollments"
            title="Days"
            divided-bottom
          />
          <LabeledDate
            v-model="subsidyProgram.enrollment_due_date"
            @input="changed = true"
            :maximum="maximumYearAsString()"
            :minimum="minimumYearAsString()"
            description="Default due date for providers to update Accepted enrollments to Enrolled"
            message="Enrollment due date (default)"
            divided-bottom
          />
          <LabeledNumber
            v-model="subsidyProgram.enrollment_rolling_due_date_days"
            @input="changed = true"
            :min="1"
            description="Number of days after an enrollment is Accepted before a provider must change it to Enrolled. Used when Enrollment Due Date is not in the future."
            message="Enrollment rolling due date days"
            title="Days"
            divided-bottom
          />
          <LabeledAutocomplete
            v-model="subsidyProgram.enrollment_report_id"
            @change="changed = true"
            @update:search="searchReports"
            :items="reports"
            :multiple="false"
            item-title="name"
            item-value="id"
            message="Provider Enrollment Download Report"
            placeholder="Type a Report name"
            clearable
            filled
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Content" />
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="contentCategory"
              :items="contentCategories"
              variant="outlined"
              hide-details
            />
          </v-col>

          <template v-if="contentCategory == 'Provider selection'">
            <LabeledTextfield
              v-model="subsidyProgram.preference_title"
              @input="changed = true"
              message="Preference title"
            />
            <LabeledTextfield
              v-model="subsidyProgram.preference_subtitle"
              @input="changed = true"
              message="Preference subtitle"
            />
            <LabeledTextfield
              v-model="subsidyProgram.select_providers_title"
              @input="changed = true"
              message="Select providers title"
            />
            <LabeledTextfield
              v-model="subsidyProgram.select_providers_subtitle"
              @input="changed = true"
              message="Select providers subtitle"
            />
            <LabeledTextfield
              v-model="subsidyProgram.rank_providers_title"
              @input="changed = true"
              message="Rank providers title"
            />
            <LabeledTextfield
              v-model="subsidyProgram.rank_providers_subtitle"
              @input="changed = true"
              message="Rank providers subtitle"
            />
            <LabeledTextfield
              v-model="subsidyProgram.current_provider_title"
              @input="changed = true"
              message="Current provider title"
            />
            <LabeledTextfield
              v-model="subsidyProgram.current_provider_subtitle"
              @input="changed = true"
              message="Current provider subtitle"
            />
            <LabeledTextfield
              v-model="subsidyProgram.staff_provider_title"
              @input="changed = true"
              message="Staff provider title"
            />
            <LabeledTextfield
              v-model="subsidyProgram.staff_provider_subtitle"
              @input="changed = true"
              message="Staff provider subtitle"
            />
            <LabeledTextfield
              v-model="subsidyProgram.sibling_provider_title"
              @input="changed = true"
              message="Sibling provider title"
            />
            <LabeledTextfield
              v-model="subsidyProgram.sibling_provider_subtitle"
              @input="changed = true"
              message="Sibling provider subtitle"
            />
            <LabeledTextarea
              v-model="subsidyProgram.no_provider_results_found_message"
              @input="changed = true"
              message="No provider results found message"
            />
          </template>

          <template v-if="contentCategory == 'Pre-eligibility'">
            <LabeledTextfield
              v-model="subsidyProgram.pre_eligibility_action_text"
              @input="changed = true"
              message="Pre-eligibility action text"
            />
            <LabeledTextarea
              v-model="subsidyProgram.pre_eligibility_success_text"
              @input="changed = true"
              message="Pre-eligibility success text"
            />
          </template>

          <template v-if="contentCategory == 'Eligibility'">
            <LabeledTextfield
              v-model="subsidyProgram.eligibility_title"
              @input="changed = true"
              message="Title"
            />
            <LabeledTextarea
              v-model="subsidyProgram.eligibility_subtitle"
              @input="changed = true"
              message="Subtitle"
            />
          </template>

          <template v-if="contentCategory == 'Enrollment decision text'">
            <LabeledTextfield
              v-model="subsidyProgram.enrollment_decision_accept_text"
              @input="changed = true"
              message="Accept enrollment text"
            />
            <LabeledTextfield
              v-model="subsidyProgram.enrollment_decision_decline_text"
              @input="changed = true"
              message="Decline enrollment text"
            />
          </template>

          <template v-if="contentCategory == 'Enrollment start date'">
            <LabeledTextfield
              v-model="subsidyProgram.enrollment_start_date_title"
              @input="changed = true"
              message="Title"
            />
            <LabeledTextarea
              v-model="subsidyProgram.enrollment_start_date_subtitle"
              @input="changed = true"
              message="Subtitle"
            />
          </template>

          <template v-if="contentCategory == 'Home address'">
            <LabeledTextfield
              v-model="subsidyProgram.home_address_title"
              @input="changed = true"
              message="Title"
            />
            <LabeledTextarea
              v-model="subsidyProgram.home_address_subtitle"
              @input="changed = true"
              message="Subtitle"
            />
            <LabeledTextfield
              v-model="subsidyProgram.verify_home_address_title"
              @input="changed = true"
              message="Verification title"
            />
            <LabeledTextarea
              v-model="subsidyProgram.verify_home_address_subtitle"
              @input="changed = true"
              message="Verification subtitle"
            />
            <LabeledTextfield
              v-model="subsidyProgram.homeless_attestation"
              @input="changed = true"
              message="Homeless attestation"
            />
          </template>

          <template v-if="contentCategory == 'Children'">
            <LabeledTextfield
              v-model="subsidyProgram.child_title"
              @input="changed = true"
              message="Title"
            />
            <LabeledTextarea
              v-model="subsidyProgram.child_subtitle"
              @input="changed = true"
              message="Subtitle"
            />
            <LabeledTextfield
              v-model="subsidyProgram.verify_child_title"
              @input="changed = true"
              message="Verification title"
            />
            <LabeledTextarea
              v-model="subsidyProgram.verify_child_subtitle"
              @input="changed = true"
              message="Verification subtitle"
            />
          </template>

          <template v-if="contentCategory == 'Confirm and submit'">
            <LabeledTextfield
              v-model="subsidyProgram.confirm_title"
              @input="changed = true"
              message="Title"
            />
            <LabeledTextfield
              v-model="subsidyProgram.submit_title"
              @input="changed = true"
              message="Submission success message"
            />
          </template>
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Availability" />
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row class="mb-3">
          <LabeledDateTimeZonePicker
            v-model="subsidyProgram.opens_at"
            @change="changed = true"
            cols="6"
            message="Open date"
            clearable
          />
          <LabeledDateTimeZonePicker
            v-model="subsidyProgram.closes_at"
            @change="changed = true"
            cols="6"
            message="Close date"
            clearable
          />
        </v-row>
        <div class="d-flex align-start">
          <LabeledSwitch
            v-model="subsidyProgram.allow_editing"
            @input="changed = true"
            subtitle="Families can edit application after it has been submitted until the close date"
            title="Enable edits after submit before close date"
          />
        </div>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Labels" />
    <v-card
      v-if="labels.length == 0"
      class="pa-4 mb-4"
      border
      flat
    >
      <div v-t="'You have not created any labels yet.'" />
    </v-card>
    <v-card
      v-for="label in labels"
      :key="label.id"
      class="pa-4 mb-4"
      border
      flat
      tile
    >
      <v-row>
        <v-col>
          <v-menu
            location="left"
            max-height="300"
          >
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                class="me-3"
                size="small"
                variant="text"
                icon
              >
                <v-avatar
                  :color="label.color"
                  size="20"
                />
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="color in colorOptions"
                @click="changeColor(label, color)"
                :key="color"
              >
                <template #prepend>
                  <v-avatar
                    :color="color"
                    size="20"
                  />
                </template>
              </v-list-item>
            </v-list>
          </v-menu>
          <span v-text="label.name" />
        </v-col>

        <v-col class="ta-right">
          <ActionMenu
            @click:action:delete="deleteLabel(label)"
            @click:action:edit="$refs.addEditLabelDialog.open(label)"
            :items="[
              { avatar: 'edit', title: 'Edit', event: 'edit' },
              { avatar: 'delete', title: 'Delete', event: 'delete' },
            ]"
            button-icon="more_vert"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-btn
      @click="$refs.addEditLabelDialog.open({})"
      :disabled="processing"
      color="primary"
    >
      {{ $t('Add label') }}
    </v-btn>

    <ResourceDialog
      @save="addEditLabel"
      ref="addEditLabelDialog"
      :fields="[
        { text: 'Name', value: 'name', required: true },
        { text: 'Color', value: 'color', required: true, type: 'color-hex' },
      ]"
      :processing="processing"
      title="Add label"
    />

    <v-divider class="my-8" />

    <SectionHeader title="Data mappings" />
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <LabeledSimpleSelect
            v-model="subsidyProgram.group_schema_id"
            @change="changed = true"
            :items="groupSchemaItems"
            cols="4"
            item-title="name"
            item-value="id"
            message="Group schema"
            chips
          />
          <LabeledSimpleSelect
            v-model="subsidyProgram.child_schema_id"
            @change="changed = true"
            :items="childSchemaItems"
            cols="4"
            item-title="name"
            item-value="id"
            message="Child schema"
            chips
          />
          <LabeledSimpleSelect
            v-model="subsidyProgram.provider_schema_id"
            @change="changed = true"
            :items="providerSchemaItems"
            cols="4"
            item-title="name"
            item-value="id"
            message="Provider schema"
            chips
          />
          <LabeledSimpleSelect
            v-model="subsidyProgram.associated_schema_ids"
            @change="changed = true"
            :items="otherSchemaItems"
            cols="12"
            item-title="name"
            item-value="id"
            message="Other associated schemas (reviews, agreements, etc)"
            chips
            multiple
          />
          <LabeledSimpleSelect
            v-model="subsidyProgram.enrolled_form_schema_id"
            @change="changed = true"
            :items="formSchemaItems"
            cols="3"
            item-title="name"
            item-value="id"
            message="Intake form"
            clearable
          />
          <LabeledSimpleSelect
            v-model="subsidyProgram.decline_form_schema_id"
            @change="changed = true"
            :items="formSchemaItems"
            cols="3"
            item-title="name"
            item-value="id"
            message="Decline form"
            clearable
          />
          <LabeledSimpleSelect
            v-model="subsidyProgram.unenroll_form_schema_id"
            @change="changed = true"
            :items="formSchemaItems"
            cols="3"
            item-title="name"
            item-value="id"
            message="Unenroll form"
            clearable
          />
          <LabeledSimpleSelect
            v-model="subsidyProgram.transfer_form_schema_id"
            @change="changed = true"
            :items="formSchemaItems"
            cols="3"
            item-title="name"
            item-value="id"
            message="Transfer form"
            clearable
          />
          <LabeledSimpleSelect
            v-model="subsidyProgram.withdraw_form_schema_id"
            @change="changed = true"
            :items="formSchemaItems"
            cols="3"
            item-title="name"
            item-value="id"
            message="Withdraw form"
            clearable
          />
          <LabeledSimpleSelect
            v-model="subsidyProgram.accept_form_schema_id"
            @change="changed = true"
            :items="formSchemaItems"
            cols="3"
            item-title="name"
            item-value="id"
            message="Accept form"
            clearable
          />
          <LabeledSimpleSelect
            v-model="subsidyProgram.submitted_survey_schema_id"
            @change="changed = true"
            :items="surveySchemaItems"
            cols="3"
            item-title="name"
            item-value="id"
            message="Submitted survey"
            clearable
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Scoring" />
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <div class="d-flex align-start justify-space-between">
          <div>
            <div
              v-t="'Enable automatic scoring of applications'"
              class="fs-16"
            />
            <div
              v-t="'Applications will be scored by the criteria you set below.'"
              class="fs-16 c-light-black"
            />
          </div>
          <v-switch
            v-model="subsidyProgram.enable_scoring"
            @update:model-value="changed = true"
            class="mt-0"
            hide-details
            inset
          />
        </div>
        <v-row
          v-if="subsidyProgram.enable_scoring"
          class="mt-4 mb-4"
        >
          <LabeledFormulaField
            v-model="subsidyProgram.score_formula"
            @input="changed = true"
            message="Scoring formula"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Attestation" />
    <v-card
      v-if="organization"
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row class="mb-3">
          <v-col>
            <v-select
              v-model="selectedLanguage"
              :items="organization.languages"
              variant="outlined"
              hide-details
            />
          </v-col>
        </v-row>
        <div class="fs-20 mb-2">Family</div>
        <v-row>
          <LabeledTextarea
            v-model="subsidyProgram.attestation_text[selectedLanguageShort]"
            @input="changed = true"
            :placeholder="
              $t('E.g., “I agree the information is correct and may be shared with XYZ.”')
            "
            class="mb-4"
            message="Attestation text"
          />
          <LabeledSwitch
            v-model="subsidyProgram.enable_esign"
            @input="changed = true"
            subtitle="Families will be required to type or draw their signature before submitting."
            title="Enable E-Sign of application"
          />
          <template v-if="!subsidyProgram.enable_esign">
            <LabeledTextfield
              v-model="subsidyProgram.attestation_label"
              @input="changed = true"
              :placeholder="$t('E.g., “I have read and accept the above”')"
              message="Confirmation checkbox label (multi-language support not yet available)"
            />
          </template>
        </v-row>
        <v-divider class="my-6" />

        <div class="fs-20 mb-2">Admin</div>
        <v-row>
          <LabeledTextarea
            v-model="subsidyProgram.admin_attestation_text[selectedLanguageShort]"
            @input="changed = true"
            :placeholder="
              $t(
                'E.g., “I agree the information is correct and the family has authorized it to be shared with XYZ.”',
              )
            "
            class="mb-4"
            message="Attestation text"
          />
          <LabeledSwitch
            v-model="subsidyProgram.enable_admin_esign"
            @input="changed = true"
            subtitle="Admins will be required to type or draw their signature before submitting."
            title="Enable Admin E-Sign of application"
          />
          <template v-if="!subsidyProgram.enable_admin_esign">
            <LabeledTextfield
              v-model="subsidyProgram.admin_attestation_label[selectedLanguageShort]"
              @input="changed = true"
              :placeholder="$t('E.g., “I have read and accept the above”')"
              message="Confirmation checkbox label"
            />
          </template>
        </v-row>
        <v-divider class="my-6" />

        <div class="fs-20 mb-2">Third party</div>
        <v-row>
          <LabeledTextarea
            v-model="subsidyProgram.third_party_attestation_text[selectedLanguageShort]"
            @input="changed = true"
            :placeholder="
              $t(
                'E.g., “I attest on behalf of the family that the information submitted is correct.”',
              )
            "
            class="mb-4"
            message="Attestation text"
          />
          <LabeledSwitch
            v-model="subsidyProgram.enable_third_party_esign"
            @input="changed = true"
            subtitle="Third parties will be required to type or draw their signature before submitting."
            title="Enable Third-party E-Sign of application"
          />
          <template v-if="!subsidyProgram.enable_third_party_esign">
            <LabeledTextfield
              v-model="subsidyProgram.third_party_attestation_label[selectedLanguageShort]"
              @input="changed = true"
              :placeholder="$t('E.g., “I have read and accept the above”')"
              message="Confirmation checkbox label"
            />
          </template>
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <DangerBar
      @destroy="destroy"
      @duplicate="duplicate"
      @publish="publish"
      @unpublish="unpublish"
      :processing="processing"
      :published="!!subsidyProgram.published_at"
      publishable
    />

    <SaveBar
      @cancel="load"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />
  </v-container>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Api from '@/specialist/services/bright_finder.js';
import colors from 'vuetify/util/colors';
import DangerBar from '@/shared/components/DangerBar.vue';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LabeledCombobox from '@/shared/components/form/LabeledCombobox.vue';
import LabeledDate from '@/shared/components/form/LabeledDate.vue';
import LabeledDateTimeZonePicker from '@/shared/components/form/LabeledDateTimeZonePicker.vue';
import LabeledFormulaField from '@/shared/components/form/LabeledFormulaField.vue';
import LabeledNumber from '@/shared/components/form/LabeledNumber.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SectionHeader from '@/shared/components/content/SectionHeader.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import _ from 'lodash';
import useEventBus from '@/shared/composables/useEventBus';
import { isDateFormatValid, dateObjectToIso8601 } from '@/shared/services/date-helper';
import { useDate } from 'vuetify';
import { useRoute, useRouter } from 'vue-router';

const VIRTUAL_ATTRIBUTES = ['recipient_roles', 'statuses'];

const eventBus = useEventBus();

const date = useDate();
const router = useRouter();
const route = useRoute();

const colorOptions = Object.values(colors).map((value) => value.lighten2);
const contentCategories = [
  'Eligibility',
  'Enrollment start date',
  'Enrollment decision text',
  'Home address',
  'Children',
  'Pre-eligibility',
  'Provider selection',
  'Confirm and submit',
];

const addEditLabelDialog = ref(null);
const changed = ref(false);
const childSchemaItems = ref([]);
const contentCategory = ref('Eligibility');
const formSchemaItems = ref([]);
const groupSchemaItems = ref([]);
const labels = ref([]);
const organization = ref(null);
const otherSchemaItems = ref([]);
const providerSchemaItems = ref([]);
const processing = ref(false);
const reports = ref([]);
const selectedLanguage = ref(null);
const selectionLimit = ref(false);
const selectionMinimum = ref(false);
const subsidyProgram = ref(null);
const surveySchemaItems = ref([]);

const selectedLanguageShort = computed(() => {
  return selectedLanguage.value ? selectedLanguage.value.split('-')[0] : null;
});

onMounted(load);

function maximumYearAsString(date) {
  const computedDate = dateAsObject(date) ?? new Date();
  if (!date) computedDate.setFullYear(computedDate.getFullYear() + 5);
  return dateObjectToIso8601(computedDate);
}

function minimumYearAsString(date) {
  const computedDate = dateAsObject(date) ?? new Date();
  if (!date) computedDate.setFullYear(computedDate.getFullYear() - 5);
  return dateObjectToIso8601(computedDate);
}

function dateAsObject(dateObjectOrString) {
  if (dateObjectOrString instanceof Date && !isNaN(dateObjectOrString)) return dateObjectOrString;

  const dateData = { name: 'dateObjectOrString', value: dateObjectOrString };

  try {
    if (isDateFormatValid(dateData)) return date.parseISO(dateObjectOrString);
  } catch (_error) {
    return null;
  }
}

function destroy() {
  if (confirm('Are you sure you want to destroy this program?')) {
    processing.value = true;
    Api.organization.subsidy_program.destroy(
      route.params.subsidyProgramId,
      () => {
        router.push({ name: 'ChildSubsidyProgramIndex' });
      },
      (err) => {
        eventBus.error(err);
      },
    );
  }
}

function duplicate() {
  if (confirm('Are you sure you want to duplicate this program?')) {
    processing.value = true;
    Api.organization.subsidy_program.create(
      { copy_id: route.params.subsidyProgramId },
      () => {
        processing.value = false;
        eventBus.chime('Copied');
        router.push({ name: 'ChildSubsidyProgramIndex' });
      },
      (err) => {
        eventBus.error(err);
      },
    );
  }
}

function getApplicationFamiliesSettings() {
  return [
    {
      attribute: 'enable_home_address_validation',
      title: 'Enable home address validation',
      subtitle:
        'Require that home address information pass Google Address validation before' +
        ' being allowed to proceed.',
    },
    {
      attribute: 'enable_homeless_address_option',
      title: 'Allow homeless address option',
      subtitle:
        'Allow families to skip required home address input if family indicates they are' +
        ' experiencing homelessness.',
    },
    {
      attribute: 'eligibility',
      title: 'Display eligibility report',
      subtitle:
        'Families will be shown a summary of their eligibility status and will only be able' +
        ' to complete applications for eligible children.',
    },
    {
      attribute: 'verify_home_address',
      title: 'Collect verification documents for primary address',
      subtitle:
        'Families will be asked to provide verification documentation to prove their residency.',
    },
    subsidyProgram.value.verify_home_address
      ? {
          attribute: 'verify_home_address_mandatory',
          title: 'Verification documents for primary address are mandatory',
          subtitle:
            'Families will be required to provide proof of residency documentation before submitting.',
        }
      : null,
    {
      attribute: 'verify_child',
      title: 'Collect verification documents for each child',
      subtitle:
        'Families will be asked to provide documentation for their child, such as a birth certificate' +
        ' or SSN card.',
    },
    subsidyProgram.value.verify_child
      ? {
          attribute: 'verify_child_mandatory',
          title: 'Verification documents for each child are mandatory',
          subtitle:
            'Families will be required to provide documentation for each of their children.',
        }
      : null,
    {
      attribute: 'allow_parent_review',
      title: 'Allow parent review of application before submit',
      subtitle: 'Families will be shown a full summary of their application before they submit.',
    },
    {
      attribute: 'enable_parent_review_locked',
      locked: !subsidyProgram.value.allow_parent_review,
      title: 'Locked parent review',
      subtitle:
        'If this is enabled, families will not be able to edit information on the final review page.',
    },
    {
      attribute: 'enable_submit_signout',
      title: 'Automatically sign out applicant on submit',
      subtitle: 'Families will be automatically signed out of the application after they submit.',
    },
    {
      attribute: 'enable_multiple_applications',
      title: 'Enable multiple applications',
      subtitle: 'Families can apply multiple times to the same program for the same child.',
    },
    {
      attribute: 'lock_parent_document_uploads',
      title: 'Lock parent document uploads on submitted and locked applications',
      subtitle:
        'Parents cannot attach documents when subsidy application is locked. They will be able to upload attachments if the subsidy is unlocked.',
    },
    {
      attribute: 'enable_parent_enrollments_review',
      title: 'Enable parent enrollments review',
      subtitle:
        'If this is enabled, families will be able to view enrollments on the dashboard for the program.',
    },
  ].filter(Boolean);
}

function getApplicationSpecialistsSettings() {
  return [
    {
      attribute: 'enable_priority',
      title: 'Enable application priority assignment',
      subtitle: 'Applications can be assigned a priority level that can be sorted and filtered on.',
    },
    {
      attribute: 'display_priority_score',
      title: 'Display priority score',
      subtitle: 'Display priority score to application reviewers.',
    },
    {
      attribute: 'enable_individual_review',
      title: 'Enable individual review of application',
      subtitle: 'Applications can be assigned to individual team members.',
    },
    {
      attribute: 'enable_team_review',
      title: 'Enable team review of application',
      subtitle: 'Applications can be assigned to teams.',
    },
    {
      attribute: 'enable_clear_outdated_assignment_configuration_team',
      title: 'Enable clearing of team when assignment configuration is outdated',
      subtitle:
        'When a team assigned to a subsidy is tied to any Assignment Configurations and the subsidy no ' +
        'longer meets the criteria of any Assignment Configuration, the team assignment is removed.',
    },
    {
      attribute: 'enable_admin_mandatory_field_enforcement_on_submit',
      title: 'Enable admin mandatory field enforcement on submit',
      subtitle:
        'When this is enabled, admins will not be able to submit applications unless they' +
        ' have entered data into all mandatory fields.',
    },
    {
      attribute: 'enable_historical_eligibility_results',
      title: 'Enable historical eligibility results',
      subtitle:
        'Creates eligibility results records on subsidy applications to allow for historical ' +
        'reporting on eligibility.',
    },
    {
      attribute: 'enable_set_application_submitted_at_date',
      title: 'Enable specialist to set subsidy application submitted at date',
      subtitle:
        'If enabled, specialists are able to set the submitted at date ' +
        'for the subsidy application when they submit on behalf of a parent.',
    },
    {
      attribute: 'enable_third_party_applications',
      title: 'Enable third-party applications',
      subtitle:
        'When this is enabled, users can indicate they are applying on behalf of someone else' +
        ' and are prompted to respond to a different attestation.',
    },
    {
      attribute: 'enable_reviewer_sequence',
      title: 'Enable reviewer question order',
      subtitle: 'Applications have a separate order of questions designed for the review process.',
    },
    {
      attribute: 'disable_subsidy_unlocking',
      title: 'Remove lock/unlock subsidy button',
      subtitle:
        'This setting removes the ability to unlock subsidy applications for all users.' +
        ' Unlock preferences will still be enabled.',
    },
  ].filter(Boolean);
}

function getApplicationProvidersSettings() {
  return [
    {
      attribute: 'enable_provider_review',
      title: 'Enable provider review of application',
      subtitle:
        'Providers will be able to see the applications of any children enrolled at their locations.',
    },
    {
      attribute: 'disable_provider_access_to_subsidy_attachments',
      locked: !subsidyProgram.value.enable_provider_review,
      title: 'Remove provider access to subsidy application documents',
      subtitle: 'Providers will not see any documents attached to the subsidy application.',
    },
    {
      attribute: 'enable_limit_provider_attachment_access_by_enrollment_status',
      locked:
        !subsidyProgram.value.enable_provider_review &&
        !subsidyProgram.value.disable_provider_access_to_subsidy_attachments,
      title: 'Limit provider view of subsidy application documents to Accepted/Enrolled statuses',
      subtitle:
        'Providers will only see subsidy application attached documents when enrollment is in Accepted or Enrolled status.',
    },
    {
      attribute: 'enable_provider_review_viewable_fields',
      title: 'Enable provider review of additional fields',
      subtitle:
        'Providers will be able to see additional view-only child and group fields for each enrollment.',
    },
  ].filter(Boolean);
}

function getPlacementsSettings() {
  return [
    {
      attribute: 'enable_deferred_acceptance',
      title: 'Enable Deferred Acceptance',
      subtitle: 'Allow administrators to automatically place children with Deferred Acceptance.',
    },
    {
      attribute: 'enable_daa_prioritize_coc_sibling_steps',
      locked: !subsidyProgram.value.enable_deferred_acceptance,
      title: 'Enable stepped DAA run that prioritizes current provider and sibling provider',
      subtitle:
        'Enables a version of the DAA that prioritizes placements at providers designated as COC or ' +
        'sibling providers and runs recursively to optimize placements.',
    },
    {
      attribute: 'enable_daa_reset',
      locked: !subsidyProgram.value.enable_deferred_acceptance,
      title: 'Enable Deferred Acceptance reset',
    },
    {
      attribute: 'enable_daa_run',
      locked: !subsidyProgram.value.enable_deferred_acceptance,
      title: 'Enable Deferred Acceptance run',
    },
    {
      attribute: 'enable_disallow_daa_standby_placements',
      title: 'Exclude Standby status from DAA',
    },
    {
      attribute: 'enable_provider_direct_enrollment_of_students',
      title: 'Enable provider direct enrollment of students',
      subtitle: 'Allows providers to review and move enrollments to offered for families',
    },
    {
      attribute: 'enable_iep_direct_placement',
      title: 'Enable IEP direct placement by specialists',
      subtitle:
        'Applications that are marked IEP = true are excluded from the DAA and placements' +
        ' are managed by specialists.',
    },
    {
      attribute: 'enable_offer_iep_referred_enrollments',
      title: 'Enable Offering IEP direct placements',
      subtitle:
        'When IEP direct placement is enabled, this setting allows enrollments for the ' +
        'referred program to automatically be Offered to the family if an IEP seat is available.',
    },
  ].filter(Boolean);
}

async function load() {
  changed.value = false;
  loadOrganization();
  loadSchemas();
  await loadSubsidyProgram();
}

function loadOrganization() {
  Api.organization.get((resp) => {
    organization.value = resp.data;
    selectedLanguage.value = organization.value.languages[0].value;
  });
}

function loadSchemas() {
  Api.organization.schema.index({}, ({ data }) => {
    groupSchemaItems.value = data.filter((schema) => schema.data_type === 'Group');
    childSchemaItems.value = data.filter((schema) => schema.data_type === 'Child');
    providerSchemaItems.value = data.filter((schema) => schema.data_type === 'Provider');
    otherSchemaItems.value = data.filter((schema) =>
      ['Agreement', 'Form', 'Review'].includes(schema.data_type),
    );
    formSchemaItems.value = data.filter((schema) => schema.data_type === 'Form');
    surveySchemaItems.value = data.filter((schema) => schema.data_type === 'Survey');
  });
}

async function loadSubsidyProgram() {
  Api.organization.subsidy_program.get(route.params.subsidyProgramId, async (resp) => {
    subsidyProgram.value = resp.data;
    selectionLimit.value = resp.data.selection_limit > 0;
    selectionMinimum.value = resp.data.selection_minimum > 0;
    loadSchemas();
    await loadLabels();
    loadEnrollmentReport();
  });
}

function loadEnrollmentReport() {
  if (subsidyProgram.value?.enrollment_report_id) {
    Api.organization.report.get(subsidyProgram.value?.enrollment_report_id, (resp) => {
      reports.value = [resp.data];
    });
  }
}

function searchReports(query) {
  Api.organization.report.index(
    {
      subsidy_program_id: subsidyProgram.value?.id,
      data_type: 'Enrollment',
      query,
    },
    (resp) => {
      reports.value = resp.data;
    },
  );
}

function omitVirtualAttributes(subsidyProgram) {
  return _.omit(subsidyProgram, VIRTUAL_ATTRIBUTES);
}

function publish() {
  subsidyProgram.value.published_at = new Date().toISOString();
  save();
}

function resetSelectionLimit() {
  subsidyProgram.value.selection_limit = 0;
  changed.value = true;
}

function resetSelectionMinimum() {
  subsidyProgram.value.selection_minimum = 0;
  changed.value = true;
}

function save() {
  processing.value = true;
  Api.organization.subsidy_program.update(
    route.params.subsidyProgramId,
    omitVirtualAttributes(subsidyProgram.value),
    (resp) => {
      processing.value = false;
      subsidyProgram.value = resp.data;
      changed.value = false;
    },
    (err) => {
      processing.value = false;
      eventBus.error(err);
    },
  );
}

function unpublish() {
  subsidyProgram.value.published_at = null;
  save();
}

async function loadLabels() {
  const resp = await Api.organization.label.index({ subsidy_program_id: subsidyProgram.value.id });
  labels.value = resp.data;
  processing.value = false;
}

async function addEditLabel(label) {
  processing.value = true;
  if (label.id) {
    await Api.organization.label.update(label.id, label);
  } else {
    await Api.organization.label.create({ subsidy_program_id: subsidyProgram.value.id, label });
  }
  await loadLabels();
  addEditLabelDialog.value.close();
}

async function changeColor(label, color) {
  const localLabel = label;
  localLabel.color = color;
  await addEditLabel(localLabel);
}

async function deleteLabel(label) {
  if (
    confirm(
      'Are you sure you want to remove this label? Applications that have been assigned this label will retain it until removed.',
    )
  ) {
    processing.value = true;
    await Api.organization.label.destroy(label.id);
    await loadLabels();
  }
}
</script>
