<template>
  <v-container
    v-if="funds"
    class="mxw-1200 pa-6"
  >
    <v-row>
      <v-col>
        <div class="fw-600 bc-h5">Funds</div>
      </v-col>
      <v-col
        v-if="funds.length > 0"
        class="ta-right"
      >
        <v-btn
          @click="$refs.createDialog.open()"
          color="primary"
        >
          Create fund
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="funds.length == 0"
      class="d-flex justify-center"
      style="margin-top: 15%"
    >
      <v-col class="ta-center">
        <div class="fw-600 fs-20 mb-4">You do not have any funds.</div>
        <v-btn
          @click="$refs.createDialog.open()"
          color="primary"
          size="x-large"
          rounded
        >
          Create fund
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-for="(fund, index) in funds"
      :key="index"
    >
      <v-col>
        <v-card
          border
          flat
          tile
        >
          <v-card-text>
            <v-row class="d-flex align-center">
              <v-col>
                <span v-text="fund.name" />
              </v-col>
              <v-col class="d-flex justify-end">
                <v-btn
                  @click="destroy(fund)"
                  :aria-label="$t('Close')"
                  variant="text"
                  icon
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ResourceDialog
      @save="create"
      ref="createDialog"
      :fields="[{ text: 'Name', value: 'name' }]"
      title="New fund"
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder.js';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import store from '@/specialist/store';

const createDialog = ref(null);
const funds = ref(null);

onMounted(() => {
  store.commit('setNavigation', null);
  load();
});

function create(newVal) {
  Api.organization.fund.create(newVal, (resp) => {
    funds.value.push(resp.data);
    createDialog.value.close();
  });
}

function destroy(fund) {
  // eslint-disable-next-line no-alert
  if (!confirm(`Are you sure you want to destroy the fund ${fund.name}?`)) return;

  Api.organization.fund.destroy(fund.id, () => {
    load();
  });
}

function load() {
  Api.organization.fund.index({}, (resp) => {
    funds.value = resp.data;
  });
}
</script>
