<template>
  <v-card
    border
    tile
  >
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col cols="8">
          <div
            @click="$emit('open')"
            class="fs-18 fw-500 underlined c-primary pointer"
            data-cy="agreement-name"
          >
            {{ agreement.name }}
          </div>
          <div
            class="fs-16"
            data-cy="agreement-signer"
          >
            Signer: {{ memberName }}
          </div>
          <div class="fs-12 c-light-black">ID #{{ agreement.id }}</div>
        </v-col>
        <v-col
          class="d-flex align-center justify-end"
          cols="4"
        >
          <div
            class="me-4"
            data-cy="agreement-status"
          >
            <span class="fs-16">
              <v-icon
                color="green"
                start
              >
                {{ statusMesage[0] }}
              </v-icon>
              <span v-t="statusMesage[1]" />
            </span>
          </div>

          <v-btn
            v-if="!agreement.sent_at"
            @click="sendAgreement"
            :loading="processing"
            class="me-3"
            color="primary"
            data-cy="agreement-send-button"
            variant="flat"
          >
            <span v-t="'Send'" />
          </v-btn>

          <ActionMenu
            @click:action:accept="accept"
            @click:action:delete="destroy"
            @click:action:download="download"
            @click:action:preview="$emit('open')"
            :items="actionItems"
            button-data-cy="agreement-action-button"
            button-icon="more_vert"
            left
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import { useStore } from 'vuex';
import useEventBus from '@/shared/composables/useEventBus';

const props = defineProps({
  agreement: {
    type: Object,
    default: null,
  },
  locked: Boolean,
  members: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['change', 'destroy', 'open']);
const store = useStore();
const eventBus = useEventBus();

const processing = ref(false);
const schema = ref(store.state.schemas[props.agreement.schema_id]);

const actionItems = computed(() => {
  const actions = [];
  if (props.agreement.submitted_at) {
    if (props.agreement.status !== 'Accepted') {
      actions.push({ event: 'accept', title: 'Accept', avatar: 'check' });
    }
    if (!schema.value.meta.signature_template_id) {
      actions.push({ event: 'preview', title: 'View', avatar: 'file_open' });
    }
    actions.push({ event: 'download', title: 'Download', avatar: 'file_download' });
  } else {
    actions.push({ event: 'delete', title: 'Delete', avatar: 'delete' });
    actions.push({ event: 'preview', title: 'Preview', avatar: 'file_open' });
  }
  return actions;
});

const link = computed(() => {
  return Api.organization.agreement.downloadUrl(
    props.agreement.id,
    props.agreement.name.replaceAll(' ', '-'),
  );
});

const memberName = computed(() => {
  if (props.members && props.members.length > 0) {
    const member = props.members.find(
      (memberObject) => memberObject.id === props.agreement.member_id,
    );
    if (member) return member.name || member.email;

    return 'Unknown signer';
  }
  return null;
});

const statusMesage = computed(() => {
  if (props.agreement.submitted_at) {
    if (props.agreement.status === 'Accepted') {
      return ['check_circle', 'Signed and accepted'];
    }
    return ['check', 'Signed'];
  }
  if (!props.agreement.submitted_at && props.agreement.viewed_at) {
    return ['visibility', 'Viewed'];
  }
  if (props.agreement.sent_at && !props.agreement.submitted_at && !props.agreement.viewed_at) {
    return ['email', 'Sent'];
  }
  if (!props.agreement.sent_at) {
    return ['drafts', 'Not sent'];
  }
  return [];
});

function accept() {
  Api.organization.agreement.update(
    props.agreement.id,
    { status: 'Accepted' },
    () => {
      emit('change');
      eventBus.chime('Agreement acepted');
      processing.value = false;
    },
    (err) => {
      eventBus.error(err);
      processing.value = false;
    },
  );
}

function destroy() {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you sure you want to remove this agreement?')) return;

  processing.value = true;
  Api.organization.agreement.destroy(props.agreement.id, () => {
    emit('destroy');
  });
}

function download() {
  window.open(link.value);
}

function sendAgreement() {
  processing.value = true;
  Api.organization.agreement.update(
    props.agreement.id,
    { send_requested: true },
    () => {
      emit('change');
      eventBus.chime('Sent');
      processing.value = false;
    },
    (err) => {
      eventBus.error(err);
      processing.value = false;
    },
  );
}
</script>
