<template>
  <div
    v-if="agreement"
    fluid
  >
    <PageTitle
      :back-name="$t('Terms agreements')"
      :back-route="{ name: 'TermsAgreementIndex' }"
      :title="agreement.name"
      flat
      outlined
    />

    <v-container fluid>
      <v-card
        border
        flat
        tile
      >
        <v-card-text>
          <v-row>
            <LabeledTextfield
              v-model="agreement.title"
              message="Title"
              filled
            />
            <LabeledControl message="Content">
              <div
                id="editor"
                class="w-100pc br-0 mb-3"
              />
            </LabeledControl>
            <LabeledTextfield
              v-model="agreement.accept_label"
              message="Accept button label"
              filled
            />
          </v-row>
        </v-card-text>

        <v-divider class="mb-4" />

        <v-card-actions>
          <v-btn
            @click="save"
            color="primary"
            size="x-large"
          >
            {{ $t('Save') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="destroy"
            color="red"
            variant="text"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script setup>
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import Api from '@/specialist/services/bright_finder';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import { useRoute, useRouter } from 'vue-router';
import useEventBus from '@/shared/composables/useEventBus';

const agreement = ref(null);
const editor = ref(null);
const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();

onMounted(async () => {
  await load();
});

async function destroy() {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you sure you want to delete these terms?')) return;

  await Api.organization.termsAgreement.destroy(route.params.termsAgreementId);

  router.push({ name: 'TermsAgreementIndex' });
}

async function load() {
  const resp = await Api.organization.termsAgreement.get(route.params.termsAgreementId);
  agreement.value = resp.data;
  loadEditor();
}

function loadEditor() {
  setTimeout(() => {
    editor.value = new Editor({
      el: document.querySelector('#editor'),
      height: '300px',
      initialEditType: 'wysiwyg',
      initialValue: agreement.value.content || '',
      previewStyle: 'vertical',
    });
  }, 100);
}

function save() {
  agreement.value.content = editor.value.getMarkdown().replaceAll('\\_', '_');
  Api.organization.termsAgreement.update(agreement.value.id, agreement.value, (resp) => {
    agreement.value = resp.data;
    eventBus.chime('Saved');
  });
}
</script>
