<template>
  <v-container
    v-if="cohort && assignment"
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      :back-route="{
        name: 'CohortShow',
        params: { cohortId: cohort.data.id },
        query: { tab: 'course' },
      }"
      :title="assignment.name"
      back-name="Cohort"
      flat
    />

    <v-tabs
      v-model="tab"
      @update:model-value="tabChange"
      bg-color="surface"
      grow
    >
      <v-tab value="media">
        {{ $t('Media') }}
      </v-tab>
      <v-tab value="activities">
        {{ $t('Activities') }}
      </v-tab>
    </v-tabs>

    <v-window
      v-if="assignment"
      v-model="tab"
      class="border-t"
    >
      <v-window-item value="media">
        <v-card
          class="ma-4 rounded"
          border
          flat
          tile
        >
          <v-card-title class="text-h6 font-weight-black">
            {{ $t('Instructions') }}
          </v-card-title>

          <v-divider />

          <v-card-text class="py-0">
            <MarkdownContent
              :content="assignment?.text?.en || 'No instructions'"
              class="no-drag pa-6 fs-16 mnh-100"
            />
          </v-card-text>
        </v-card>

        <v-card
          class="ma-4 rounded"
          border
          flat
          tile
        >
          <ContentPlayer
            v-if="assignment?.assets?.en"
            @update-video-progress="videoProgress = $event"
            :url="assignment?.assets?.en"
            :watched-video-progress="videoProgress"
          />
        </v-card>

        <CommentsCard
          :assignment="assignment"
          :model-id="assignment.id"
          class="ma-4"
          model-type="assignment"
          tile
        />
      </v-window-item>

      <v-window-item value="activities">
        <v-card
          class="ma-4 rounded"
          border
          flat
          tile
        >
          <v-card-title class="text-h6 font-weight-black">
            {{ $t('Activity') }}
          </v-card-title>

          <v-divider />

          <AssignmentLearnerActivities
            :assignment="assignment"
            :cohort="cohort"
          />
        </v-card>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import AssignmentLearnerActivities from '@/specialist/views/learning/cohort/AssignmentLearnerActivities.vue';
import CommentsCard from '@/shared/components/learning/CommentsCard.vue';
import ContentPlayer from '@/shared/components/learning/ContentPlayer.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const route = useRoute();

const assignment = ref(null);
const cohort = ref(null);
const processing = ref(true);
const tab = ref('media');
const videoProgress = ref(0);

watch(
  () => route.params.assignmentId,
  async (newVal) => {
    if (newVal) await load();
  },
  {
    immediate: true,
  },
);

onMounted(() => {
  if (route.query?.tab) {
    tab.value = route.query.tab;
  } else {
    tabChange('media');
  }
});

async function load() {
  processing.value = true;
  await Promise.all([loadAssignment(), loadCohort()]);
}

async function loadAssignment() {
  processing.value = true;
  const response = await Api.public_api.organization.assignment.index({
    lesson_id: route.params.lessonId,
  });

  const assignments =
    response?.data?.filter((assignment) => assignment.id === route.params.assignmentId) || [];
  assignment.value = assignments[0] || {};

  processing.value = false;
}

async function loadCohort() {
  const response = await Api.cohort.get(route.params.cohortId, {
    include: 'course,facilitator,members',
  });
  cohort.value = response?.data || {};
}

function tabChange(tab) {
  router.push({
    query: { tab },
  });
}
</script>
