<template>
  <v-container
    class="h-100pc oy-hidden pa-0"
    fluid
  >
    <PageTitle title="Revisions" />

    <div class="pa-2">
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col class="d-flex align-center">
          <v-select
            v-model="selectedRevisionView"
            :items="revisionViews"
            aria-label="Select a view"
            class="me-3 mxw-400"
            item-title="name"
            item-value="id"
            placeholder="Select a view"
            variant="solo"
            chips
            clearable
            hide-details
            return-object
          />
          <v-btn
            v-if="selectedRevisionView"
            @click="$refs.revisionViewDialog.open(selectedRevisionView)"
            :loading="processing"
            class="me-3"
            color="primary"
          >
            <span v-t="'Edit view'" />
          </v-btn>
          <v-btn
            @click="$refs.revisionViewDialog.open({})"
            :aria-label="$t('Create new revision view')"
            :loading="processing"
            class="me-3"
            color="primary"
            variant="text"
            icon
          >
            <v-icon>add</v-icon>
          </v-btn>
          <v-btn
            @click="load"
            :aria-label="$t('Refresh results')"
            :loading="processing"
            color="primary"
            variant="text"
            icon
          >
            <v-icon>refresh</v-icon>
          </v-btn>
          <v-pagination
            v-if="selectedRevisionView && revisions && revisions.length > 0"
            v-model="page"
            :length="pages"
            :loading="processing"
            :total-visible="4"
          />
        </v-col>
      </v-row>

      <v-row
        class="h-100pc oy-hidden"
        dense
      >
        <v-col
          class="h-100pc oy-hidden-scroll"
          cols="7"
        >
          <ResourceDialog
            @save="saveRevisionView"
            ref="revisionViewDialog"
            :fields="newRevisionViewFields"
            :max-width="null"
            :processing="processing"
            title="Revision view"
            fullscreen
          />

          <v-card
            class="bb-1 bc-very-light-grey pa-0"
            tile
          >
            <v-card-text>
              <v-row dense>
                <v-col
                  class="fw-600 fs-16 c-black d-none d-md-flex"
                  md="5"
                >
                  Update made to
                </v-col>
                <v-col
                  class="fw-600 fs-16 c-black d-none d-md-flex"
                  md="3"
                >
                  Updated by
                </v-col>
                <SortableTableHeader
                  @toggle="sortOrder = sortOrder == 'asc' ? 'desc' : 'asc'"
                  :sort-dir="sortOrder"
                  field="date"
                  md="3"
                  sort-field="date"
                  title="Date"
                />
                <v-col cols="1" />
              </v-row>
            </v-card-text>
          </v-card>

          <template v-if="!!revisions">
            <template v-if="selectedRevisionView">
              <NullState
                v-if="revisions && revisions.length == 0"
                title="Your revision view did not find any revisions matching your criteria."
                hide-new-button
              />
            </template>
            <template v-else>
              <NullState
                title="Select or create a revision view to see revisions."
                hide-new-button
              />
            </template>

            <div
              v-for="revision in revisions"
              :key="revision.id"
            >
              <v-card
                @click="selectedRevision = revision"
                tile
              >
                <v-card-text>
                  <v-row dense>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <p class="mb-1 fs-15 fw-600">
                        {{ revision.owner_type }} #{{ revision.owner_id }}
                      </p>
                      <p class="mb-1 fs-14">
                        {{ revision.owner_name }}
                      </p>
                      <p class="my-0 fs-14">
                        {{ Object.keys(revision.data).join(', ') }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <div class="fs-14">
                        {{ revision.author_email }}
                      </div>
                      <div
                        v-if="revision.author_role"
                        class="fs-14 c-light-black"
                      >
                        {{ revision.author_role }}
                      </div>
                    </v-col>
                    <v-col
                      class="fs-14"
                      cols="12"
                      md="3"
                    >
                      <LongDateTime :date="revision.created_at" />
                    </v-col>
                    <v-col
                      class="d-flex align-start justify-end"
                      cols="1"
                    >
                      <v-icon
                        v-if="revision.reviewed"
                        color="primary"
                        size="24"
                      >
                        check_circle
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-divider />
            </div>
          </template>

          <template v-else>
            <div class="mx-auto py-8 ta-center">
              <v-progress-circular
                size="60"
                indeterminate
              />
            </div>
          </template>

          <VerticalSpacer :min-height="100" />
        </v-col>

        <v-col
          class="h-100pc oy-hidden-scroll"
          cols="5"
        >
          <v-card
            v-if="selectedRevision"
            tile
          >
            <v-card-text>
              <v-row class="align-center mb-2">
                <v-col
                  class="d-flex align-center"
                  cols="10"
                >
                  <div class="d-inline-block">
                    <div class="c-black fs-16 fw-600 mb-0">
                      {{ selectedRevision.owner_type }} #{{ selectedRevision.owner_id }}
                    </div>
                    <div class="fs-14 mb-1">
                      {{ selectedRevision.owner_name }}
                    </div>
                    <div class="fs-14">
                      <LongDateTime :date="selectedRevision.created_at" />
                    </div>
                    <div
                      v-text="
                        [
                          selectedRevision.author_type,
                          selectedRevision.author_role,
                          selectedRevision.author_name,
                          selectedRevision.author_email,
                        ]
                          .filter((authorAttribute) => !!authorAttribute)
                          .join(', ')
                      "
                      class="fs-14"
                    />
                    <div
                      v-if="selectedRevision.author_signed_name"
                      class="fs-14"
                    >
                      Signed name: {{ selectedRevision.author_signed_name }}
                    </div>
                    <div
                      v-if="selectedRevision.description"
                      class="fs-14"
                    >
                      Description: {{ selectedRevision.description }}
                    </div>
                    <img
                      v-if="selectedRevision.author_signature_image"
                      :src="selectedRevision.author_signature_image"
                      class="w-100pc b-1"
                    />
                    <div
                      v-text="selectedRevision.id"
                      class="fs-12 c-light-black"
                    />
                  </div>
                </v-col>

                <v-col
                  class="d-flex justify-end align-start"
                  cols="2"
                >
                  <v-btn
                    @click="changeReviewed"
                    :aria-label="$t('Toggle reviewed')"
                    variant="text"
                    icon
                  >
                    <v-icon
                      v-if="!selectedRevision.reviewed"
                      size="28"
                    >
                      radio_button_unchecked
                    </v-icon>
                    <v-icon
                      v-else
                      color="primary"
                      size="28"
                    >
                      check_circle
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-card
                v-for="field in Object.keys(selectedRevision.data)"
                :key="field"
                class="mb-3"
                border
                tile
              >
                <v-card-text class="py-1">
                  <v-row
                    class="mt-0"
                    dense
                  >
                    <v-col
                      class="fw-600 fs-14"
                      cols="3"
                    >
                      {{ $t('Field') }}
                    </v-col>
                    <v-col class="fs-14">
                      {{ $t(field) }}
                    </v-col>
                  </v-row>
                  <v-row
                    class="mt-0"
                    dense
                  >
                    <v-col
                      class="fw-600 fs-14"
                      cols="3"
                    >
                      {{ $t('Original') }}
                    </v-col>
                    <v-col class="fs-14">
                      {{ selectedRevision.data[field][0] || '-' }}
                    </v-col>
                  </v-row>
                  <v-row
                    class="mt-0"
                    dense
                  >
                    <v-col
                      class="fw-600 fs-14"
                      cols="3"
                    >
                      {{ $t('Updated to') }}
                    </v-col>
                    <v-col class="fs-14">
                      {{ selectedRevision.data[field][1] || '-' }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <div class="mt-5">
                <v-textarea
                  v-model="selectedRevision.notes"
                  @update:model-value="changed = true"
                  class="mb-3"
                  variant="filled"
                  hide-details
                />
                <v-btn
                  v-if="changed"
                  @click="saveRevision"
                  :loading="processingNote"
                  color="primary"
                >
                  <span v-t="'Save'" />
                </v-btn>
              </div>
            </v-card-text>
          </v-card>

          <template
            v-if="selectedRevisionView && revisions && revisions.length > 0 && !selectedRevision"
          >
            <NullState
              title="Click a revision to view details"
              hide-new-button
            />
          </template>

          <VerticalSpacer :min-height="200" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    LongDateTime,
    ResourceDialog,
  },

  data() {
    return {
      changed: false,
      navDrawer: false,
      newRevisionViewFields: [
        { text: 'Name', value: 'name' },
        { text: 'Revision owner IDs', value: 'owner_ids', multiple: true },
        { text: 'Revision owner types', value: 'owner_types', multiple: true },
        { text: 'Fields', value: 'fields', multiple: true },
        { text: 'Include creates', value: 'include_creates', type: 'boolean' },
        { text: 'Start date', value: 'start_at', type: 'date' },
        { text: 'Finish date', value: 'finish_at', type: 'date' },
        { text: 'Notes', value: 'notes', rows: 1 },
      ],
      page: 1,
      pages: 1,
      processing: false,
      processingNote: false,
      revisions: [],
      revisionViews: [],
      selectedRevision: null,
      selectedRevisionView: null,
      sortOrder: 'desc',
    };
  },

  watch: {
    navDrawer(newVal) {
      if (!newVal) {
        this.selectedRevision = null;
      }
    },

    page() {
      this.load();
    },

    selectedRevision(newVal) {
      this.changed = false;
      if (newVal) {
        this.navDrawer = true;
      } else {
        this.navDrawer = false;
      }
    },

    selectedRevisionView(newVal) {
      if (newVal) {
        if (this.$route.query.revision_view_id !== newVal.id) {
          this.$router.push({ query: { revision_view_id: newVal.id } });
        }
        this.page = 1;
        this.selectedRevision = null;
        this.load();
      } else {
        this.revisions = [];
        this.$router.push({ query: {} });
      }
    },

    sortOrder() {
      this.page = 1;
      this.load();
    },
  },
  created() {
    this.loadRevisionViews();
  },

  methods: {
    changeReviewed() {
      this.selectedRevision.reviewed = !this.selectedRevision.reviewed;
      this.saveRevision();
    },

    saveRevision() {
      this.processingNote = true;
      Api.organization.revision.update(
        this.selectedRevision.id,
        { notes: this.selectedRevision.notes, reviewed: this.selectedRevision.reviewed },
        () => {
          this.changed = false;
          this.processingNote = false;
        },
      );
    },

    saveRevisionView(val) {
      this.processing = true;
      if (val.id) {
        Api.organization.revision_view.update(
          val.id,
          val,
          () => {
            this.processing = false;
            this.$refs.revisionViewDialog.close();
            this.loadRevisionViews();
            this.load();
          },
          (err) => {
            this.processing = false;
            this.$eventBus.$emit('chime', err.response.data.errors[0]);
          },
        );
      } else {
        Api.organization.revision_view.create(
          val,
          (resp) => {
            this.selectedRevisionView = resp.data;
            this.processing = false;
            this.$refs.revisionViewDialog.close();
            this.loadRevisionViews();
            this.load();
          },
          (err) => {
            this.processing = false;
            this.$eventBus.$emit('chime', err.response.data.errors[0]);
          },
        );
      }
    },

    load() {
      this.processing = true;
      const filters = {};
      filters.page = this.page;
      filters.order = this.sortOrder;

      if (this.selectedRevisionView) {
        filters.revision_view_id = this.selectedRevisionView.id;
      } else {
        filters.owner_type = 'Provider';
      }

      this.revisions = null;

      Api.organization.revision.index(filters, (resp) => {
        this.pages = parseInt(resp.headers['x-page-count'] || 0, 10);
        this.revisions = resp.data;
        this.processing = false;
      });
    },

    loadRevisionViews() {
      Api.organization.revision_view.index({}, (resp) => {
        this.revisionViews = resp.data;
        if (this.$route.query.revision_view_id) {
          this.selectedRevisionView = this.revisionViews.find(
            (rv) => rv.id === this.$route.query.revision_view_id,
          );
        }
      });
    },

    selectRevision(revision) {
      this.selectedRevision = revision;
    },
  },
};
</script>
