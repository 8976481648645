<template>
  <div class="h-100pc w-100pc p-relative">
    <v-toolbar
      elevation="5"
      extension-height="48"
      extended
    >
      <TitleBar
        data-cy="page-title"
        name="Messaging"
        indented
      />

      <template #extension>
        <v-tabs
          color="primary"
          height="48px"
        >
          <v-tab
            v-if="$store.state.pages.Messaging.features.enable_instant_messages"
            :to="{ name: 'MessageIndex' }"
            class="tt-none"
            exact
          >
            {{ $t('Messages') }}
          </v-tab>
          <v-tab
            v-if="$store.state.pages.Messaging.features.enable_announcements"
            :to="{ name: 'AnnouncementIndex' }"
            class="tt-none"
            exact
          >
            {{ $t('Announcements') }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <router-view />
  </div>
</template>
