<template>
  <v-container
    v-if="dashboards"
    class="bg-super-light-blue mxw-1400"
  >
    <template v-if="dashboard">
      <v-row class="d-flex align-center">
        <v-col
          cols="12"
          sm="6"
        >
          <TitleBar
            :name="dashboard.name"
            :should-wrap-text="true"
          >
            <template #inline-actions>
              <ActionMenu
                v-if="dashboardOptions.length > 0"
                @click:action:switch="$router.push({ query: { dashboardId: $event.id } })"
                :items="dashboardOptions"
              />

              <ActionMenu
                @click:action:create="$refs.newDialog.open()"
                @click:action:edit="
                  $router.push({ name: 'DashboardEdit', params: { dashboardId: dashboard.id } })
                "
                :items="actionItems"
                button-icon="more_vert"
              />
            </template>
          </TitleBar>
        </v-col>
        <v-col
          cols="6"
          sm="3"
        >
          <v-menu
            v-model="startDatePicker"
            :close-on-content-click="false"
            min-width="auto"
            transition="scale-transition"
          >
            <template #activator="{ props }">
              <v-text-field
                v-bind="props"
                v-model="startDate"
                label="Start date"
                prepend-inner-icon="event"
                variant="filled"
                hide-details
                readonly
              />
            </template>
            <v-date-picker
              v-model="startDate"
              @input="startDatePicker = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="6"
          sm="3"
        >
          <v-menu
            v-model="finishDatePicker"
            :close-on-content-click="false"
            location="left"
            min-width="auto"
            transition="scale-transition"
          >
            <template #activator="{ props }">
              <v-text-field
                v-bind="props"
                v-model="finishDate"
                label="Finish date"
                prepend-inner-icon="event"
                variant="filled"
                hide-details
                readonly
              />
            </template>
            <v-date-picker
              v-model="finishDate"
              @input="finishDatePicker = false"
            />
          </v-menu>
        </v-col>
      </v-row>

      <div v-if="metrics && metrics.length > 0">
        <v-divider class="my-4" />

        <v-row class="mb-8">
          <v-col>
            <grid-layout
              v-model:layout="dashboard.layouts.lg"
              :auto-size="true"
              :row-height="50"
            >
              <grid-item
                v-for="item in dashboard.layouts.lg"
                :key="item.i"
                :h="item.h"
                :i="item.i"
                :w="item.w"
                :x="item.x"
                :y="item.y"
                static
              >
                <MetricCard
                  :metric="metrics.find((metric) => metric.id == item.i)"
                  readonly
                />
              </grid-item>
            </grid-layout>
          </v-col>
        </v-row>

        <v-divider class="my-8" />
      </div>
    </template>
    <NullState
      v-if="dashboards.length == 0"
      @new="$refs.newDialog.open()"
      new-button-title="Create your first dashboard"
    />
    <ResourceDialog
      @save="create"
      ref="newDialog"
      :fields="[{ text: 'Name', value: 'name' }]"
      title="New dashboard"
    />
  </v-container>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import MetricCard from '@/admin/components/MetricCard.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { GridLayout, GridItem } from 'grid-layout-plus';

export default {
  compatConfig: { MODE: 3 },

  components: {
    GridItem,
    GridLayout,
    MetricCard,
    ResourceDialog,
  },

  data() {
    return {
      actionItems: [
        { event: 'edit', title: 'Edit dashboard', avatar: 'edit' },
        { event: 'create', title: 'Create new dashboard', avatar: 'add' },
      ],
      dashboard: null,
      dashboards: null,
      finishDate: null,
      finishDatePicker: null,
      metrics: null,
      startDate: null,
      startDatePicker: null,
    };
  },

  computed: {
    dashboardOptions() {
      if (this.dashboards && this.dashboards.length > 0 && this.dashboard) {
        return this.dashboards
          .map((dashboard) => ({
            event: 'switch',
            title: dashboard.name,
            id: dashboard.id,
          }))
          .filter((dashboard) => dashboard.id !== this.dashboard.id);
      }

      return [];
    },
  },

  watch: {
    '$route.query.dashboardId': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.metrics = [];
          this.loadDashboard();
        }
      },
    },

    finishDate(newVal) {
      if (newVal && this.startDate) {
        this.loadMetrics();
      }
    },

    startDate(newVal) {
      if (newVal && this.finishDate) {
        this.loadMetrics();
      }
    },
  },
  created() {
    this.load();
  },

  methods: {
    create(newVal) {
      Api.organization.dashboard.create(
        newVal,
        (resp) => {
          this.$router.push({ name: 'DashboardEdit', params: { dashboardId: resp.data.id } });
        },
        (err) => {
          this.$eventBus.$emit('error', err);
        },
      );
    },

    load() {
      Api.organization.dashboard.index({}, (resp) => {
        this.dashboards = resp.data;
        if (this.dashboards.length > 0) {
          const first =
            this.dashboards.find((dashboard) => dashboard.default) || this.dashboards[0];
          if (!this.$route.query.dashboardId) {
            this.$router.push({ query: { dashboardId: first.id } });
          }
        }
      });
    },

    loadDashboard() {
      Api.organization.dashboard.get(this.$route.query.dashboardId, (resp) => {
        this.dashboard = resp.data;
        this.loadMetrics();
      });
    },

    loadMetrics() {
      const filters = { dashboard_id: this.$route.query.dashboardId };
      if (this.startDate && this.finishDate) {
        filters.start_date = this.startDate;
        filters.finish_date = this.finishDate;
      }

      Api.organization.metric.index(filters, (resp) => {
        this.dashboard.layouts.lg
          .filter((item) => !resp.data.find((metric) => metric.id === item.i))
          .forEach((item) => {
            this.changed = true;
            this.dashboard.layouts.lg.splice(
              this.dashboard.layouts.lg.findIndex((index) => index.i === item.id),
              1,
            );
          });

        resp.data
          .filter((metric) => !this.dashboard.layouts.lg.find((item) => metric.id === item.i))
          .forEach((metric) => {
            this.changed = true;
            this.dashboard.layouts.lg.push({
              i: metric.id,
              x: 1,
              y: 1,
              w: 1,
              h: 1,
            });
          });

        this.metrics = resp.data;

        if (this.dashboard.layouts.lg.length === 0) {
          this.dashboard.layouts.lg = resp.data.map((metric) => ({
            i: metric.id,
            x: metric.x,
            y: metric.y,
            w: metric.w,
            h: metric.h,
          }));
        }
      });
    },
  },
};
</script>
