<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle title="Enrollments" />

    <v-toolbar
      class="mb-3 px-4 bb-1"
      extension-height="60"
      extended
      flat
    >
      <v-row>
        <template v-if="subsidyPrograms.length > 0">
          <v-col class="mxw-400">
            <v-select
              v-model="selectedSubsidyProgram"
              @update:model-value="handleSubsidyProgramChange"
              :aria-label="selectedSubsidyProgram?.name || 'No subsidy program name'"
              :items="subsidyPrograms"
              density="compact"
              item-title="name"
              item-value="id"
              variant="filled"
              hide-details
              return-object
            />
          </v-col>
        </template>

        <v-col class="mxw-340">
          <v-text-field
            v-model="filters.query"
            @update:model-value="reload()"
            id="search_link"
            :placeholder="$t('Filter results by name or child ID')"
            color="secondary"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
            tracked
          />
        </v-col>
      </v-row>

      <template #extension>
        <v-row
          class="d-flex align-center"
          dense
        >
          <v-col>
            <FilterMenu
              :active="!!filters.status"
              classes="d-none d-md-inline-flex"
              test-id="filter-status-button"
              title="Status"
              paddingless
            >
              <template #card>
                <v-list
                  v-model:selected="filters.status"
                  @update:selected="reload()"
                >
                  <v-list-item
                    v-for="option in allStatuses"
                    :key="option.value"
                    :title="option.text"
                    :value="option.value"
                  >
                    <template #prepend="{ isSelected }">
                      <v-checkbox-btn
                        :model-value="isSelected"
                        false-icon="check_box_outline_blank"
                        tabindex="-1"
                        true-icon="check_box"
                      />
                    </template>
                  </v-list-item>
                </v-list>
              </template>
            </FilterMenu>

            <FilterMenu
              :active="!!filters.provider_id"
              classes="d-none d-md-inline-flex"
              title="Location"
            >
              <template #card>
                <v-autocomplete
                  v-model="filters.provider_id"
                  @update:model-value="handleProviderChange()"
                  @update:search="loadProviders($event)"
                  @vue:mounted="loadProviders('')"
                  :items="providers"
                  item-title="name"
                  item-value="id"
                  variant="filled"
                  clearable
                  hide-details
                  hide-no-data
                />
              </template>
            </FilterMenu>

            <template v-if="programs.length > 0">
              <FilterMenu
                ref="programFilter"
                :active="!!filters.program_id"
                :title="terms.program"
                min-width="400"
              >
                <template #card>
                  <v-select
                    v-model="filters.program_id"
                    @update:model-value="reload()"
                    :items="programs"
                    :placeholder="$t('Classroom')"
                    item-title="name"
                    item-value="id"
                    variant="filled"
                    auto-select-first
                    clearable
                    left
                  />
                </template>
              </FilterMenu>
            </template>

            <FilterMenu
              :active="!!filters.due_date"
              classes="d-none d-md-inline-flex"
              title="Due date"
            >
              <template #card>
                <LabeledDatePicker
                  v-model="filters.due_date"
                  @change="reload()"
                  message="Due date before"
                />
              </template>
            </FilterMenu>

            <template v-if="enableFilteringBySubsidyStatus">
              <FilterMenu
                :active="filters.subsidy_statuses?.length > 0"
                classes="d-none d-md-inline-flex"
                title="Subsidy status"
                paddingless
              >
                <template #card>
                  <v-autocomplete
                    v-model="filters.subsidy_statuses"
                    @update:model-value="reload()"
                    :aria-label="$t('Filter by subsidy status')"
                    :disabled="processing"
                    :items="subsidyStatuses"
                    :menu="true"
                    :placeholder="$t('Filter by subsidy status')"
                    density="compact"
                    prepend-inner-icon="search"
                    variant="filled"
                    autofocus
                    chips
                    clearable
                    multiple
                  />
                </template>
              </FilterMenu>
            </template>
          </v-col>
          <v-col class="d-flex align-center justify-end">
            <template v-if="downloadUrl">
              <v-btn
                :href="downloadUrl"
                color="primary"
                variant="flat"
              >
                <span v-t="'Download'" />
              </v-btn>
            </template>

            <div data-testid="action-items">
              <ActionMenu
                @click:action:graduate="$refs.graduateEnrolledChildrenDialog.open()"
                @click:action:publish_to_families="enrollmentStatusDueDateDialogIsVisible = true"
                @click:action:publish_to_providers="
                  updateEnrollmentStatuses(ENROLLMENT_STATUSES.PLACED)
                "
                @click:action:release="$refs.releasePastDueResourceDialog.open()"
                @click:action:reset="resetDeferredAcceptance"
                @click:action:run="$refs.runDAAConfirmationDialog.open()"
                :items="getActionItems"
                button-class="ms-3"
                button-icon="more_vert"
              />
            </div>
          </v-col>
        </v-row>
      </template>
    </v-toolbar>

    <v-container
      class="px-3 py-0"
      fluid
    >
      <template v-if="lastRun">
        <template v-if="lastRun.finished_at">
          <v-alert
            v-if="lastRun.error"
            type="warning"
            tile
          >
            <LongDateTime
              :date="lastRun.finished_at"
              prefix="A Deferred Acceptance run failed at"
              suffix=". Please contact support for assistance."
            />
            <div>
              Error details: {{ lastRun.error?.slice(0, 400) }}.
              <a
                v-if="lastRunSummaryDownloadUrl"
                :href="lastRunSummaryDownloadUrl"
              >
                Download DAA results summary.
              </a>
            </div>
          </v-alert>
          <v-alert
            v-else
            type="success"
            tile
          >
            <LongDateTime
              :date="lastRun.finished_at"
              prefix="A Deferred Acceptance run completed at"
              suffix=". "
            />
            <a
              v-if="lastRunSummaryDownloadUrl"
              :href="lastRunSummaryDownloadUrl"
            >
              Download DAA results summary.
            </a>
          </v-alert>
        </template>
        <template v-else>
          <v-alert
            color="primary"
            type="info"
            tile
          >
            <template #prepend>
              <v-icon
                class="me-3"
                size="24"
              >
                pending
              </v-icon>
            </template>

            <LongDateTime
              :date="lastRun.created_at"
              prefix="A Deferred Acceptance run was started at"
            />

            <template #append>
              <v-btn
                @click="checkRunStatus"
                :aria-label="$t('Refresh status')"
                variant="text"
                icon
              >
                <v-icon>refresh</v-icon>
              </v-btn>
            </template>
          </v-alert>
        </template>
      </template>

      <v-card
        class="bb-1 pa-0"
        border
        flat
        tile
      >
        <v-card-text>
          <v-card
            class="bb-1 bc-extra-light-gray mb-4"
            flat
            tile
          >
            <v-card-text class="pt-0 px-0 pb-3 fs-15">
              <v-row dense>
                <v-col class="d-flex align-center">
                  <template v-if="filterSummary">
                    <v-icon start> filter_alt </v-icon>
                    <span
                      v-t="'Filtering by:'"
                      class="me-1"
                    />
                    <span class="fw-600">{{ $t(filterSummary) }}</span>
                    <span>.&nbsp;</span>
                  </template>
                  <span v-if="filters.page == 1">
                    {{ $t('Displaying first') }} <strong>{{ enrollments.length }}</strong>
                    {{ $t('of') }} <strong>{{ total }}</strong> {{ $t('results') }}.
                  </span>
                  <span v-else>
                    {{ $t('Displaying results') }}
                    <strong>
                      {{ pageStartCount }}-{{ pageStartCount + enrollments.length - 1 }}
                    </strong>
                    {{ $t('out of') }} <strong>{{ total }}</strong> {{ $t('results') }}.
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-row role="row">
            <TableHeader
              cols="1"
              title="Child ID"
            />

            <SortableTableHeader
              @toggle="toggleSort($event)"
              :sort-dir="filters.sort_dir"
              :sort-field="filters.sort_field"
              field="name"
              md="2"
              title="Name"
            />

            <SortableTableHeader
              @toggle="toggleSort($event)"
              :sort-dir="filters.sort_dir"
              :sort-field="filters.sort_field"
              field="status"
              md="1"
              title="Status"
            />

            <SortableTableHeader
              @toggle="toggleSort($event)"
              :sort-dir="filters.sort_dir"
              :sort-field="filters.sort_field"
              field="provider_id"
              md="2"
              title="Location"
            />

            <SortableTableHeader
              @toggle="toggleSort($event)"
              :sort-dir="filters.sort_dir"
              :sort-field="filters.sort_field"
              :title="terms.program"
              field="program_id"
              md="2"
            />

            <SortableTableHeader
              @toggle="toggleSort($event)"
              :sort-dir="filters.sort_dir"
              :sort-field="filters.sort_field"
              field="waitlist_number"
              md="1"
              title="Waitlist"
            />

            <SortableTableHeader
              @toggle="toggleSort($event)"
              :sort-dir="filters.sort_dir"
              :sort-field="filters.sort_field"
              field="preference_order"
              md="1"
              title="Ranking"
            />

            <SortableTableHeader
              @toggle="toggleSort($event)"
              :sort-dir="filters.sort_dir"
              :sort-field="filters.sort_field"
              field="score"
              md="1"
              title="Score"
            />

            <SortableTableHeader
              @toggle="toggleSort($event)"
              :sort-dir="filters.sort_dir"
              :sort-field="filters.sort_field"
              field="due_date"
              md="1"
              title="Due"
            />
          </v-row>
        </v-card-text>
      </v-card>

      <div
        v-show="processing"
        class="mxw-800 mx-auto py-12 ta-center"
      >
        <v-progress-circular
          color="primary"
          size="75"
          indeterminate
        />
      </div>

      <div v-show="!processing">
        <v-card
          v-for="enrollment in enrollments"
          :key="enrollment.id"
          class="bt-0"
          border
          flat
          tile
        >
          <v-card-text>
            <v-row
              :key="enrollment.id"
              role="row"
            >
              <TableCell
                :content="enrollment.child_external_id"
                cols="1"
              />
              <template v-if="enrollment.subsidy_id">
                <TableCell
                  :content="enrollment.name"
                  :to="{
                    name: 'SubsidyShow',
                    params: { id: enrollment.subsidy_id },
                    query: { tab: 'Enrollment' },
                  }"
                  cols="2"
                />
              </template>
              <template v-else>
                <TableCell
                  :content="enrollment.name"
                  :to="{
                    name: 'GroupShow',
                    params: { groupId: enrollment.group_id },
                    query: { tab: 'children' },
                  }"
                  cols="2"
                />
              </template>
              <TableCell
                :content="getStatusText(enrollment.status)"
                cols="1"
              />
              <TableCell
                :content="enrollment.meta.provider.name"
                :to="{ name: 'ProviderShow', params: { providerId: enrollment.provider_id } }"
                cols="2"
              />
              <TableCell
                :content="enrollment.meta.program ? enrollment.meta.program.name : null"
                cols="2"
              />
              <TableCell
                :content="enrollment.waitlist_number"
                cols="1"
              />
              <TableCell
                :content="enrollment.preference_order"
                cols="1"
              />
              <TableCell
                :content="enrollment.score"
                cols="1"
              />
              <TableCell
                :content="enrollment.due_date"
                cols="1"
                transform="date"
              />
            </v-row>
          </v-card-text>
        </v-card>

        <NullState
          v-if="enrollments.length == 0"
          title="No enrollments found"
          hide-new-button
        />

        <v-pagination
          v-show="pages > 1"
          v-model="filters.page"
          @update:model-value="reload($event)"
          :length="pages"
          :total-visible="8"
          class="mt-4"
        />
      </div>

      <VerticalSpacer :min-height="50" />
    </v-container>

    <ResourceDialog
      @save="updatePastDueEnrollments"
      ref="releasePastDueResourceDialog"
      :fields="[
        {
          text: 'Current status',
          value: 'old_status',
          items: releasedCurrentStatuses,
          itemText: 'text',
          itemValue: 'value',
        },
        {
          text: 'New status',
          value: 'status',
          items: releasedNewStatuses,
          itemText: 'text',
          itemValue: 'value',
        },
      ]"
      :processing="processing"
      title="Release past-due enrollments"
    />

    <ResourceDialog
      @save="createDeferredAcceptanceRun"
      ref="runDAAConfirmationDialog"
      :max-width="700"
      :processing="processing"
      :save-button-disabled="!createDaaRunFormValid"
      :save-button-text="'Run'"
      :sub-title="'If you do not set any parameters, the Deferred Acceptance algorithm will be run for all enrollments associated with a submitted application'"
      :title="'Set Deferred Acceptance Algorithm Parameters'"
    >
      <template #form>
        <v-form
          v-model="createDaaRunFormValid"
          ref="createDaaRunForm"
          validate-on="lazy"
        >
          <v-btn
            v-show="showDeferredAcceptanceParams === false"
            @click="showDeferredAcceptanceParams = true"
            color="primary"
          >
            Add parameters
          </v-btn>
          <LabeledSimpleSelect
            v-show="showDeferredAcceptanceParams"
            v-model="allowed_subsidy_statuses"
            :items="DEFERRED_ACCEPTANCE_SUBSIDY_STATUSES"
            message="Application status"
            multiple
          />
          <LabeledDatePicker
            v-show="showDeferredAcceptanceParams"
            v-model="submitted_at_cutoff_time"
            @input="changed = true"
            message="Submitted before"
          />

          <template v-if="shouldShowScoreFields">
            <v-row>
              <v-col>
                <LabeledTextfield
                  v-show="showDeferredAcceptanceParams"
                  v-model="minScore"
                  @input="changed = true"
                  :rules="getMinScoreRules"
                  message="Minimum score"
                />
              </v-col>
              <v-col>
                <LabeledTextfield
                  v-show="showDeferredAcceptanceParams"
                  v-model="maxScore"
                  @input="changed = true"
                  :rules="getMaxScoreRules"
                  message="Maximum score"
                />
              </v-col>
            </v-row>
          </template>
        </v-form>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @save="updateEnrollmentStatuses(ENROLLMENT_STATUSES.GRADUATED)"
      ref="graduateEnrolledChildrenDialog"
      :max-width="700"
      :processing="processing"
      :save-button-text="$t('Graduate')"
      :title="$t('Graduate enrolled children')"
    >
      <template #form>
        <LabeledDatePicker
          v-model="enrollmentEndDate"
          @input="changed = true"
          :max="selectedSubsidyProgramEndDate"
          :min="selectedSubsidyProgramStartDate"
          message="Enrollment end date"
        />
      </template>
    </ResourceDialog>

    <v-dialog
      v-model="enrollmentStatusDueDateDialogIsVisible"
      max-width="400"
      scrim="transparent"
    >
      <v-card
        border
        flat
        tile
      >
        <v-card-title>Send placement offer</v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          <v-row dense>
            <LabeledDatePicker
              v-model="enrollmentOfferDueDate"
              :disallow-before-date="new Date().toISOString()"
              cols="12"
              message="Due date"
            />
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="mt-2">
          <v-btn
            @click="cancelPublishToFamilies"
            variant="text"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="updateEnrollmentStatuses(ENROLLMENT_STATUSES.OFFERED)"
            :disabled="!enrollmentOfferDueDate"
            color="primary"
            data-cy="save-button"
          >
            {{ $t('Publish to families') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import NullState from '@/shared/components/NullState.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SortableTableHeader from '@/shared/components/SortableTableHeader.vue';
import TableHeader from '@/shared/components/TableHeader.vue';
import TableCell from '@/shared/components/TableCell.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';

import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';
import {
  DEFERRED_ACCEPTANCE_SUBSIDY_STATUSES,
  STATIC_STATUSES,
  NEUTRAL_STATUSES,
  POSITIVE_STATUSES,
} from '@/specialist/services/statuses';
import _ from 'lodash';

import { useI18n } from 'vue-i18n';
import useEnrollmentStatuses from '@/shared/composables/useEnrollmentStatuses';
import { useStore } from 'vuex';
import useEventBus from '@/shared/composables/useEventBus';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import { useRoute } from 'vue-router';
import useTerms from '@/shared/composables/useTerms';

const { t } = useI18n();
const { getStatusText, allStatuses, releasedCurrentStatuses, releasedNewStatuses } =
  useEnrollmentStatuses();
const store = useStore();
const eventBus = useEventBus();
const { updateQuery } = useRouterHelper();
const route = useRoute();
const { terms } = useTerms();

const createDaaRunForm = ref(null);
const createDaaRunFormValid = ref(true);
const defaultFilters = ref({
  page: 1,
  subsidy_statuses: [],
  due_date: null,
  status: null,
  provider_id: null,
  program_id: null,
  subsidy_program_id: null,
  sort_dir: null,
  sort_field: null,
  query: null,
});
const downloadUrl = ref(null);
const enrollments = ref([]);
const allowed_subsidy_statuses = ref([]);
const minScore = ref(null);
const maxScore = ref(null);
const enrollmentEndDate = ref(null);
const enrollmentOfferDueDate = ref(null);
const enrollmentStatusDueDateDialogIsVisible = ref(false);
const enrollmentQueryCount = ref(0);
const lastRun = ref(null);
const lastRunSummaryDownloadUrl = ref(null);
const pages = ref(0);
const pageSize = ref(0);
const pageStartCount = ref(0);
const processing = ref(false);
const programs = ref([]);
const providers = ref([]);
const providerQueryCount = ref(0);
const selectedSubsidyProgram = ref(null);
const selectedSubsidyProgramStartDate = ref(null);
const selectedSubsidyProgramEndDate = ref(null);
const showDeferredAcceptanceParams = ref(false);
const submitted_at_cutoff_time = ref(null);
const subsidyPrograms = ref([]);
const subsidyStatuses = ref(STATIC_STATUSES.concat(NEUTRAL_STATUSES, POSITIVE_STATUSES));
const total = ref(0);
const filters = ref({});
const runDAAConfirmationDialog = ref(null);
const graduateEnrolledChildrenDialog = ref(null);
const releasePastDueResourceDialog = ref(null);

const enableFilteringBySubsidyStatus = computed(() => {
  return selectedSubsidyProgram.value?.enable_enrollment_filtering_by_subsidy_status;
});

const filterSummary = computed(() => {
  const appliedFilters = [];
  const pushFilterSummary = (filter, transform) => {
    if (filter?.length > 0) appliedFilters.push(t(transform ? transform(filter) : filter));
  };

  let statusFilter = Array.isArray(filters.value.status)
    ? filters.value.status.join(',')
    : filters.value.status;
  pushFilterSummary(statusFilter, getStatusText);
  pushFilterSummary(
    filters.value.program_id,
    (id) => programs.value.find((program) => program.id === id)?.name,
  );
  pushFilterSummary(
    filters.value.subsidy_statuses,
    () => `Subsidy statuses: ${filters.value.subsidy_statuses.join(', ')}`,
  );

  return appliedFilters.length > 0 ? appliedFilters.join(', ') : null;
});

const getActionItems = computed(() => {
  const running = lastRun.value && !lastRun.value.finished_at;
  const items = [];
  if (!store.state.profile.org_enrollments_admin) return items;

  items.push({
    avatar: 'school',
    event: 'graduate',
    subtitle: t('Set end date and move all Enrolled enrollments to Graduated'),
    title: t('Graduate enrolled children'),
  });

  if (!enableDeferredAcceptance.value) return items;

  if (selectedSubsidyProgram.value?.enable_daa_run) {
    items.push({
      avatar: 'play_circle_outline',
      disabled: running,
      subtitle: 'Run placement algorithm on enrollments.',
      title: 'Set parameters and run DAA',
      event: 'run',
    });
  }

  items.push(
    {
      avatar: 'storefront',
      subtitle: 'Providers will be able to see all placed children',
      title: 'Publish to providers',
      event: 'publish_to_providers',
    },
    {
      avatar: 'supervisor_account',
      subtitle: 'Families will be able to see all their enrollment statuses',
      title: 'Publish to families',
      event: 'publish_to_families',
    },
  );

  if (selectedSubsidyProgram.value?.enable_daa_reset) {
    const daaResetSubtitle = `Reset ${terms.value.proposed},
      ${terms.value.waitlisted}, and ${terms.value.standby} enrollments to
      ${terms.value.selected} status`;
    items.push({
      avatar: 'restart_alt',
      subtitle: daaResetSubtitle,
      title: 'Reset',
      event: 'reset',
    });
  }

  items.push({
    avatar: 'undo',
    subtitle: 'Reset status, reset due date, and release seats for past-due enrollments',
    title: 'Release past-due enrollments',
    event: 'release',
  });

  return items;
});

const enableDeferredAcceptance = computed(() => {
  return (
    store.state.profile.org_enrollments_admin &&
    selectedSubsidyProgram.value?.enable_deferred_acceptance
  );
});

const getMinScoreRules = computed(() => {
  if (!minScore.value) return [];

  return [
    (v) => (/^-?\d+$/.test(v) ? true : 'Score parameter must be an integer'),
    (v) => (Number.isSafeInteger(Number(v)) ? true : 'Score parameter is too large'),
    (v) =>
      !maxScore.value || Number(v) < Number(maxScore.value)
        ? true
        : 'Minimum score must be less than maximum score',
  ];
});

const getMaxScoreRules = computed(() => {
  if (!maxScore.value) return [];

  return [
    (v) => (/^-?\d+$/.test(v) ? true : 'Score parameter must be an integer'),
    (v) => (Number.isSafeInteger(Number(v)) ? true : 'Score parameter is too large'),
    (v) =>
      !minScore.value || Number(v) > Number(minScore.value)
        ? true
        : 'Maximum score must be greater than minimum score',
  ];
});

const nonEmptyFilters = computed(() => {
  return _.pickBy(filters.value, (value) => value != null && value !== '' && !_.isEmpty(value));
});

const shouldShowScoreFields = computed(() => {
  return selectedSubsidyProgram.value?.enable_scoring;
});

watch(() => minScore.value, validateCreateDaaRunForm);
watch(() => maxScore.value, validateCreateDaaRunForm);

onMounted(async () => {
  await load();
});

async function checkRunStatus() {
  if (!filters.value.subsidy_program_id) return;
  const resp = await Api.organization.subsidy_program.deferred_acceptance.get(
    filters.value.subsidy_program_id,
  );

  lastRun.value = resp?.data;

  // If the user has permission to download the run summary and there's one available, set the URL
  if (
    !store.state.profile.org_enrollments_admin ||
    !lastRun.value?.run_summary ||
    Object.keys(lastRun.value.run_summary).length === 0
  ) {
    lastRunSummaryDownloadUrl.value = null;
  } else {
    lastRunSummaryDownloadUrl.value =
      Api.organization.subsidy_program.deferred_acceptance.downloadDebugYamlUrl(
        filters.value.subsidy_program_id,
        lastRun.value.id,
      );
  }
}

async function createDeferredAcceptanceRun() {
  const message =
    'Please confirm that you would like to begin processing enrollments with Deferred Acceptance?';
  // eslint-disable-next-line no-restricted-globals, no-alert
  if (!confirm(message)) return false;

  processing.value = true;
  eventBus.chime('Processing started.');

  const deferredAcceptanceParams = {};

  if (allowed_subsidy_statuses.value) {
    deferredAcceptanceParams['allowed_subsidy_statuses'] = allowed_subsidy_statuses.value;
  } else {
    deferredAcceptanceParams['allowed_subsidy_statuses'] = ['Submitted'];
  }
  if (submitted_at_cutoff_time.value) {
    deferredAcceptanceParams['submitted_at_cutoff_time'] = submitted_at_cutoff_time.value;
  }
  if (minScore.value) deferredAcceptanceParams['min_score'] = minScore.value;
  if (maxScore.value) deferredAcceptanceParams['max_score'] = maxScore.value;

  if (createDaaRunFormValid.value) {
    const resp = await Api.organization.subsidy_program.deferred_acceptance.create(
      filters.value.subsidy_program_id,
      deferredAcceptanceParams,
    );
    lastRun.value = resp?.data;

    // ? might be unnecessary with the form reset below
    submitted_at_cutoff_time.value = null;
    allowed_subsidy_statuses.value = [];
    minScore.value = null;
    maxScore.value = null;

    runDAAConfirmationDialog.value.close();
    createDaaRunForm.value.reset();

    return loadEnrollments();
  }
}

async function updateEnrollmentStatuses(newStatus) {
  const enrollmentParams = {
    status: newStatus,
  };
  let message = null;
  if (enrollmentOfferDueDate.value) {
    message = `Are you sure you want to change and publish the status of these enrollments to ${newStatus}?`;
    enrollmentParams['due_date'] = enrollmentOfferDueDate.value;
  }
  if (enrollmentEndDate.value) {
    message = `Are you sure you want to graduate these enrollments?`;
    enrollmentParams['end_date'] = enrollmentEndDate.value;
  }
  // eslint-disable-next-line no-restricted-globals, no-alert
  if (!confirm(message)) return;

  processing.value = true;
  enrollmentStatusDueDateDialogIsVisible.value = false;
  await Api.organization.subsidy_program.enrollment
    .bulkUpdate(filters.value.subsidy_program_id, enrollmentParams)
    .catch((error) => eventBus.error(error));

  if (enrollmentOfferDueDate.value) {
    enrollmentOfferDueDate.value = null;
  }
  if (enrollmentEndDate.value) {
    enrollmentEndDate.value = null;
    graduateEnrolledChildrenDialog.value.close();
  }
  await loadEnrollments();
}

async function updatePastDueEnrollments(oldStatus, newStatus) {
  processing.value = true;
  await Api.organization.subsidy_program.enrollment
    .bulkUpdate(filters.value.subsidy_program_id, {
      release_past_due_enrollments: true,
      ...oldStatus,
      ...newStatus,
    })
    .catch((error) => eventBus.error(error));

  releasePastDueResourceDialog.value.close();
  await loadEnrollments();
}

function cancelPublishToFamilies() {
  enrollmentStatusDueDateDialogIsVisible.value = false;
  enrollmentOfferDueDate.value = null;
}

async function load() {
  filters.value = defaultFilters.value;
  processing.value = true;

  const resp = await Api.organization.subsidy_program.index();
  subsidyPrograms.value = resp.data;

  loadFilters();
  await updateQuery({ ...nonEmptyFilters.value });

  if (filters.value.provider_id) {
    loadProvider();
    loadPrograms();
  }
  await loadEnrollments();
}

async function loadEnrollments() {
  processing.value = true;
  downloadUrl.value = Api.organization.enrollment.downloadUrl(
    nonEmptyFilters.value,
    'Enrollments.csv',
  );

  const currentEnrollmentQueryCount = enrollmentQueryCount.value;
  Api.organization.enrollment.index(route.query, (resp) => {
    if (currentEnrollmentQueryCount === enrollmentQueryCount.value) {
      pages.value = parseInt(resp.headers['x-page-count'] || 0, 10);
      pageSize.value = parseInt(resp.headers['x-page-size'] || 0, 10);
      total.value = parseInt(resp.headers['x-total-count'] || 0, 10);
      pageStartCount.value = pageSize.value * (filters.value.page - 1) + 1;
      enrollments.value = resp.data;
      processing.value = false;
    }
  });
  await checkRunStatus();
}

function loadProviders(query) {
  providerQueryCount.value += 1;
  const currentProviderQueryCount = providerQueryCount.value;
  Api.organization.provider.index({ query, page_size: 200 }, (resp) => {
    if (providerQueryCount.value === currentProviderQueryCount) {
      providers.value = resp.data;
    }
  });
}

function loadProvider() {
  Api.organization.provider.get(filters.value.provider_id, (resp) => {
    providers.value = [resp.data];
  });
}

function loadPrograms() {
  Api.public_api.organization.program.index({ provider_id: filters.value.provider_id }, (resp) => {
    programs.value = resp.data;
  });
}

function loadFilters() {
  const queryFilters = { ...route.query };
  const useQuery = Object.keys(queryFilters).some(
    (key) => !_.isEqual(queryFilters[key], defaultFilters.value[key]),
  );
  const filtersHash = useQuery ? queryFilters : {};

  if (!filtersHash.subsidy_program_id && subsidyPrograms.value.length > 0) {
    filtersHash.subsidy_program_id = subsidyPrograms.value[0].id;
  }
  selectedSubsidyProgram.value = subsidyPrograms.value.find(
    (sp) => sp.id === filtersHash.subsidy_program_id,
  );

  selectedSubsidyProgramStartDate.value = selectedSubsidyProgram.value?.enrollment_start_date;
  selectedSubsidyProgramEndDate.value = selectedSubsidyProgram.value?.enrollment_end_date;

  if (!selectedSubsidyProgram.value?.enable_enrollment_filtering_by_subsidy_status) {
    filtersHash.subsidy_statuses = [];
  }
  Object.keys(defaultFilters.value).forEach((key) => {
    if (!_.isString(filtersHash[key])) return;
    if (_.isArray(defaultFilters.value[key])) filtersHash[key] = [filtersHash[key]];
    if (_.isNumber(defaultFilters.value[key])) filtersHash[key] = parseInt(filtersHash[key], 10);
  });
  filters.value = { ...filters.value, ...filtersHash };
}

async function handleProviderChange() {
  filters.value.program_id = null;
  programs.value = [];
  if (filters.value.provider_id) {
    loadProvider();
    loadPrograms();
  }
  await reload();
}

async function handleSubsidyProgramChange() {
  filters.value.subsidy_program_id = selectedSubsidyProgram.value.id;
  filters.value['subsidy_statuses'] = [];
  selectedSubsidyProgramStartDate.value = selectedSubsidyProgram.value.enrollment_start_date;
  selectedSubsidyProgramEndDate.value = selectedSubsidyProgram.value.enrollment_end_date;
  await reload();
}

async function reload(newPage) {
  filters.value.page = newPage || 1;
  await updateQuery({ ...filters.value });
  await loadEnrollments();
}

async function toggleSort(field) {
  if (filters.value.sort_field !== field) {
    filters.value.sort_dir = 'desc';
  } else {
    filters.value.sort_dir = filters.value.sort_dir === 'asc' ? 'desc' : 'asc';
  }
  filters.value.sort_field = field;
  await reload();
}

async function resetDeferredAcceptance() {
  // eslint-disable-next-line no-restricted-globals, no-alert
  if (!confirm('Please confirm that you would like to RESET all enrollments?')) return false;

  processing.value = true;
  await Api.organization.subsidy_program.deferred_acceptance.destroy(
    filters.value.subsidy_program_id,
  );

  await loadEnrollments();
}

async function validateCreateDaaRunForm() {
  await createDaaRunForm.value.validate();
}
</script>
