<template>
  <v-container
    v-if="collection"
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <div class="mt-3 mx-3">
      <v-card
        class="bt-1 br-1 bl-1 bc-outlined-gray"
        flat
        tile
      >
        <v-card-text class="fs-15 py-3">
          <v-row dense>
            <v-col class="d-flex align-center">
              <p class="mb-0 py-1">
                {{ $t('Displaying') }} <strong>{{ collection.length }}</strong> {{ $t('results') }},
                {{ $t('out of') }} <strong>{{ totalItemCount }}</strong> {{ $t('total results') }}.
              </p>
            </v-col>

            <v-btn
              v-if="isLearningAdmin"
              @click="$refs.newCohortDialog.open()"
              color="primary"
              prepend-icon="add"
            >
              {{ $t('New cohort') }}
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <v-data-table-server
      @click:row="handleRowClick"
      @update:page="handlePageChange"
      :headers="tableHeaders"
      :hide-default-footer="!totalItemCount || pages === 0"
      :items="collection"
      :items-length="totalItemCount"
      :items-per-page="pageSize"
      :loading="processing"
      :page="filters.page"
      class="mx-3 b-radius-0 b-1 bc-outlined-gray fixed-height"
      item-key="id"
      no-data-text="No cohorts found"
      disable-sort
      fixed-header
      hover
    >
      <template #item.facilitator="{ item }">
        <template v-if="item.relationships?.facilitator?.data?.name">
          <router-link
            v-if="
              store.state.profile.org_settings_access && item.relationships?.facilitator?.data?.id
            "
            @click.stop.prevent
            :to="{
              name: 'SpecialistShow',
              params: { id: item.relationships.facilitator.data.id },
            }"
          >
            {{ $t(item.relationships.facilitator.data.name) }}
          </router-link>

          <span v-else>
            {{ $t(item.relationships.facilitator.data.name) }}
          </span>
        </template>
      </template>

      <template #item.course="{ item }">
        <template v-if="item.relationships?.course?.data">
          <router-link
            v-if="isLearningAdmin"
            @click.stop.prevent
            :to="{
              name: 'CourseShow',
              params: { courseId: item.relationships.course.data.id },
              query: { tab: 'settings' },
            }"
          >
            {{ $t(item.relationships.course.data.name) }}
          </router-link>

          <span v-else>
            {{ $t(item.relationships.course.data.name) }}
          </span>
        </template>
      </template>

      <template #item.status="{ item }">
        <v-chip
          :color="`${cohortStatus(item, 'color')}-lighten-3`"
          size="small"
        >
          {{ cohortStatus(item, 'text') }}
        </v-chip>
      </template>

      <template #item.startDate="{ item }">
        <LongDate
          :date="item?.attributes?.start_date"
          short-month
        />
      </template>

      <template #item.endDate="{ item }">
        <LongDate
          :date="item?.attributes?.end_date"
          short-month
        />
      </template>

      <template #item.actions="{ item }">
        <div class="d-flex justify-end align-center">
          <ActionMenu
            v-if="isLearningAdmin"
            @click:action:delete="destroy(item)"
            @click:action:duplicate="duplicate(item)"
            :items="actionItems"
            button-icon="more_vert"
          />
        </div>
      </template>
    </v-data-table-server>

    <ResourceDialog
      @save="create"
      @search="loadCourses"
      ref="newCohortDialog"
      :fields="[
        {
          text: 'Name',
          value: 'name',
          required: true,
        },
        {
          text: 'Course',
          value: 'course_id',
          items: courses,
          itemText: 'name',
          itemValue: 'id',
          required: true,
          search: true,
        },
      ]"
      :processing="processing"
      save-button-text="Create"
      title="Create new cohort"
    />
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import LongDate from '@/shared/components/LongDate.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const DEFAULT_PAGE_SIZE = 10;

const eventBus = useEventBus();
const router = useRouter();
const store = useStore();
const { getFiltersFromQuery, updateQuery } = useRouterHelper();

const actionItems = ref([
  {
    title: 'Delete',
    event: 'delete',
  },
]);
const cohortStatuses = ref({
  draft: {
    color: 'light-grey',
    text: 'Draft',
  },
  upcoming: {
    color: 'amber',
    text: 'Upcoming',
  },
  active: {
    color: 'light-green',
    text: 'Active',
  },
  complete: {
    color: 'primary',
    text: 'Complete',
  },
});
const courses = ref([]);
const collection = ref(null);
const facilitators = ref([]);
const filters = ref(getFiltersFromQuery({ paged: true }));
const pages = ref(1);
const pageSize = ref(DEFAULT_PAGE_SIZE);
const processing = ref(false);
const tableHeaders = ref([
  {
    title: 'Name',
    value: 'attributes.name',
  },
  {
    title: 'Facilitator',
    value: 'facilitator',
  },
  {
    title: 'Course',
    value: 'course',
  },
  {
    title: 'Status',
    value: 'status',
    align: 'center',
  },
  {
    title: 'Start date',
    value: 'startDate',
    align: 'center',
  },
  {
    title: 'End date',
    value: 'endDate',
    align: 'center',
  },
  {
    title: '',
    value: 'actions',
  },
]);
const today = ref(new Date());
const totalItemCount = ref(null);

const isLearningAdmin = computed(() => {
  return store.state.profile?.org_learning_admin;
});

function cohortStatus(cohort, attribute) {
  if (!cohort?.attributes?.start_date || !cohort?.attributes?.end_date)
    return cohortStatuses.value.draft[attribute];

  const startDate = new Date(cohort.attributes.start_date);
  const endDate = new Date(cohort.attributes.end_date);

  if (startDate > today.value) return cohortStatuses.value.upcoming[attribute];
  if (today.value > endDate) return cohortStatuses.value.complete[attribute];

  return cohortStatuses.value.active[attribute];
}

async function create(cohort) {
  processing.value = true;

  const params = {
    type: 'cohort',
    attributes: {
      name: cohort.name,
    },
    relationships: {
      course: {
        data: {
          type: 'course',
          id: cohort.course_id,
        },
      },
    },
  };

  const response = await Api.cohort.create(params);

  processing.value = false;

  if (response.status !== 201) return;

  eventBus.chime('Created');

  router.push({
    name: 'CohortShow',
    params: {
      cohortId: response?.data?.data?.id,
    },
    query: {
      tab: 'learners',
    },
  });

  processing.value = false;
}

async function destroy(cohort) {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you sure you want to delete this cohort?')) return;

  processing.value = true;

  const response = await Api.cohort.destroy(cohort.id);

  processing.value = false;

  if (response?.status !== 200) return;

  eventBus.chime('Deleted');

  await load();
}

async function duplicate(cohort) {
  processing.value = true;

  const response = await Api.cohort.create(cohort);

  processing.value = false;

  if (response.status !== 201) return;

  eventBus.chime('Duplicated');

  router.push({
    name: 'CohortShow',
    params: {
      cohortId: response?.data?.data?.id,
    },
    query: {
      tab: 'learners',
    },
  });

  processing.value = false;
}

async function handlePageChange(newValue) {
  processing.value = true;

  if (newValue) filters.value.page = newValue;
  await updateQuery({ ...filters.value });
  await load();
}

function handleRowClick(event, { item: cohort }) {
  router.push({
    name: 'CohortShow',
    params: {
      cohortId: cohort.id,
    },
    query: {
      tab: 'learners',
    },
  });
}

async function load() {
  processing.value = true;

  await loadCourses();

  const response = await Api.cohort.index({
    page: {
      limit: DEFAULT_PAGE_SIZE,
      offset: (filters.value.page - 1) * DEFAULT_PAGE_SIZE,
    },
    sort: 'name',
  });

  pages.value = response?.data?.meta?.total_pages || 1;
  pageSize.value = response?.data?.meta?.page_limit || DEFAULT_PAGE_SIZE;
  totalItemCount.value = response?.data?.meta?.total_count || 0;
  collection.value = response?.data?.data;
  if (!collection.value || collection.value.length === 0) {
    processing.value = false;
    return;
  }

  collection.value = await Promise.all(
    collection.value.map(async (cohort) => {
      const response = await Api.cohort.get(cohort.id, { include: 'course,facilitator' });

      if (response?.status !== 200) return;

      const facilitator =
        response.data?.included?.filter((included) => included.type === 'facilitator') || [];
      if (facilitator.length) {
        facilitators.value.push(...facilitator);
        response.data.data.relationships.facilitator.data.name = facilitator[0].attributes.name;
      }

      const course =
        response.data?.included?.filter((included) => included.type === 'course') || [];
      if (course.length) {
        response.data.data.relationships.course.data.name = course[0].attributes.name;
      }

      return response.data.data;
    }),
  );

  processing.value = false;
}

async function loadCourses(query) {
  const filters = {};
  if (query) filters.query = query[1];

  const response = await Api.organization.course.index(filters);
  courses.value = response?.data;
}

onMounted(load);
</script>
