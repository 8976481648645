<template>
  <v-card
    class="mb-4"
    border
    flat
    tile
  >
    <v-card-title class="tab-title">
      <v-row>
        <v-col
          class="d-flex align-center"
          cols="8"
        >
          <span v-t="title" />
        </v-col>
        <v-col />
      </v-row>
    </v-card-title>

    <v-divider class="mb-0" />

    <v-card-text>
      <v-row class="mb-2 d-flex align-center">
        <v-col
          cols="12"
          lg="9"
        >
          <ActionMenu
            @click:action:change="changeProgram($event)"
            :button-block="$vuetify.display.mdAndDown"
            :button-title="selectedProgram?.name || 'Select a classroom'"
            :items="programActionItems"
            button-class="me-2 bg-super-light-grey px-3 py-2 rounded"
            button-color="black"
            button-icon-side="right"
            data-testid="program-select"
            button-text
          />

          <ActionMenu
            v-if="selectedProgram"
            @click:action:change="changeOperatingLog($event)"
            :button-block="$vuetify.display.mdAndDown"
            :button-title="selectedMonth || 'Select a month'"
            :items="operatingLogActionItems"
            button-class="bg-super-light-grey px-3 py-2 rounded"
            button-color="black"
            button-icon-side="right"
            button-text
          />
        </v-col>

        <v-col
          v-if="selectedOperatingLog"
          class="d-flex justify-end"
          cols="12"
          lg="3"
        >
          <v-btn
            @click="handleOpenOperatingLog"
            :block="$vuetify.display.mdAndDown"
            :loading="processing"
            :variant="!!selectedOperatingLog.submitted_at ? 'outlined' : 'flat'"
            class="me-3"
            color="primary"
          >
            <template v-if="selectedOperatingLog.submitted_at">
              <v-icon class="me-2"> check </v-icon>
              <span v-t="'Submitted'" />
            </template>
            <template v-else>
              <span v-t="'Submit'" />
            </template>
          </v-btn>

          <ActionMenu
            @click:action:unsubmit="unsubmitAttendance"
            :items="operatingLogActions"
            button-icon="more_vert"
            label="Download Data"
          />
        </v-col>
      </v-row>

      <template v-if="selectedOperatingLog">
        <AttendanceLogEditor
          @delete:attendance-log="deleteAttendanceLog($event)"
          @queue:attendance-log-change="queueAttendanceLogChange($event)"
          @queue:attendance-log-question="queueAttendanceLogQuestion($event)"
          @queue:operating-log-change="queueOperatingLogChange($event)"
          :attendance-log-questions="attendanceLogQuestions"
          :attendance-log-schema="attendanceLogSchema"
          :attendance-logs="attendanceLogs"
          :attendance-totals="attendanceTotals"
          :daily-totals="dailyTotals"
          :errors="errors"
          :operating-log="selectedOperatingLog"
          :operating-log-schema="operatingLogSchema"
          :queued-attendance-log-answers="queuedAttendanceLogAnswers"
          :queued-attendance-log-changes="queuedAttendanceLogChanges"
          :queued-attendance-log-question-id="queuedAttendanceLogQuestionId"
          :queued-operating-log-changes="queuedOperatingLogChanges"
          :submit-revision="selectedOperatingLog?.submission_revision"
        />
      </template>

      <template v-else>
        <NullState hide-new-button />
      </template>
    </v-card-text>

    <ResourceDialog
      @close="clearAttendanceChangeQueue"
      ref="attendanceLogStatusDialog"
      :processing="processing"
      :title="`Set ${terms.attendance} status`"
      closeable
      hide-actions
    >
      <template #form>
        <div class="ta-center">
          <v-btn
            v-for="status in $store.state.config.attendance_statuses"
            @click="updateAttendanceLogsStatus(status)"
            :key="status.code"
            :color="status.color"
            :loading="processing"
            class="mb-2 me-2 px-4"
            size="x-large"
            variant="flat"
            block
            tile
          >
            <span>{{ status.code }} - {{ status.title }}</span>
          </v-btn>
        </div>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @close="clearOperatingLogChangeQueue"
      ref="operatingLogStatusDialog"
      :max-width="600"
      :processing="processing"
      title="Set operating status"
      closeable
      hide-actions
    >
      <template #form>
        <div class="ta-center">
          <v-btn
            v-for="status in $store.state.config.operating_statuses"
            @click="updateOperatingLog(status)"
            :key="status.code"
            :color="status.color"
            :loading="processing"
            class="mb-2 me-2 px-4"
            size="x-large"
            variant="flat"
            block
            label
            tile
          >
            {{ status.code }} - {{ status.title }}
          </v-btn>
        </div>
      </template>
    </ResourceDialog>

    <AttendanceLogQuestionDialog
      @close="clearAttendanceLogQuestionQueue"
      @save="updateAttendanceLogsAnswers"
      ref="attendanceLogQuestionDialog"
      :processing="processing"
      :schema-definition="attendanceLogSchema?.definition"
    />

    <OperatingLogEditor @change="selectedOperatingLog = $event" />

    <ChangesQueuedAlert
      @clear:attendance-log-changes="clearAttendanceChangeQueue"
      @clear:attendance-log-question="clearAttendanceLogQuestionQueue"
      @clear:attendance-log-status="clearAttendanceLog"
      @clear:operating-log-changes="clearOperatingLogChangeQueue"
      @clear:operating-log-status="clearOperatingLog"
      @edit:attendance-log-question="openAttendanceLogQuestionDialog"
      @edit:attendance-log-status="$refs.attendanceLogStatusDialog.open()"
      @edit:operating-log="$refs.operatingLogStatusDialog.open()"
      :queued-attendance-log-answers="queuedAttendanceLogAnswers"
      :queued-attendance-log-changes="queuedAttendanceLogChanges"
      :queued-operating-log-changes="queuedOperatingLogChanges"
      :show-attendance-log-change-queue="showAttendanceLogChangeQueue"
      :show-attendance-log-question-queue="showAttendanceLogQuestionQueue"
      :show-operating-log-change-queue="showOperatingLogChangeQueue"
    />
  </v-card>
</template>

<script>
import API from '@/shared/mixins/api';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Attendance from '@/shared/mixins/attendance';
import AttendanceLogEditor from '@/shared/components/attendance-logs/AttendanceLogEditor.vue';
import AttendanceLogQuestionDialog from '@/shared/components/attendance-logs/AttendanceLogQuestionDialog.vue';
import ChangesQueuedAlert from '@/shared/components/ChangesQueuedAlert.vue';
import NullState from '@/shared/components/NullState.vue';
import OperatingLogEditor from '@/shared/components/OperatingLogEditor.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { validateAttendance } from '@/shared/services/attendance-validation';
import RouterHelper from '@/shared/mixins/RouterHelper';
import Terms from '@/shared/mixins/terms';

export default {
  compatConfig: { MODE: 3 },

  components: {
    ActionMenu,
    AttendanceLogEditor,
    AttendanceLogQuestionDialog,
    ChangesQueuedAlert,
    NullState,
    OperatingLogEditor,
    ResourceDialog,
  },

  mixins: [API, Attendance, RouterHelper, Terms],

  props: {
    title: String,
  },

  data() {
    return {
      processing: false,
      errors: [],
    };
  },

  computed: {
    attendanceLink() {
      if (!this.selectedOperatingLog) return null;

      const programName = this.programs.find(
        (program) => program.id === this.selectedOperatingLog.program_id,
      ).name;
      const filename = `${programName} ${this.terms.attendance} for ${this.selectedOperatingLog.year}-${this.selectedOperatingLog.month}`;
      return this.scopedApi().attendance_log.downloadUrl(filename, this.selectedOperatingLog.id);
    },

    operatingLogLink() {
      if (!this.selectedOperatingLog) return null;

      const programName = this.programs.find(
        (program) => program.id === this.selectedOperatingLog.program_id,
      ).name;
      const filename = `${programName} ${this.terms.attendance} for ${this.selectedOperatingLog.year}-${this.selectedOperatingLog.month}`;
      return this.scopedApi().operating_log.downloadUrl(this.selectedOperatingLog.id, filename);
    },

    operatingLogActions() {
      if (this.selectedOperatingLog) {
        const perennialLinks = [
          { href: this.attendanceLink, title: `Download ${this.terms.attendance}` },
          { href: this.operatingLogLink, title: 'Download Form' },
        ];

        if (this.selectedOperatingLog.submitted_at) {
          perennialLinks.push({ event: 'unsubmit', title: 'Unsubmit' });
        }

        return perennialLinks;
      }

      return [];
    },

    operatingLogActionItems() {
      return this.operatingLogs.map((ol) => ({
        event: 'change',
        operatingLogId: ol.id,
        title: [this.$a.assets.months[ol.month], ol.year].join(' '),
      }));
    },

    programActionItems() {
      return this.programs.map((program) => ({
        event: 'change',
        title: program.name,
        programId: program.id,
      }));
    },

    selectedMonth() {
      if (!this.selectedOperatingLog) return null;

      return [
        this.$a.assets.months[this.selectedOperatingLog.month],
        this.selectedOperatingLog.year,
      ].join(' ');
    },
  },

  watch: {
    '$route.params.providerId': {
      immediate: true,
      handler() {
        this.selectedProgram = null;
        this.loadPrograms();
        this.loadAttendanceLogQuestions();
      },
    },
    '$route.query.tab': {
      handler(newVal) {
        if (newVal && newVal === 'attendance') {
          this.loadPrograms();
        }
      },
    },
  },

  created() {
    this.loadPrograms();
    this.loadAttendanceLogQuestions();
  },

  methods: {
    deleteAttendanceLog(log) {
      if (
        confirm(
          `Are you sure you want to remove the ${this.terms.attendance} record from this classroom for ${log.name} for this month?`,
        )
      ) {
        this.scopedApi().attendance_log.destroy(log.id, () => this.loadAttendanceLogs());
      }
    },

    async handleOpenOperatingLog() {
      this.errors = validateAttendance(
        this.attendanceLogs,
        this.attendanceLogQuestions,
        !this.operatingLogSchema.meta.disable_daily_attendance,
      );

      if (this.errors.length > 0) {
        return this.$eventBus.$emit('error', [`${this.terms.attendance} log is not complete.`]);
      }

      return await this.updateQuery({ action: 'editOperatingLog' });
    },

    async loadPrograms() {
      this.attendanceLogs = [];
      this.operatingLogs = [];
      const { data } = await this.api.program.index(this.$route.params.providerId);
      this.programs = data;
    },

    // Used in attendance mixin and OperatingLogEditor
    scopedApi() {
      return this.api.organization;
    },

    async unsubmitAttendance() {
      await this.scopedApi().operating_log.update(this.selectedOperatingLog.id, {
        submitted: false,
      });
      const resp = await this.scopedApi().operating_log.get(this.selectedOperatingLog.id);
      if (resp.status != 200) return;

      this.$eventBus.$emit('chime', 'Unsubmitted');
      this.selectedOperatingLog = resp.data;
    },
  },
};
</script>
