<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle :title="$store.state.pages.Grants.title || 'Grants'" />

    <v-toolbar
      class="mb-3 px-4 bb-1"
      extension-height="60"
      extended
    >
      <v-row>
        <v-col class="mxw-400">
          <v-select
            v-model="filters.grant_program_id"
            @update:model-value="updateFilters()"
            :aria-label="grantProgram?.name"
            :items="grantPrograms"
            density="compact"
            item-title="name"
            item-value="id"
            variant="filled"
            hide-details
          />
        </v-col>
        <v-col class="mxw-340">
          <v-text-field
            v-model="filters.query"
            @update:model-value="updateFilters()"
            id="search_link"
            :aria-label="$t('Filter results by name')"
            :placeholder="$t('Filter results by name')"
            color="secondary"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
            tracked
          />
        </v-col>
      </v-row>

      <template
        v-if="grantProgram"
        #extension
      >
        <div class="d-flex flex-grow-1">
          <FilterMenu
            :active="!!filters.status"
            classes="d-none d-md-inline-flex"
            title="Status"
            paddingless
          >
            <template #card>
              <v-list
                v-model:selected="filters.status"
                @update:selected="updateFilters"
                :item-value="(v) => v"
                :items="statusOptions"
              >
                <template #prepend="{ isSelected }">
                  <v-checkbox-btn
                    :model-value="isSelected"
                    false-icon="check_box_outline_blank"
                    tabindex="-1"
                    true-icon="check_box"
                  />
                </template>
              </v-list>
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="grantProgram.enable_priority"
            :active="!!filters.priority"
            classes="d-none d-md-inline-flex"
            title="Priority"
            paddingless
          >
            <template #card>
              <v-list
                v-model:selected="filters.priority"
                @update:selected="updateFilters()"
                :item-value="(v) => v"
                :items="['Low', 'Medium', 'High']"
              >
                <template #prepend="{ isSelected }">
                  <v-checkbox-btn
                    :model-value="isSelected"
                    false-icon="check_box_outline_blank"
                    tabindex="-1"
                    true-icon="check_box"
                  />
                </template>
              </v-list>
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="grantProgram.enable_team_review"
            :active="!!filters.team_id"
            classes="d-none d-md-inline-flex"
            title="Team"
            paddingless
          >
            <template #card>
              <v-list
                v-model:selected="filters.team_id"
                @update:selected="updateFilters()"
                :items="assignedTeamFilterItems"
                density="compact"
                item-title="name"
                item-value="id"
              >
                <template #prepend="{ isSelected }">
                  <v-checkbox-btn
                    :model-value="isSelected"
                    density="compact"
                    false-icon="check_box_outline_blank"
                    tabindex="-1"
                    true-icon="check_box"
                  >
                    <template #input="{ inputNode, icon }">
                      <v-icon
                        :icon="icon"
                        size="x-small"
                      />
                      <component :is="inputNode" />
                    </template>
                  </v-checkbox-btn>
                </template>
              </v-list>
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="grantProgram.enable_individual_review && $store.state.profile.org_grants_admin"
            :active="!!filters.reviewer_id"
            classes="d-none d-md-inline-flex"
            title="Assigned to"
            paddingless
          >
            <template #card>
              <v-list
                v-model:selected="filters.reviewer_id"
                @update:selected="updateFilters()"
                :items="assignedReviewerFilterItems"
                density="compact"
                item-title="name"
                item-value="id"
              >
                <template #prepend="{ isSelected }">
                  <v-checkbox-btn
                    :model-value="isSelected"
                    density="compact"
                    false-icon="check_box_outline_blank"
                    tabindex="-1"
                    true-icon="check_box"
                  >
                    <template #input="{ inputNode, icon }">
                      <v-icon
                        :icon="icon"
                        size="x-small"
                      />
                      <component :is="inputNode" />
                    </template>
                  </v-checkbox-btn>
                </template>
              </v-list>
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="grantProgram.enable_stages"
            :active="!!filters.stage_id"
            classes="d-none d-md-inline-flex"
            title="Stage"
            paddingless
          >
            <template #card>
              <v-list
                v-model:selected="filters.stage_id"
                @update:selected="updateFilters()"
                :items="grantProgram.stages"
                item-title="title"
                item-value="id"
              >
                <template #prepend="{ isSelected }">
                  <v-checkbox-btn
                    :model-value="isSelected"
                    false-icon="check_box_outline_blank"
                    tabindex="-1"
                    true-icon="check_box"
                  />
                </template>
              </v-list>
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="grantProgramLabels.length > 0"
            :active="filters.label_ids?.length > 0"
            classes="d-none d-md-inline-flex"
            title="Label"
          >
            <template #card>
              <v-autocomplete
                v-model="filters.label_ids"
                @update:model-value="updateFilters()"
                :aria-label="$t('Filter by label name')"
                :closable-chips="true"
                :disabled="processing"
                :items="grantProgramLabels"
                :menu="true"
                :placeholder="$t('Filter by label name')"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              >
                <template #chip="{ item, props }">
                  <v-chip v-bind="props">
                    <template #prepend>
                      <v-avatar
                        :color="item.raw.color"
                        start
                      />
                    </template>
                    {{ item.raw.name }}
                  </v-chip>
                </template>
                <template #item="{ item, props }">
                  <v-list-item v-bind="props">
                    <template #prepend="{ isSelected }">
                      <v-checkbox-btn
                        :key="item.value"
                        :model-value="isSelected"
                        :ripple="false"
                        tabindex="-1"
                      />
                    </template>
                    <template #append>
                      <v-avatar
                        :color="item.raw.color"
                        size="20"
                      />
                    </template>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </template>
          </FilterMenu>

          <v-spacer />

          <v-btn
            v-if="grantProgram.enable_team_review"
            @click="draftAssignTeam"
            :disabled="selectedItems.length === 0"
            class="me-3"
            color="primary"
            data-cy="assign-team-button"
            variant="flat"
          >
            {{ $t('Assign team') }}
          </v-btn>

          <v-btn
            v-if="grantProgram.enable_individual_review"
            @click="draftAssignReviewer"
            :disabled="selectedItems.length === 0"
            class="me-3"
            color="primary"
            data-cy="assign-reviewer-button"
            variant="flat"
          >
            {{ $t('Assign member') }}
          </v-btn>

          <v-btn
            v-if="$store.state.profile.org_grants_edit"
            @click="$refs.newGrant.open()"
            color="primary"
            data-cy="create-button"
            prepend-icon="add"
            variant="flat"
          >
            {{ $t('New') }}
          </v-btn>
        </div>
      </template>
    </v-toolbar>

    <v-container
      v-if="grantProgram"
      class="px-3 py-0"
      fluid
    >
      <v-data-table-server
        v-model="selectedItems"
        @update:page="handlePageChange"
        @update:sort-by="handleSortChange"
        :headers="tableHeaders"
        :items="collection"
        :items-length="totalItemCount"
        :items-per-page="pageSize"
        :loading="processing"
        :page="filters.page"
        :show-select="true"
        :sort-by="sortByData"
        item-key="id"
        select-strategy="page"
        sticky
      >
        <template #item.id="{ item }">
          <router-link
            :to="{
              name: 'GrantShow',
              params: { id: item.id },
            }"
          >
            {{ item.name }}
          </router-link>
        </template>

        <template #item.business_id="{ item }">
          <router-link
            :to="{
              name: 'BusinessShow',
              params: { businessId: item.business_id },
            }"
            target="_blank"
          >
            {{ item.business_name }}
          </router-link>
        </template>

        <template #item.provider_id="{ item }">
          <router-link
            v-if="item.provider_id"
            :to="{
              name: 'ProviderShow',
              params: { providerId: item.provider_id },
            }"
            target="_blank"
          >
            {{ item.provider_name }}
          </router-link>
        </template>

        <template #item.team_id="{ item }">
          <v-tooltip
            location="bottom"
            center
          >
            <template #activator="{ props }">
              <v-chip
                v-show="item.team_name"
                @click="modifyTeam(item)"
                v-bind="props"
                data-cy="grant-team"
                size="small"
              >
                {{ initials(item.team_name) }}
              </v-chip>
            </template>

            <span v-text="item.team_name" />
          </v-tooltip>
        </template>

        <template #item.reviewer_id="{ item }">
          <v-tooltip
            location="bottom"
            center
          >
            <template #activator="{ props }">
              <v-chip
                v-show="item.reviewer_name"
                @click="modifyReviewer(item)"
                v-bind="props"
                data-cy="grant-reviewer"
                size="small"
              >
                {{ initials(item.reviewer_name) }}
              </v-chip>
            </template>
            <span v-text="item.reviewer_name" />
          </v-tooltip>
        </template>

        <template #item.submitted_at="{ item }">
          <LongDate :date="item.submitted_at" />
        </template>

        <template #item.stage_id="{ item }">
          {{ grantProgram.stages.find((s) => s.id == item.stage_id)?.title }}
        </template>

        <template #item.labels="{ item }">
          <LabelValue
            @edit="$refs.grantLabelDialog.open(item)"
            :applied-labels="item.relationships.labels.data"
            :disabled="processing"
          />
        </template>

        <template #item.score="{ item }">
          <v-chip
            :color="getGrantScoreColor(item)"
            :text="item.score || '0'"
            class="w-48 justify-center"
          />
        </template>

        <template #loading>
          <div class="mxw-800 mx-auto py-12 ta-center">
            <v-progress-circular
              color="primary"
              size="75"
              indeterminate
            />
          </div>
        </template>

        <template #no-data>
          <NullState class="my-4" />
        </template>
      </v-data-table-server>
    </v-container>

    <LabelDialog
      @change="updateEditedGrantLabels"
      @close="loadGrants()"
      ref="grantLabelDialog"
      :program-labels="grantProgramLabels"
      label-owner-type="Grant"
    />

    <v-dialog
      v-model="assignTeamDialogIsVisible"
      max-width="400"
    >
      <v-card>
        <v-card-title>Assign</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-select
                v-model="assignTeamTo"
                :items="assignedTeamFilterItems"
                data-cy="team-selector"
                item-title="name"
                item-value="id"
                variant="filled"
                clearable
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="cancel"
            variant="text"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            @click="assignTeam()"
            color="primary"
            data-cy="save-button"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="assignReviewerDialogIsVisible"
      max-width="400"
    >
      <v-card>
        <v-card-title>Assign</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-select
                v-model="assignReviewerTo"
                :items="assignedReviewerFilterItems"
                data-cy="reviewer-selector"
                item-title="name"
                item-value="id"
                variant="filled"
                clearable
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="cancel"
            variant="text"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            @click="assignReviewer()"
            color="primary"
            data-cy="save-button"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ResourceDialog
      @save="createGrant"
      @search="searchBusinesses"
      ref="newGrant"
      :fields="newGrantFields"
      title="New application"
    />
  </v-container>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import LabelDialog from '@/specialist/components/LabelDialog.vue';
import LabelValue from '@/specialist/components/LabelValue.vue';
import LongDate from '@/shared/components/LongDate.vue';
import NullState from '@/shared/components/NullState.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import RouterHelper from '@/shared/mixins/RouterHelper';
import Terms from '@/shared/mixins/terms';

export default {
  compatConfig: { MODE: 3 },

  components: {
    FilterMenu,
    LabelDialog,
    LabelValue,
    LongDate,
    NullState,
    PageTitle,
    ResourceDialog,
  },

  mixins: [RouterHelper, Terms],

  data() {
    return {
      activeEditGrant: null,
      assignTeamDialogIsVisible: false,
      assignedTeamFilterItems: [],
      assignTeamTo: null,
      assignReviewerDialogIsVisible: false,
      assignedReviewerFilterItems: [],
      assignReviewerTo: null,
      businesses: [],
      businessQueryCount: 0,
      collection: [],
      filters: this.getFiltersFromQuery({ paged: true }),
      grantProgram: null,
      grantPrograms: [],
      grantProgramLabels: [],
      pageSize: null,
      processing: false,
      searchCounter: 0,
      selectedItems: [],
      statusOptions: [
        'In progress',
        'Submitted',
        'Reviewed',
        'Awaiting documents',
        'Denied',
        'Withdrawn',
        'Approved',
      ],
      totalItemCount: null,
    };
  },

  computed: {
    newGrantFields() {
      return [
        {
          text: 'Application name',
          value: 'Name',
          required: true,
        },
        {
          text: 'Business',
          value: 'business_id',
          items: this.businesses,
          itemText: 'name',
          itemValue: 'id',
          search: true,
          required: true,
        },
      ];
    },

    tableHeaders() {
      let headers = [
        { title: 'Application', value: 'id' },
        { title: 'Business', value: 'business_id' },
        { title: 'Location', value: 'provider_id' },
        { title: this.terms.program, value: 'program_name' },
      ];

      if (this.grantProgram.enable_team_review)
        headers.push({ title: 'Team', value: 'team_id', align: 'center' });
      if (this.grantProgram.enable_individual_review)
        headers.push({ title: 'Assigned', value: 'reviewer_id', align: 'center' });

      headers.push(
        { title: 'Date', key: 'submitted_at', align: 'center' },
        { title: 'Status', key: 'status', align: 'center' },
      );

      if (this.grantProgram.enable_stages)
        headers.push({ title: 'Stage', value: 'stage_id', align: 'center' });
      if (this.grantProgram.enable_priority)
        headers.push({ title: 'Priority', key: 'priority', align: 'center' });
      if (this.grantProgramLabels.length > 0)
        headers.push({ title: 'Labels', value: 'labels', align: 'center' });
      if (this.grantProgram.enable_scoring)
        headers.push({ title: 'Score', value: 'score', align: 'center' });
      return headers;
    },

    sortByData() {
      if (!this.filters?.sort) return [];
      return [{ key: this.filters.sort, order: this.filters.sort_dir }];
    },
  },

  created() {
    this.loadTeams();
    this.loadReviewers();
    this.loadGrantPrograms();
  },

  methods: {
    assignTeam() {
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      const grantsToUpdate = this.activeEditGrant ? [this.activeEditGrant.id] : this.selectedItems;
      for (const id of grantsToUpdate) {
        Api.organization.grant.update(id, { team_id: this.assignTeamTo }, (resp) => {
          const index = this.collection.findIndex((item) => item.id === id);
          this.collection[index].team_name = resp.data.team_name;
        });
      }
      this.assignTeamDialogIsVisible = false;
      this.activeEditGrant = null;
      this.selectedItems = [];
    },

    assignReviewer() {
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      const grantsToUpdate = this.activeEditGrant ? [this.activeEditGrant] : this.selectedItems;
      for (const id of grantsToUpdate) {
        Api.organization.grant.update(id, { reviewer_id: this.assignReviewerTo }, (resp) => {
          const index = this.collection.findIndex((item) => item.id === id);
          this.collection[index].reviewer_name = resp.data.reviewer_name;
        });
      }
      this.assignReviewerDialogIsVisible = false;
      this.activeEditGrant = null;
      this.selectedItems = [];
    },

    cancel() {
      this.assignReviewerDialogIsVisible = false;
      this.assignTeamDialogIsVisible = false;
      this.activeEditGrant = null;
    },

    createGrant(newGrant) {
      this.processing = true;
      Api.organization.grant.create(
        { ...newGrant, grant_program_id: this.filters.grant_program_id },
        (resp) => {
          this.processing = false;
          this.$router.push({ name: 'GrantShow', params: { id: resp.data.id } });
        },
        (err) => {
          this.processing = false;
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },

    draftAssignTeam() {
      this.assignTeamTo = null;
      this.assignTeamDialogIsVisible = true;
    },

    draftAssignReviewer() {
      this.assignReviewerTo = null;
      this.assignReviewerDialogIsVisible = true;
    },

    getGrantScoreColor(grant) {
      if (grant.score > 80) return 'blue-lighten-2';
      if (grant.score > 50) return 'green-lighten-2';
      if (grant.score > 30) return 'yellow-lighten-2';
      return 'grey-lighten-2';
    },

    handlePageChange(newVal) {
      this.filters.page = newVal;
      this.updateFilters(true);
    },

    handleSortChange(sorts) {
      // Note: we currently only support a single sort/dir
      // so we only access the first item in passed array
      if (sorts.length === 0) {
        this.filters.sort = null;
        this.filters.sort_dir = null;
      } else {
        this.filters.sort = sorts[0].key;
        this.filters.sort_dir = sorts[0].order;
      }
      this.processing = true;
      this.updateFilters();
    },

    initials(string) {
      if (string) {
        const wordParts = string.split(' ');
        return [wordParts[0][0], wordParts[wordParts.length - 1][0]].join('').toUpperCase();
      }
      return null;
    },

    loadGrantPrograms() {
      this.collection.splice(0);
      this.processing = true;

      Api.organization.grant_program.index((resp) => {
        this.grantPrograms = resp.data.filter((sp) => sp.published_at);

        if (this.filters.grant_program_id) {
          this.grantProgram = this.grantPrograms.find(
            (grantProgram) => grantProgram.id === this.filters.grant_program_id,
          );
          this.loadGrants();
        } else {
          this.grantProgram = this.grantPrograms[this.grantPrograms.length - 1];
          this.filters.grant_program_id = this.grantProgram.id;
          this.updateFilters();
        }
        this.loadGrantProgramLabels();
      });
    },

    async loadGrantProgramLabels() {
      const { data } = await Api.organization.label.index({
        grant_program_id: this.grantProgram.id,
      });
      this.grantProgramLabels = data;
    },

    loadGrants() {
      this.searchCounter += 1;
      const currentSearchCounter = this.searchCounter;

      Api.organization.grant.index(this.filters, (resp) => {
        if (this.searchCounter === currentSearchCounter) {
          this.pageSize = parseInt(resp.headers['x-page-size']);
          this.totalItemCount = parseInt(resp.headers['x-total-count']);
          this.collection = resp.data;
          this.selectedItems = [];
          this.processing = false;
        }
      });
    },

    loadReviewers() {
      Api.organization.member.index({ is_specialist: true }, (resp) => {
        this.assignedReviewerFilterItems = resp.data;
      });
    },

    loadTeams() {
      Api.organization.team.index({}, (resp) => {
        this.assignedTeamFilterItems = resp.data;
      });
    },

    modifyReviewer(grant) {
      this.activeEditGrant = grant;
      this.assignReviewerTo = grant.reviewer_id;
      this.assignReviewerDialogIsVisible = true;
    },

    modifyTeam(grant) {
      this.activeEditGrant = grant;
      this.assignTeamTo = grant.team_id;
      this.assignTeamDialogIsVisible = true;
    },

    searchBusinesses(newVal) {
      if (newVal[0] === 'business_id') {
        this.businessQueryCount += 1;
        const currentBusinessQueryCount = this.businessQueryCount;
        Api.organization.business.index({ query: newVal[1] }, (resp) => {
          if (this.businessQueryCount === currentBusinessQueryCount) {
            this.businesses = resp.data;
          }
        });
      }
    },

    updateEditedGrantLabels(grant) {
      const idx = this.collection.findIndex((c) => c.id === grant.id);
      this.collection[idx] = grant;
    },

    async updateFilters(paged = false) {
      if (!paged) this.filters.page = 1;
      await this.updateQuery(this.filters);
      this.loadGrants();
    },
  },
};
</script>
