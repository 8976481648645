<template>
  <v-container
    v-if="localizedContentTemplates"
    class="mxw-1200 pa-6"
  >
    <v-row>
      <v-col>
        <div class="fw-600 bc-h5">
          <span class="header-title">
            {{ contentTemplateName }} / {{ $t('Localized content templates') }}
          </span>

          <ActionMenu
            @click:action:delete="destroyContentTemplate"
            @click:action:edit="openEditNameDialog"
            id="localized-content-template-action-menu"
            :items="[
              { event: 'edit', title: 'Edit name', avatar: 'edit' },
              { event: 'delete', title: 'Delete', avatar: 'delete' },
            ]"
            button-icon="more_vert"
            left
          />
        </div>
      </v-col>
      <v-col
        v-if="localizedContentTemplates.length > 0"
        class="ta-right"
      >
        <v-btn
          @click="navigateToNewLocalizedContentTemplate"
          color="primary"
        >
          Create localized content template
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="localizedContentTemplates.length == 0"
      class="d-flex justify-center"
      style="margin-top: 15%"
    >
      <v-col class="ta-center">
        <div class="fw-600 fs-20 mb-4">You do not have any localized content templates.</div>
        <v-btn
          @click="navigateToNewLocalizedContentTemplate"
          color="primary"
          size="x-large"
          rounded
        >
          Create localized content template
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-for="(lct, index) in localizedContentTemplates"
      :key="index"
    >
      <v-col>
        <v-card
          border
          flat
          tile
        >
          <v-card-text>
            <v-row
              @click="handleNavigationToLocalizedContentTemplate(lct)"
              class="d-flex align-center"
            >
              <v-col>
                <span v-text="lct.attributes.subject" /> [<span v-text="lct.attributes.locale" />]
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ResourceDialog
      @save="updateContentTemplateName"
      ref="editNameDialog"
      :fields="[{ text: 'Name', value: 'name' }]"
      title="Edit content template name"
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();

const localizedContentTemplates = ref();
const contentTemplateId = ref(route.params.contentTemplateId);
const contentTemplateName = ref(null);
const editNameDialog = ref(null);

onMounted(async () => {
  await load();
  await loadContentTemplate();
});

function handleNavigationToLocalizedContentTemplate(localizedContentTemplate) {
  router.push({
    name: 'LocalizedContentTemplateShow',
    params: {
      contentTemplateId: contentTemplateId.value,
      localizedContentTemplateId: localizedContentTemplate.id,
    },
  });
}

function navigateToNewLocalizedContentTemplate() {
  router.push({
    name: 'LocalizedContentTemplateNew',
    params: { contentTemplateId: contentTemplateId.value },
  });
}

function openEditNameDialog() {
  editNameDialog.value.open();
}

async function loadContentTemplate() {
  // To get the content template name used in the title bar
  const response = await Api.organization.content_template.get(contentTemplateId.value);

  contentTemplateName.value = response.data?.data?.attributes?.name;
}

async function destroyContentTemplate() {
  // eslint-disable-next-line no-alert, no-restricted-globals
  if (
    confirm(
      'Are you sure you want to remove this content template and all associated localized templates?',
    )
  ) {
    const response = await Api.organization.content_template.destroy(contentTemplateId.value);

    if (response?.status === 200) {
      router.push({ name: 'ContentTemplateIndex' });
      eventBus.chime('Content template deleted.');
    }
  }
}

async function updateContentTemplateName(resourceAttributes) {
  try {
    const response = await Api.organization.content_template.update(
      contentTemplateId.value,
      resourceAttributes,
    );
    contentTemplateName.value = response.data?.data?.attributes?.name;
  } finally {
    editNameDialog.value.close();
  }
}

async function load() {
  const response = await Api.organization.localized_content_template.index({
    'filter[content_template]': contentTemplateId.value,
  });
  localizedContentTemplates.value = response?.data?.data || [];
}
</script>

<style>
.header-title {
  padding-right: 10px;
}

#localized-content-template-action-menu {
  position: relative;
  top: -2px;
}
</style>
