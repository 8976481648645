<template>
  <v-container>
    <TitleBar
      :back-route="{ name: 'SchemaUploads', params: { schemaId: $route.query.schemaId } }"
      back-name="Syncs"
      class="mb-4"
      name="New sync"
    />

    <v-card
      border
      flat
      tile
    >
      <v-card-text class="mb-4">
        <v-row>
          <LabeledTextfield
            v-model="name"
            message="Name"
          />

          <LabeledSimpleSelect
            v-model="syncType"
            :items="syncTypes"
            message="Select a sync type"
          />

          <template v-if="syncType == 'upload'">
            <LabeledSimpleSelect
              v-model="fileType"
              :items="fileTypes"
              message="Select a file type"
            />

            <LabeledControl message="Attach file">
              <v-file-input
                v-model="file"
                color="primary"
                label="Attach file"
                variant="filled"
                hide-details
              />
            </LabeledControl>
          </template>

          <template v-if="syncType == 'sftp'">
            <LabeledTextfield
              v-model="fileName"
              message="Filename"
            />
            <LabeledTextfield
              v-model="host"
              message="Host"
            />
            <LabeledTextfield
              v-model="username"
              message="Username"
            />
            <LabeledTextfield
              v-model="password"
              message="Password"
            />
          </template>

          <template v-if="syncType == 'remoteUrl'">
            <LabeledSimpleSelect
              v-model="fileType"
              :items="fileTypes"
              message="Select a file type"
            />
            <LabeledTextfield
              v-model="remoteFileUrl"
              message="Remote file url"
            />
          </template>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="submit()"
          :disabled="!syncType"
          :loading="processing"
          color="primary"
          block
        >
          {{ $t('Upload') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder.js';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import { useRoute, useRouter } from 'vue-router';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();
const processing = ref(false);
const file = ref(null);
const fileName = ref(null);
const fileType = ref(null);
const fileTypes = ref([
  { text: 'Comma Separated Values (CSV)', value: 'csv' },
  { text: 'Pipe Delimited Values', value: 'pdv' },
]);
const host = ref(null);
const name = ref(null);
const password = ref(null);
const remoteFileUrl = ref(null);
const route = useRoute();
const router = useRouter();
const syncType = ref(null);
const syncTypes = ref([
  { text: 'File upload', value: 'upload' },
  { text: 'Remote file URL', value: 'remoteUrl' },
  { text: 'Retrieve from SFTP', value: 'sftp' },
]);
const username = ref(null);

function createUploadParams(fileUploadEvent) {
  const params = {
    name: name.value,
    schema_id: route.query.schemaId,
    file_type: fileType.value,
  };

  if (syncType.value === 'upload' && fileUploadEvent) {
    params.content = fileUploadEvent.target.result;
    return params;
  }

  if (syncType.value === 'remoteUrl') {
    if (!remoteFileUrl.value || !fileType.value) {
      return eventBus.chime('Missing required parameters');
    }

    params.remote_file_url = remoteFileUrl.value;
    return params;
  }

  if (syncType.value === 'sftp') {
    if (!fileName.value || !username.value || !password.value || !host.value) {
      return eventBus.chime('Missing required SFTP parameters');
    }

    params.sftp_file_name = fileName.value;
    params.sftp_username = username.value;
    params.sftp_password = password.value;
    params.sftp_host = host.value;
    return params;
  }

  return eventBus.chime('Cannot process sync of unknown content or type');
}

function submit() {
  if (syncType.value === 'upload') {
    processing.value = true;
    const reader = new FileReader();
    reader.readAsText(file.value, 'UTF-8');
    reader.onload = save;
  } else {
    save();
  }
}

function save(fileUploadEvent) {
  const params = createUploadParams(fileUploadEvent);
  if (!params) return;

  processing.value = true;
  Api.organization.upload.create(
    params,
    (resp) => {
      eventBus.chime('File upload currently processing.');
      processing.value = false;
      router.push({ name: 'UploadShow', params: { uploadId: resp.data.id } });
    },
    (error) => {
      eventBus.error(error);
      processing.value = false;
    },
  );
}
</script>
