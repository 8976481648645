<template>
  <v-card
    border
    tile
  >
    <v-card-title>
      <v-row class="d-flex align-center">
        <v-col>
          <span>Award #{{ localValue.id.toUpperCase() }}</span>
        </v-col>
        <v-col class="d-flex justify-end">
          <template v-if="localValue.closed_at">
            <v-chip
              class="ms-3"
              color="grey-darken-1"
              size="large"
              label
            >
              Closed
            </v-chip>
          </template>

          <template v-else>
            <template v-if="localValue.submitted_at">
              <v-chip
                class="ms-3"
                color="primary"
                size="large"
                label
              >
                Awarded
              </v-chip>
            </template>

            <template v-else>
              <v-chip
                class="ms-3"
                color="primary"
                size="large"
                variant="outlined"
                label
              >
                Draft
              </v-chip>
            </template>
          </template>
        </v-col>
      </v-row>
    </v-card-title>

    <template v-if="mode == 'summary'">
      <SubsidyAwardSummaryCard
        :provider="provider"
        :subsidy-award="localValue"
        dense
      />

      <template v-if="payments.length > 0">
        <v-divider />

        <v-card-text>
          <PaymentTable
            @open="$refs.paymentEditor.open($event)"
            :checkable="false"
            :collection="payments"
          />

          <PaymentEditor
            @change="loadPayments"
            ref="paymentEditor"
          />
        </v-card-text>
      </template>
    </template>

    <template v-if="mode == 'edit' || mode === 'view'">
      <v-card-text>
        <v-stepper-vertical
          v-model="step"
          :non-linear="mode === 'view'"
          flat
          hide-actions
        >
          <v-stepper-vertical-item
            :complete="step > 1"
            :editable="mode === 'view'"
            value="1"
          >
            <template #title>
              <div
                v-t="'Calculate award'"
                class="mb-2"
              />
              <div
                v-t="'Determine the award amount and parent co-pay.'"
                class="fs-14 c-light-black"
              />
            </template>

            <v-container>
              <v-row
                class="d-flex align-center"
                dense
              >
                <LabeledCurrencyInput
                  v-model="localValue.tuition_payment_amount"
                  @input="changed = true"
                  :hard-lock="mode === 'view'"
                  cols="12"
                  md="3"
                  message="Cost of care"
                />
                <LabeledSimpleSelect
                  v-model="localValue.award_payment_period"
                  @input="changed = true"
                  :hard-lock="mode === 'view'"
                  :items="['Monthly']"
                  cols="12"
                  md="3"
                  message="Pay frequency"
                />
                <LabeledDatePicker
                  v-model="localValue.start_date"
                  @input="changed = true"
                  :hard-lock="mode === 'view'"
                  cols="12"
                  md="3"
                  message="Start date"
                />
                <LabeledDatePicker
                  v-model="localValue.finish_date"
                  @input="changed = true"
                  :hard-lock="mode === 'view'"
                  cols="12"
                  md="3"
                  message="Finish date"
                />
              </v-row>

              <v-row
                v-if="subsidyProgram.enable_copay"
                class="d-flex align-center"
                dense
              >
                <LabeledTextfield
                  v-model="localValue.projected_copay_payment_amount"
                  cols="4"
                  message="Projected copay"
                  prefix="$"
                  hard-lock
                  locked
                  readonly
                />
                <LabeledTextfield
                  v-model="localValue.copay_payment_amount"
                  @input="changed = true"
                  :hard-lock="mode === 'view'"
                  cols="4"
                  message="Monthly copay amount"
                  prefix="$"
                />
                <LabeledTextfield
                  v-model="localValue.total_copay_amount"
                  cols="4"
                  message="Total copay"
                  prefix="$"
                  hard-lock
                />
              </v-row>

              <v-row
                class="d-flex align-center"
                dense
              >
                <LabeledTextfield
                  v-model="localValue.projected_award_payment_amount"
                  cols="4"
                  message="Projected payment"
                  prefix="$"
                  hard-lock
                  locked
                  readonly
                />
                <LabeledTextfield
                  v-model="localValue.award_payment_amount"
                  @input="changed = true"
                  :hard-lock="mode === 'view'"
                  cols="4"
                  message="Monthly award payment"
                  prefix="$"
                />
                <LabeledTextfield
                  v-model="localValue.total_award_amount"
                  cols="4"
                  message="Total award"
                  prefix="$"
                  hard-lock
                />
              </v-row>

              <template v-if="mode === 'edit'">
                <v-row class="my-4">
                  <v-col>
                    <v-btn
                      @click="recalculateAward()"
                      :loading="processing"
                      color="primary"
                    >
                      <v-icon start> refresh </v-icon>
                      <span v-t="'Recalculate'" />
                    </v-btn>
                  </v-col>

                  <v-col class="ta-right">
                    <v-btn
                      @click="continueToFunding()"
                      :disabled="!localValue.award_payment_amount || changed"
                      :loading="processing"
                      color="primary"
                    >
                      <span v-t="'Save and continue'" />
                      <v-icon end> east </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-stepper-vertical-item>

          <v-stepper-vertical-item
            :complete="step > 2"
            :editable="mode === 'view'"
            value="2"
          >
            <template #title>
              <v-row dense>
                <v-col cols="9">
                  <div
                    v-t="'Reserve funds'"
                    class="mb-2"
                  />
                  <div
                    v-t="'Assign funding sources based on eligibility and fund priority.'"
                    class="fs-14 c-light-black"
                  />
                </v-col>
                <v-col
                  v-if="localValue.reserved_at"
                  class="ta-right"
                >
                  <v-chip color="primary">
                    <span class="me-1">Reserved</span><LongDate :date="localValue.reserved_at" />
                  </v-chip>
                </v-col>
              </v-row>
            </template>

            <v-container>
              <p
                v-if="localValue.funds.length === 0"
                v-t="
                  'No funding sources commmitted yet. Set an award amount and Calculate funding to generate funding source committments.'
                "
                class="fs-16 my-4"
              />

              <v-row class="fs-16">
                <v-col>Source</v-col>
                <v-col>Available</v-col>
                <v-col>Amount</v-col>
                <v-col class="ta-right"> Balance </v-col>
              </v-row>

              <v-divider class="mt-2 mb-4" />

              <v-row
                v-for="(fund, index) in eligibleFunds"
                :key="fund.id"
                class="d-flex align-center fs-16"
              >
                <v-col>
                  <div class="fs-16 c-black">
                    <span class="me-1">{{ index + 1 }}.</span>
                    <span v-text="fund.name" />
                  </div>
                </v-col>
                <v-col>
                  <div class="fs-16 c-black">
                    <span class="me-1">{{ currency(fund.available_amount) }}</span>
                  </div>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model.number="fund.amount"
                    :append-icon="mode === 'view' ? 'lock' : null"
                    :disabled="mode === 'view'"
                    density="compact"
                    prefix="$"
                    variant="filled"
                    hide-details
                  />
                </v-col>
                <v-col class="ta-right">
                  {{ currency(getFundBalance(index)) }}
                </v-col>
              </v-row>

              <v-divider class="my-4" />

              <v-row dense>
                <v-col class="fs-16 fw-500"> Total award amount </v-col>
                <v-col class="fs-16 ta-right">
                  {{ currency(localValue.total_award_amount) }}
                </v-col>
              </v-row>

              <v-row
                class="d-flex align-center"
                dense
              >
                <v-col class="fs-18 fw-600 underlined"> Total funding amount </v-col>
                <v-col class="d-flex align-center justify-end">
                  <template v-if="totalFundingAmount == localValue.total_award_amount">
                    <v-icon
                      color="green"
                      size="24"
                    >
                      check_circle
                    </v-icon>
                  </template>
                  <template v-else>
                    <v-icon
                      color="red"
                      size="24"
                    >
                      error
                    </v-icon>
                  </template>

                  <span class="ms-3 fw-600 underlined">{{ currency(totalFundingAmount) }}</span>
                </v-col>
              </v-row>

              <template v-if="mode === 'edit'">
                <v-row class="d-flex align-center my-4">
                  <template v-if="mode == 'edit'">
                    <v-col>
                      <v-btn
                        @click="recalculateFunding()"
                        :loading="processing"
                        color="primary"
                      >
                        <v-icon start> refresh </v-icon>
                        <span v-t="'Recalculate funding'" />
                      </v-btn>
                    </v-col>
                  </template>
                  <v-col class="ta-right">
                    <v-btn
                      @click="back()"
                      :loading="processing"
                      class="me-3"
                      variant="outlined"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      @click="continueToPayments()"
                      :disabled="totalFundingAmount != localValue.total_award_amount"
                      :loading="processing"
                      color="primary"
                    >
                      <template v-if="localValue.reserved_at">
                        <span v-t="'Update reservation and continue'" />
                      </template>
                      <template v-else>
                        <span v-t="'Reserve funds and continue'" />
                      </template>
                      <v-icon end> east </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-stepper-vertical-item>

          <v-stepper-vertical-item
            :complete="step > 3"
            :editable="mode === 'view'"
            value="3"
          >
            <template #title>
              <div
                v-t="'Create payment schedules'"
                class="mb-2"
              />
              <div
                v-t="'Review and confirm payment schedule'"
                class="fs-14 c-light-black"
              />
            </template>

            <v-container>
              <p
                v-if="localValue.funds.length == 0"
                v-t="
                  'No payment schedule has been created yet. Set an award amount to generate payment schedule.'
                "
                class="fs-16 my-4"
              />

              <v-row class="d-flex align-center fs-16">
                <v-col cols="2"> Period </v-col>
                <v-col cols="2"> Paid </v-col>
                <v-col cols="2"> Co-pay </v-col>
                <v-col cols="2"> Funded </v-col>
                <v-col
                  class="ta-right"
                  cols="2"
                >
                  Total Paid
                </v-col>
                <v-col
                  class="ta-right"
                  cols="2"
                >
                  Balance
                </v-col>
              </v-row>

              <v-divider class="mt-2 mb-4" />

              <v-row
                v-for="(payment, index) in localValue.scheduled_payments"
                :key="index"
                class="d-flex align-center fs-16"
              >
                <v-col cols="2">
                  <LongDate
                    :date="payment.start_date"
                    short-month
                  />
                  <span class="mx-1">-</span>
                  <br />
                  <LongDate
                    :date="payment.finish_date"
                    short-month
                  />
                </v-col>
                <v-col cols="2">
                  <LongDate
                    :date="payment.date"
                    short-month
                  />
                </v-col>
                <v-col cols="2">
                  <v-row
                    dense
                    no-gutters
                  >
                    <v-text-field
                      v-model.number="payment.copay"
                      :append-icon="mode === 'view' ? 'lock' : null"
                      :disabled="mode === 'view'"
                      density="compact"
                      prefix="$"
                      variant="filled"
                      hide-details
                    />
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-row
                    dense
                    no-gutters
                  >
                    <v-text-field
                      v-model.number="payment.amount"
                      :append-icon="mode === 'view' ? 'lock' : null"
                      :disabled="mode === 'view'"
                      density="compact"
                      prefix="$"
                      variant="filled"
                      hide-details
                    />
                  </v-row>
                </v-col>
                <v-col
                  class="ta-right"
                  cols="2"
                >
                  {{ currency(payment.copay + payment.amount) }}
                </v-col>
                <v-col
                  class="ta-right"
                  cols="2"
                >
                  {{ currency(getBalance(index)) }}
                </v-col>
              </v-row>

              <v-divider class="my-4" />

              <v-row
                v-if="subsidyProgram.enable_copay"
                dense
              >
                <v-col class="fs-16 fw-500"> Total co-pay amount </v-col>
                <v-col class="fs-16 ta-right">
                  {{ currency(localValue.total_copay_amount) }}
                </v-col>
              </v-row>

              <v-row
                class="d-flex align-center"
                dense
              >
                <v-col class="fs-18 fw-600 underlined"> Total co-pay payments </v-col>
                <v-col class="d-flex align-center justify-end">
                  <template v-if="totalPaidCopayAmount == localValue.total_copay_amount">
                    <v-icon
                      color="green"
                      size="24"
                    >
                      check_circle
                    </v-icon>
                  </template>
                  <template v-else>
                    <v-icon
                      color="red"
                      size="24"
                    >
                      error
                    </v-icon>
                  </template>

                  <span class="ms-3 fw-600 underlined">{{ currency(totalPaidCopayAmount) }}</span>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col class="fs-16 fw-500"> Total award amount </v-col>
                <v-col class="fs-16 ta-right">
                  {{ currency(localValue.total_award_amount) }}
                </v-col>
              </v-row>

              <v-row
                class="d-flex align-center"
                dense
              >
                <v-col class="fs-18 fw-600 underlined"> Total award payments </v-col>
                <v-col class="d-flex align-center justify-end">
                  <template v-if="totalPaidAwardAmount == localValue.total_award_amount">
                    <v-icon
                      color="green"
                      size="24"
                    >
                      check_circle
                    </v-icon>
                  </template>
                  <template v-else>
                    <v-icon
                      color="red"
                      size="24"
                    >
                      error
                    </v-icon>
                  </template>

                  <span class="ms-3 fw-600 underlined">{{ currency(totalPaidAwardAmount) }}</span>
                </v-col>
              </v-row>

              <template v-if="mode === 'edit'">
                <v-row>
                  <v-col>
                    <v-btn
                      @click="recalculatePayments()"
                      :loading="processing"
                      color="primary"
                    >
                      <v-icon start> refresh </v-icon>
                      <span v-t="'Recalculate payments'" />
                    </v-btn>
                  </v-col>
                  <v-col class="ta-right">
                    <v-btn
                      @click="back()"
                      :loading="processing"
                      class="me-3"
                      variant="outlined"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      @click="continueToAgreements()"
                      :disabled="
                        totalPaidCopayAmount != localValue.total_copay_amount ||
                        totalPaidAwardAmount != localValue.total_award_amount
                      "
                      :loading="processing"
                      color="primary"
                    >
                      <span v-t="'Save and continue'" />
                      <v-icon end> east </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-stepper-vertical-item>

          <v-stepper-vertical-item
            :complete="step > 4"
            :editable="mode === 'view'"
            value="4"
          >
            <template #title>
              <div
                v-t="'Agreements'"
                class="mb-2"
              />
              <div
                v-t="'Draft and send agreements to appropriate parties.'"
                class="fs-14 c-light-black"
              />
            </template>

            <v-container>
              <v-row>
                <LabeledSimpleSelect
                  v-model="localValue.provider_id"
                  @change="save()"
                  :hard-lock="mode === 'view'"
                  :items="providers"
                  item-title="name"
                  item-value="id"
                  message="Confirm provider"
                />
              </v-row>

              <p
                v-if="agreements.length === 0"
                v-t="'No agreements assigned.'"
                class="fs-16 my-4"
              />

              <v-row class="mb-2">
                <v-col>
                  <v-btn
                    @click="draftAgreementDialog.open({})"
                    :disabled="
                      mode === 'view' ||
                      !(localValue.provider_id && groupMembers && providerMembers)
                    "
                    :loading="processing"
                    class="me-3"
                    color="primary"
                  >
                    <v-icon start> add </v-icon>
                    <span v-t="'Add agreement'" />
                  </v-btn>
                  <v-btn
                    @click="loadAgreements"
                    :loading="processing"
                    color="primary"
                    variant="outlined"
                  >
                    <v-icon start> refresh </v-icon>
                    <span v-t="'Refresh status'" />
                  </v-btn>
                </v-col>
              </v-row>

              <AgreementCard
                v-for="agreement in agreements"
                @change="loadAgreements"
                @destroy="loadAgreements"
                @open="updateQuery({ agreementId: agreement.id })"
                :key="agreement.id"
                :agreement="agreement"
                :locked="mode === 'view'"
                :members="allMembers"
              />

              <ResourceDialog
                @save="createAgreement"
                ref="draftAgreementDialog"
                :fields="[
                  {
                    text: 'Agreement type',
                    value: 'schema_id',
                    items: agreementSchemas,
                    itemText: 'name',
                    itemValue: 'id',
                  },
                  { text: 'Recipient', value: 'member_id', items: getRecipient },
                ]"
                :processing="processing"
                title="Add agreement"
              />

              <template v-if="mode == 'edit'">
                <v-row class="mt-4">
                  <v-col class="ta-right">
                    <v-btn
                      @click="back()"
                      :loading="processing"
                      class="me-3"
                      variant="outlined"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      @click="continueToSummary()"
                      :disabled="!provider"
                      :loading="processing"
                      color="primary"
                    >
                      <span v-t="'Review'" />
                      <v-icon end> east </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-stepper-vertical-item>

          <v-stepper-vertical-item
            :complete="step > 5"
            :editable="mode === 'view'"
            value="5"
          >
            <template #title>
              <div
                v-t="'Finalize and submit'"
                class="mb-2"
              />
              <div
                v-t="'Review and confirm award amount and payment details'"
                class="fs-14 c-light-black"
              />
            </template>

            <v-container>
              <SubsidyAwardSummaryCard
                :provider="provider"
                :subsidy-award="localValue"
              />

              <v-row class="d-flex align-center mt-4">
                <v-col class="ta-right">
                  <template v-if="mode === 'edit'">
                    <v-btn
                      @click="back()"
                      :loading="processing"
                      class="me-3"
                      variant="outlined"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      @click="submit()"
                      :loading="processing"
                      color="primary"
                    >
                      <span v-t="'Finish and submit'" />
                    </v-btn>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-vertical-item>
        </v-stepper-vertical>
      </v-card-text>
    </template>

    <v-divider class="mb-4" />

    <v-card-actions>
      <template v-if="localValue.submitted_at">
        <template v-if="mode === 'view'">
          <v-btn
            @click="mode = 'summary'"
            color="primary"
            size="x-large"
            variant="outlined"
          >
            <span>Close</span>
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            @click="view"
            :loading="processing"
            color="primary"
            size="x-large"
            variant="outlined"
          >
            <span>Review</span>
          </v-btn>

          <v-spacer />

          <template v-if="localValue.closed_at">
            <v-btn
              @click="openAward"
              :loading="processing"
              color="red"
              size="x-large"
              variant="text"
            >
              <span>Open</span>
            </v-btn>
          </template>

          <template v-else>
            <v-btn
              @click="closeDialog.open(localValue)"
              :loading="processing"
              class="ms-3 px-4"
              color="primary"
              prepend-icon="done_outline"
              size="x-large"
            >
              <span>Close award</span>
            </v-btn>
          </template>
        </template>
      </template>
      <template v-else>
        <v-btn
          @click="destroy"
          :loading="processing"
          class="me-3 px-4"
          color="red"
          size="x-large"
        >
          <span>Cancel</span>
        </v-btn>
      </template>
    </v-card-actions>

    <ResourceDialog
      @save="closeAward"
      ref="closeDialog"
      :fields="closeFields"
      :max-width="700"
      :processing="processing"
      title="Close award"
    />

    <AgreementDialog
      ref="viewAgreementDialog"
      role="organization"
    />
  </v-card>
</template>

<script setup>
import AgreementDialog from '@/shared/components/AgreementDialog.vue';
import api from '@/specialist/services/bright_finder';
import closeFormSchema from '@/specialist/services/close-subsidy-award-form-schema';
import AgreementCard from '@/specialist/components/AgreementCard.vue';
import LabeledCurrencyInput from '@/shared/components/form/LabeledCurrencyInput.vue';
import LongDate from '@/shared/components/LongDate.vue';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import PaymentEditor from '@/specialist/components/PaymentEditor.vue';
import PaymentTable from '@/specialist/components/PaymentTable.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import SubsidyAwardSummaryCard from '@/specialist/components/subsidy/SubsidyAwardSummaryCard.vue';
import { currency } from '@/plugins/filters';
import { VStepperVertical, VStepperVerticalItem } from 'vuetify/labs/VStepperVertical';
import { onMounted, ref } from 'vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();
const { updateQuery } = useRouterHelper();

const props = defineProps({
  subsidyAward: {
    type: Object,
    default: null,
  },
  subsidyProgram: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['destroy']);

const agreements = ref([]);
const changed = ref(false);
const closeFields = ref(closeFormSchema);
const enrollments = ref([]);
const groupMembers = ref(null);
const localValue = ref(JSON.parse(JSON.stringify(props.subsidyAward)));
const mode = ref(props.subsidyAward.submitted_at ? 'summary' : 'edit');
const payments = ref([]);
const processing = ref(false);
const providerMembers = ref(null);
const providers = ref([]);
const schemas = ref([]);
const step = ref(props.subsidyAward.step);
const draftAgreementDialog = ref(null);
const closeDialog = ref(null);

const agreementSchemas = computed(() =>
  schemas.value.filter((schema) => schema.data_type === 'Agreement' && schema.id),
);

const allMembers = computed(() => (groupMembers.value || []).concat(providerMembers.value || []));

const eligibleFunds = computed(() => localValue.value.funds.filter((fund) => fund.eligible));

const provider = computed(() =>
  providers.value.find((provider) => provider.id === localValue.value.provider_id),
);

const totalFundingAmount = computed(() => {
  if (localValue.value.funds.length > 0) {
    return Number(
      localValue.value.funds
        .map((item) => Number(item.amount))
        .reduce((amount, total) => amount + total, 0),
    ).toFixed(2);
  }
  return 0;
});

const totalPaidAwardAmount = computed(() => {
  if (localValue.value.scheduled_payments.length > 0) {
    return Number(
      localValue.value.scheduled_payments
        .map((item) => Number(item.amount))
        .reduce((amount, total) => amount + total, 0),
    ).toFixed(2);
  }
  return 0;
});

const totalPaidCopayAmount = computed(() => {
  if (localValue.value.scheduled_payments.length > 0) {
    return Number(
      localValue.value.scheduled_payments
        .map((item) => Number(item.copay))
        .reduce((amount, total) => amount + total, 0),
    ).toFixed(2);
  }
  return 0;
});

watch(
  () => localValue.value.provider_id,
  (newVal) => {
    if (newVal) {
      loadProviderMembers();
    } else {
      providerMembers.value = [];
    }
  },
);

onMounted(async () => {
  loadEnrollments();
  loadSchemas();
  loadAgreements();
  loadGroupMembers();
  await loadPayments();
});

async function closeAward(val) {
  processing.value = true;
  const resp = await api.organization.subsidy_award
    .close(localValue.value.id, {
      finish_date: val.finish_date,
      last_payment_date: val.last_payment_date,
      refund_amount: val.refund_amount,
      refund_date: val.refund_date,
    })
    .catch((error) => eventBus.error(error));
  processing.value = false;
  if (!resp) return;

  localValue.value = resp.data;
  await loadPayments();
  closeDialog.value.close();
  mode.value = 'summary';
}

async function continueToAgreements() {
  localValue.value.funding_calculation_requested = false;
  localValue.value.funding_reservation_requested = false;
  localValue.value.payment_calculation_requested = false;
  await saveAndContinue();
}

async function continueToFunding() {
  localValue.value.funding_calculation_requested = true;
  localValue.value.funding_reservation_requested = false;
  localValue.value.payment_calculation_requested = false;
  await saveAndContinue();
}

async function continueToPayments() {
  localValue.value.funding_calculation_requested = false;
  localValue.value.funding_reservation_requested = true;
  localValue.value.payment_calculation_requested = true;
  await saveAndContinue();
}

async function continueToSummary() {
  // eslint-disable-next-line no-alert, no-mixed-operators
  if (
    (agreements.value.length > 0 &&
      !!agreements.value.every((agreement) => !!agreement.submitted_at)) ||
    confirm('Are you sure you want to proceed without all agreements signed?')
  ) {
    await saveAndContinue();
  }
}

function back() {
  step.value -= 1;
}

function createAgreement(newAg) {
  processing.value = true;
  api.organization.agreement.create(
    {
      ...newAg,
      owner_id: localValue.value.id,
      owner_type: 'SubsidyAward',
    },
    (resp) => {
      agreements.value.push(resp.data);
      processing.value = false;
      draftAgreementDialog.value.close();
    },
    (err) => {
      eventBus.error(err.response.data.errors[0]);
      processing.value = false;
    },
  );
}

async function destroy() {
  // eslint-disable-next-line no-alert
  if (confirm('Are you sure you want to delete this award? This cannot be undone.')) {
    processing.value = true;
    await api.organization.subsidy_award.destroy(localValue.value.id);
    emit('destroy');
  }
}

function getBalance(index) {
  const scheduled_payments = localValue.value.scheduled_payments
    .slice(0, index + 1)
    .map((item) => Number(item.amount));
  const paid = scheduled_payments.reduce((amount, sum) => sum + amount, 0).toFixed(2);
  return localValue.value.total_award_amount - paid;
}

function getFundBalance(index) {
  const funds = eligibleFunds.value.slice(0, index + 1).map((item) => Number(item.amount));
  const committed = funds.reduce((amount, sum) => sum + amount, 0);
  return localValue.value.total_award_amount - committed;
}

function getProviders() {
  enrollments.value.forEach((er) => {
    if (!providers.value.find((provider) => provider.id === er.provider_id)) {
      api.public_api.provider.get(er.provider_id, (resp) => {
        providers.value.push(resp.data);
      });
    }
  });
}

function getRecipient(val) {
  if (val.schema_id) {
    const role = agreementSchemas.value.find((schema) => schema.id === val.schema_id).meta
      .signer_role;
    if (role === 'Provider') {
      return providerMembers.value.map((member) => ({ text: member.name, value: member.id }));
    }
    return groupMembers.value.map((member) => ({ text: member.name, value: member.id }));
  }
  return [];
}

function loadAgreements() {
  processing.value = true;
  api.organization.agreement.index({ owner_id: localValue.value.id }, (resp) => {
    agreements.value = resp.data;
    processing.value = false;
  });
}

function loadEnrollments() {
  api.organization.enrollment.index({ subsidy_id: localValue.value.subsidy_id }, (resp) => {
    enrollments.value = resp.data;
    processing.value = false;

    getProviders();
  });
}

function loadGroupMembers() {
  api.organization.member.index({ group_id: localValue.value.group_id }, (resp) => {
    groupMembers.value = resp.data;
  });
}

async function loadPayments() {
  const resp = await api.organization.payment.index({
    application_award_id: localValue.value.id,
  });
  payments.value = resp.data;
  processing.value = false;
}

function loadProviderMembers() {
  api.organization.member.index({ provider_id: localValue.value.provider_id }, (resp) => {
    providerMembers.value = resp.data;
  });
}

function loadSchemas() {
  api.public_api.organization.schema.index((resp) => {
    schemas.value = resp.data;
  });
}

async function openAward() {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you certain you want to RE-OPEN this award?')) return;

  processing.value = true;
  const resp = await api.organization.subsidy_award
    .open(localValue.value.id)
    .catch((error) => eventBus.error(error));
  if (!resp) return;

  localValue.value = resp.data;
  await loadPayments();
  processing.value = false;
  mode.value = 'summary';
}

async function recalculateAward() {
  localValue.value.award_calculation_requested = true;
  await save();
}

async function recalculateFunding() {
  localValue.value.funding_calculation_requested = true;
  await save();
}

async function recalculatePayments() {
  localValue.value.payments_calculation_requested = true;
  await save();
}

async function save() {
  processing.value = true;
  const params = {
    award_calculation_requested: localValue.value.award_calculation_requested,
    award_payment_amount: localValue.value.award_payment_amount,
    award_payment_period: localValue.value.award_payment_period,
    copay_payment_amount: localValue.value.copay_payment_amount,
    finish_date: localValue.value.finish_date,
    funding_calculation_requested: localValue.value.funding_calculation_requested,
    funding_reservation_requested: localValue.value.funding_reservation_requested,
    funds: localValue.value.funds,
    payment_calculation_requested: localValue.value.payment_calculation_requested,
    scheduled_payments: localValue.value.scheduled_payments,
    provider_id: localValue.value.provider_id,
    start_date: localValue.value.start_date,
    step: localValue.value.step,
    submit_requested: localValue.value.submit_requested,
    tuition_payment_amount: localValue.value.tuition_payment_amount,
  };
  const resp = await api.organization.subsidy_award
    .update(localValue.value.id, params)
    .catch((err) => {
      processing.value = false;
      this.$eventBus.$emit('error', err);
    });
  if (!resp) return;

  localValue.value = resp.data;
  localValue.value.funding_calculation_requested = false;
  localValue.value.funding_reservation_requested = false;
  localValue.value.award_calculation_requested = false;
  localValue.value.payment_calculation_requested = false;
  changed.value = false;
  processing.value = false;
}

async function saveAndContinue() {
  localValue.value.step = step.value + 1;
  await save();
  step.value += 1;
}

async function submit() {
  localValue.value.submit_requested = true;
  await save();
  mode.value = 'summary';
  await loadPayments();
}

function view() {
  step.value = 1;
  mode.value = 'view';
}
</script>
