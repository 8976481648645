<template>
  <LabeledControl
    :cols="cols"
    :message="message"
  >
    <Codemirror
      @update:model-value="handleUpdateModelValue"
      :disabled="locked"
      :extensions="extensions"
      :model-value="localValue"
    />
  </LabeledControl>
</template>

<script>
import Labeled from '@/shared/mixins/labeled';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';
import Schematized from '@/shared/mixins/schematized';
import { Codemirror } from 'vue-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { tomorrow } from 'thememirror';

const extensions = [javascript(), tomorrow];

export default {
  compatConfig: { MODE: 3 },

  components: {
    Codemirror,
    LabeledControl,
  },

  mixins: [Labeled, Schematized],

  data() {
    return {
      extensions,
    };
  },
};
</script>

<style scoped>
.v-codemirror {
  font-size: 14px;
}
</style>
