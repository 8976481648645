<template>
  <div>
    <ResourceDialog
      @save="$emit('save', $event)"
      ref="editorDialog"
      :max-width="600"
      :processing="processing"
      title="Manage columns"
    >
      <template #form="{ localValue }">
        <v-row dense>
          <v-col>
            <DataItem
              v-for="(column, index) in localValue"
              @remove="localValue.splice(index, 1)"
              :key="index"
              :header="column.data_type"
              :name="column.alias_name"
              block
              dense
            />

            <p
              v-if="localValue.length == 0"
              v-t="'No columns have been added yet.'"
              class="fs-14"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col class="d-flex">
            <v-btn
              @click="$refs.addDialog.open()"
              color="primary"
              variant="flat"
            >
              <span v-t="'Add'" />
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @save="handleAdd"
      ref="addDialog"
      save-button-text="Add"
      title="Select column"
      close-on-save
    >
      <template #form="{ localValue }">
        <LabeledAutocomplete
          v-model="localValue.value"
          :item-title="(item) => `${item.data_type} - ${item.alias_name}`"
          :items="addableColumns"
          message="Column"
          clearable
          return-object
        />
      </template>
    </ResourceDialog>
  </div>
</template>

<script setup>
import DataItem from '@/shared/components/form/DataItem.vue';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { v4 as uuid } from 'uuid';

defineEmits(['save']);

const props = defineProps({
  allowedProperties: {
    type: Array,
    default: () => [],
  },
  processing: Boolean,
});

const addDialog = ref(null);
const editorDialog = ref(null);

const addableColumns = computed(() => {
  const existingColumns = editorDialog.value.getLocalValue();

  if (Object.keys(existingColumns).length === 0) {
    return props.allowedProperties;
  }

  return props.allowedProperties.filter(
    (prop) =>
      existingColumns.findIndex(
        (col) =>
          `${prop.data_type}_${prop.property_name}` === `${col.data_type}_${col.property_name}`,
      ) === -1,
  );
});

function close() {
  editorDialog.value.close();
}

function handleAdd(newVal) {
  const existingColumns = editorDialog.value.getLocalValue();
  const key = uuid().replaceAll('-', '');
  const newColumn = { ...newVal.value, key };
  editorDialog.value.setLocalValue(existingColumns.concat([newColumn]));
}

function open(existingValues) {
  editorDialog.value.open(existingValues);
}

defineExpose({ open, close });
</script>
