<template>
  <div v-if="draft && schemas">
    <div v-if="draft.id">
      <div v-if="draft.definition">
        <template v-if="draft.definition.properties.custom">
          <v-card
            class="mb-4"
            color="transparent"
            dense
            flat
          >
            <SchemaElement
              @change="updateElement('custom', $event)"
              :definition="draft.definition"
              :depth="0"
              :details="false"
              :index="0"
              :is-child="false"
              :schemas="schemas"
              field="custom"
              title="Custom fields"
              children-removable
              open-now
            />
          </v-card>
        </template>

        <v-divider class="my-6" />

        <v-card
          v-if="draft.definition"
          class="mb-4"
          color="transparent"
          dense
          flat
        >
          <v-card-title class="fs-20 fw-500 px-0"> Standard fields </v-card-title>
          <v-card-text class="px-0 bg-white py-0">
            <v-expansion-panels
              class="bc-outlined-grey b-1"
              flat
              tile
            >
              <SchemaElement
                v-for="(entry, index) in standardEntries"
                @change="updateElement(entry[0], $event)"
                :key="index"
                :definition="draft.definition"
                :depth="1"
                :field="entry[0]"
                :index="index"
                :schemas="schemas"
                children-removable
                lock-format
                lock-type
              />
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </div>

      <SaveBar
        @cancel="cancel"
        @save="save"
        :model-value="changed"
        :processing="processing"
      />
    </div>
  </div>
</template>

<script setup>
import baseApi from '@/shared/services/bright_finder';
import organizationApi from '@/specialist/services/bright_finder';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import SchemaElement from '@/admin/components/schemas/SchemaElement.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const props = defineProps({
  schema: {
    type: Object,
    default: null,
  },
});

const changed = ref(false);
const draft = ref(null);
const standardEntries = ref(null);
const processing = ref(false);
const schemas = ref(null);

const cancel = () => {
  window.location.reload();
};

const load = () => {
  baseApi.public_api.organization.schema.index((resp) => {
    schemas.value = resp.data;
  });
};

const save = () => {
  processing.value = true;
  organizationApi.organization.schema.update(
    draft.value.id,
    draft.value,
    () => {
      processing.value = false;
      changed.value = false;
    },
    (err) => {
      processing.value = false;
      eventBus.chime(err.response.data.errors[0]);
    },
  );
};

const setDraft = (schema) => {
  draft.value = null;
  standardEntries.value = null;
  processing.value = true;

  setTimeout(() => {
    draft.value = JSON.parse(JSON.stringify(schema));
    if (draft.value.definition) {
      standardEntries.value = Object.entries(draft.value.definition.properties).filter(
        (entry) => !['custom', 'sources'].includes(entry[0]),
      );
    }
    processing.value = false;
    changed.value = false;
  }, 250);
};

const updateElement = (element, newVal) => {
  draft.value.definition.properties[element] = newVal;
  changed.value = true;
};

onMounted(() => {
  load();
  setDraft(props.schema);
});
</script>
