<template>
  <v-card
    :to="{ name: 'SiteTemplateShow', params: { siteTemplateId: siteTemplate.id } }"
    border
  >
    <v-card-text>
      <v-row>
        <v-col>
          <v-img
            :src="imgSrc"
            class="d-inline-block va-middle me-3"
            width="20px"
          />
          <span
            v-text="siteTemplate.name"
            class="fs-18 fw-600 c-black va-middle"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import useAssets from '@/shared/composables/useAssets';

const $a = useAssets();

const props = defineProps({
  siteTemplate: {
    type: Object,
    default: () => ({}),
  },
});

const imgSrc = computed(() => {
  if (props.siteTemplate.site_type === 'parent') {
    return $a.url('images.search');
  }
  if (props.siteTemplate.site_type === 'manager') {
    return $a.url('images.providers');
  }
  if (props.siteTemplate.site_type === 'specialist') {
    return $a.url('images.organizations');
  }
  return null;
});
</script>
