<template>
  <v-card class="pa-6">
    <v-row dense>
      <v-col cols="12">
        <div class="fs-28 fw-600 c-black mb-3">Danger</div>
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
      >
        <div class="fs-18 fw-600 c-black">Be careful! This cannot be undone.</div>
      </v-col>
      <v-col cols="12">
        <v-btn
          @click="destroy()"
          color="red"
          rounded
        >
          Delete page
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import Api from '@/admin/services/bright_finder';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';

const props = defineProps({
  siteTemplate: {
    type: Object,
    required: true,
  },
  pageTemplate: {
    type: Object,
    required: true,
  },
});

const router = useRouter();
const eventBus = useEventBus();

function destroy() {
  Api.organization.siteTemplate.pageTemplate.destroy(
    props.siteTemplate.id,
    props.pageTemplate.id,
    () => {
      router.push({
        name: 'SiteTemplateShow',
        params: { siteTemplateId: props.siteTemplate.id },
      });
    },
    (err) => {
      eventBus.chime(err.response.data.errors[0]);
    },
  );
}
</script>
