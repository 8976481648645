<template>
  <v-container
    v-if="contentTemplates"
    class="mxw-1200 pa-6"
  >
    <v-row>
      <v-col>
        <div class="fw-600 bc-h5">Content Templates</div>
      </v-col>
      <v-col
        v-if="contentTemplates.length > 0"
        class="ta-right"
      >
        <v-btn
          @click="$refs.createDialog.open()"
          color="primary"
        >
          Create content template
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="contentTemplates.length == 0"
      class="d-flex justify-center"
      style="margin-top: 15%"
    >
      <v-col class="ta-center">
        <div class="fw-600 fs-20 mb-4">You do not have any content templates.</div>
        <v-btn
          @click="$refs.createDialog.open()"
          color="primary"
          size="x-large"
          rounded
        >
          Create content template
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-for="(ct, index) in contentTemplates"
      :key="index"
    >
      <v-col>
        <v-card
          border
          flat
          tile
        >
          <v-card-text>
            <v-row
              @click="handleNavigationToLocalizedIndex(ct)"
              class="d-flex align-center"
            >
              <v-col>
                <span v-text="ct.attributes.name" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ResourceDialog
      @save="create"
      ref="createDialog"
      :fields="contentTemplateFields"
      title="New content template"
    />
  </v-container>
</template>

<script setup>
import { useRouter } from 'vue-router';
import Api from '@/specialist/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

const router = useRouter();

const contentTemplates = ref();
const contentTemplateFields = ref([
  { text: 'Name', value: 'name' },
  {
    text: 'Communication type',
    value: 'communication_type',
    items: ['email', 'text'],
    required: true,
  },
]);

// refs
const createDialog = ref(null);

onMounted(load);

function handleNavigationToLocalizedIndex(contentTemplate) {
  router.push({
    name: 'LocalizedContentTemplateIndex',
    params: { contentTemplateId: contentTemplate.id },
  });
}

async function create(attributes) {
  try {
    const res = await Api.organization.content_template.create(attributes);
    const { data } = res.data;

    contentTemplates.value.push(data);
  } finally {
    createDialog.value.close();
  }
}

async function load() {
  const res = await Api.organization.content_template.index();
  const { data } = res.data;

  contentTemplates.value = data || [];
}
</script>
