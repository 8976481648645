<template>
  <v-row
    v-if="opening"
    class="d-flex align-center"
  >
    <v-col class="d-flex align-center justify-start">
      <template v-if="opening">
        <v-icon
          class="c-green me-2"
          size="24"
        >
          check_circle
        </v-icon>
        <div class="d-inline-block fs-16 fw-500">
          {{ $t('Openings available:') }} <strong>{{ program.name }}</strong>
        </div>
      </template>
      <template v-else>
        <v-icon
          class="c-green me-2"
          size="24"
        >
          minus
        </v-icon>
      </template>
    </v-col>
    <v-col
      v-if="!readonly"
      class="d-flex align-center justify-end"
    >
      <v-btn
        @click="$emit('reserve', [program, opening])"
        :loading="processing"
        color="primary"
      >
        {{ $t('Reserve opening') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    openings: {
      type: Array,
      default: null,
    },
    processing: Boolean,
    program: {
      type: Object,
      default: null,
    },
    readonly: Boolean,
  },

  emits: ['reserve'],

  computed: {
    opening() {
      return this.openings.find((opening) => opening.programs.includes(this.program.id));
    },
  },
};
</script>
