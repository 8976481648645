<template>
  <FilterMenu
    :active="filters.team_ids?.length > 0"
    classes="d-none d-md-inline-flex"
    title="Team"
    paddingless
  >
    <template #card>
      <v-autocomplete
        v-model="filters.team_ids"
        @update:model-value="handleFilterChange()"
        :aria-label="$t('Filter by team')"
        :closable-chips="true"
        :disabled="processing"
        :items="teams"
        :menu="true"
        :placeholder="$t('Filter by team')"
        density="compact"
        item-title="name"
        item-value="id"
        prepend-inner-icon="search"
        variant="filled"
        autofocus
        chips
        clearable
        multiple
      >
        <template #chip="{ item, props }">
          <v-chip v-bind="props">
            <template #prepend>
              <v-avatar
                :color="item.raw.color"
                start
              />
            </template>
            {{ item.raw.name }}
          </v-chip>
        </template>

        <template #item="{ item, props }">
          <v-list-item v-bind="props">
            <template #prepend="{ isSelected }">
              <v-checkbox-btn
                :key="item.value"
                :model-value="isSelected"
                :ripple="false"
                data-testid="team-filter-checkbox"
                tabindex="-1"
              />
            </template>

            <template #append>
              <v-avatar
                :color="item.raw.color"
                size="20"
              />
            </template>
          </v-list-item>
        </template>
      </v-autocomplete>
    </template>
  </FilterMenu>
</template>

<script setup>
import FilterMenu from '@/shared/components/form/FilterMenu.vue';

const filters = defineModel({ required: true });

defineProps({
  teams: {
    type: Array,
    required: true,
  },
  processing: {
    type: Boolean,
    required: true,
  },
  handleFilterChange: {
    type: Function,
    required: true,
  },
});
</script>
