<template>
  <div fluid>
    <PageTitle
      :title="title"
      flat
      outlined
    />

    <v-container fluid>
      <v-card
        class="mb-4"
        border
        flat
        tile
      >
        <v-card-text>
          <v-row>
            <div class="mt-2 v-col v-col-12">
              <v-row dense>
                <div class="labeled-input v-col v-col-12">Job type</div>
                <div class="col">
                  <v-chip color="#205c6f">
                    {{ jobType }}
                  </v-chip>
                </div>
              </v-row>
            </div>

            <LabeledTextfield
              v-model="jobConfig.attributes.name"
              :hard-lock="!!jobConfig.attributes.enabled_at"
              message="Name"
              filled
              mandatory
            />

            <LabeledSimpleSelect
              v-model="jobConfig.content_template_id"
              :hard-lock="!!jobConfig.attributes.enabled_at"
              :items="contentTemplates"
              class="mt-2"
              item-title="attributes.name"
              item-value="id"
              message="Content template"
              mandatory
            />

            <LabeledSimpleSelect
              v-model="jobConfig.site_id"
              :hard-lock="!!jobConfig.attributes.enabled_at"
              :items="sites"
              class="mt-2"
              item-title="name"
              item-value="id"
              message="Site"
              mandatory
            />

            <v-card
              v-if="showTestText"
              class="ml-2 mt-2 v-col-8"
              border
              flat
              tile
            >
              <v-card-title>
                {{ t('Test your text message content') }}
              </v-card-title>
              <v-divider />
              <v-card-text>
                <div class="fs-16 c-light-black mb-3">
                  {{ t('Test your text message content before sending to recipients.') }}
                </div>
                <v-row class="mb-3">
                  <LabeledTextfield
                    v-model="previewPhone"
                    mask="(###) ###-####"
                    message="Recipient phone number:"
                  />
                </v-row>

                <v-btn
                  @click="sendPreviewText"
                  :loading="previewProcessing"
                  color="primary"
                >
                  <span v-t="'Send text message'" />
                </v-btn>
              </v-card-text>
            </v-card>

            <LabeledSwitch
              v-model="jobConfig.recurring"
              :hard-lock="!!jobConfig.attributes.enabled_at"
              subtitle="Allow this job to be run on a scheduled basis"
              title="Recurring job"
            />

            <v-card-text v-if="jobConfig.recurring">
              <v-row class="inset-card">
                <div class="mt-2 v-col v-col-12">
                  <v-row dense>
                    <div class="labeled-input v-col v-col-12">Frequency:</div>
                    <div
                      class="col"
                      data-testid="frequency-select"
                    >
                      <v-radio-group
                        v-model="jobConfig.attributes.frequency"
                        class="mt-0"
                        hide-details
                      >
                        <v-radio
                          v-for="freq in frequencies"
                          :key="freq"
                          :label="freq"
                          :value="freq"
                          color="primary"
                          hide-details
                        />
                      </v-radio-group>
                    </div>
                  </v-row>
                </div>

                <LabeledSimpleSelect
                  v-if="jobConfig.attributes.frequency == 'monthly'"
                  v-model="jobConfig.attributes.mday"
                  :hard-lock="!!jobConfig.attributes.enabled_at"
                  :items="mdayOptions"
                  :message="t('Day')"
                  class="mt-2"
                  mandatory
                />
                <LabeledSimpleSelect
                  v-else
                  v-model="jobConfig.attributes.weekday"
                  :hard-lock="!!jobConfig.attributes.enabled_at"
                  :items="weekdayOptions"
                  :message="t('Day')"
                  class="mt-2"
                  mandatory
                />

                <LabeledSimpleSelect
                  v-model="jobConfig.attributes.utc_hour"
                  :hard-lock="!!jobConfig.attributes.enabled_at"
                  :items="utcHourOptions"
                  class="mt-2"
                  message="UTC hour"
                  mandatory
                />

                <LabeledSimpleSelect
                  v-model="jobConfig.attributes.utc_min"
                  :hard-lock="!!jobConfig.attributes.enabled_at"
                  :items="utcMinOptions"
                  class="mt-2"
                  message="UTC minute"
                  mandatory
                />

                <div
                  v-if="localeDateTime"
                  class="col"
                >
                  <v-chip color="grey">
                    Example:&nbsp; <LongDateTime :date="localeDateTime.toString()" />
                  </v-chip>
                </div>
              </v-row>
            </v-card-text>

            <LabeledSwitch
              v-model="jobConfig.attributes.enabled"
              subtitle="Enable this job. Click “Run” to send if no schedule is set. Must be disabled to make changes."
              title="Job enabled"
            />
          </v-row>
        </v-card-text>

        <v-divider class="mb-4" />

        <v-card-actions>
          <v-btn
            @click="update"
            color="primary"
            size="x-large"
          >
            {{ t('Save') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="destroy"
            color="red"
            variant="text"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
        border
        flat
        tile
      >
        <v-card-title>Job runs</v-card-title>

        <v-divider class="mb-2" />

        <v-card-text v-if="jobConfig.attributes.schedule_type === 'on_demand'">
          <div
            v-t="'Clicking run will send this instantly.'"
            class="fs-16 fw-500 mb-4"
          />
          <div>
            <v-btn
              @click="handleRunJob"
              :disabled="!!jobConfig.attributes.enabled_at === false"
              :loading="runProcessing"
              class="mx-2"
              color="primary"
              variant="outlined"
            >
              Run
            </v-btn>
          </div>
        </v-card-text>

        <v-data-table
          :headers="headers"
          :items="scheduledJobs"
          class="elevation-1"
        >
          <template #item.completed_at="{ item }">
            <div v-if="item.completed_at">
              <v-chip color="green">
                <LongDateTime :date="item.completed_at" />
              </v-chip>
            </div>

            <div v-else>
              <v-chip> N/A </v-chip>
            </div>
          </template>

          <template #item.schedule_at="{ item }">
            <LongDateTime :date="item.schedule_at" />
          </template>

          <template #item.canceled_at="{ item }">
            <div v-if="item.canceled_at">
              <LongDateTime :date="item.canceled_at" />
            </div>

            <div v-else>
              <v-chip> N/A </v-chip>
            </div>
          </template>

          <template #item.errored_at="{ item }">
            <div v-if="item.errored_at">
              <v-chip color="red">
                <LongDateTime :date="item.errored_at" />
              </v-chip>
            </div>

            <div v-else>
              <v-chip> N/A </v-chip>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import Api from '@/specialist/services/bright_finder.js';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import { toSentenceCase } from '@/shared/services/string_utils';
import PageTitle from '@/shared/components/PageTitle.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const contentTemplates = ref([]);
const frequencies = ref([t('monthly'), t('weekly')]);
const sites = ref([]);
const jobConfig = ref({
  attributes: {
    name: null,
    job_type: null,
    enabled_at: null,
    enabled: null,
    schedule_type: null,
    mday: null,
    frequency: null,
    utc_hour: null,
    utc_min: null,
  },
  recurring: false,
  id: null,
  content_template_id: null,
  site_id: null,
});
const mdayOptions = ref([]);
const weekdayOptions = ref([
  t('Sunday'),
  t('Monday'),
  t('Tuesday'),
  t('Wednesday'),
  t('Thursday'),
  t('Friday'),
  t('Saturday'),
]);
const utcHourOptions = ref([]);
const utcMinOptions = ref([0, 15, 30, 45]);
const runProcessing = ref(false);
const headers = ref([
  { title: 'Completed at', key: 'completed_at' },
  { title: 'Scheduled at', key: 'schedule_at' },
  { title: 'Canceled at', key: 'canceled_at' },
  { title: 'Errored at', key: 'errored_at' },
]);
const scheduledJobs = ref([]);
const localeDateTime = ref(null);
const previewPhone = ref('');
const previewProcessing = ref(false);
const selectedContentTemplate = ref();

const showTestText = computed(() => {
  return (
    contentTemplates.value.find((ct) => ct.id === jobConfig.value.content_template_id)?.attributes
      ?.communication_type === 'text'
  );
});

const title = computed(() => {
  return `Edit job config / ${jobConfig.value.attributes.name}`;
});

const jobType = computed(() => {
  return toSentenceCase(jobConfig.value.attributes.job_type);
});

const recurringPropsWatchable = computed(() => {
  // watch multiple properties Vue2 https://github.com/vuejs/vue/issues/844#issuecomment-265315349
  const { mday, utc_hour: utcHour, utc_min: utcMin } = jobConfig.value.attributes;
  // eslint-disable-next-line no-sequences
  return mday, utcHour, utcMin, Date.now();
});

watch(recurringPropsWatchable, () => {
  localeDateTimeString();
});

onMounted(async () => {
  await load();
  populateRecurringOptions();
});

async function loadContentTemplates() {
  const res = await Api.organization.content_template.index();
  const { data } = res.data;

  contentTemplates.value == data || [];
}

function populateRecurringOptions() {
  mdayOptions.value == Array.from({ length: 31 }, (_, i) => i + 1);
  utcHourOptions.value == [...Array(24).keys()]; // Adds 23
}

function localeDateTimeString() {
  const { mday, utc_hour: utcHour, utc_min: utcMin } = jobConfig.value.attributes;

  if (mday) {
    const date = new Date();
    date.setUTCDate(mday);
    date.setUTCHours(utcHour);
    date.setUTCMinutes(utcMin);

    localeDateTime.value == date;
  }

  return null;
}

async function loadSites() {
  // Site endpoint still uses callback signature

  await Api.organization.site.index({}, (resp) => {
    sites.value == resp.data;
  });
}

async function handleRunJob() {
  const { id } = jobConfig.value;

  try {
    runProcessing.value == true;
    await Api.organization.job_run.create({}, id);
  } finally {
    runProcessing.value == false;
  }
}

async function load() {
  const { jobConfigId } = route.params;
  const res = await Api.organization.job_config.get(jobConfigId);
  const { data } = res.data;

  const { attributes, relationships } = data;
  const { content_template: contentTemplate, site } = relationships;

  await loadContentTemplates();
  await loadSites();

  jobConfig.value = {
    attributes: {
      ...attributes,
      enabled: !!attributes.enabled_at,
    },
    recurring: attributes.schedule_type === 'recurring',
    content_template_id: contentTemplate?.data?.id,
    site_id: site?.data?.id,
    id: data?.id,
  };

  await loadScheduledJobs(data?.id);
}

async function loadScheduledJobs(contentTemplateId) {
  const response = await Api.organization.job_run.index({
    'filter[job_config]': contentTemplateId,
  });
  const { data } = response.data;

  const attributes = data.map((job) => job.attributes);
  scheduledJobs.value == attributes || [];
}

async function destroy() {
  const { id } = jobConfig.value;
  const res = await Api.organization.job_config.destroy(id);

  const { status } = res;

  if (status === 200) {
    router.push({ name: 'JobConfigIndex' });
    eventBus.chime('Job config deleted.');
  }
}

async function update() {
  const {
    id,
    attributes,
    recurring,
    content_template_id: contentTemplateId,
    site_id: siteId,
  } = jobConfig.value;

  const newAttributes = {
    ...attributes,
    schedule_type: recurring === true ? 'recurring' : 'on_demand',
  };

  const res = await Api.organization.job_config.update(
    id,
    newAttributes,
    contentTemplateId,
    siteId,
  );

  const { data } = res.data;

  if (data) {
    eventBus.chime('Job config updated.');
    await load();
  }
}

async function sendPreviewText() {
  selectedContentTemplate.value ==
    contentTemplates.value.find((ct) => ct.id === jobConfig.value.content_template_id);
  if (!selectedContentTemplate.value) {
    return null;
  }

  try {
    previewProcessing.value == true;
    await Api.organization.job_config.preview(jobConfig.value.id, previewPhone.value);
  } finally {
    previewProcessing.value == false;
  }
}
</script>

<style>
.inset-card {
  padding-left: 25px;
}
</style>
