<template>
  <v-container
    v-if="dashboard"
    class="mxw-1400"
  >
    <TitleBar
      :back-route="{ name: 'AnalyticsIndex', query: { dashboardId: dashboard.id } }"
      :name="dashboard.name"
      :should-wrap-text="true"
      back-name="Analytics"
    >
      <template #inline-actions>
        <ActionMenu
          @click:action:delete="destroy()"
          @click:action:rename="$refs.renameDialog.open({ name: dashboard.name })"
          :items="actionItems"
        />
      </template>
      <template #actions>
        <v-btn
          @click="addMetric"
          color="primary"
          variant="flat"
        >
          <v-icon>add</v-icon>
          <span v-t="'Add metric'" />
        </v-btn>
      </template>
    </TitleBar>

    <ResourceDialog
      @save="update"
      ref="renameDialog"
      :fields="[{ text: 'Name', value: 'name' }]"
      :processing="processing"
      title="Rename dashboard"
    />

    <div v-if="metrics && metrics.length > 0">
      <v-divider class="my-4" />

      <v-row class="mb-8">
        <v-col>
          <grid-layout
            v-model:layout="dashboard.layouts.lg"
            :auto-size="true"
            :row-height="50"
          >
            <grid-item
              v-for="item in dashboard.layouts.lg"
              @moved="changed = true"
              @resized="changed = true"
              :key="item.i"
              :h="item.h"
              :i="item.i"
              :w="item.w"
              :x="item.x"
              :y="item.y"
              drag-allow-from=".draggable-handle"
              drag-ignore-from=".no-drag"
            >
              <MetricCard
                @destroy="destroyMetric(item.i)"
                @duplicate="duplicateMetric(item.i)"
                @edit="editMetric(item.i)"
                :metric="metrics.find((metric) => metric.id == item.i)"
              />
            </grid-item>
          </grid-layout>
        </v-col>
      </v-row>

      <v-divider class="my-8" />
    </div>

    <v-row
      v-else
      class="d-flex align-center justify-center px-2"
    >
      <v-col cols="3">
        <v-img :src="$a.url('icons.nullstate')" />
      </v-col>
    </v-row>

    <v-dialog
      v-model="metricDialogIsVisible"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="d-flex">
          <span>Metric</span>
          <v-spacer />
          <v-btn
            @click="metricDialogIsVisible = false"
            :aria-label="$t('Close')"
            icon="close"
            variant="text"
          />
        </v-card-title>
        <v-card-text>
          <v-row>
            <LabeledTextfield
              v-model="selectedMetric.name"
              cols="12"
              message="Name"
            />
            <LabeledAutocomplete
              v-model="selectedMetric.report_id"
              @update:search="reportQuery = $event"
              :items="reports"
              cols="12"
              item-title="name"
              item-value="id"
              message="Report"
            />
            <LabeledCustomSelect
              v-model="selectedMetric.chart_type"
              :items="chartOptions"
              cols="6"
              message="Chart type"
            />
          </v-row>

          <template v-if="selectedMetric.report_id && reports && reports.length > 0">
            <v-row v-show="selectedMetric.chart_type == 'sum'">
              <LabeledAutocomplete
                v-model="selectedMetric.field"
                :items="selectedReportColumns"
                cols="12"
                item-title="key"
                item-value="key"
                message="Sum"
              />
            </v-row>

            <v-row v-show="selectedMetric.chart_type == 'time_series'">
              <LabeledAutocomplete
                v-model="selectedMetric.field"
                :items="selectedReportColumns"
                cols="12"
                item-title="key"
                item-value="key"
                message="Date field"
              />
            </v-row>

            <v-row v-show="['bar', 'pie-donut', 'pie'].includes(selectedMetric.chart_type)">
              <LabeledAutocomplete
                v-model="selectedMetric.group_by"
                :items="selectedReportColumns"
                cols="12"
                item-title="key"
                item-value="key"
                message="Group by"
              />
            </v-row>

            <v-row>
              <LabeledAutocomplete
                v-model="selectedMetric.date_by"
                :items="selectedReportColumns"
                cols="12"
                item-title="key"
                item-value="key"
                message="Date by"
              />
            </v-row>
          </template>

          <div class="d-flex align-center mt-8">
            <v-btn
              @click="metricDialogIsVisible = false"
              :loading="processing"
              color="primary"
              size="x-large"
              variant="text"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              @click="createOrUpdateMetric"
              :loading="processing"
              class="mb-4"
              color="primary"
              size="x-large"
            >
              Save
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <SaveBar
      @cancel="load"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />
  </v-container>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import LabeledCustomSelect from '@/shared/components/form/LabeledCustomSelect.vue';
import MetricCard from '@/admin/components/MetricCard.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { GridLayout, GridItem } from 'grid-layout-plus';

export default {
  compatConfig: { MODE: 3 },

  components: {
    GridItem,
    GridLayout,
    LabeledCustomSelect,
    MetricCard,
    ResourceDialog,
  },

  data() {
    return {
      actionItems: [
        { event: 'delete', title: 'Delete dashboard', avatar: 'delete' },
        { event: 'rename', title: 'Rename dashboard', avatar: 'edit' },
      ],
      changed: false,
      chartOptions: [
        { text: 'Count', value: 'count', icon: 'numbers' },
        { text: 'Sum', value: 'sum', icon: 'functions' },
        { text: 'Bar', value: 'bar', icon: 'bar_chart' },
        { text: 'Time series', value: 'time_series', icon: 'show_chart' },
        { text: 'Pie', value: 'pie', icon: 'pie_chart' },
        { text: 'Donut', value: 'pie-donut', icon: 'donut_small' },
        { text: 'Table', value: 'table', icon: 'format_list_numbered' },
      ],
      dashboard: null,
      metricDialogIsVisible: false,
      metrics: null,
      newField: null,
      newMetric: {},
      reportQuery: null,
      reportQueryCount: 0,
      reports: [],
      processing: false,
      selectedMetric: this.blankMetric(),
      selectedReportColumns: [],
    };
  },

  watch: {
    reportQuery(newVal) {
      if (newVal) {
        this.searchReports();
      }
    },

    // eslint-disable-next-line func-names
    'selectedMetric.report_id': function (newVal) {
      if (newVal) {
        this.loadSelectedReport();
      }
    },
  },

  created() {
    this.load();
  },

  methods: {
    addMetric() {
      this.selectedMetric = this.blankMetric();
      this.metricDialogIsVisible = true;
    },

    blankMetric() {
      return {
        chart_type: null,
        name: null,
        report_id: null,
      };
    },

    createMetric() {
      this.processing = true;
      this.selectedMetric.dashboard_id = this.dashboard.id;
      Api.organization.metric.create(
        this.selectedMetric,
        (resp) => {
          this.metrics.push(resp.data);
          let layout;
          if (this.selectedMetric.duplicated_metric_id) {
            layout = JSON.parse(
              JSON.stringify(
                this.dashboard.layouts.lg.find(
                  (metric) => metric.i === this.selectedMetric.duplicated_metric_id,
                ),
              ),
            );
            layout.i = resp.data.id;
          } else if (this.dashboard.layouts.lg.length === 0) {
            layout = {
              i: resp.data.id,
              x: 0,
              y: 0,
              w: 3,
              h: 3,
            };
          } else {
            // eslint-disable-next-line prefer-spread
            const y =
              Math.max.apply(
                Math,
                this.dashboard.layouts.lg.map((metric) => metric.y || 0),
              ) + 1;
            layout = {
              i: resp.data.id,
              x: 0,
              y,
              w: 3,
              h: 3,
            };
          }
          this.dashboard.layouts.lg.push(layout);
          this.save();
          this.metricDialogIsVisible = false;
          this.processing = false;
          this.$eventBus.$emit('chime', 'Saved');
          this.selectedMetric = this.blankMetric();
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.processing = false;
        },
      );
    },

    createOrUpdateMetric() {
      this.processing = true;
      if (this.selectedMetric.id) {
        this.updateMetric();
      } else {
        this.createMetric();
      }
    },

    destroy() {
      // eslint-disable-next-line no-alert
      if (confirm('Are you sure you want to remove this dashboard?')) {
        Api.organization.dashboard.destroy(this.dashboard.id, () =>
          this.$router.push({ name: 'AnalyticsIndex' }),
        );
      }
    },

    destroyMetric(id) {
      const metric = this.metrics.find((metric) => metric.id === id);
      // eslint-disable-next-line no-alert
      if (confirm('Are you sure you want to remove this metric?')) {
        this.processing = true;
        this.dashboard.layouts.lg.splice(
          this.dashboard.layouts.lg.findIndex((item) => item.i === metric.id),
          1,
        );
        this.save();

        Api.organization.metric.destroy(metric.id, () => {
          this.processing = false;
          this.load();
        });
      }
    },

    duplicateMetric(id) {
      const metric = this.metrics.find((metric) => metric.id === id);
      this.selectedMetric = JSON.parse(JSON.stringify(metric));
      this.selectedMetric.id = null;
      this.selectedMetric.duplicated_metric_id = metric.id;
      this.metricDialogIsVisible = true;
    },

    editMetric(id) {
      this.selectedMetric = this.metrics.find((metric) => metric.id === id);
      this.loadSelectedReport();
      this.metricDialogIsVisible = true;
    },

    load() {
      Api.organization.dashboard.get(this.$route.params.dashboardId, (resp) => {
        this.dashboard = resp.data;
        this.loadMetrics();
      });
    },

    loadMetrics() {
      Api.organization.metric.index({ dashboard_id: this.$route.params.dashboardId }, (resp) => {
        this.dashboard.layouts.lg
          .filter((item) => !resp.data.find((metric) => metric.id === item.i))
          .forEach((item) => {
            this.changed = true;
            this.dashboard.layouts.lg.splice(
              this.dashboard.layouts.lg.findIndex((index) => index.i === item.id),
              1,
            );
          });

        resp.data
          .filter((metric) => !this.dashboard.layouts.lg.find((item) => metric.id === item.i))
          .forEach((metric) => {
            this.changed = true;
            this.dashboard.layouts.lg.push({
              i: metric.id,
              x: 1,
              y: 1,
              w: 1,
              h: 1,
            });
          });

        this.metrics = resp.data;

        if (this.dashboard.layouts.lg.length === 0) {
          this.dashboard.layouts.lg = resp.data.map((metric) => ({
            i: metric.id,
            x: metric.x,
            y: metric.y || 0,
            w: metric.w,
            h: metric.h,
          }));
        }
      });
    },

    loadSelectedReport() {
      Api.organization.report.get(this.selectedMetric.report_id, (resp) => {
        this.reports = [resp.data];
        this.selectedReportColumns = resp.data.columns;
      });
    },

    removeField(field) {
      this.selectedMetric.fields.splice(this.selectedMetric.fields.indexOf(field), 1);
    },

    save() {
      this.processing = true;
      Api.organization.dashboard.update(
        this.$route.params.dashboardId,
        this.dashboard,
        (resp) => {
          this.dashboard = resp.data;
          this.processing = false;
          this.changed = false;
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.processing = false;
        },
      );
    },

    searchReports() {
      this.reportQueryCount += 1;
      const currentQueryCount = this.reportQueryCount;
      Api.organization.report.index({ query: this.reportQuery }, (resp) => {
        if (this.reportQueryCount === currentQueryCount) {
          this.reports.splice(0);
          this.reports.push(...resp.data);
        }
      });
    },

    update(vals) {
      this.processing = true;
      Api.organization.dashboard.update(
        this.$route.params.dashboardId,
        vals,
        (resp) => {
          this.dashboard = resp.data;
          this.processing = false;
          this.changed = false;
          this.$refs.renameDialog.close();
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.processing = false;
        },
      );
    },

    updateMetric(metric) {
      if (metric) {
        this.selectedMetric = metric;
      }
      this.processing = true;
      Api.organization.metric.update(
        this.selectedMetric.id,
        this.selectedMetric,
        (resp) => {
          this.selectedMetric.value = resp.data.value;
          this.metricDialogIsVisible = false;
          this.processing = false;
          this.loadMetrics();
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.processing = false;
        },
      );
    },
  },
};
</script>
