<template>
  <div tabindex="0">
    <div
      :class="divClass"
      class="pa-3 w-90pc b-radius-8"
    >
      <MarkdownContent
        :content="message.text"
        class="fs-16 fw-500 message-container"
      />
    </div>
    <LongDateTime
      :date="message.created_at"
      :prefix="message.meta.member_name + ' - '"
      class="fs-12"
    />
  </div>
</template>

<script setup>
import LongDateTime from '@/shared/components/LongDateTime.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  message: {
    type: Object,
    required: true,
  },
});

const isSelf = computed(() => props.message.member_id === store.state.profile.id);
const divClass = computed(() =>
  isSelf.value ? 'c-white bg-dark-blue' : 'c-black bg-super-light-blue',
);
</script>
