<template>
  <v-container
    v-if="jobConfigs"
    class="mxw-1200 pa-6"
  >
    <v-row>
      <v-col>
        <div class="fw-600 bc-h5">Job Config</div>
      </v-col>
      <v-col
        v-if="jobConfigs.length > 0"
        class="ta-right"
      >
        <v-btn
          @click="$refs.createDialog.open()"
          color="primary"
        >
          Create job config
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="jobConfigs.length == 0"
      class="d-flex justify-center"
      style="margin-top: 15%"
    >
      <v-col class="ta-center">
        <div class="fw-600 fs-20 mb-4">You do not have any job configs.</div>
        <v-btn
          @click="$refs.createDialog.open()"
          color="primary"
          size="x-large"
          rounded
        >
          Create job config
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-for="(jc, index) in jobConfigs"
      :key="index"
    >
      <v-col>
        <v-card
          border
          flat
          tile
        >
          <v-card-text>
            <v-row
              @click="handleNavigationToJobConfig(jc)"
              class="d-flex align-center"
            >
              <v-col>
                <span v-text="jc.attributes.name" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ResourceDialog
      @save="create"
      ref="createDialog"
      :fields="[
        { text: 'Name', value: 'name' },
        {
          text: 'Type',
          value: 'job_type',
          items: jobTypes,
          itemText: 'name',
          itemValue: 'value',
        },
        {
          text: 'Content template',
          value: 'content_template_id',
          items: contentTemplates,
          itemText: 'attributes.name',
          itemValue: 'id',
        },
        {
          text: 'Site',
          value: 'site_id',
          items: sites,
          itemText: 'name',
          itemValue: 'id',
        },
      ]"
      title="New job config"
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder.js';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const contentTemplates = ref([]);
const createDialog = ref();
const jobConfigs = ref([]);
const jobTypes = [
  { name: 'Attendance log reminder', value: 'attendance_log_reminders' },
  { name: 'Provider availability reminder', value: 'provider_availability_reminders' },
];
const sites = ref([]);

onMounted(async () => {
  await load();
});

function handleNavigationToJobConfig(jobConfig) {
  router.push({ name: 'JobConfigShow', params: { jobConfigId: jobConfig.id } });
}

async function create(resourceAttrs) {
  // Strip and restructure attributes per endpoint requirements
  const { name, job_type, content_template_id: contentTemplateId, site_id: siteId } = resourceAttrs;
  const attributes = { name, job_type };

  try {
    const res = await Api.organization.job_config.create(attributes, contentTemplateId, siteId);
    const { data } = res.data;

    jobConfigs.value.push(data);
  } finally {
    createDialog.value.close();
  }
}

async function loadContentTemplates() {
  const res = await Api.organization.content_template.index();
  const { data } = res.data;

  contentTemplates.value = data || [];
}

async function loadSites() {
  // Site endpoint still uses callback signature

  await Api.organization.site.index({}, (resp) => {
    sites.value = resp.data;
  });
}

async function load() {
  const res = await Api.organization.job_config.index();
  const { data } = res.data;

  await loadContentTemplates();
  await loadSites();

  jobConfigs.value = data || [];
}
</script>
