<template>
  <v-container
    v-if="familySubsidyProgram && familySubsidySchema"
    class="mxw-1200"
  >
    <TitleBar
      :back-name="familySubsidyProgram.name"
      :back-route="{ name: 'FamilySubsidyProgramIndex' }"
      name="Questions"
    />

    <v-expansion-panels
      v-model="open"
      class="mb-12"
      variant="accordion"
      flat
      multiple
      tile
    >
      <QuestionList
        @cancel="load"
        @change="load"
        @edit="edit($event)"
        @new="$refs.newQuestionDialog.open('FamilySubsidy', $event, true)"
        @new-section="$refs.newQuestionDialog.open('FamilySubsidy', $event, true, true)"
        @settings="settings($event)"
        :processing="processing"
        :questions="sortedEligibilityQuestions"
        :schemas="schemas"
        :selected-question="selectedQuestion"
        :verification="false"
        title="Eligibility questions"
      />

      <QuestionList
        @cancel="load"
        @change="load"
        @edit="edit($event)"
        @new="$refs.newQuestionDialog.open('FamilySubsidy', $event, false)"
        @settings="settings($event)"
        :processing="processing"
        :questions="sortedOtherQuestions"
        :schemas="schemas"
        :selected-question="selectedQuestion"
        :verification="false"
        title="Other questions"
      />

      <template v-if="familySubsidyProgram.enable_household_members">
        <v-expansion-panel
          :ripple="false"
          class="bg-transparent"
        >
          <v-expansion-panel-title disable-icon-rotate>
            <h2 class="text-h6 d-flex align-center">
              <v-avatar
                class="me-3"
                color="primary"
                size="30"
              >
                <span class="c-white fs-14">1</span>
              </v-avatar>
              <span v-t="'Add household members'" />
            </h2>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-card
              border
              tile
            >
              <v-card-text>
                <FormQuestion
                  :title="'List information for members in the household/family'"
                  dense
                  hide-actions
                  paddingless
                >
                  <HouseholdMembersCard
                    :value="[]"
                    staged
                  />
                </FormQuestion>
              </v-card-text>
            </v-card>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <QuestionList
          @cancel="load"
          @change="load"
          @edit="edit($event)"
          @new="$refs.newQuestionDialog.open('HouseholdMember', $event, false)"
          @settings="settings($event)"
          :processing="processing"
          :questions="sortedHouseholdMemberQuestions"
          :schemas="schemas"
          :selected-question="selectedQuestion"
          :verification="false"
          title="Household member questions"
        />
      </template>
    </v-expansion-panels>

    <NewQuestionDialog
      @created="load"
      ref="newQuestionDialog"
      :owner-id="familySubsidyProgram.id"
      :schema="familySubsidySchema"
      :schemas="schemas"
      owner-type="FamilySubsidyProgram"
    />

    <ProcessingOverlay :processing="processing" />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder.js';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import HouseholdMembersCard from '@/shared/components/household-members/HouseholdMembersCard.vue';
import LinkedList from '@/shared/services/linked-list';
import NewQuestionDialog from '@/shared/components/form/NewQuestionDialog.vue';
import ProcessingOverlay from '@/shared/components/ProcessingOverlay.vue';
import QuestionList from '@/admin/components/QuestionList.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const route = useRoute();
const store = useStore();

const detailsDrawer = ref(null);
const familySubsidyProgram = ref(null);
const familySubsidySchema = ref(null);
const householdMemberSchema = ref(
  Object.values(store.state.schemas).filter((schema) => schema.data_type === 'HouseholdMember')[0],
);
const open = ref([0, 1]);
const processing = ref(false);
const questions = ref([]);
const schemas = ref([]);
const selectedQuestion = ref(null);
const sortedEligibilityQuestions = ref([]);
const sortedOtherQuestions = ref([]);
const sortedHouseholdMemberQuestions = ref([]);

onMounted(async () => {
  await load();
});

async function load() {
  processing.value = true;
  selectedQuestion.value = null;
  await loadFamilySubsidyProgram();
  await loadSchema();
  await loadQuestions();
  processing.value = false;
}

async function loadFamilySubsidyProgram() {
  const resp = await Api.organization.family_subsidy_program.get(route.params.id);
  familySubsidyProgram.value = resp.data;
}

async function loadQuestions() {
  const filters = {
    owner_id: familySubsidyProgram.value.id,
    owner_type: 'FamilySubsidyProgram',
  };
  const resp = await Api.public_api.organization.question.index(filters);
  questions.value = resp.data;
  sortedEligibilityQuestions.value = sortQuestions(questions.value, 'FamilySubsidy', true);
  sortedOtherQuestions.value = sortQuestions(questions.value, 'FamilySubsidy', false);
  sortedHouseholdMemberQuestions.value = sortQuestions(questions.value, 'HouseholdMember', false);
  processing.value = false;
}

async function loadSchema() {
  const resp = await Api.organization.schema.get(
    familySubsidyProgram.value.family_subsidy_schema_id,
  );
  familySubsidySchema.value = resp.data;
  schemas.value = [familySubsidySchema.value, householdMemberSchema.value];
}

function edit(question) {
  selectedQuestion.value = question;
}

function filterQuestionsForModel(questions, syncedModel) {
  return questions.filter((question) => question.synced_model === syncedModel);
}

function sortQuestions(questions, syncedModel, eligibility) {
  return LinkedList.sort(
    filterQuestionsForModel(questions, syncedModel).filter(
      (question) => question.eligibility === eligibility,
    ),
  );
}

function settings(question) {
  selectedQuestion.value = question;
  detailsDrawer.value.open();
}
</script>
