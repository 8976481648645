<template>
  <span v-text="initials"></span>
</template>

<script setup>
import { toAcronym } from '@/shared/services/string_utils';

const props = defineProps({
  value: {
    type: String,
    default: null,
  },
});

const initials = computed(() => {
  if (!props.value) return null;
  return toAcronym(props.value);
});
</script>
