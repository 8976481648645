<template>
  <v-container
    v-if="collection"
    class="mxw-1200 pa-6"
  >
    <v-row>
      <v-col>
        <h1 class="fw-600 fs-24">
          <span v-t="`${terms.family_subsidy} ${terms.programs.toLowerCase()}`" />
        </h1>
      </v-col>
      <v-col
        v-if="collection.length > 0"
        class="ta-right"
      >
        <v-btn
          @click="$refs.newDialog.open()"
          color="primary"
          variant="flat"
        >
          <span v-t="'New'" />
        </v-btn>
      </v-col>
    </v-row>

    <template v-if="collection.length == 0">
      <NullState @new="$refs.newDialog.open({})" />
    </template>

    <v-row
      v-for="program in collection"
      :key="program.id"
    >
      <v-col cols="12">
        <v-card
          :ripple="false"
          :to="{ name: 'FamilySubsidyProgramShow', params: { id: program.id } }"
          border
          flat
          tile
        >
          <v-card-text>
            <div v-text="program.name" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ResourceDialog
      @save="create"
      ref="newDialog"
      :fields="fields"
      title="New"
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder.js';
import NullState from '@/shared/components/NullState.vue';
import useTerms from '@/shared/composables/useTerms';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const { terms } = useTerms();
const collection = ref(null);
const familySubsidySchemas = ref([]);
const groupSchemas = ref([]);
const processing = ref(false);

const fields = computed(() => [
  { text: 'Name', value: 'name' },
  {
    text: 'Group schema',
    value: 'group_schema_id',
    items: groupSchemas.value,
    itemText: 'name',
    itemValue: 'id',
  },
  {
    text: `${terms.family_subsidy} schema`,
    value: 'family_subsidy_schema_id',
    items: familySubsidySchemas.value,
    itemText: 'name',
    itemValue: 'id',
  },
]);

onMounted(async () => {
  await load();
  await loadSchemas();
});

async function create(newVal) {
  processing.value = true;
  const resp = await Api.organization.family_subsidy_program.create(newVal);
  processing.value = false;
  if (!resp?.data?.id) return;

  router.push({ name: 'FamilySubsidyProgramShow', params: { id: resp.data.id } });
}

async function load() {
  const resp = await Api.organization.family_subsidy_program.index();
  collection.value = resp.data;
}

async function loadSchemas() {
  const { data } = await Api.organization.schema.index();
  familySubsidySchemas.value = data.filter((schema) => schema.data_type === 'FamilySubsidy');
  groupSchemas.value = data.filter((schema) => schema.data_type === 'Group');
}
</script>
