<template>
  <ProgramMessaging
    @change="changed = $event"
    @load="load"
    @new-message-template="addMessageTemplate"
    @save="save"
    :back-route="{ name: 'FamilySubsidyProgramShow' }"
    :changed="changed"
    :event-names="eventNames"
    :owner-type="'FamilySubsidyProgram'"
    :processing="processing"
    :program="familySubsidyProgram"
  />
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ProgramMessaging from '@/admin/components/ProgramMessaging.vue';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import useEventBus from '@/shared/composables/useEventBus';

const changed = ref(true);
const eventNames = ref(null);
const familySubsidyProgram = ref({});
const familySubsidyProgramId = ref(null);
const processing = ref(false);
const eventBus = useEventBus();
const route = useRoute();

onMounted(async () => {
  processing.value = true;
  familySubsidyProgramId.value = route.params.id;
  await load();
});

function addMessageTemplate(messageTemplate) {
  familySubsidyProgram.value.message_templates.push(messageTemplate);
  changed.value = true;
}

async function load() {
  changed.value = false;

  await loadFamilySubsidyProgram();
}

async function loadFamilySubsidyProgram() {
  const response = await Api.organization.family_subsidy_program.get(familySubsidyProgramId.value);
  familySubsidyProgram.value = response.data;
  processing.value = false;
  eventNames.value = familySubsidyProgram.value.event_names;
}

async function save() {
  processing.value = true;
  const params = {
    id: route.params.id,
    enable_parent_subsidy_submitted_alert:
      familySubsidyProgram.value.enable_parent_subsidy_submitted_alert,
    parent_subsidy_submitted_alert_subject_template:
      familySubsidyProgram.value.parent_subsidy_submitted_alert_subject_template,
    parent_subsidy_submitted_alert_template:
      familySubsidyProgram.value.parent_subsidy_submitted_alert_template,
    message_templates: familySubsidyProgram.value.message_templates,
  };

  const response = await Api.organization.family_subsidy_program
    .update(params)
    .catch((error) => eventBus.chime(error.response.data.errors[0]));
  processing.value = false;
  if (!response?.data) {
    await load();
    return;
  }

  familySubsidyProgram.value = response.data;
  changed.value = false;
  eventBus.chime('Saved');
}
</script>
