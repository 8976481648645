<template>
  <v-alert
    v-if="provider"
    :icon="request.approved ? 'check' : 'add'"
    border="start"
    border-color="primary"
    color="white"
    type="info"
  >
    <div>
      <div class="fs-22 fw-500">
        {{ provider.name }}
      </div>
      <div class="fs-16 fw-500">
        {{ getProviderAddress() }}
      </div>
      <v-alert
        v-if="request.member_name || request.member_email"
        :icon="request.approved ? 'how_to_reg' : 'person_add'"
        color="white"
      >
        <div>
          <div
            v-if="request.member_name"
            class="fs-16 fw-500"
          >
            {{ request.member_name }}
          </div>
          <div class="fs-16 fw-500">
            {{ request.member_email }}
          </div>
        </div>
      </v-alert>
      <v-alert
        v-if="request.request_reason"
        class="py-1"
        color="surface-light"
        icon="info"
      >
        <div>
          <p>{{ request.request_reason }}</p>
        </div>
      </v-alert>
      <div v-if="request.approved">
        <span class="me-1"> Approved by {{ request.approver_name }} on </span>
        <LongDateTime :date="request.approved_at" />
      </div>
    </div>
    <template #append>
      <template v-if="!request.approved">
        <v-btn
          @click="approveRequest()"
          :loading="processing"
          color="green"
        >
          {{ $t('Approve') }}
        </v-btn>
        <v-btn
          @click="denyRequest()"
          :loading="processing"
          class="ms-3"
          color="red"
        >
          {{ $t('Deny') }}
        </v-btn>
      </template>
    </template>
  </v-alert>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import { formatAddress } from '@/shared/services/address';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import useEventBus from '@/shared/composables/useEventBus';

const CONFIRM_TRANSFER_TEXT =
  'Are you sure you want to approve the transfer of the provider to this business? ' +
  'All staff members associated to the prior business will lose access, and staff members of the new ' +
  'business can gain access to all child information associated with this provider.';

const eventBus = useEventBus();

const props = defineProps({
  request: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['approved', 'destroy']);

const processing = ref(false);
const provider = ref(null);

onMounted(() => {
  load();
});

function approveRequest() {
  // eslint-disable-next-line no-alert
  if (confirm(CONFIRM_TRANSFER_TEXT)) {
    processing.value = true;
    api.organization.ownership_request.update(
      props.request.id,
      { approved: true },
      () => {
        processing.value = false;
        emit('approved');
      },
      (err) => {
        eventBus.chime(err.response.data.errors[0]);
      },
    );
  }
}

function denyRequest() {
  // eslint-disable-next-line no-alert
  if (confirm('Are you sure you want to deny this request?')) {
    processing.value = true;
    api.organization.ownership_request.destroy(props.request.id, () => {
      emit('destroy');
      eventBus.chime('Request denied');
    });
  }
}

function load() {
  api.organization.provider.get(props.request.provider_id, (resp) => {
    provider.value = resp.data;
  });
}

function getProviderAddress() {
  if (!provider.value) return null;
  return formatAddress(provider.value.address1, provider.value.city);
}
</script>
