<template>
  <div>
    <ResourceDialog
      @save="$emit('save', $event)"
      ref="editorDialog"
      :max-width="600"
      :processing="processing"
      title="More filters"
    >
      <template #form="{ localValue }">
        <v-row dense>
          <v-col>
            <DataItem
              v-for="(filter, index) in localValue"
              @remove="localValue.splice(index, 1)"
              :key="index"
              :header="getAliasNameForColumn(filter.key)"
              :name="getHeaderForFilter(filter)"
              block
            />

            <p
              v-if="localValue.length == 0"
              v-t="'No filters have been added yet.'"
              class="fs-14"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col class="d-flex">
            <v-btn
              @click="$refs.addDialog.open()"
              color="primary"
              variant="flat"
            >
              <span v-t="'Add'" />
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @save="handleAdd"
      ref="addDialog"
      title="Add filter"
      close-on-save
    >
      <template #form="{ localValue }">
        <LabeledSimpleSelect
          v-model="localValue.key"
          :item-title="(item) => `${item.data_type} - ${item.alias_name}`"
          :items="columns"
          item-value="key"
          message="Column"
        />
        <LabeledSimpleSelect
          v-model="localValue.operator"
          :items="operators"
          item-title="text"
          message="Operator"
          return-object
        />
        <template v-if="localValue.operator?.compareable">
          <LabeledTextfield
            v-model="localValue.value"
            message="Value"
          />
        </template>
      </template>
    </ResourceDialog>
  </div>
</template>

<script setup>
import DataItem from '@/shared/components/form/DataItem.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import Operators from '@/specialist/services/report-comparators';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

defineEmits(['save']);

const operators = ref(Object.values(Operators));
const addDialog = ref(null);
const editorDialog = ref(null);

const props = defineProps({
  columns: {
    type: Array,
    default: () => [],
  },
  processing: Boolean,
});

defineExpose({ open, close });

function getAliasNameForColumn(key) {
  return props.columns.find((column) => column.key === key)?.alias_name;
}

function getHeaderForFilter(filter) {
  return [Operators[filter.operator].text, filter.value].join(' - ');
}

function handleAdd(newVal) {
  const existingFilters = editorDialog.value.getLocalValue();
  const newFilter = { key: newVal.key, operator: newVal.operator.value, value: newVal.value };

  editorDialog.value.setLocalValue(existingFilters.concat([newFilter]));
}

function open(existingValues) {
  editorDialog.value.open(existingValues);
}

function close() {
  editorDialog.value.close();
}
</script>
