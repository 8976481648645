<template>
  <v-container
    v-if="collection"
    class="mxw-1200 pa-6"
  >
    <TitleBar
      @create="$refs.newDialog.open({})"
      class="mb-4"
      name="Terms agreements"
      createable
    />

    <v-card
      v-for="agreement in collection"
      :key="agreement.id"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <v-col>
            <router-link
              :to="{ name: 'TermsAgreementShow', params: { termsAgreementId: agreement.id } }"
            >
              {{ agreement.name }}
            </router-link>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <NullState
      v-if="collection.length == 0"
      @new="$refs.newDialog.open({})"
      new-button-title="New agreement"
    />

    <ResourceDialog
      @save="create"
      ref="newDialog"
      :fields="[{ text: 'Name', value: 'name' }]"
      save-button-text="Save"
      title="New terms agreement"
      close-on-save
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import NullState from '@/shared/components/NullState.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import { useRouter } from 'vue-router';

const collection = ref(null);
const router = useRouter();

onMounted(async () => {
  await load();
});

function create(newVal) {
  Api.organization.termsAgreement.create(newVal, (resp) => {
    router.push({
      name: 'TermsAgreementShow',
      params: { termsAgreementId: resp.data.id },
    });
  });
}

async function load() {
  const response = await Api.organization.termsAgreement.index();
  collection.value = response.data;
}
</script>
