<template>
  <v-container
    v-if="siteTemplates"
    class="mxw-1200 pa-6"
  >
    <TitleBar
      @create="$router.push({ name: 'SiteTemplateNew' })"
      create-text="New template"
      name="Templates"
      createable
    />

    <NullState
      v-if="siteTemplates.length === 0"
      title="No site templates found"
    />

    <v-row
      v-for="(site_template, index) in siteTemplates"
      :key="index"
    >
      <v-col>
        <SiteTemplatePreview :site-template="site_template" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import Api from '@/admin/services/bright_finder';
import NullState from '@/shared/components/NullState.vue';
import SiteTemplatePreview from '@/admin/components/site-templates/SiteTemplatePreview.vue';
import TitleBar from '@/shared/components/TitleBar.vue';

const siteTemplates = ref(null);

async function load() {
  const response = await Api.organization.siteTemplate.index();
  siteTemplates.value = response?.data;
}

onMounted(load);
</script>
