<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      @create="newTaskDialog.open()"
      create-text="New task"
      title="Tasks"
      createable
    />

    <v-toolbar class="px-3">
      <FilterMenu
        classes="d-none d-md-inline-flex"
        tid="task_status_filter"
        title="Status"
        paddingless
        tile
      >
        <template #card>
          <v-list
            v-model:selected="status"
            @update:selected="reload"
          >
            <v-list-item
              title="Open"
              value="open"
            >
              <template #prepend="{ isSelected }">
                <v-radio
                  :model-value="isSelected"
                  color="primary"
                  tabindex="-1"
                />
              </template>
            </v-list-item>

            <v-list-item
              title="Complete"
              value="complete"
            >
              <template #prepend="{ isSelected }">
                <v-radio
                  :model-value="isSelected"
                  color="primary"
                  tabindex="-1"
                />
              </template>
            </v-list-item>
          </v-list>
        </template>
      </FilterMenu>

      <FilterMenu
        classes="d-none d-md-inline-flex"
        tid="task_type_filter"
        title="Name"
      >
        <template #card>
          <v-autocomplete
            v-model="taskTypeFilter"
            @update:model-value="reload()"
            :aria-label="$t('Filter by name (task type)')"
            :disabled="processing"
            :items="taskTypeFilterItems"
            :menu="true"
            :placeholder="$t('Filter by name (task type)')"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="search"
            variant="filled"
            autofocus
            chips
            clearable
            multiple
          />
        </template>
      </FilterMenu>

      <FilterMenu
        classes="d-none d-md-inline-flex"
        tid="task_assignment_filter"
        title="Assigned"
      >
        <template #card>
          <v-autocomplete
            v-model="specialistFilter"
            v-model:search="specialistQuery"
            @update:model-value="reload()"
            :aria-label="$t('Filter by assigned')"
            :disabled="processing"
            :items="specialists"
            :menu="true"
            :placeholder="$t('Filter by assigned')"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="search"
            variant="filled"
            autofocus
            chips
            clearable
            multiple
          />
        </template>
      </FilterMenu>

      <FilterMenu
        classes="d-none d-md-inline-flex"
        tid="task_date_filter"
        title="Date"
        paddingless
        tile
      >
        <template #card>
          <v-list
            v-model:selected="sortOrder"
            @update:selected="reload()"
          >
            <v-list-item
              title="Newest created first"
              value="desc"
            >
              <template #prepend="{ isSelected }">
                <v-radio
                  :model-value="isSelected"
                  color="primary"
                  tabindex="-1"
                />
              </template>
            </v-list-item>

            <v-list-item
              title="Oldest created first"
              value="asc"
            >
              <template #prepend="{ isSelected }">
                <v-radio
                  :model-value="isSelected"
                  color="primary"
                  tabindex="-1"
                />
              </template>
            </v-list-item>
          </v-list>
        </template>
      </FilterMenu>

      <v-spacer />

      <v-btn
        v-show="selected.length > 0"
        @click="assignDialogIsVisible = true"
        color="primary"
        variant="outlined"
      >
        {{ $t('Assign') }}
      </v-btn>

      <v-btn
        v-show="selected.length > 0 && status == 'open'"
        @click="markSelectedComplete()"
        class="ms-2"
        color="primary"
        variant="outlined"
      >
        {{ $t('Mark complete') }}
      </v-btn>

      <v-btn
        v-show="selected.length > 0 && status == 'complete'"
        @click="markSelectedIncomplete()"
        class="ms-2"
        color="primary"
        variant="flat"
      >
        {{ $t('Mark as incomplete') }}
      </v-btn>
    </v-toolbar>

    <!-- <v-tabs
      v-model="tab"
      @update:model-value="tabChange"
      bg-color="surface"
      grow
    >
      <v-tab value="list">
        {{ $t('List') }}
      </v-tab>

      <v-tab value="calendar">
        {{ $t('Calendar') }}
      </v-tab>
    </v-tabs> -->

    <v-window
      v-model="tab"
      :mandatory="false"
      class="border-t"
    >
      <v-window-item value="list">
        <TaskTable
          @open="open"
          @page-change="page = $event"
          @selected="selected = $event"
          :items="tasks"
          :items-per-page="itemsPerPage"
          :page="page"
          :pages="pages"
          :processing="processing"
          :selected="selected"
          :server-items-length="serverItemsLength"
        />
      </v-window-item>

      <v-window-item value="calendar">
        <TaskCalendar
          @open="open"
          :selected="selected"
          :tasks="tasks"
        />
      </v-window-item>
    </v-window>

    <TaskShow
      @save="load"
      ref="taskNavigationDrawer"
      :processing="processing"
      :specialists="specialists"
    />

    <NewTaskDialog
      @change="load"
      ref="newTaskDialog"
      :minimum-due-date="minimumDueDate"
    />

    <v-dialog
      v-model="assignDialogIsVisible"
      max-width="768"
    >
      <v-card
        class="pt-3"
        border
        tile
      >
        <v-card-title>
          {{ $t('Assign task') }}
        </v-card-title>

        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-select
                v-model="assignTo"
                :items="specialists"
                item-title="name"
                item-value="id"
                placeholder="Assign to"
                variant="filled"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="assignDialogIsVisible = false">
            {{ $t('Cancel') }}
          </v-btn>

          <v-spacer />

          <v-btn
            @click="assignTasks()"
            :disabled="!assignTo"
            color="primary"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import NewTaskDialog from '@/specialist/components/tasks/NewTaskDialog.vue';
import TaskCalendar from '@/specialist/components/tasks/TaskCalendar.vue';
import TaskShow from '@/specialist/components/tasks/TaskShow.vue';
import TaskTable from '@/specialist/components/tasks/TaskTable.vue';
// eslint-disable-next-line no-unused-vars
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
// const router = useRouter();

const assignDialogIsVisible = ref(false);
const assignTo = ref(null);
const itemsPerPage = ref(25);
const page = ref(1);
const pages = ref(1);
const processing = ref(true);
const newTaskDialog = ref(null);
const selected = ref([]);
const serverItemsLength = ref(0);
const sortOrder = ref('desc');
const specialistFilter = ref([]);
const specialistQuery = ref(null);
const specialists = ref([]);
const status = ref('open');
const tab = ref('list');
const taskNavigationDrawer = ref(null);
const tasks = ref([]);
const taskTypeFilter = ref([]);
const taskTypeFilterItems = ref([]);

watch(page, load);

watch(specialistQuery, async (newValue) => {
  if (!newValue || newValue === '') {
    specialists.value = [];
  } else {
    await loadSpecialists();
  }
});

watch(status, async (newValue) => {
  if (!newValue || newValue === '') status.value = 'open';
});

async function assignTasks() {
  await Promise.all(
    selected.value.map(async (taskId) => {
      await Api.organization.task.update(taskId, { owner_id: assignTo.value });
    }),
  );

  assignDialogIsVisible.value = false;
  await reload();
}

async function load() {
  processing.value = true;

  if (route.query?.tab) tab.value = route.query.tab;

  const filters = {};
  filters.status = status.value;
  filters.page = page.value;

  if (taskTypeFilter.value) filters.task_type = taskTypeFilter.value;
  if (specialistFilter.value) filters.assigned = specialistFilter.value;
  if (sortOrder.value) filters.order = sortOrder.value;

  const response = await Api.organization.task.index(filters);

  tasks.value = response?.data;
  pages.value = parseInt(response.headers['x-page-count'] || 0, 10);
  serverItemsLength.value = parseInt(response.headers['x-total-count'] || 0, 10);
  itemsPerPage.value = parseInt(response.headers['x-page-size'] || 0, 10);

  processing.value = false;

  await loadTaskTypes();
  await loadSpecialists();
}

async function loadSpecialists() {
  Api.organization.member.index(
    { is_specialist: true, query: specialistQuery.value },
    (response) => {
      specialists.value = response.data;
    },
  );
}

async function loadTaskTypes() {
  const response = await Api.organization.taskType.index();
  taskTypeFilterItems.value = response?.data;
}

async function markComplete(taskId) {
  await Api.organization.task.update(taskId, { status: 'complete' });
}

async function markIncomplete(taskId) {
  await Api.organization.task.update(taskId, { status: 'open' });
}

async function markSelectedComplete() {
  await Promise.all(
    selected.value.map(async (taskId) => {
      await markComplete(taskId);
    }),
  );

  await reload();
}

async function markSelectedIncomplete() {
  await Promise.all(
    selected.value.map(async (taskId) => {
      await markIncomplete(taskId);
    }),
  );

  await reload();
}

async function open(task) {
  processing.value = true;
  await taskNavigationDrawer.value.open(task);
  processing.value = false;
}

async function reload() {
  page.value = 1;

  selected.value = [];
  await load();
}

// function tabChange(tab) {
//   router.push({ query: { tab } });
// }

onMounted(load);
</script>
