<template>
  <div>
    <v-row class="d-flex align-center mb-2">
      <v-spacer />

      <v-col
        :class="$vuetify.display.smAndUp ? 'ta-right' : ''"
        cols="12"
        sm="3"
      >
        <v-btn
          v-if="messageTemplates.length > 0"
          @click="$refs.newMessageTemplateDialog.open({})"
          color="primary"
          data-testid="message-template-new-button"
          prepend-icon="add"
        >
          {{ $t('New message template') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-card
      v-for="message in messageTemplates"
      :key="message.id"
      class="mb-4"
      border
      tile
    >
      <v-card-text>
        <LabeledTextfield
          v-model="message.name"
          @change="$emit('change', true)"
          message="Name"
        />

        <LabeledTextarea
          v-model="message.content"
          @change="$emit('change', true)"
          message="Template"
        />
      </v-card-text>

      <v-card-title class="fs-12 fw-400">
        {{ $t('ID') + ': ' + message.id }}
      </v-card-title>
    </v-card>

    <NullState
      v-if="messageTemplates.length == 0"
      @new="$refs.newMessageTemplateDialog.open({})"
      data-testid="new-message-template-button"
      new-button-title="New message template"
    />

    <ResourceDialog
      @save="addMessageTemplate"
      ref="newMessageTemplateDialog"
      :fields="messageTemplateFields"
      :processing="processing"
      title="Create new message template"
    />
  </div>
</template>
<script setup>
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { v4 as uuidv4 } from 'uuid';

defineProps({
  messageTemplates: {
    type: Array,
    default: () => [],
  },
  processing: Boolean,
});

const emit = defineEmits(['change', 'newMessageTemplate']);

const messageTemplateFields = ref([{ text: 'Name', value: 'name' }]);
const newMessageTemplateDialog = ref(null);

function addMessageTemplate(messageTemplate) {
  const newMessageTemplate = {
    ...messageTemplate,
    id: uuidv4(),
  };

  emit('newMessageTemplate', newMessageTemplate);
  newMessageTemplateDialog.value.close();
}
</script>
