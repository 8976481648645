<template>
  <router-view
    @refresh="load()"
    :site-template="siteTemplate"
  />
</template>

<script setup>
import Api from '@/admin/services/bright_finder';
import { onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const siteTemplate = ref(null);

watch(() => route.params, load);

onBeforeUnmount(() => {
  store.commit('setNavigation', null);
});

onMounted(load);

function load() {
  Api.organization.siteTemplate.get(route.params.siteTemplateId, (resp) => {
    siteTemplate.value = resp.data;
    setNavigation();
  });
}

function pageNavigationItems() {
  const pages = siteTemplate.value.page_templates.map((page) => ({
    title: page.name,
    route: 'PageTemplateShow',
    params: { siteTemplateId: page.site_template_id, pageTemplateId: page.id },
  }));

  pages.push({
    title: 'Add Page',
    icon: 'add',
    route: 'PageTemplateNew',
    params: {
      siteTemplateId: siteTemplate.value.id,
    },
  });

  return pages;
}

function setNavigation() {
  store.commit('setNavigation', [
    {
      icon: 'chevron_left',
      type: 'back',
      title: 'Back',
      route: 'SiteTemplateIndex',
    },
    {
      type: 'section',
      items: [
        {
          title: 'Settings',
          route: 'SiteTemplateShow',
          params: {
            siteTemplateId: siteTemplate.value.id,
          },
        },
      ],
    },
    {
      type: 'section',
      header: 'Pages',
      items: pageNavigationItems(),
    },
  ]);
}
</script>
