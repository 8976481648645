<template>
  <v-container class="mxw-1200">
    <router-view />
  </v-container>
</template>

<script setup>
import links from '@/admin/services/family-subsidy-program-links';
import store from '@/specialist/store';
import { useRoute } from 'vue-router';

const route = useRoute();

onBeforeUnmount(() => {
  store.commit('setNavigation', null);
});

onMounted(() => {
  load();
});

function load() {
  store.commit('setNavigation', null);
  store.commit('setNavigation', [
    {
      icon: 'chevron_left',
      type: 'back',
      title: 'Back',
      route: 'FamilySubsidyProgramIndex',
    },
    {
      type: 'section',
      items: links.map((link) => ({ ...link, params: { id: route.params.id } })),
    },
  ]);
}
</script>
