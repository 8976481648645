<template>
  <v-col class="d-flex justify-end align-center">
    <v-icon
      :color="iconColor"
      size="large"
      start
    >
      {{ icon }}
    </v-icon>

    <v-btn
      @click="$emit('toggle')"
      :data-cy="dataCy"
      :loading="processing"
      :outlined="outlined"
      :variant="variant"
      color="primary"
      block
    >
      <span>{{ $t(buttonText) }}</span>
    </v-btn>
  </v-col>
</template>

<script setup>
const props = defineProps({
  enabled: Boolean,
  enabledText: {
    type: String,
    default: null,
  },
  dataCy: {
    type: String,
    default: null,
  },
  disabledText: {
    type: String,
    default: null,
  },
  processing: Boolean,
});

defineEmits(['toggle']);

const buttonText = computed(() => {
  return props.enabled ? props.enabledText : props.disabledText;
});

const icon = computed(() => {
  return props.enabled ? 'verified' : 'hide_source';
});

const iconColor = computed(() => {
  return props.enabled ? 'green' : 'gray';
});

const outlined = computed(() => {
  return props.enabled;
});

const variant = computed(() => {
  return props.enabled ? 'flat' : 'outlined';
});
</script>
