/**
 * Use assets service.
 *
 * Swap in replacement for `this.$a` in Vue2 components when migrating to Vue3 Composition API.
 *
 * @returns {{
 * data: (key: string) => string,
 * seek: (keyAry: string[], obj: any) => any,
 * url: (key: string) => string,
 * }}
 */
export default function useAssets() {
  const assets = inject('assets');

  if (!assets) {
    throw new Error(
      'useAssets() must be used inside a component with an assets provider. Something may have gone wrong with main.js.',
    );
  }

  return assets;
}
